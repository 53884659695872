/*eslint-disable */
import React from 'react';
import BusinessFormBaseLayout from '@/views/GrantForm/BusinessFormBaseLayout';
import { useGetProjectInformation3 } from '@/views/GrantForm/services/BusinessPlan/ProjectInformation.service';
import ControlledRadioButtonGroup from '@/components/forms/FormInput/ControlledRadioButton';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import { Row, Col } from 'react-bootstrap';
import { OptionType } from '@/utils/constants/globals.interfaces';
import { IBusinessPlanFormStage } from '@/views/GrantForm/GrantForm.interfaces';
import { validateInputLength } from '@/views/GrantForm/services/GrantFormBaseLayout.service';

const ProjectInformation3 = ({
  isPreview,
  isPrint,
}: IBusinessPlanFormStage) => {
  const { control, watch, handleSubmit, onSubmit, userData, isLoading } =
    useGetProjectInformation3();

  const has_business_benefitted = watch(
    'has_business_benefitted_from_any_grant'
  );

  return (
    <BusinessFormBaseLayout
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      page={3}
      title="Project Information"
      isLoading={isLoading}
      formStage={'project_information_3'}
      preview={isPreview}
      print={isPrint}
    >
      <Row>
        <Col md={12} xs={12}>
          <ControlledRadioButtonGroup
            name={'has_business_benefited_from_any_grant'}
            options={
              [
                { label: 'Yes', value: 'Y' },
                { label: 'No', value: 'N' },
              ] as OptionType<string, string>[]
            }
            title={'Has your business benefitted from any grant programme?'}
            control={control}
            defaultValue={
              userData?.data?.profile?.grant_information?.applicant_information
                ?.has_business_benefited_from_any_grant == true
                ? 'Y'
                : 'N'
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information
                  ?.applicant_information
                  ?.has_business_benefited_from_any_grant,
            }}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information
                    ?.applicant_information
                    ?.has_business_benefited_from_any_grant == true
                  ? 'Y'
                  : 'N'
                : null
            }
            isDisabled={isPreview === true}
          />
        </Col>
      </Row>
      {watch('has_business_benefited_from_any_grant') == 'Y' && (
        <Row>
          <Col md={12} xs={12}>
            <ControlledTextInput
              name={'grant_details'}
              title={
                'If ‘Yes’, state name of the grant awarding organization, amount received, purpose of grant and year of grant receipt. (Not more than 20 words)'
              }
              control={control}
              as={'textarea'}
              placeholder="Your response must not be more than 20 words"
              defaultValue={
                userData?.data?.profile?.grant_information
                  ?.applicant_information?.grant_details
              }
              rules={{
                required:
                  !userData?.data?.profile?.grant_information
                    ?.applicant_information?.grant_details,

                validate: {
                  valid: (v) => {
                    return validateInputLength(v, 20);
                  },
                },
              }}
              value={
                isPreview
                  ? userData?.data?.profile?.grant_information
                      ?.applicant_information?.grant_details
                  : null
              }
              isDisabled={isPreview === true}
              isPrint={isPrint}
            />
          </Col>
        </Row>
      )}
    </BusinessFormBaseLayout>
  );
};

export default ProjectInformation3;
