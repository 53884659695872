/*eslint-disable*/
import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { IGrantFormBaseLayout } from './GrantForm.interfaces';
import OnboardProgress from './components/OnboardingProgress';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import MainButton from '@/components/buttons/Buttons';
import { TextField } from '@/components/forms';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import FormLoader from './components/FormLoader';
import { getPreviousRoute } from './services/GrantFormBaseLayout.service';

const CustomTextField = styled(TextField)`
  margin-bottom: 70px !important;
`;

const GrantFormBaseLayout = ({
  onSubmit,
  handleSubmit,
  isLoading,
  stage,
  preview,
  print,
  ...props
}: IGrantFormBaseLayout) => {
  const navigate = useNavigate();
  return (
    <>
      {print === true && (
        <TextField
          text={props.title}
          className={'fs-5 fw-bold mb-5 text-center mt-5'}
        />
      )}
      {preview === true && <>{props.children}</>}
      {!preview && (
        <Container style={{ padding: '20px' }}>
          <ToastContainer />
          <Row style={{ marginTop: '20px', marginBottom: '40px' }}>
            <Col>
              <div className="px-4 float-end fw-bold">
                <Link to="/dashboard" className="text-decoration-none">
                  <MainButton
                    text={'Save and Continue Later'}
                    variant="outline-primary"
                    size="md"
                  />
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={3}>
              <OnboardProgress page={props.page} />
            </Col>

            <Col md={9} lg={9} xs={12} sm={12}>
              <form onSubmit={handleSubmit?.(onSubmit)}>
                <TextField
                  text={'Application Form for Start-up Grants Applicants'}
                  className={'fs-5 fw-bold mb-5 text-center'}
                />
                <CustomTextField
                  text={props?.title || ''}
                  className={'fs-5 fw-bold mb-5 text-center'}
                  fontSize={12}
                />
                {isLoading && <FormLoader />}
                {!isLoading && <>{props.children}</>}
                {props.page !== 9 && (
                  <Row>
                    <div className="d-flex  align-items-center h-100">
                      <Col md={12}>
                        <Row className="mt-5">
                          <Col md={4}>
                            <MainButton
                              text={'Previous'}
                              type={'button'}
                              variant="outline-secondary"
                              size="lg"
                              className="fs-6"
                              onClick={() => {
                                if (
                                  getPreviousRoute({ stage: stage }) ==
                                  'dashboard'
                                ) {
                                  navigate('/dashboard');
                                } else {
                                  console.log(
                                    getPreviousRoute({ stage: stage })
                                  );
                                  navigate(
                                    `/grants/${getPreviousRoute({
                                      stage: stage,
                                    })}`
                                  );
                                }
                              }}
                            />
                          </Col>
                          <Col md={{ span: 4, offset: 4 }}>
                            <MainButton
                              text={'Next'}
                              type={onSubmit ? 'submit' : 'button'}
                              variant="primary"
                              size="lg"
                              className="fs-6"
                              isLoading={isLoading}
                              isDisabled={props?.isDisabled || false}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </div>
                  </Row>
                )}
              </form>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default GrantFormBaseLayout;
