/*eslint-disable*/
import React, { useRef } from 'react';
import { OptionType } from '@/utils/constants/globals.interfaces';
import html2pdf from 'html2pdf.js';

export const formStages: OptionType<string, number>[] = [
  {
    label: 'Personal Information',
    value: 0,
  },
  {
    label: 'Personal Information II',
    value: 1,
  },
  {
    label: 'Business Form',
    value: 2,
  },
  {
    label: 'Business Form II',
    value: 3,
  },
  {
    label: 'Product Information',
    value: 4,
  },
  {
    label: 'Product Information II',
    value: 5,
  },
  {
    label: 'Technical Capacity',
    value: 6,
  },
  {
    label: 'Product Marketing',
    value: 7,
  },
  {
    label: 'Revenue Generation',
    value: 8,
  },
  {
    label: 'Revenue Generation II',
    value: 9,
  },
  {
    label: 'Funding Requirements',
    value: 10,
  },
  {
    label: 'Impact',
    value: 11,
  },
  {
    label: 'Impact II',
    value: 12,
  },
];
export const useGetPreviewGrantForm = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const [printState, setPrintState] =
    React.useState<'print' | 'preview'>('preview');
  const personalInformationRef = useRef(null);
  const personalInformation2Ref = useRef(null);
  const businessFormRef = useRef(null);
  const productInformationRef = useRef(null);
  const productInformation2Ref = useRef(null);
  const technicalCapacityRef = useRef(null);
  const productMarketingRef = useRef(null);
  const revenueGenerationRef = useRef(null);
  const revenueGeneration2Ref = useRef(null);
  const fundingRequirementsRef = useRef(null);
  const impactRef = useRef(null);
  const impact2Ref = useRef(null);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const printForm = () => {
    const combinedForm2 = document.createElement('div');
    combinedForm2.style.padding = '20px';
    combinedForm2.appendChild(personalInformationRef.current.cloneNode(true));
    combinedForm2.appendChild(personalInformation2Ref.current.cloneNode(true));
    combinedForm2.appendChild(businessFormRef.current.cloneNode(true));
    combinedForm2.appendChild(productInformationRef.current.cloneNode(true));
    combinedForm2.appendChild(productInformation2Ref.current.cloneNode(true));
    combinedForm2.appendChild(technicalCapacityRef.current.cloneNode(true));
    combinedForm2.appendChild(productMarketingRef.current.cloneNode(true));
    combinedForm2.appendChild(revenueGenerationRef.current.cloneNode(true));
    combinedForm2.appendChild(revenueGeneration2Ref.current.cloneNode(true));
    combinedForm2.appendChild(fundingRequirementsRef.current.cloneNode(true));
    combinedForm2.appendChild(impactRef.current.cloneNode(true));
    combinedForm2.appendChild(impact2Ref.current.cloneNode(true));
    const options = {
      filename: 'form.pdf', // Set the desired filename
      image: { type: 'jpeg', quality: 0.98 }, // Optional: Set image options
      html2canvas: { scale: 2 }, // Optional: Set html2canvas options
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }, // Optional: Set jsPDF options
    };
    html2pdf()?.set(options).from(combinedForm2).save();
    setPrintState('preview');
  };

  React.useEffect(() => {
    if (printState === 'print') {
      printForm();
    }
  }, [printState]);

  return {
    tabValue,
    printState,
    formStages,
    personalInformationRef,
    personalInformation2Ref,
    businessFormRef,
    productInformationRef,
    productInformation2Ref,
    technicalCapacityRef,
    productMarketingRef,
    revenueGenerationRef,
    revenueGeneration2Ref,
    fundingRequirementsRef,
    impactRef,
    impact2Ref,
    setPrintState,
    setTabValue,
    handleTabChange,
    printForm,
  };
};
