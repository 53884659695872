import constant from 'src/utils/constants/constants';
import { toast } from 'react-toastify';
import {
  handleErrorMessage,
  setAccessToken,
} from 'src/utils/helpers/functions';
import axios from 'axios';

const errorAlert = (msg) => toast.error(msg);
// const successAlert = (msg) => toast.success(msg)

export const updateUserProfileold =
  (userId, data, redirectUrl) => async (dispatch) => {
    setAccessToken();
    dispatch({ type: 'USER_PROCESS' });
    await axios
      .patch(`${constant.BASE_API}/client/auth/profile/${userId}`, data)
      .then(function (response) {
        window.localStorage.setItem('user', JSON.stringify(response?.data));
        dispatch({
          type: 'UPDATE_USER_SUCCESS',
          payload: response?.data,
          success: true,
        });
      })
      .then(function () {
        if (redirectUrl) {
          window.location.replace(redirectUrl);
        } else {
          return;
        }
      })
      .catch(function (error) {
        let errorMessage = handleErrorMessage(error?.response);
        errorAlert(errorMessage);
        dispatch({ type: 'UPDATE_USER_FAILED', error: errorMessage });
      });
  };

export function getUserProfile() {
  setAccessToken();
  return axios.get(`${constant.BASE_API}/client/auth/profile/self`);
}

export function getCertificates() {
  setAccessToken();
  return axios.get(`${constant.BASE_API}/common/files`);
}

export function updateUserProfile(data) {
  setAccessToken();
  const { id: userId, data: requestData } = data;
  return axios.patch(`${constant.BASE_API}/client/auth/profile/${userId}`, {
    profile: requestData,
    business_profile: { other_industry: requestData?.other_industry },
  });
}

export function updateFullUserProfile(data) {
  setAccessToken();
  const { id: userId } = data;
  return axios.patch(
    `${constant.BASE_API}/client/auth/profile/${userId}`,
    data
  );
}

export function uploadCertificate(data) {
  setAccessToken();
  return axios.post(`${constant.BASE_API}/common/files`, data);
}

export function updateUserProfileWithouthCallBack(data) {
  setAccessToken();
  const { id: userId, updatedUser } = data;

  axios.patch(
    `${constant.BASE_API}/client/auth/profile/${userId}`,
    updatedUser
  );
}

export function getUserGrantInformation() {
  setAccessToken();
  return axios.get(`${constant.BASE_API}/client/grants/`);
}

export function updateGrantInformation(data) {
  setAccessToken();
  return axios.patch(
    `${constant.BASE_API}/client/grants/${data?.id}`,
    data?.data
  );
}

export function createGrantBusinessExpenditure(data) {
  setAccessToken();
  return axios.post(
    `${constant.BASE_API}/client/grants/business-expenditure`,
    data
  );
}

export function getGrantBusinessExpenditures() {
  setAccessToken();
  return axios.get(`${constant.BASE_API}/client/grants/business-expenditure`);
}

export function deleteGrantBusinessExpenditure(id) {
  setAccessToken();
  return axios.delete(
    `${constant.BASE_API}/client/grants/business-expenditure/${id}`
  );
}

export function updateGrantBusinessExpenditure(id, data) {
  setAccessToken();
  return axios.patch(
    `${constant.BASE_API}/client/grants/business-expenditure/${id}`,
    data
  );
}
