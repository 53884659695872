import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";


import OnboardProgress from "@components/layout/navigation/OnboardProgressNav";
import { ToastContainer } from "react-toastify";
import ApplicationTermsForm from "@components/forms/Auth/ApplicationTermsForm";
import OnboardTopNav from "@components/layout/OnboardTopNav";



const ApplicationTerms = () =>{    
    return(
        <Fragment>
            <ToastContainer/>
            <OnboardTopNav/>
            <Row>
            <OnboardProgress page={8}/>
            <Col md={9} sm={12}>
                
                <ApplicationTermsForm/>  
                    
            </Col>
        </Row>
        </Fragment>
    )
}


export default ApplicationTerms