/*eslint-disable*/
import { defaultBlue } from '@/utils/constants/colors.constants';
import { styled, Typography, Button } from '@mui/material';
export const AccordionActionButton = styled(Button)(({ theme }) => ({
  textTransform: 'initial',
}));

export const AccordionActionText = styled(Typography)(({ theme }) => ({
  marginLeft: '10px',
  fontFamily: 'inherit',
  color: defaultBlue,
}));

export const ClearButton = styled(Button)({
  color: 'red',
  width: '100%',
  border: '2px dashed red',
  padding: '14px',
});

export const ClearButtonText = styled(Typography)(({ theme }) => ({
  fontFamily: 'inherit',
  color: 'red',
  // marginLeft: '10px',
  textTransform: 'initial',
  fontSize: '14px',
}));
