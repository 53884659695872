import constant from "../constants/constants";
import axios from "axios";
import { toast } from "react-toastify";


export function handleErrorMessage(response) {
    try {
        switch(response?.status){
            case 400:
                var errorMessage = Object.values(response?.data)[0].constructor === Array ? Object.values(response?.data)[0][0] :Object.values(Object.values(response?.data)[0])[0][0]
                return errorMessage || Object.values(Object.values(response?.data)[0])[0][0]
            case 401:
                localStorage.clear();
                window.location.replace('/login');
                return response?.data?.detail
            case 404:
                return response?.data?.detail
            default:
                return 'Unable to reach GEA servers, Please check your network connection';
        }
    }catch(e) {
        return 'Unable to reach GEA servers, Please check your network connection';
    }
    
}

export function setAccessToken() {
    const accessToken = localStorage.getItem('accessToken');
    if(accessToken){
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    return
}

export async function refreshToken() {
    const accessToken = localStorage.getItem('accessToken');
    if(accessToken){
        setAccessToken()
        await axios.get(`${constant.BASE_API}/client/auth/account/refresh`).then(function (response) {
            window.localStorage.setItem('accessToken', response.headers?.['set-auth-token']);
        })
        .catch(function (error) {
            handleErrorMessage(error.toJSON())
            return error
        });
    }
    return
}
export const pickFieldValues = (pickFields, object) =>{
    const picked = pickFields.reduce(function(o, k) { o[k] = object[k]; return o; }, {})
    return picked
}

export const getRegions = () =>{
    return axios.get(`${constant.BASE_API}/locations/regions`)
}
export const getDistricts = (region) =>{
    return axios.get(`${constant.BASE_API}/locations/districts?region=${region}`)
}


export const getIndustries = () =>{
    return axios.get(`${constant.BASE_API}/common/industries`)
}

export const getTrainingCategories = () =>{
    return axios.get(`${constant.BASE_API}/client/training/categories`)
}

export function maxDate(numOfYears) {
    var dtToday = new Date();
    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();
    var maxDate = year + "-" + month + "-" + day;
    if (numOfYears) {
      month = "12";
      day = "31";
      year = dtToday.getFullYear() - numOfYears;
      maxDate = year + "-" + month + "-" + day;
    }
    return maxDate;
  }
  
  
export function minDate(numOfYears) {
    var dtToday = new Date();
    var month = "01";
    var day = "01";
    var year = dtToday.getFullYear() - numOfYears;
    var maxDate = year + "-" + month + "-" + day;
    return maxDate;
  }
  

export function cleanArray(array){
    var filtered = array.filter(function(el) { return el; });
    return filtered
}


export function storeLocalStorage(title, data){
    window.localStorage.setItem(title, data);
    return
}

export function getLocalStorage(title){
    return window.localStorage.getItem(title);
}

export const messageAlert = (messageType, msg) => messageType==='success'?toast.success(msg):toast.error(msg);

export function getRegionOrDistrictObject(data){
    var newData = data
    if(typeof(newData) === "string"){
        newData = JSON.parse(data)
    }
    return { id: newData?.id, name: newData?.name }

}

export const handleLoginRedirect = (onboarding_data) => {

    const onboarding = onboarding_data;

      const allStages = [
        "personal_information",
        "education",
        "training",
        "accumen",
        "financial_literacy",
        "other_information",
        "application_terms",
      ];

      for (let stage of allStages) {
        const stageCompleted = onboarding[stage];

        if (!stageCompleted) {
          return `/onboarding/${stage}`;

        }
        
      }
      return `/onboarding/personal_information`;
}

export const checkIfBlank = (text) => {
    text = text?.trim();
    if (text.length == 0)
    {
        return constant.BLANK_SPACES;
    }
    return constant.ACCEPT_ALL;
}
export const checkIfBlankNumb = (text) => {
    text = text?.trim();
    if (text?.length == 0)
    {
        return constant.BLANK_SPACES;
    }
    return constant.ONLY_NUMBERS;
}

export const snakeToCamelCase = sentence => 
 !sentence ? null :
  sentence  
    .split('_')  
    .map((word) => {  
     
        return firstUppercase(word);  
    })  
    .join(' ');  
const firstUppercase = word => word &&  
    word?.charAt(0).toUpperCase() + word.slice(1); 




