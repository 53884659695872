// import axios from 'axios';
// import { toast } from 'react-toastify';

const INITIAL_STATE = {
    loading: false,
    training: [],
    error: null,
    success:null,
};

const Training = (state = INITIAL_STATE, action) => {
    const { type, payload, error, success} = action;
    switch (type) {
        case 'TRAINING_REQUEST':
            return { ...state, loading: true };
        case 'TRAINING_REQUEST_SUCCESS':
            return { training: payload, success:success, loading:false};
        case 'TRAINING_REQUEST_FAILED':
            return { ...state, error:error, loading:false};
        case 'USER_TRAINING_REQUEST_SUCCESS':
            return { training: payload, success:success, loading:false};
        case 'USER_TRAINING_REQUEST_FAILED':
            return { ...state, error:error, loading:false};
        case 'UPDATE_TRAINING_REQUEST_SUCCESS':
            return { training: payload, success:success, loading:false};
        case 'UPDATE_TRAINING_REQUEST_FAILED':
            return { ...state, error:error, loading:false};
        default:
            return state;
    }
};

export default Training;


