/*eslint-disable*/

import {
  IGrantUpdateParams,
  ITechnicalCapacityForm,
  IUserUpdateParams,
} from '../GrantForm.interfaces';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { OptionType } from '@/utils/constants/globals.interfaces';
import { logError, useGetUserObject } from './GrantFormBaseLayout.service';
import { IUserModel } from '@/utils/constants/userModel.interface';
import { AxiosResponse } from 'axios';
import { updateFullUserProfile, updateGrantInformation } from '@/api/User/User';

export const useGetTechnicalCapacity = () => {
  const { control, watch, formState, handleSubmit } =
    useForm<ITechnicalCapacityForm>();
  const navigate = useNavigate();
  const { userData, userError, userLoading } = useGetUserObject();
  let _userData = userData as AxiosResponse<Partial<IUserModel>>;
  let grantUpdateParams: IGrantUpdateParams = {
    id: undefined,
    data: undefined,
  };

  let userUpdateParams: IUserUpdateParams = {
    id: undefined,
    data: undefined,
  };
  const onSubmit: SubmitHandler<ITechnicalCapacityForm> = async (data) => {
    try {
      grantUpdateParams.id = _userData?.data?.profile?.grant_information?.id;
      grantUpdateParams.data = {
        ..._userData?.data?.profile?.grant_information,
        has_required_techinical_expertise:
          data.has_required_techinical_expertise == 'Y' ? true : false,
        has_capacity_to_provide_additional_resources:
          data.has_capacity_to_provide_additional_resources == 'Y'
            ? true
            : false,
        has_an_active_financial_management_process:
          data.has_an_active_financial_management_process == 'Y' ? true : false,
      };
      userUpdateParams.id = _userData?.data?.profile?.client;
      userUpdateParams.data = {
        grant_onboarding_data: {
          ..._userData?.data?.grant_onboarding_data,
          technical_capacity: true,
        },
      };
      userUpdateParams.data.id = _userData?.data?.profile?.client;
      await updateGrantInformation(grantUpdateParams);
      await updateFullUserProfile(userUpdateParams.data);
    } catch (e) {
      logError(e);
    }
    navigate('/grants/product_marketing');
  };

  return {
    control,
    watch,
    formState,
    handleSubmit,
    onSubmit,
    userData: _userData,
    isLoading: userLoading,
  };
};

export const hasExpertiseOptions: OptionType<string, string>[] = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];

export const hasCapacityOptions: OptionType<string, string>[] = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];

export const hasActiveFinancialProcessOptions: OptionType<string, string>[] = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];
