import React from "react";
import MainButton from "@components/buttons/Buttons";
import "./style.css";
import { Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import CheckIcon from "@components/buttons/CheckIcon";
import { TextField } from "..";
import { Required } from "@components/utils";

const GetStartedForm = () => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="mt-2 col-md-8">
        <TextField
          text={"Create Your Account"}
          className={"fs-4 fw-bold mb-3"}
        />
        <TextField
          text="You will need the following to complete your application:"
          className={"mb-4"}
        />
        <Stack gap={4} className="mb-5" >
          <div className="req-text">
            <CheckIcon /> Digital Address
          </div>
          <div className="req-text">
            <CheckIcon /> Valid National ID Card preferably “Ghana Card”
          </div>
          <div className="req-text">
            <CheckIcon /> Certificate of the most recent education attained
          </div >
          <div className="req-text">
            <CheckIcon /> Valid mobile number
          </div>
          <div className="req-text">
            <CheckIcon /> Note! All fields with{" "}
            <Required/> are required
          </div>
          <div style={{fontSize:'14px'}}>
            <b className="text-danger">Note!</b> You will receive an OTP to validate your mobile number. After succesfully validating your phone number, 
            you will receive a unique code for subsequent logins. To Login just provide your phone number and this unique code.
          </div>
        </Stack>
        <Link to="/signup" className="text-decoration-none">
          <MainButton text={"Get Started"} />
        </Link>
      </div>
    </div>
  );
};

export default GetStartedForm;
