/*eslint-disable*/
import React from 'react';
import { Fragment } from 'react';
import { Form } from 'react-bootstrap';
import { ITextInput } from '@utils/constants/globals.interfaces';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';

const ErrorMessageWrapper = styled.div`
  font-size: 15px;
  color: red;
  margin-top: 5px;
`;

const PrintPreviewComponent = styled.div`
border:1px solid gray;
width:100%;
padding:10px;
border-radius:8px;
margin-bottom:12px;
font-size:14px;
line-height:1.5;
word-break:break-word;`


const ControlledTextInput = (props: ITextInput) => {
  return (
    <Fragment>
      {props.title && (
        <Form.Label className="controlled-input-label">
          {props.title}{' '}
          {props.isRequired && <b className="text-danger fs-6 fw-bold">*</b>}
        </Form.Label>
      )}
      {(props.isPrint&& props.as == 'textarea') && <PrintPreviewComponent aria-disabled={'true'}>{props.defaultValue}</PrintPreviewComponent>}
      {!props.isPrint &&  <Controller
        {...props}
        name={props.name || ''}
        defaultValue={props.defaultValue}
        render={({
          field: { ref, name, onChange, value },
          fieldState: { error },
        }) => {
          return (
            <div
              className="controlled-input-wrapper"
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: props?.inputType ? 'unset' : '20px',
              }}
            >
              <Form.Control
                name={name}
                required={props?.isRequired}
                type={props?.type || 'text'}
                placeholder={props.placeholder}
                onChange={onChange}
                disabled={props.isDisabled}
                defaultValue={props.defaultValue}
                value={props.value || value}
                max={props?.maxDate}
                className={`form-text-input ${props?.error && 'is-invalid'}`}
                maxLength={props?.length || null}
                min={props?.min}
                style={{
                  border:
                    props?.hasBorder == false ? 'unset' : '1px solid #DFE2E5',
                }}
                as={props?.as || 'input'}
              />{props.footer}
              {error && (
                <ErrorMessageWrapper>
                  {error.message || ' *This field is required '}
                </ErrorMessageWrapper>
              )}
            </div>
          );
        }}
      />}
     
    </Fragment>
  );
};

export default ControlledTextInput;
