/*eslint-disable */
import React from 'react';
import { useGetSustainabilty } from '@/views/GrantForm/services/BusinessPlan/Sustainability.service';
import BusinessFormBaseLayout from '@/views/GrantForm/BusinessFormBaseLayout';
import { Row, Col } from 'react-bootstrap';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import ControlledRadioButtonGroup from '@/components/forms/FormInput/ControlledRadioButton';
import { IBusinessPlanFormStage } from '@/views/GrantForm/GrantForm.interfaces';
import { validateInputLength } from '@/views/GrantForm/services/GrantFormBaseLayout.service';

const Sustainability = ({ isPreview, isPrint }: IBusinessPlanFormStage) => {
  const {
    control,
    watch,
    handleSubmit,
    formState,
    onSubmit,
    userData,
    isLoading,
  } = useGetSustainabilty();
  return (
    <BusinessFormBaseLayout
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      page={6}
      title={
        userData?.data?.skipped_advanced_training == false
          ? 'Sustainability and Risks'
          : 'Other Issues'
      }
      isLoading={isLoading}
      formStage="sustainability"
      preview={isPreview}
      print={isPrint}
    >
      {/* <Row>
        <Col md={12} xs={12}>
          <ControlledTextInput
            control={control}
            name={'alternative_funding_plan'}
            title="Alternatively, should you not be successful in accessing grant funding under the Programme, how will you access alternative funding to develop and supply your products or services?"
            as={'textarea'}
            placeholder="Your response must not be more than 50 words"
            defaultValue={
              userData?.data?.profile?.grant_information
                ?.alternative_funding_plan
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information
                  ?.alternative_funding_plan,
            }}
            isDisabled={isPreview === true}
          />
        </Col>
      </Row> */}
      {userData?.data?.skipped_advanced_training == false && (
        <>
          <Row>
            <Col md={12} xs={12}>
              <ControlledTextInput
                control={control}
                name={'sustainability_plan'}
                title="What are your plans to ensure operations are sustained after grant disbursement from GEA?(Not more than 50 words)"
                as={'textarea'}
                placeholder="Your response must not be more than 50 words"
                defaultValue={
                  userData?.data?.profile?.grant_information
                    ?.sustainability_plan
                }
                rules={{
                  required:
                    !userData?.data?.profile?.grant_information
                      ?.sustainability_plan,
                  validate: {
                    valid: (v) => {
                      return validateInputLength(v, 50);
                    },
                  },
                }}
                value={
                  isPreview
                    ? userData?.data?.profile?.grant_information
                        ?.sustainability_plan
                    : null
                }
                isDisabled={isPreview === true}
                isPrint={isPrint}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12}>
              <ControlledTextInput
                control={control}
                name={'expected_issues_that_may_affect_execution'}
                title="Are there any issues/risks that might affect the successful execution of your proposed project? E.g., an ongoing litigation that may affect the project, etc. Please detail if applicable and indicate how these would be mitigated.(Not more than 50 words)"
                as={'textarea'}
                placeholder="Your response must not be more than 50 words"
                defaultValue={
                  userData?.data?.profile?.grant_information
                    ?.expected_issues_that_may_affect_execution
                }
                rules={{
                  required:
                    !userData?.data?.profile?.grant_information
                      ?.expected_issues_that_may_affect_execution,
                  validate: {
                    valid: (v) => {
                      return validateInputLength(v, 50);
                    },
                  },
                }}
                value={
                  isPreview
                    ? userData?.data?.profile?.grant_information
                        ?.expected_issues_that_may_affect_execution
                    : null
                }
                isDisabled={isPreview === true}
                isPrint={isPrint}
              />
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col md={12} xs={12}>
          <ControlledRadioButtonGroup
            control={control}
            name={'received_support_from_bac'}
            title="Did you receive support from a Business Advisory Centre (BAC)/ Business Resource Centre (BRC) or anyone else to complete this template?"
            options={[
              { label: 'Yes', value: 'Y' },
              { label: 'No', value: 'N' },
            ]}
            defaultValue={
              userData?.data?.profile?.grant_information
                ?.received_support_from_bac == true
                ? 'Y'
                : 'N'
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information
                  ?.received_support_from_bac,
            }}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information
                    ?.received_support_from_bac == true
                  ? 'Y'
                  : 'N'
                : null
            }
            isDisabled={isPreview === true}
            isPrint={isPrint}
          />
        </Col>
      </Row>
    </BusinessFormBaseLayout>
  );
};

export default Sustainability;
