/*eslint-disable*/
import React from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import '../../../components/layout/navigation/style.css';
import propTypes from 'prop-types';
import { IOnboardProgress } from '../GrantForm.interfaces';
import { ProgressNumber } from './ProgressNumber';

const OnboardProgress = ({ page }: IOnboardProgress) => {
  // const navigate = useNavigate();
  // React.useEffect(()=>{
  //     navigate('/deadline');
  // },[])

  return (
    <Col className="d-none d-md-block d-lg-block">
      {/* <LogoDark type="large" className="px-5 w-75" /> */}
      <Row className="mt-1">
        <Stack className="mb-5  px-5 gap-5">
          <ProgressNumber
            number={1}
            description="Personal Information"
            active={true}
          />
          <ProgressNumber
            number={2}
            description="Business Information"
            active={page >= 2}
          />
          <ProgressNumber
            number={3}
            description="Product/Services Information"
            active={page >= 3}
          />
          <ProgressNumber
            number={4}
            description="Technical and Financial Capacity"
            active={page >= 4}
          />
          <ProgressNumber
            number={5}
            description="Product/Service Marketing"
            active={page >= 5}
          />
          <ProgressNumber
            number={6}
            description="Revenue Generation"
            active={page >= 6}
          />
          <ProgressNumber
            number={7}
            description="Funding Requirements"
            active={page >= 7}
          />
          <ProgressNumber number={8} description="Impact" active={page >= 8} />
          <ProgressNumber number={8} description="Preview" active={page >= 9} />
        </Stack>
      </Row>
    </Col>
  );
};

OnboardProgress.propTypes = {
  page: propTypes.number,
};

export default OnboardProgress;
