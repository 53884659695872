/*eslint-disable*/
import React, { ChangeEvent, Fragment } from 'react';
import { styled, Box, Input, Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { defaultBlue } from '@/utils/constants/colors.constants';
import { IFieldListComponent } from '../GrantForm.interfaces';
import { messageAlert } from '@/utils/helpers/functions';

interface ICustomFileUpload {
  onFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onFileRemove?: (index: number) => void;
  isMulti?: boolean;
  files?: File[];
  text?: string;
}

export const FileInputWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  border: '1px solid #dfe2e5',
  borderRadius: '5px',
}));

export const FileInfoWrapper = styled(Box)(({ theme }) => ({
  width: ' 75%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '5px',
}));

export const CustomInput = styled('input')({
  display: 'none',
});

export const UploadWrapper = styled(Box)(({ theme }) => ({
  width: '25%',
}));

export const AttachButton = styled(Button)(({ theme }) => ({
  textTransform: 'initial',
  width: '100%',
  color: 'black',
  backgroundColor: '#e9e9e9',
  fontFamily: 'inherit',
  padding: '10px',
  display: 'block',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  ':hover': {
    cursor: 'pointer',
  },
  height: '100%',
}));

const FileListComponent = ({ name, index, onclick }: IFieldListComponent) => {
  const truncatedName = name?.length > 15 ? name?.slice(0, 15) + '...' : name;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f2f3f8',
        borderRadius: '4px',
        paddingLeft: '0px',
        paddingRight: '5px',
        marginRight: '3px',
        border: `1px solid ${defaultBlue}`,
      }}
    >
      <Button
        style={{
          padding: '0px',
          minWidth: 'unset',
          marginRight: '10px',
          borderTopRightRadius: '0px',
          borderBottomRightRadius: '0px',
          borderRight: '1px solid gray',
        }}
        onClick={() => {
          onclick(index);
        }}
      >
        <Close width="12px" style={{ color: 'red' }} />
      </Button>
      <Box
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '120px', // Adjust this width as needed
        }}
      >
        {truncatedName}
      </Box>
    </Box>
  );
};

const CustomFileUpload = ({
  onFileChange,
  onFileRemove,
  files,
  text,
  isMulti,
}: ICustomFileUpload) => {
  return (
    <FileInputWrapper>
      <UploadWrapper>
        <label
          htmlFor="custom-file-upload"
          style={{ width: '100%', height: '100%' }}
        >
          <CustomInput
            accept=" .jpg, .png,.pdf"
            id={`custom-file-upload`}
            type="file"
            multiple={isMulti}
            onChange={(e) => {
                  if(e?.target?.files[0]?.size > 5097152){
               messageAlert('error','file is too big, must be at most 5mb or less')
               return;
              };

              onFileChange?.(e);
            }}
            
          />
          <AttachButton as="div">
            <div>{text || 'Attach File'}</div>
          </AttachButton>
        </label>
      </UploadWrapper>
      <FileInfoWrapper>
        <Box sx={{ width: '100%', display: 'flex', overflowX: 'auto' }}>
          {files?.map((file, index) => {
            return (
              <FileListComponent
                index={index}
                onclick={onFileRemove}
                name={file.name}
              />
            );
          })}
        </Box>
      </FileInfoWrapper>
    </FileInputWrapper>
  );
};

export default CustomFileUpload;
