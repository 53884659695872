import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { TextField } from "..";
import propTypes from "prop-types";
import { InputSelect } from "@components/select/Select";
import constant from "src/utils/constants/constants";
import FormInput from "../FormInput/FormInput";
import MainButton, { ButtonGutter } from "@components/buttons/Buttons";
import { connect } from "react-redux";
import { updateUserProfile } from "src/api/User/User";
import { pickFieldValues } from "src/utils/helpers/functions";
import { Link } from "react-router-dom";


function LoanBusinessLocation({updateUserProfile, loading}){
    const user = JSON.parse(window.localStorage.getItem('user'))
    const [state, setState] = useState(user?.business_profile)
    function handleChange({target}){
        setState({...state, [target.name]:target.value})
    }

    function handleSubmit(e){
        e.preventDefault();
        const userProfile ={}
        if(user){
            userProfile.business_profile = pickFieldValues(['digital_address'], state)
            userProfile.onboarding_data = {loan:{...user?.onboarding_data?.loan, location_info:true}}
            updateUserProfile(user?.user?.id, userProfile, '/loan_application/business_financial_info')
        }
    }
    
    return(
        <Form as={Col} md={12}>
            <Row className="mb-4">
                <Col md={8} className="text-center">
                    <TextField text="Location Information" className={'fs-5 fw-bold mb-3'}/>
                    <TextField text={'Where is your business located'}/>
                </Col>
            </Row>
            <Row className="mb-5">
                <Form.Group as={Col} md="6">
                    <InputSelect 
                        title="Region" 
                        name="region" 
                        options={constant.OPERATIONAL_STAGE} 
                        isRequired={true}/>
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <InputSelect 
                        title="District" 
                        name="district" 
                        options={constant.OPERATIONAL_STAGE} 
                        isRequired={true}/>
                </Form.Group>
            </Row>
            <Row className="mb-5">
                <Form.Group as={Col} md="6">
                    <FormInput title="Digital Address" name="digital_address" defaultValue={state?.digital_address} onChange={handleChange} type="text" placeholder="Address" isRequired={true}/>
                </Form.Group>
            </Row>
            <Row>
                <ButtonGutter>
                    <Col md={4}>
                        <Link to="/loan_application/business_info" className="text-decoration-none">
                            <MainButton text={'Previous'} type={'submit'} variant="outline-secondary" size="lg" className="fs-6"/>
                        </Link>
                    </Col>
                    <Col md={{ span: 4, offset: 4 }}>
                        <MainButton text={'Next'} type={'submit'} onClick={handleSubmit} isLoading={loading} variant="primary" size="lg"  className="fs-6" isDisabled={!(state?.digital_address)}/>
                    </Col>
                </ButtonGutter>   
            </Row>
        </Form>
    )
}
LoanBusinessLocation.propTypes = {
    handleChange:propTypes.func,
    updateUserProfile:propTypes.func,
    loading:propTypes.bool
}

function mapStateToProps({user}){
    return {loading:user.loading};

}

export default connect(mapStateToProps, {updateUserProfile})(LoanBusinessLocation);