/*eslint-disable*/
import React from 'react';
import BusinessFormBaseLayout from '@/views/GrantForm/BusinessFormBaseLayout';
import { Row, Col } from 'react-bootstrap';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import { useGetApplicantInformation3 } from '@/views/GrantForm/services/BusinessPlan/ApplicantInformation.service';
import { IBusinessPlanFormStage } from '@/views/GrantForm/GrantForm.interfaces';
import { validateInputLength } from '@/views/GrantForm/services/GrantFormBaseLayout.service';
import {
  product_has_fda_approval_or_now_applying,
  who_are_your_customers,
} from '@/utils/constants/skippedapplicantquestions';
import ControlledSelectInput from '@/components/forms/FormInput/ControlledSelectInput';
import ControlledRadioButtonGroup from '@/components/forms/FormInput/ControlledRadioButton';

const ApplicantInformation3 = ({
  isPreview,
  isPrint,
}: IBusinessPlanFormStage) => {
  const { control, handleSubmit, onSubmit, userData, isLoading } =
    useGetApplicantInformation3();
  return (
    <BusinessFormBaseLayout
      page={2}
      title={'Applicant Information'}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      isLoading={isLoading}
      formStage="application_information_3"
      preview={isPreview}
      print={isPrint}
    >
      <Row>
        {userData?.data?.skipped_advanced_training == false && (
          <Col md={12} xs={12}>
            <ControlledTextInput
              control={control}
              name={'business_stage'}
              title={
                'Tell us about the stage of your product(s) or services. Are you at the ideation stage, or the testing stage, or perhaps the stage where you can scale your startup (that is, the commercialization stage)?  (your responses must not be more than 500 words)'
              }
              type={'text'}
              as={'textarea'}
              defaultValue={
                userData?.data?.profile?.grant_information
                  ?.applicant_information?.business_stage
              }
              rules={{
                required:
                  !userData?.data?.profile?.grant_information
                    ?.applicant_information?.business_stage,
                validate: {
                  valid: (v) => {
                    return validateInputLength(v, 500);
                  },
                },
              }}
              value={
                isPreview
                  ? userData?.data?.profile?.grant_information
                      ?.applicant_information?.business_stage
                  : null
              }
              placeholder="Your response should not be more than 500 words"
              isDisabled={isPreview == true}
              isPrint={isPrint}
            />
          </Col>
        )}
      </Row>
      {userData?.data?.skipped_advanced_training == true && (
        <Row>
          <Col md={12} xs={12}>
            <ControlledRadioButtonGroup
              control={control}
              name="product_has_fda_approval_or_now_applying"
              title={product_has_fda_approval_or_now_applying}
              options={[
                { label: 'Yes', value: 'Y' },
                { label: 'In the Process', value: 'IN_THE_PROCESS' },
                { label: "No", value: 'N' },
              ]}
              rules={{
                required:
                  !userData?.data?.profile?.grant_information
                    ?.applicant_information
                    ?.product_has_fda_approval_or_now_applying,
              }}
              defaultValue={
                userData?.data?.profile?.grant_information
                  ?.applicant_information
                  ?.product_has_fda_approval_or_now_applying
              }
              isDisabled={isPreview == true}
              isPrint={isPrint}
            />
          </Col>
        </Row>
      )}
      {userData?.data?.skipped_advanced_training == false && (
        <Row>
          <Col md={12} xs={12}>
            <ControlledTextInput
              control={control}
              name={'product_has_fda_approval_or_now_applying'}
              title={`Tell us if your product(s) has 
              received the relevant certification (e.g., FDA, GSA) or whether you are now 
              applying for or pursuing certification.`}
              type={'text'}
              as={'textarea'}
              defaultValue={
                userData?.data?.profile?.grant_information
                  ?.applicant_information
                  ?.product_has_fda_approval_or_now_applying
              }
              rules={{
                required:
                  !userData?.data?.profile?.grant_information
                    ?.applicant_information
                    ?.product_has_fda_approval_or_now_applying,
                validate: {
                  valid: (v) => {
                    return validateInputLength(v, 500);
                  },
                },
              }}
              value={
                isPreview
                  ? userData?.data?.profile?.grant_information
                      ?.applicant_information
                      ?.product_has_fda_approval_or_now_applying
                  : null
              }
              placeholder="Your response should not be more than 500 words"
              isDisabled={isPreview == true}
              isPrint={isPrint}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col md={12} xs={12}>
          <ControlledTextInput
            control={control}
            name={'who_are_your_customers'}
            title={
              userData?.data?.skipped_advanced_training == false
                ? 'Who are the customers of the products/services? Mention some. For new products/services yet to enter the market, tell us about your immediate target market and how your plan to access those markets identified. (your responses must not be more than 500 words)'
                : who_are_your_customers
            }
            defaultValue={
              userData?.data?.profile?.grant_information?.applicant_information
                ?.who_are_your_customers
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information
                  ?.applicant_information?.who_are_your_customers,
              validate: {
                valid: (v) => {
                  return validateInputLength(v, 500);
                },
              },
            }}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information
                    ?.applicant_information?.who_are_your_customers
                : null
            }
            type={'text'}
            as={'textarea'}
            placeholder="Your response should not be more than 500 words"
            isDisabled={isPreview == true}
            isPrint={isPrint}
          />
        </Col>
      </Row>
    </BusinessFormBaseLayout>
  );
};

export default ApplicantInformation3;
