
import LoanBusinessInfo from "@components/forms/Loan/BusinessInfoForm";
import LoanApplicationLayout from "@components/layout/layouts/LoanApplicationLayout/LoanApplicationLayout";

import React from "react";

export default function LoanApplicationBusinessInfo() {
    
    return(
        <LoanApplicationLayout page={1}>
            <LoanBusinessInfo/>
        </LoanApplicationLayout>              
    )

}


