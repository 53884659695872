/*eslint-disable*/
import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@mui/material';
import * as Mui from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import {
  defaultGray,
  defaultLightBlue,
} from '@/utils/constants/colors.constants';
import styled from 'styled-components';

const AccordionWrapper = Mui.styled(Accordion)({
  fontFamily: 'inherit!important',
  boxShadow: 'unset!important',
  border: `1px solid #E8EEF8`,
  borderRadius: '5px',
});

const AccordionHeader = Mui.styled(AccordionSummary)({
  backgroundColor: '#FAFBFF',
  '.&Mui-expanded': {
    minHeight: 'unset!important',
  },
});

const AccordionHeaderText = Mui.styled(Typography)({
  color: defaultGray,
  fontFamily: 'inherit!important',
  fontSize: '15px',
  fontWeight: '600',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const CustomAccordionDetails = Mui.styled(AccordionDetails)({
  paddingTop: '20px',
});

export const IconWrapper = Mui.styled(Box)({
  color: defaultGray,
  '&:hover': {
    cursor: 'pointer',
  },
});

const ActionWrapper = Mui.styled(Box)({
  display: 'flex',
  justifyContent: 'center',
});

const AccordionHeaderRight = styled.div`
  margin-left: auto;
  display: flex;
`;

type ActionType = 'delete' | 'edit';

interface IGrantAccordionBaseProps {
  title: string;
  children: React.ReactNode;
  expandable: boolean;
  defaultOpen?: boolean;
  isStacked?: boolean;
  actions?: ActionType[];
  onDelete?: () => void;
  onEdit?: () => void;
}

export default function GrantAccordionBase({
  title,
  children,
  expandable,
  defaultOpen,
  actions,
  isStacked,
  onDelete,
  onEdit,
}: IGrantAccordionBaseProps) {
  const [expanded, setExpanded] = useState<boolean>(
    defaultOpen !== undefined ? defaultOpen : true
  );

  const handleAccordionChange = () => {
    if (expandable) {
      setExpanded(!expanded);
    }
  };

  const returnStyles = ({ isStacked }): React.CSSProperties => {
    return {
      marginTop: isStacked == true ? '0px' : '10px',
      marginBottom: isStacked == true ? '0px' : '10px',
    };
  };

  return (
    <AccordionWrapper
      className="grant-base-accordion"
      expanded={expanded}
      onChange={handleAccordionChange}
      sx={returnStyles({ isStacked })}
    >
      <AccordionHeader
        expandIcon={
          <Mui.Tooltip
            placement="top"
            title={expanded == true ? 'Close' : 'Expand'}
          >
            <ActionWrapper>
              {expandable && (
                <IconWrapper>
                  {expanded == true ? <RemoveIcon /> : <AddIcon />}
                </IconWrapper>
              )}
            </ActionWrapper>
          </Mui.Tooltip>
        }
        aria-controls="panel-content"
        id="panel-header"
      >
        <AccordionHeaderText>{title}</AccordionHeaderText>
        <AccordionHeaderRight>
          {actions?.map((action) => {
            switch (action) {
              case 'delete':
                return (
                  <Mui.Tooltip placement="top" title={'Delete'}>
                    <IconWrapper
                      onClick={() => {
                        setExpanded(true);
                        onDelete?.();
                      }}
                    >
                      <DeleteIcon />
                    </IconWrapper>
                  </Mui.Tooltip>
                );

              case 'edit':
                return (
                  <Mui.Tooltip placement="top" title={'Edit'}>
                    <IconWrapper onClick={onEdit}>
                      <Edit />
                    </IconWrapper>
                  </Mui.Tooltip>
                );
            }
          })}
        </AccordionHeaderRight>
      </AccordionHeader>
      <CustomAccordionDetails>{children}</CustomAccordionDetails>
    </AccordionWrapper>
  );
}
