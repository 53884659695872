/*eslint-disable*/

import React from 'react';
import ControlledRadioButtonGroup from '@/components/forms/FormInput/ControlledRadioButton';
import {
  fdaCertficationOptions,
  gsaCertficationOptions,
  useGetProductInformationForm2,
} from '../../../services/ProductInformation.service';
import GrantFormBaseLayout from '../../../GrantFormBaseLayout';
import { Row, Col } from 'react-bootstrap';
import { IGrantFormStage } from '@/views/GrantForm/GrantForm.interfaces';
const ProductInformation2 = ({ isPreview, isPrint }: IGrantFormStage) => {
  const {
    watch,
    control,
    handleSubmit,
    formState,
    onSubmit,
    isLoading,
    userData,
  } = useGetProductInformationForm2();
  return (
    <GrantFormBaseLayout
      page={3}
      title={'Products/Services Information'}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      isLoading={isLoading}
      stage={'product_information_2'}
      preview={isPreview}
      print={isPrint}
    >
      <form>
        <Row>
          <Col md={6} xs={12}>
            <ControlledRadioButtonGroup
              control={control}
              title={
                'Do you have Ghana Standards Authority (GSA) certification?'
              }
              defaultValue={
                userData?.data?.profile?.grant_information
                  ?.has_gsa_certification == true
                  ? 'Y'
                  : 'N'
              }
              name={'has_gsa_certification'}
              options={gsaCertficationOptions}
              rules={{
                required:
                  !userData?.data?.profile?.grant_information
                    ?.has_gsa_certification,
              }}
              isDisabled={isPreview == true}
            />
          </Col>
          <Col md={6} xs={12}>
            <ControlledRadioButtonGroup
              control={control}
              title={'Do you have Food and Drug Authority (FDA) certification?'}
              name={'has_fda_certification'}
              defaultValue={
                userData?.data?.profile?.grant_information
                  ?.has_fda_certification == true
                  ? 'Y'
                  : 'N'
              }
              options={fdaCertficationOptions}
              rules={{
                required:
                  !userData?.data?.profile?.grant_information
                    ?.has_fda_certification,
              }}
              isDisabled={isPreview == true}
            />
          </Col>
        </Row>
      </form>
    </GrantFormBaseLayout>
  );
};

export default ProductInformation2;
