import React, { useState } from "react";
import propTypes from "prop-types";
import { Row, Col, Form } from "react-bootstrap";
import TextField from "@components/forms/TextField/TextField";
import { Controller, useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
import MainButton from "@components/buttons/Buttons";
import { ErrorText, Required } from "@components/utils";
import { checkIfBlank } from "@utils/helpers/functions";
// import constant from "@utils/constants/constants";
// import FormInput from "@components/forms/FormInput/FormInput";
// import FormInput from "@components/forms/FormInput/FormInput";

// const validationSchema = yup.object({
//   owns_bank_account: yup.bool().required("Required"),
// });
export default function FinancialLiteracy({
  disabled = false,
  state,
  colSize,
  onSubmit,
  loading,
  onPreviousButtonClicked,
}) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const [controlState, setControlState] = useState(state);
  function handleChange({ target }) {
    setControlState({ ...controlState, [target.name]: target.value });
  }
const gift_expenditure = state?.gift_expenditure?.split('^')
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="d-flex  align-items-center h-100">
        <Col md={colSize ? colSize : 12}>
          <TextField
            text={"Financial Literacy"}
            className={"fs-5 fw-bold mb-5 text-center"}
          />
          {/* <Row className="mb-5">
            <Form.Group as={Col} md="6" className="mt-2">
                <Controller
                    name="amount_needed_to_start_business"
                    control={control}
                    render={({ field }) =>(
                <FormInput
                  {...field}
                  title="How much cash would you require to start your business?"
                  name="amount_needed_to_start_business"
                  isDisabled={disabled}
                  isRequired
                  type="text"
                  defaultValue={state.amount_needed_to_start_business}
                  placeholder="Specify"
                />
              )}/>
            </Form.Group>

            <Form.Group as={Col} md="6" className="mt-2">
                <Controller
                    name="amount_you_can_contribute"
                    control={control}
                    render={({ field }) =>(
                <FormInput
                  {...field}
                  title="How much can you contribute?"
                  name="amount_you_can_contribute"
                  isDisabled={disabled}
                  type="text"
                  isRequired
                  defaultValue={state.amount_you_can_contribute}
                  placeholder="Specify"
                />
              )}/>
            </Form.Group>
          </Row> */}
          <Row className="mb-5">
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label className="big-label">
              Should you receive money as a gift, to spend freely, state 3 top uses for the fund
                <Required />
              </Form.Label>
            </Form.Group>
            <Row>
            
              <Form.Group as={Col} md="12 mb-3">
                <Controller
                    name="gift_expenditure1"
                    control={control}
                    rules={{
                      required:{
                        value:gift_expenditure?.[0]?false:true,
                        message:'Field is required'
                      },
                      pattern:{
                        value:checkIfBlank(watch('gift_expenditure1')||''),
                        message:'You cannot submit an empty space'
                      }
                    }}
                    render={({ field }) =>(
                <Form.Control as="textarea" rows={2}
                  {...field}
                  placeholder="1."
                  required
                  defaultValue={gift_expenditure?.[0]}
                  name="gift_expenditure1"
                  type="text"
                  disabled={disabled}
                  // error={errors?.gift_expenditure1?.message}
                />
                )}/>
                <span style={{color:'red'}}>
                  {errors?.gift_expenditure1?.message}
                </span>
                
              </Form.Group>
              <Form.Group as={Col} md="12 mb-3">
                <Controller
                    name="gift_expenditure2"
                    control={control}
                    rules={{
                      required:{
                        value:gift_expenditure?.[1]?false:true,
                        message:'Field is required'
                      },
                      pattern:{
                        value:checkIfBlank(watch('gift_expenditure2')||''),
                        message:'You cannot submit an empty space'
                      }
                    }}
                    render={({ field }) =>(
                <Form.Control as="textarea" rows={2}
                  {...field}
                  required
                  placeholder="2."
                  defaultValue={gift_expenditure?.[1]}
                  name="gift_expenditure2"
                  type="text"
                  disabled={disabled}
                />
                )}/>
                  <span style={{color:'red'}}>
                  {errors?.gift_expenditure2?.message}
                </span>

              </Form.Group>
              <Form.Group as={Col} md="12">
                <Controller
                    name="gift_expenditure3"
                    control={control}
                    rules={{
                      required:{
                        value:gift_expenditure?.[2]?false:true,
                        message:'Field is required'
                      },
                      pattern:{
                        value:checkIfBlank(watch('gift_expenditure3')||''),
                        message:'You cannot submit an empty space'
                      }
                    }}
                    render={({ field }) =>(
                <Form.Control as="textarea" rows={2}
                  {...field}
                  placeholder="3."
                  required
                  defaultValue={gift_expenditure?.[2]}
                  name="gift_expenditure3"
                  type="text"
                  disabled={disabled}
                />
                )}/>
                  <span style={{color:'red'}}>
                  {errors?.gift_expenditure3?.message}
                </span>
                
              </Form.Group>
              
             
            </Row>
            
          </Row>
          <Row className="mb-5">
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label className="big-label">
                Do you have a bank account?
                <Required />
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    {...register("owns_bank_account")}
                    value={true}
                    inline
                    required
                    label="Yes"
                    name="owns_bank_account"
                    type="radio"
                    id={`inline-radio-1`}
                    disabled={disabled}
                    defaultChecked={state?.owns_bank_account === true}
                  />
                  <Form.Check
                    {...register("owns_bank_account")}
                    value={false}
                    inline
                    required
                    label="No"
                    name="owns_bank_account"
                    type="radio"
                    id={`inline-radio-2`}
                    disabled={disabled}
                    defaultChecked={state?.owns_bank_account === false}
                  />
                </Col>
                {errors?.bank_account?.message && (
                  <ErrorText error={errors?.bank_account?.message} />
                )}
              </Row>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label className="big-label">
                Do you understand your requirments for tax?
                <Required />
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    {...register("understands_tax_requirements")}
                    value={true}
                    inline
                    required
                    label="Yes"
                    name="understands_tax_requirements"
                    type="radio"
                    id={`inline-radio-1`}
                    disabled={disabled}
                    defaultChecked={state?.understands_tax_requirements === true}
                  />
                  <Form.Check
                    {...register("understands_tax_requirements")}
                    value={false}
                    inline
                    required
                    label="No"
                    name="understands_tax_requirements"
                    type="radio"
                    id={`inline-radio-2`}
                    disabled={disabled}
                    defaultChecked={state?.understands_tax_requirements === false}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Row>

          <Row className="mb-5">
            {/* <Form.Group as={Col} md="6">
              <Form.Label className="big-label">
                Have you ever written a business plan?
                <Required />
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    {...register("has_written_a_business_plan")}
                    value={true}
                    inline
                    required
                    label="Yes"
                    name="has_written_a_business_plan"
                    type="radio"
                    id={`inline-radio-1`}
                    disabled={disabled}
                    defaultChecked={state?.has_written_a_business_plan === true}
                  />
                  <Form.Check
                    {...register("has_written_a_business_plan")}
                    value={false}
                    inline
                    required
                    label="No"
                    name="has_written_a_business_plan"
                    type="radio"
                    id={`inline-radio-2`}
                    disabled={disabled}
                    defaultChecked={state?.has_written_a_business_plan === false}
                  />
                </Col>
              </Row>
            </Form.Group> */}
            <Form.Group as={Col} md="6">
              <Form.Label className="big-label">
                Have you had training in financial literacy?
                <Required />
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    {...register("has_previous_financial_training", {
                      onChange: (e) => {handleChange(e)},
                    })}
                    value={true}
                    inline
                    required
                    label="Yes"
                    name="has_previous_financial_training"
                    type="radio"
                    id={`inline-radio-1`}
                    disabled={disabled}
                    defaultChecked={state?.has_previous_financial_training === true}
                  />
                  <Form.Check
                    {...register("has_previous_financial_training", {
                      onChange: (e) => {handleChange(e)},
                    })}
                    value={false}
                    inline
                    required
                    label="No"
                    name="has_previous_financial_training"
                    type="radio"
                    id={`inline-radio-2`}
                    disabled={disabled}
                    defaultChecked={state?.has_previous_financial_training === false}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group as={Col} md="6">
                <Form.Label className="big-label">
                Do you have a business plan?
                  <Required/>
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Check
                      defaultChecked={
                        state?.has_business_plan === true
                      }
                      value={true}
                      required
                      inline
                      label="Yes"
                      {...register("has_business_plan")}
                      name="has_business_plan"
                      type="radio"
                      disabled={disabled}
                      id={`inline-radio-1`}
                    />
                    <Form.Check
                      defaultChecked={
                        state?.has_business_plan === false
                      }
                      value={false}
                      required
                      inline
                      disabled={disabled}
                      label="No"
                      {...register("has_business_plan")}
                      name="has_business_plan"
                      type="radio"
                      id={`inline-radio-2`}
                    />
                  </Col>
                </Row>
            </Form.Group>
          </Row>

          <Row className="mb-5">
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label className="big-label">
                Have you ever created a budget for a business plan?
                <Required />
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    {...register("ever_created_business_plan_budget", {
                      onChange: (e) => {handleChange(e)},
                    })}
                    value={true}
                    inline
                    required
                    label="Yes"
                    name="ever_created_business_plan_budget"
                    type="radio"
                    id={`inline-radio-1`}
                    disabled={disabled}
                    defaultChecked={state?.ever_created_business_plan_budget === true}
                  />
                  <Form.Check
                    {...register("ever_created_business_plan_budget", {
                      onChange: (e) => {handleChange(e)},
                    })}
                    value={false}
                    inline
                    required
                    label="No"
                    name="ever_created_business_plan_budget"
                    type="radio"
                    id={`inline-radio-2`}
                    disabled={disabled}
                    defaultChecked={state?.ever_created_business_plan_budget === false}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label className="big-label">
              If no, do you require training to do so?
                <Required />
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    {...register("desires_training_to_create_plan_budget", {
                      onChange: (e) => {handleChange(e)},
                    })}
                    value={true}
                    inline
                    required
                    label="Yes"
                    name="desires_training_to_create_plan_budget"
                    type="radio"
                    id={`inline-radio-1`}
                    disabled={
                      disabled || ['true', true].includes(controlState?.ever_created_business_plan_budget)
                    }
                    defaultChecked={state?.desires_training_to_create_plan_budget === true}
                  />
                  <Form.Check
                     {...register("desires_training_to_create_plan_budget", {
                      onChange: (e) => {handleChange(e)},
                    })}
                    value={false}
                    inline
                    required
                    label="No"
                    name="desires_training_to_create_plan_budget"
                    type="radio"
                    id={`inline-radio-2`}
                    disabled={
                      disabled || ['true', true].includes(controlState?.ever_created_business_plan_budget)
                    }
                    defaultChecked={state?.desires_training_to_create_plan_budget === false}
                  />
                </Col>
              </Row>
            </Form.Group>
            {onSubmit && (
              <div className="row mt-5 mb-3">
                <Col md={4} className="mb-3">
                  <MainButton
                    text={"Previous"}
                    type={"button"}
                    onClick={onPreviousButtonClicked}
                    variant="outline-secondary"
                    size="lg"
                    className="fs-6"
                  />
                </Col>
                <Col md={{ span: 4, offset: 4 }}>
                  <MainButton
                    text={"Save and Continue"}
                    type={"submit"}
                    variant="primary"
                    size="lg"
                    isLoading={loading}
                    className="fs-6"
                  />
                </Col>
              </div>
            )}
          </Row>
        </Col>
      </Row>
    </Form>
  );
}

FinancialLiteracy.propTypes = {
  disabled: propTypes.bool,
  state: propTypes.object,
  colSize: propTypes.number,
  onSubmit: propTypes.func,
  loading: propTypes.bool,
  onPreviousButtonClicked: propTypes.func,
};
