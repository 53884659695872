/*eslint-disable*/
import React from 'react';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import {
  IRevenueInput,
  ITextInput,
} from '@/utils/constants/globals.interfaces';
import styled from 'styled-components';
import { gray2 } from '@/utils/constants/colors.constants';
import { Form } from 'react-bootstrap';

const RevenueInputWrapper = styled.div`
  border: 1px solid ${gray2};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  padding-left: 20px;
  border-radius: 5px;
  font-weight: 500;
`;

const RevenuePrefixWrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
`;

const TextInputWrapper = styled.div`
  width: 90%;
`;

const RevenueInput = (props: IRevenueInput) => {
  return (
    <div>
      <Form.Label className="controlled-input-label">{props.title}</Form.Label>
      <RevenueInputWrapper>
        <RevenuePrefixWrapper>{props?.prefix || 'GH₵'}</RevenuePrefixWrapper>
        <TextInputWrapper>
          <ControlledTextInput
            {...props}
            title={''}
            hasBorder={false}
            inputType="prefixed"
          />
        </TextInputWrapper>
      </RevenueInputWrapper>
    </div>
  );
};

export default RevenueInput;
