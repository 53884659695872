/*eslint-disable*/
import { IUserModel } from '@/utils/constants/userModel.interface';
import { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useGetUserObject } from '../GrantFormBaseLayout.service';
import { IUserUpdateParams } from '../../GrantForm.interfaces';
import { updateFullUserProfile } from '@/api/User/User';

export const useGetConsent = () => {
  const { control, handleSubmit, register, watch, formState } = useForm();
  const { userData, refetch, userError, userLoading } = useGetUserObject();
  let _userData = userData as AxiosResponse<Partial<IUserModel>>;
  const navigate = useNavigate();
  const onSubmit = async (data: any) => {
    let userUpdateParams: Partial<IUserUpdateParams> = {
      id: undefined,
      data: undefined,
    };
    userUpdateParams.data = {
      profile: {
        is_grant_application_complete: true,
        is_business_plan_application_complete: true,
      },
    };

    userUpdateParams.data.id = _userData?.data?.profile?.client;
    await updateFullUserProfile(userUpdateParams.data);
    navigate('/grants/businessplan/success');
  };

  return { control, handleSubmit, watch, register, onSubmit, formState };
};
