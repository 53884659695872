/*eslint-disable*/
import React from 'react';
import ControlledRadioButtonGroup from '@/components/forms/FormInput/ControlledRadioButton';
import {
  hasActiveFinancialProcessOptions,
  hasCapacityOptions,
  hasExpertiseOptions,
  useGetTechnicalCapacity,
} from '../../../services/TechnicalCapacity.service';
import GrantFormBaseLayout from '../../../GrantFormBaseLayout';
import { Row, Col } from 'react-bootstrap';
import { IGrantFormStage } from '@/views/GrantForm/GrantForm.interfaces';
const TechnicalCapacity = ({ isPreview, isPrint }: IGrantFormStage) => {
  const {
    watch,
    control,
    handleSubmit,
    formState,
    onSubmit,
    isLoading,
    userData,
  } = useGetTechnicalCapacity();

  return (
    <GrantFormBaseLayout
      page={4}
      title={'Technical and Financial Capacity'}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      stage={'technical_capacity'}
      preview={isPreview}
      print={isPrint}
    >
      <form>
        <Row>
          <Col md={6} xs={12}>
            <ControlledRadioButtonGroup
              control={control}
              title={
                'Does the business have the required technical expertise to deliver the product/service?'
              }
              name={'has_required_techinical_expertise'}
              options={hasExpertiseOptions}
              rules={{
                required:
                  !userData?.data?.profile?.grant_information
                    ?.has_required_techinical_expertise,
              }}
              defaultValue={
                userData?.data?.profile?.grant_information
                  ?.has_required_techinical_expertise == true
                  ? 'Y'
                  : 'N'
              }
              isDisabled={isPreview == true}
            />
          </Col>
          <Col md={6} xs={12}>
            <ControlledRadioButtonGroup
              control={control}
              title={
                'Does the business have the capacity to provide additional resources to implement the plan?'
              }
              name={'has_capacity_to_provide_additional_resources'}
              options={hasCapacityOptions}
              rules={{
                required:
                  !userData?.data?.profile?.grant_information
                    ?.has_capacity_to_provide_additional_resources,
              }}
              defaultValue={
                userData?.data?.profile?.grant_information
                  ?.has_capacity_to_provide_additional_resources == true
                  ? 'Y'
                  : 'N'
              }
              isDisabled={isPreview == true}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <ControlledRadioButtonGroup
              control={control}
              title={
                'Does the business have an active financial management process/system in place?'
              }
              name={'has_an_active_financial_management_process'}
              options={hasActiveFinancialProcessOptions}
              rules={{
                required:
                  !userData?.data?.profile?.grant_information
                    ?.has_an_active_financial_management_process,
              }}
              defaultValue={
                userData?.data?.profile?.grant_information
                  ?.has_an_active_financial_management_process == true
                  ? 'Y'
                  : 'N'
              }
              isDisabled={isPreview == true}
            />
          </Col>
        </Row>
      </form>
    </GrantFormBaseLayout>
  );
};

export default TechnicalCapacity;
