/*eslint-disable*/
import { OptionType } from '@/utils/constants/globals.interfaces';
import { useForm } from 'react-hook-form';
import {
  IBusinessInformation,
  IBusinessInformationII,
  IGrantUpdateParams,
  IShareholderForm,
  IUserUpdateParams,
} from '../GrantForm.interfaces';
import { SubmitHandler } from 'react-hook-form/dist/types';
import { useNavigate } from 'react-router-dom';
import { useGetGrantObject } from './GrantFormBaseLayout.service';
import { useGetUserObject } from './GrantFormBaseLayout.service';
import { AxiosResponse } from 'axios';
import { IUserModel } from '@/utils/constants/userModel.interface';
import { useGetRegions } from './GrantFormBaseLayout.service';
import { logError } from './GrantFormBaseLayout.service';
import { getRegionID } from './GrantFormBaseLayout.service';
import { updateFullUserProfile, updateGrantInformation } from '@/api/User/User';
import { handleErrorMessage, messageAlert } from '@/utils/helpers/functions';

export const useGetBusinessForm = () => {
  const { control, watch, handleSubmit, formState } =
    useForm<IBusinessInformation>();
  const {
    getGrantData: grantData,
    getGrantError: grantError,
    getGrantLoading: grantLoading,
  } = useGetGrantObject();
  const {
    getRegionsData: regions,
    getRegionsError: regionsError,
    getRegionsLoading: regionsLoading,
  } = useGetRegions();
  const { userData, refetch, userError, userLoading } = useGetUserObject();
  const navigate = useNavigate();
  let _userData = userData as AxiosResponse<Partial<IUserModel>>;

  const onSubmit: SubmitHandler<Partial<IBusinessInformation>> = async (
    data
  ) => {
    if (!userError && !grantError && !regionsError) {
      let params: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };
      let userUpdateParams: IUserUpdateParams = {
        id: undefined,
        data: undefined,
      };

      params.id = _userData.data.profile?.grant_information?.id;
      params.data = {
        ..._userData?.data?.profile?.grant_information,
        business_phone: `+233${data.telephone}`,
        enterprise_name: data.business_name,
        address_line_1: data?.address_line_1,
        address_line_2: data?.address_line_2,
        applicant_information: {
          ..._userData?.data?.profile?.grant_information?.applicant_information,
          region: parseInt(data?.region || '1'),
          city: data?.city,
        },

        mission: data.mission,
        vision: data.vision,
        has_business_plan: data.hasBusinessPlan == 'Y' ? true : false,
      };

      userUpdateParams.data = {
        grant_onboarding_data: {
          ..._userData?.data?.grant_onboarding_data,
          business_form: true,
        },
      };
      userUpdateParams.data.id = _userData?.data?.profile?.client;

      try {
        await updateFullUserProfile(userUpdateParams.data);
        await updateGrantInformation(params);
        navigate('/grants/business_form_2');
      } catch (e) {
        logError(e);
      }
    }
  };

  return {
    control,
    watch,
    handleSubmit,
    formState,
    onSubmit,
    grantData,
    grantError,
    grantLoading,
    updateGrantLoading: false,
    regions: regions?.data.map((region: any) => {
      return { label: region.name, value: region.id };
    }),
    _userData,
    regionsLoading,
    userLoading,
  };
};

//************************************************* */
// this is the hook for the second business form
//************************************************ */

export const useGetBusinessForm2 = () => {
  const { control, watch, handleSubmit, formState } =
    useForm<IBusinessInformationII>();
  const {
    control: shareholderControl,
    watch: shareholderWatch,
    handleSubmit: shareholderHandleSubmit,
  } = useForm<IShareholderForm>();

  const { userData, userError, userLoading, refetch, isRefetching } =
    useGetUserObject();
  const _userData = userData as AxiosResponse<IUserModel>;
  const navigate = useNavigate();
  let grantUpdateParams: IGrantUpdateParams = {
    id: undefined,
    data: undefined,
  };

  const onSubmit: SubmitHandler<IBusinessInformationII> = async (data) => {
    let userUpdateParams: IUserUpdateParams = {
      id: undefined,
      data: undefined,
    };
    try {
      if (!userError) {
        let shareholderSum = 0;
        _userData?.data?.profile?.grant_information?.shareholders?.forEach(
          (shareholder) => {
            shareholderSum =
              shareholderSum + parseInt(shareholder?.share_holding || '0');
          }
        );
        if (shareholderSum < 100) {
          messageAlert(
            'error',
            'Shareholder Percentages should add up to 100%'
          );
          return;
        }
        if (shareholderSum > 100) {
          messageAlert(
            'error',
            'Shareholder Percentages should be less than 100%'
          );
          return;
        }
        grantUpdateParams.id = _userData.data.profile?.grant_information?.id;
        grantUpdateParams.data = {
          ..._userData?.data?.profile?.grant_information,
          full_time_female_employees: parseInt(data.full_time_female_employees),
          full_time_male_employees: parseInt(data.full_time_male_employees),
          part_time_female_employees: parseInt(data.part_time_female_employees),
          part_time_male_employees: parseInt(data.part_time_male_employees),
        };

        userUpdateParams.id = _userData?.data?.profile?.client;
        userUpdateParams.data = {
          grant_onboarding_data: {
            ..._userData?.data?.grant_onboarding_data,
            business_form_2: true,
          },
        };
        userUpdateParams.data.id = _userData?.data?.profile?.client;

        await updateGrantInformation(grantUpdateParams);
        await updateFullUserProfile(userUpdateParams.data);
        navigate('/grants/product_information');
      }
    } catch (e) {
      logError(e);
    }
  };
  const onShareholderSubmit: SubmitHandler<IShareholderForm> = async (data) => {
    if (!userError) {
      //check if the new value exceeds 100%...
      let shareholderSum = 0;
      _userData?.data?.profile?.grant_information?.shareholders?.forEach(
        (shareholder) => {
          shareholderSum =
            shareholderSum + parseInt(shareholder?.share_holding || '0');
        }
      );
      shareholderSum = shareholderSum + parseInt(data.share_holding);
      if (shareholderSum > 100) {
        messageAlert('error', 'Shareholder Percentages should not exceed 100%');
        return;
      }
      const newDirectorArray = [
        ...(_userData?.data?.profile?.grant_information?.shareholders || []),
        { name: data.name, share_holding: data.share_holding },
      ];
      grantUpdateParams.id = _userData?.data?.profile?.grant_information?.id;
      grantUpdateParams.data = {
        ..._userData?.data?.profile?.grant_information,
        shareholders: newDirectorArray,
      };
      try {
        await updateGrantInformation(grantUpdateParams);
        refetch();
      } catch (e) {
        logError(e);
      }
    }
  };

  const onShareholderDelete = async (id: number | string | undefined) => {
    try {
      let grantParams: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };
      let shareholderArray =
        _userData?.data?.profile?.grant_information?.shareholders?.filter(
          (shareholder) => {
            return shareholder?.id !== id;
          }
        );
      grantParams.id = _userData?.data?.profile?.grant_information?.id;
      grantParams.data = {
        ..._userData?.data?.profile?.grant_information,
        shareholders: shareholderArray,
      };
      await updateGrantInformation(grantParams);
      refetch();
    } catch (e) {
      logError(e);
    }
  };

  return {
    control,
    watch,
    handleSubmit,
    formState,
    onSubmit,
    shareholderControl,
    shareholderWatch,
    shareholderHandleSubmit,
    onShareholderSubmit,
    onShareholderDelete,
    userLoading: userLoading || isRefetching,
    userData: _userData,
  };
};

export const hasRegisteredBusinessOptions: OptionType<string, string>[] = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];

export const hasBusinessPlanOptions: OptionType<string, string>[] = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];
