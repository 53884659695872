import MainButton from "@components/buttons/Buttons";
import React from "react";
import { Card } from "react-bootstrap";
import training_thumb from "@images/bg/jas_hero_4.jpg";
import propTypes from "prop-types";

export default function TrainingProgramCard({
  title,
  training_thumbnail = training_thumb,
}) {
  const cardStyle = {
    borderRadius: "10px 10px 0px 0px",
    height: "318px",
    width: "100%",
  };

  const imageStyle = {
    width: "100%",
    height: "300px",
    backgroundImage: `linear-gradient(rgba(6, 6, 6, 0.197), rgba(6, 6, 6, 0.179)), url(
        ${training_thumbnail}
    )`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: "10px 10px 0px 0px",
  };
  return (
    <>
      <Card style={cardStyle}>
        <div className="vh-25" style={imageStyle} variant="top"></div>
        <Card.Body>
          <h5 className="mb-3">{title}</h5>
          <div className="d-flex justify-items-start">
            <MainButton text="view" dark />
          </div>
        </Card.Body>
      </Card>
      <br />
    </>
  );
}

TrainingProgramCard.propTypes = {
  title: propTypes.string,
  training_thumbnail: propTypes.string,
};
