/*eslint-disable*/
import React from 'react';
import {
  genderOptions,
  maritalStatusOptions,
  useGetPersonalInformation,
  validateDigitalAddress,
} from '@views/GrantForm/services/PersonalInformation.service';
import GrantFormBaseLayout from '@views/GrantForm/GrantFormBaseLayout';
import { Box } from '@mui/material';
import { Row, Col } from 'react-bootstrap';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import ControlledRadioButtonGroup from '@/components/forms/FormInput/ControlledRadioButton';
import ControlledSelectInput from '@/components/forms/FormInput/ControlledSelectInput';
import { getCountryOptions } from '../../../services/GrantFormBaseLayout.service';
import { IGrantFormStage } from '@/views/GrantForm/GrantForm.interfaces';

const GrantsPersonalInformation = ({ isPreview, isPrint }: IGrantFormStage) => {
  const { control, handleSubmit, onSubmit, watch, _userData, userLoading } =
    useGetPersonalInformation();

  return (
    <GrantFormBaseLayout
      page={1}
      title={'Personal Information'}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      isLoading={userLoading}
      stage={'personal_information'}
      preview={isPreview}
      print={isPrint}
    >
      <Row>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'first_name'}
            defaultValue={_userData?.data?.user?.first_name}
            title={'First Name'}
            isDisabled={true}
            rules={{
              required: {
                message: 'First Name is Required',
                value: !_userData?.data?.user?.first_name ? true : false,
              },
            }}
            placeholder={'First Name'}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'last_name'}
            title={'Last Name'}
            defaultValue={_userData?.data?.user?.last_name}
            rules={{
              required: {
                message: 'Last Name is Required',
                value: false,
              },
            }}
            placeholder={'Last  Name'}
            isDisabled={true}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'middle_name'}
            title={'Other Name'}
            defaultValue={_userData?.data?.user?.middle_name}
            rules={{
              required: {
                message: 'Other Name is Required',
                value: false,
              },
            }}
            placeholder={'Other Name'}
            isDisabled={true}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'date_of_birth'}
            title={'Date of Birth'}
            type={'date'}
            placeholder="Date of Birth"
            isDisabled={true}
            defaultValue={_userData?.data?.user?.date_of_birth}
            rules={{
              required: {
                message: 'Last Name is Required',
                value: !_userData?.data?.user?.date_of_birth ? true : false,
              },
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <ControlledRadioButtonGroup
            control={control}
            name={'gender'}
            title={'Gender'}
            options={genderOptions}
            defaultValue={_userData?.data?.user?.gender}
            rules={{
              required: {
                message: 'Gender is Required',
                value: !_userData?.data?.user?.gender ? true : false,
              },
            }}
            isDisabled={true}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledSelectInput
            control={control}
            name={'country'}
            title={'Nationality'}
            options={getCountryOptions()}
            defaultValue={_userData?.data?.profile?.country}
            rules={{
              required: {
                message: 'Nationality is Required',
                value: !_userData?.data?.profile?.country ? true : false,
              },
            }}
            isDisabled={true}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <ControlledSelectInput
            control={control}
            title={'Marital Status'}
            name={'marital_status'}
            defaultValue={_userData?.data?.profile?.marital_status}
            options={maritalStatusOptions}
            rules={{
              required: {
                message: '',
                value: !_userData?.data?.profile?.marital_status ? true : false,
              },
            }}
            isDisabled={true}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            title={'Digital Address'}
            name={'digital_address'}
            placeholder="XX-XXX-XXXX"
            defaultValue={_userData?.data?.profile?.digital_address}
            rules={{
              required: {
                message: 'Enter a valid digital address',
                value: false,
              },
              validate: {
                valid: (v) => {
                  return validateDigitalAddress(
                    v,
                    _userData?.data?.profile?.digital_address
                  );
                },
              },
            }}
            isDisabled={true}
          />
          <Box>
            <span style={{ fontStyle: 'italic' }}>
              Format: XX-XXX-XXXX or XX-XXXX-XXXX or XX-XXXXX-XXXX
            </span>
          </Box>
        </Col>
      </Row>
      <Row style={{ marginTop: '0.7rem' }}>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            title={'Phone Number 1'}
            name={'phone_number'}
            placeholder="Phone Number 1"
            defaultValue={_userData?.data?.user?.phone_number}
            rules={{
              required: {
                message: 'Gender is Required',
                value: !_userData?.data?.user?.phone_number ? true : false,
              },
            }}
            isDisabled={true}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            title={'Phone Number 2'}
            name={'temp_phone_number'}
            placeholder="Phone Number 2"
            defaultValue={_userData?.data?.user?.temp_phone_number}
            rules={{
              required: {
                message: 'Gender is Required',
                value: !_userData?.data?.user?.temp_phone_number ? true : false,
              },
            }}
            isDisabled={true}
          />
        </Col>
      </Row>
    </GrantFormBaseLayout>
  );
};

export default GrantsPersonalInformation;
