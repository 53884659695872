import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { TextField } from "..";
import propTypes from "prop-types";
import { InputSelect } from "@components/select/Select";
import constant from "src/utils/constants/constants";
import FormInput from "../FormInput/FormInput";
import MainButton, { ButtonGutter } from "@components/buttons/Buttons";
import { connect } from "react-redux";
import { updateUserProfile } from "src/api/User/User";
import { pickFieldValues } from "src/utils/helpers/functions";
import { Link } from "react-router-dom";


function LoanBusinessSupportHistoryForm({updateUserProfile, loading}){
    const user = JSON.parse(window.localStorage.getItem('user'))
    const [state, setState] = useState(user?.business_profile)
    function handleChange({target}){
        if(target.name === 'previously_been_funded'){
            setState({...state, [target.name]:target.checked})
        }else{
            setState({...state, [target.name]:target.value})
        }
    }

    function handleSubmit(e){
        e.preventDefault();
        const userProfile ={}
        if(user){
            userProfile.business_profile = pickFieldValues(['previously_been_funded', 'previous_type', 'previous_funding_date', 
            'previous_funding_amount', 'previous_funding_repayment_status'], state)
            userProfile.onboarding_data = {loan:{...user?.onboarding_data?.loan, support_history:true}}
            updateUserProfile(user?.user?.id, userProfile, '/loan_application/business_guarantor_1')
        }
    }
    
    return(
        <Form as={Col} md={12}>
            <Row className="mb-4">
                <Col md={8} className="text-center">
                    <TextField text="NEIP or GEA Support History" className={'fs-5 fw-bold mb-3'}/>
                    <TextField text={'Have you received support from NEIP or GEA before '}/>
                </Col>
            </Row>
            <Row className="mb-5">
                <Form.Group as={Col} md="6">
                    <Form.Label className="big-label">Have you received funding or support from NEIP or GEA before?<sup className="text-danger">*</sup></Form.Label>
                    <Row>
                        <Col>
                            <Form.Check
                                value={true}
                                required
                                inline
                                label="Yes"
                                name="previously_been_funded"
                                type="radio"
                                id={`inline-radio-1`}
                                onChange={handleChange}
                                defaultChecked={state?.previously_been_funded}
                            />
                            <Form.Check
                                value={false}
                                required
                                inline
                                label="No"
                                name="previously_been_funded"
                                type="radio"
                                id={`inline-radio-2`}
                                onChange={handleChange}
                                defaultChecked={!state?.previously_been_funded}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <InputSelect onChange={handleChange} 
                        defaultSelect={state?.previous_type}
                        defaultValue={state?.previous_type}
                        title="Type of Support?" 
                        name="previous_type" 
                        options={constant.SUPPORT_TYPE} 
                        isRequired={true}/>
                </Form.Group>
            </Row>
            <Row className="mb-5">
                <Form.Group as={Col} md="6">
                    <FormInput title="Which year did you receive the funds?*" name="previous_funding_date" defaultValue={state?.previous_funding_date} onChange={handleChange} type="date" placeholder="Specify" isRequired={true}/>
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <FormInput title="How much did you receive?" name="previous_funding_amount" defaultValue={state?.previous_funding_amount} onChange={handleChange} type="text" placeholder="Specify"/>
                </Form.Group>
            </Row>
            <Row className="mb-5">
                <Form.Group as={Col} md="6">
                    <InputSelect onChange={handleChange} 
                        defaultSelect={state?.previous_funding_repayment_status}
                        defaultValue={state?.previous_funding_repayment_status}
                        title="Payment?" 
                        name="previous_funding_repayment_status" 
                        options={constant.REPAYMENT_STATUS} 
                        isRequired={true}/>
                </Form.Group>
                
            </Row>
            <Row>
                <ButtonGutter>
                    <Col md={4}>
                        <Link to="/loan_application/business_financial_info" className="text-decoration-none">
                            <MainButton text={'Previous'} type={'submit'} variant="outline-secondary" size="lg" className="fs-6"/>
                        </Link>
                    </Col>
                    <Col md={{ span: 4, offset: 4 }}>
                        <MainButton text={'Next'} type={'submit'} onClick={handleSubmit} isLoading={loading} variant="primary" size="lg"  className="fs-6" isDisabled={!(state?.previously_been_funded
                            && state?.previous_type && state?.previous_funding_date && state?.previous_funding_amount && state?.previous_funding_repayment_status)}/>
                    </Col>
                </ButtonGutter>   
            </Row>
        </Form>
    )
}
LoanBusinessSupportHistoryForm.propTypes = {
    handleChange:propTypes.func,
    updateUserProfile:propTypes.func,
    loading:propTypes.bool
}

function mapStateToProps({user}){
    return {loading:user.loading};

}

export default connect(mapStateToProps, {updateUserProfile})(LoanBusinessSupportHistoryForm);