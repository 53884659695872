import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { TextField } from "..";
import propTypes from "prop-types";
import FormInput, { PhoneNumberInput } from "../FormInput/FormInput";
import MainButton, { ButtonGutter } from "@components/buttons/Buttons";
import { connect } from "react-redux";
import { updateUserProfile } from "src/api/User/User";
import { pickFieldValues } from "src/utils/helpers/functions";
import { Link } from "react-router-dom";


function LoanBusinessFirstGuarantorForm({updateUserProfile, loading}){
    const user = JSON.parse(window.localStorage.getItem('user'))
    const [state, setState] = useState(user?.business_profile)
    const [phoneNumberValue, setPhoneNumberValue] = useState()
    function handleChange({target}){
        setState({...state, [target.name]:target.value})
    }

    function handleSubmit(e){
        e.preventDefault();
        const userProfile ={}
        if(user){
            userProfile.business_profile = pickFieldValues(['operational_stage'], state)
            userProfile.onboarding_data = {loan:{...user?.onboarding_data?.loan, guarantor_info_1:true}}
            updateUserProfile(user?.user?.id, userProfile, '/loan_application/business_guarantor_2')
        }
    }
    
    return(
        <Form as={Col} md={12}>
            <Row className="mb-4">
                <Col md={8} className="text-center">
                    <TextField text="First Guarantor" className={'fs-5 fw-bold mb-3'}/>
                    <TextField text={'Give us details about your first guarantor'}/>
                </Col>
            </Row>
            <Row className="mb-5">
                <Form.Group as={Col} md="6">
                    <FormInput title="First Name" name="guarantor_first_name" onChange={handleChange} type="text" placeholder="Name" isRequired={true}/>
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <FormInput title="Surname" name="guarantor_last_name" onChange={handleChange} type="text" placeholder="Name" isRequired={true}/>
                </Form.Group>
            </Row>
            <Row className="mb-5">
                <Form.Group as={Col} md="6">
                    <PhoneNumberInput title="Phone number" value={phoneNumberValue} onChange={setPhoneNumberValue} placeholder="Phone number" isRequired={true}/>
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <FormInput title="Email" name="guarantor_email" onChange={handleChange} type="email" placeholder="Email" isRequired={true}/>
                </Form.Group>
            </Row>
            <Row className="mb-5">
                <Form.Group as={Col} md="6">
                    <FormInput title="National ID (Ghana Card) Number" name="guarantor_id"  type="text" placeholder="Card Number" isRequired={true}/>
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <FormInput title="Occupation" name="guarantor_occupation"  onChange={handleChange} type="text" placeholder="Occupation" />
                </Form.Group>
            </Row>
            <Row className="mb-5">
                <Form.Group as={Col} md="6">
                    <FormInput title="Residential Address" name="guarantor_address"  type="text" placeholder="Address" onChange={handleChange} />
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <FormInput title="Tax Identification Number" name="guarantor_tax_id"  onChange={handleChange} type="text" placeholder="ID Number" isRequired={true}/>
                </Form.Group>
            </Row>
            <Row className="mb-5">
                <Form.Group as={Col} md="6">
                    <FormInput title="Business Address" name="guarantor_business_address"  type="text" placeholder="Address" onChange={handleChange} />
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <FormInput title="Relationship with Appllicant" name="guarantor_relationship"  onChange={handleChange} type="text" placeholder="Relationship" isRequired={true}/>
                </Form.Group>
            </Row>
            <Row>
                <ButtonGutter>
                    <Col md={4}>
                    <Link to="/loan_application/business_support_history" className="text-decoration-none">
                        <MainButton text={'Previous'} type={'submit'} variant="outline-secondary" size="lg" className="fs-6" />
                        </Link>
                    </Col>
                    <Col md={{ span: 4, offset: 4 }}>
                       
                        <MainButton text={'Next'} type={'submit'} onClick={handleSubmit} isLoading={loading} variant="primary" size="lg"  className="fs-6" isDisabled={!(state?.guarantor_first_name && 
                            state?.guarantor_last_name && phoneNumberValue &&  state?.guarantor_email)}/>
                    </Col>
                </ButtonGutter>   
            </Row>
        </Form>
    )
}
LoanBusinessFirstGuarantorForm.propTypes = {
    handleChange:propTypes.func,
    updateUserProfile:propTypes.func,
    loading:propTypes.bool
}

function mapStateToProps({user}){
    return {loading:user.loading};

}

export default connect(mapStateToProps, {updateUserProfile})(LoanBusinessFirstGuarantorForm);