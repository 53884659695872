/*eslint-disable */
import React from 'react';
import BusinessFormBaseLayout from '@/views/GrantForm/BusinessFormBaseLayout';
import {
  DefaultPerformanceIndicatorsFieldState,
  getDefaultIndicatorState,
  getOtherIndicators,
  useGetExpectedResults,
} from '@/views/GrantForm/services/BusinessPlan/ExpectedResults.service';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import PerformanceIndicatorComponent from '../../PerformanceIndicatorComponent';
import GrantAccordionBase from '../../GrantAccordionBase';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import AddButton from '../../AddButton';
import { IBusinessPlanFormStage } from '@/views/GrantForm/GrantForm.interfaces';
import FormLoader from '../../FormLoader';
import FormInputTable from '@/components/tables/FormInputTable';
import { ExpectedResultColumns } from '@/views/GrantForm/services/BusinessPlan/ExpectedResults.service';
import { FormTableInputOptions } from '@/views/GrantForm/services/GrantFormBaseLayout.service';

const TitleTop = styled.div`
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 15px;
`;

const TitleBottom = styled.div`
  margin-bottom: 20px;
  font-weight: 800;
  font-size: 15px;
`;

const ExpectedResults = ({ isPreview, isPrint }: IBusinessPlanFormStage) => {
  const {
    control,
    otherIndicatorControl,
    userData,
    isLoading,
    isRefetching,
    handleSubmit,
    expectedResultsLoader,
    onSubmit,
    loadIndicatorTables,
    onOtherIndicatorSubmit,
    otherIndicatorHandleSubmit,
    onIndicatorDelete,
    onOtherIndicatorEdit,
  } = useGetExpectedResults();
  return (
    <BusinessFormBaseLayout
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      page={5}
      title={'Expected Result and Impact of Grant Support'}
      isLoading={isLoading}
      formStage={'expected_result'}
      preview={isPreview}
      print={isPrint}
    >
      <>
        {(isPreview || isPrint) && (
          <Row style={{ marginBottom: '20px' }}>
            <Col md={12} xs={12}>
              <FormInputTable
                title="Expected Results"
                columns={ExpectedResultColumns}
                rows={loadIndicatorTables(
                  userData?.data?.profile?.grant_information
                    ?.performance_indicators
                )}
                options={FormTableInputOptions}
              />
            </Col>
          </Row>
        )}
        {!isPreview && !isPrint && (
          <>
            <Row>
              <Col md={12} xs={12}>
                <TitleTop>
                  How will grant support affect your performance indicators? Use
                  the table provided below. If you do not have an existing
                  business complete only the expected incremental changes.
                </TitleTop>
              </Col>
              <Col md={12} xs={12}>
                <TitleBottom>Expected Grants Results/Impacts: </TitleBottom>
              </Col>
            </Row>
            <Row>
              {DefaultPerformanceIndicatorsFieldState.map(
                (indicator, index) => {
                  return (
                    <Col md={12} xs={12} key={`${indicator.name_of_indicator}`}>
                      <PerformanceIndicatorComponent
                        id={null}
                        control={control}
                        indicatorProps={indicator}
                        defaultFieldMapping={getDefaultIndicatorState({
                          keyname: indicator.name_of_indicator,
                          indicatorArray:
                            userData?.data?.profile?.grant_information
                              ?.performance_indicators,
                        })}
                        disabled={isPreview === true}
                        defaultOpen={isPrint || isPreview}
                        valueType={indicator.type}
                        placeholder={indicator.placeholder}
                      />
                    </Col>
                  );
                }
              )}
            </Row>

            <Row
              style={{
                marginTop: '30px',
                borderBottom: '1px solid #e1e1e1',
                borderTop: '1px solid #e1e1e1',
                marginBottom: '30px',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
            >
              <TitleTop>Other Indicators</TitleTop>
              {(expectedResultsLoader || isRefetching) && <FormLoader />}
              {(!expectedResultsLoader || !isRefetching) && (
                <>
                  {getOtherIndicators({
                    indicatorArray:
                      userData?.data?.profile?.grant_information
                        ?.performance_indicators,
                  })?.map((indicator) => {
                    return (
                      <PerformanceIndicatorComponent
                        id={indicator.id}
                        key={`${indicator?.name_of_indicator}`}
                        defaultFieldMapping={indicator}
                        type={'readonly'}
                        indicatorProps={indicator}
                        deletable={true}
                        onDelete={() => {
                          onIndicatorDelete(indicator.id);
                        }}
                        onEdit={onOtherIndicatorEdit}
                        disabled={false}
                        defaultOpen={isPrint || isPreview}
                      />
                    );
                  })}
                </>
              )}
            </Row>

            {!isPrint && !isPreview && (
              <form
                onSubmit={otherIndicatorHandleSubmit(onOtherIndicatorSubmit)}
                id={'indicator-form'}
              >
                <Row>
                  <Col md={12} xs={12}>
                    <GrantAccordionBase
                      expandable
                      title={
                        'Others (Include here and insert as many rows as needed any additional measurable indicators that grant support would directly impact incrementally.)'
                      }
                    >
                      <Row>
                        <Col md={12} xs={12}>
                          <ControlledTextInput
                            name="name_of_indicator"
                            title="Performance Indicator"
                            control={otherIndicatorControl}
                            placeholder="Name of Indicator"
                            rules={{ required: true }}
                            isDisabled={
                              isPreview == true || expectedResultsLoader
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} xs={12}>
                          <ControlledTextInput
                            name="pre_grant_state"
                            title="Before Grant Support (for existing busineses)"
                            control={otherIndicatorControl}
                            as={'textarea'}
                            placeholder={
                              'Your response should not be more than 20 words'
                            }
                            rules={{ required: true }}
                            isDisabled={
                              isPreview == true || expectedResultsLoader
                            }
                            isPrint={isPrint}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} xs={12}>
                          <ControlledTextInput
                            name="post_grant_state"
                            title="After grant support (expected incremental changes)"
                            control={otherIndicatorControl}
                            as={'textarea'}
                            placeholder={
                              'Your response should not be more than 20 words'
                            }
                            rules={{ required: true }}
                            isDisabled={
                              isPreview == true || expectedResultsLoader
                            }
                            isPrint={isPrint}
                          />
                        </Col>
                      </Row>
                      {/* <Row>
                  <Col md={12} xs={12}>
                    <ControlledTextInput
                      name="details_of_benefits"
                      title="Explain how grant support will translate into the expected incremental changes"
                      control={otherIndicatorControl}
                      as={'textarea'}
                      placeholder={
                        'Your response should not be more than 20 words'
                      }
                      rules={{ required: true }}
                      isDisabled={isPreview == true}
                    />
                  </Col>
                </Row> */}
                      <Row>
                        <Col md={12} xs={12}>
                          <AddButton
                            text="Save Other Performance Indicator"
                            onClick={otherIndicatorHandleSubmit(
                              onOtherIndicatorSubmit
                            )}
                            disabled={isPreview === true}
                          />
                        </Col>
                      </Row>
                    </GrantAccordionBase>
                  </Col>
                </Row>
              </form>
            )}
          </>
        )}
      </>
    </BusinessFormBaseLayout>
  );
};

export default ExpectedResults;
