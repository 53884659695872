import React from "react";
import { Col, Row } from "react-bootstrap";
import ResendForm from "./ResendForm";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const Resend = () =>{

    const {id} = useParams();


    return(
        <>
         <ToastContainer/>
            <Col sm={12} xs={12} md={12} className="auth-right" style={{justifyContent:'center',alignItems:'center'}}>
                <Row style={{height:'100vh'}}>
                    <Col>
                        <ResendForm id={id}/>
                    </Col>
                </Row>
            </Col>
        </> 
    )
}


export default Resend