
import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";



export default function LoanApplicationBaseLayout() {
    
    return(
        <Fragment>
            <ToastContainer/>
            <Outlet/>
        </Fragment>
    )

}




