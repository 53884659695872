/*eslint-disable*/
import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import styled from 'styled-components';

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormLoader = (props: IContentLoaderProps) => (
  <FormWrapper>
    <ContentLoader
      viewBox="0 0 400 160"
      height={160}
      width={400}
      backgroundColor="transparent"
      {...props}
    >
      <circle cx="150" cy="86" r="8" />
      <circle cx="194" cy="86" r="8" />
      <circle cx="238" cy="86" r="8" />
    </ContentLoader>
  </FormWrapper>
);

FormLoader.metadata = {
  name: 'RioF',
  github: 'clariokids',
  description: 'Three Dots',
  filename: 'ThreeDots',
};

export default FormLoader;
