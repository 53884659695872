/*eslint-disable*/
import React from 'react';
import BusinessFormBaseLayout from '@/views/GrantForm/BusinessFormBaseLayout';
import { Row, Col } from 'react-bootstrap';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import { useGetApplicantInformation2 } from '@/views/GrantForm/services/BusinessPlan/ApplicantInformation.service';
import ControlledSelectInput from '@/components/forms/FormInput/ControlledSelectInput';
import ControlledRadioButtonGroup from '@/components/forms/FormInput/ControlledRadioButton';
import InstructionText from '../../InstructionText';
import {
  geaTrainingOptions,
  genderOptionsGrants,
  pwdOptions,
} from '@/views/GrantForm/services/PersonalInformation.service';
import { IBusinessPlanFormStage } from '@/views/GrantForm/GrantForm.interfaces';
import { validateInputLength } from '@/views/GrantForm/services/GrantFormBaseLayout.service';
import { product_overview } from '@/utils/constants/skippedapplicantquestions';

const ApplicantInformation2 = ({
  isPreview,
  isPrint,
}: IBusinessPlanFormStage) => {
  const {
    control,
    watch,
    handleSubmit,
    formState,
    onSubmit,
    regions,
    userData,
    isLoading,
  } = useGetApplicantInformation2();

  return (
    <BusinessFormBaseLayout
      page={2}
      title={'Applicant Information'}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      isLoading={isLoading || !userData?.data?.profile}
      formStage="application_information_2"
      preview={isPreview}
      print={isPrint}
    >
      <Row>
        <Col md={6} xs={12}>
          <ControlledRadioButtonGroup
            control={control}
            name={'gender_of_owner'}
            title={'Gender of owner'}
            options={genderOptionsGrants}
            defaultValue={
              userData?.data?.profile?.grant_information?.applicant_information
                ?.gender_of_owner
            }
            value={
              isPreview
                ? userData?.data?.profile?.grant_information
                    ?.applicant_information?.gender_of_owner
                : null
            }
            isDisabled={isPreview == true}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledRadioButtonGroup
            control={control}
            name={'owner_is_disabled'}
            title={'Is the owner a person with disability PWD?'}
            options={pwdOptions}
            defaultValue={
              userData?.data?.profile?.grant_information?.applicant_information
                ?.owner_is_disabled == true
                ? 'Y'
                : 'N'
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information
                  ?.applicant_information?.owner_is_disabled,
            }}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information
                    ?.applicant_information?.owner_is_disabled == true
                  ? 'Y'
                  : 'N'
                : null
            }
            isDisabled={isPreview == true}
          />
        </Col>
      </Row>

      <Row>
        <Col md={12} xs={12}>
          <ControlledSelectInput
            control={control}
            name={'level_of_training_under_this_program'}
            title={'What level of training have you reached under the program?'}
            placeholder={'Region'}
            defaultValue={
              userData?.data?.profile?.grant_information?.applicant_information
                ?.level_of_training_under_this_program
            }
            rules={{
              required: {
                message: 'Other Name is Required',
                value:
                  !userData?.data?.profile?.grant_information
                    ?.applicant_information
                    ?.level_of_training_under_this_program,
              },
            }}
            options={geaTrainingOptions.filter((option) => {
              return option.value !== 'basic_training';
            })}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information
                    ?.applicant_information
                    ?.level_of_training_under_this_program
                : null
            }
            isDisabled={isPreview == true}
            isPrint={isPrint}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12} style={{ marginBottom: '20px' }}>
          <InstructionText
            title="Provide an overview of your enterprise, your product/service"
            subtitle={
              userData?.data?.skipped_advanced_training == false
                ? 'What sector do you operate in? Tell us about your product(s)/ services, production capacity, volume of production, product/ service lines, target market and size, exports (if any) and export destinations (if applicable). (Your responses must not be more than 500 words)'
                : product_overview
            }
          />
        </Col>
        <Col md={12} xs={12}>
          <ControlledTextInput
            control={control}
            name={'product_overview'}
            type={'text'}
            as={'textarea'}
            defaultValue={
              userData?.data?.profile?.grant_information?.applicant_information
                ?.product_overview
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information
                  ?.applicant_information?.product_overview,
              validate: {
                valid: (v) => {
                  return validateInputLength(v, 500);
                },
              },
            }}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information
                    ?.applicant_information?.product_overview
                : null
            }
            isDisabled={isPreview == true}
            placeholder="Your response should not be more than 500 words"
            isPrint={isPrint}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12}>
          <ControlledTextInput
            control={control}
            name={'how_did_you_identify_opportunity'}
            title={
              'Next, tell us about how the opportunity in your area of business or interest was identified.  (your responses must not be more than 500 words)'
            }
            type={'text'}
            as={'textarea'}
            defaultValue={
              userData?.data?.profile?.grant_information?.applicant_information
                ?.how_did_you_identify_opportunity
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information
                  ?.applicant_information?.how_did_you_identify_opportunity,
              validate: {
                valid: (v) => {
                  return validateInputLength(v, 500);
                },
              },
            }}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information
                    ?.applicant_information?.how_did_you_identify_opportunity
                : null
            }
            placeholder="Your response must not be more than 500 words"
            isDisabled={isPreview == true}
            isPrint={isPrint}
          />
        </Col>
      </Row>
    </BusinessFormBaseLayout>
  );
};

export default ApplicantInformation2;
