import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import ReduxThunk from 'redux-thunk';
import auth from './authentication/actions'
import user from './user/actions'
import training from './training/actions'





const rootReducer = combineReducers({
  auth,
  user,
  training
  
  
});
const middlewares = [ReduxThunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));

export default store;
