/*eslint-disable*/
import React from 'react';
import { useForm } from 'react-hook-form';
import {
  logError,
  useGetRegions,
  useGetUserObject,
} from '../GrantFormBaseLayout.service';
import {
  IGrantFormModel,
  IUserModel,
} from '@/utils/constants/userModel.interface';
import { AxiosResponse } from 'axios';
import { useGetGrantObject } from '../GrantFormBaseLayout.service';
import { IGrantUpdateParams } from '../../GrantForm.interfaces';
import { updateGrantInformation } from '@/api/User/User';
import { useNavigate } from 'react-router-dom';

export const useGetApplicantInformation = () => {
  const { control, watch, handleSubmit, formState } = useForm();
  const navigate = useNavigate();
  const {
    getRegionsData: regions,
    getRegionsError: regionsError,
    getRegionsLoading: regionsLoading,
  } = useGetRegions();
  const { userData, userLoading } = useGetUserObject();
  let _userData = userData as AxiosResponse<Partial<IUserModel>>;
  let grantUpdateParams: IGrantUpdateParams = {
    id: undefined,
    data: undefined,
  };

  const onSubmit = async (
    data: Partial<IGrantFormModel['applicant_information'] & any>
  ) => {
    try {
      grantUpdateParams.id = _userData.data.profile?.grant_information?.id;
      grantUpdateParams.data = {
        ..._userData?.data?.profile?.grant_information,
        enterprise_name: data.enterprise_name,
        address_line_1: data.address_line_1 || '*',
        address_line_2: data.address_line_2 || '*',
        applicant_information: {
          ..._userData?.data?.profile?.grant_information?.applicant_information,
          // ghana_card_number: data?.ghana_card_number,
          // digital_address: data?.digital_address,
          contact_person_name: data?.contact_person_name,
          contact_person_telephone: data?.contact_person_telephone,
          contact_person_email: data?.contact_person_email,
          region:
            parseInt(data?.region) ||
            _userData?.data?.profile?.grant_information?.applicant_information
              ?.region,
          city: data.city || '*',
        },
      };
      await updateGrantInformation(grantUpdateParams);
      navigate('/grants/businessplan/application_information_2');
    } catch (e) {
      logError(e);
    }
  };

  return {
    control,
    watch,
    handleSubmit,
    formState,
    onSubmit,
    regions: regions?.data.map((region: any) => {
      return { label: region.name, value: region.id };
    }),
    userData: _userData,
    isLoading: regionsLoading || userLoading,
    grantData: _userData?.data?.profile?.grant_information,
  };
};

export const useGetApplicantInformation2 = () => {
  const { control, watch, handleSubmit, formState } = useForm();
  const navigate = useNavigate();
  const {
    getRegionsData: regions,
    getRegionsError: regionsError,
    getRegionsLoading: regionsLoading,
  } = useGetRegions();

  const { userData, refetch, userError, userLoading } = useGetUserObject();
  let _userData = userData as AxiosResponse<Partial<IUserModel>>;
  _userData?.data?.profile?.grant_information?.succession_plan;
  let grantUpdateParams: IGrantUpdateParams = {
    id: undefined,
    data: undefined,
  };

  const onSubmit = async (
    data: Partial<IGrantFormModel['applicant_information']>
  ) => {
    try {
      grantUpdateParams.id = _userData.data.profile?.grant_information?.id;
      grantUpdateParams.data = {
        ..._userData?.data?.profile?.grant_information,
        applicant_information: {
          ..._userData?.data?.profile?.grant_information?.applicant_information,
          ...data,
        },
      };
      await updateGrantInformation(grantUpdateParams);
      navigate('/grants/businessplan/application_information_3');
    } catch (e) {
      logError(e);
    }
  };

  return {
    control,
    watch,
    handleSubmit,
    formState,
    onSubmit,
    regions: regions?.data.map((region: any) => {
      return { label: region.name, value: region.name };
    }),
    userData: _userData,
    isLoading: regionsLoading || userLoading,
  };
};

export const useGetApplicantInformation3 = () => {
  const { control, watch, handleSubmit, formState } = useForm();
  const navigate = useNavigate();
  const { userData, refetch, userError, userLoading } = useGetUserObject();
  let _userData = userData as AxiosResponse<Partial<IUserModel>>;
  let grantUpdateParams: IGrantUpdateParams = {
    id: undefined,
    data: undefined,
  };
  const onSubmit = async (
    data: Partial<IGrantFormModel['applicant_information']>
  ) => {
    try {
      grantUpdateParams.id = _userData?.data?.profile?.grant_information?.id;
      grantUpdateParams.data = {
        ..._userData?.data?.profile?.grant_information,
        applicant_information: {
          ..._userData?.data?.profile?.grant_information?.applicant_information,
          business_stage: data?.business_stage,
          product_has_fda_approval_or_now_applying:
            data?.product_has_fda_approval_or_now_applying,
          who_are_your_customers: data?.who_are_your_customers,
        },
      };
      await updateGrantInformation(grantUpdateParams);
      navigate('/grants/businessplan/application_information_4');
    } catch (e) {
      logError(e);
    }
  };

  return {
    control,
    watch,
    handleSubmit,
    formState,
    onSubmit,
    userData: _userData,
    isLoading: userLoading,
  };
};

export const useGetApplicantInformation4 = () => {
  const { control, watch, handleSubmit, formState } = useForm();
  const { userData, refetch, userError, userLoading } = useGetUserObject();
  const navigate = useNavigate();
  let grantUpdateParams: IGrantUpdateParams = {
    id: undefined,
    data: undefined,
  };
  let _userData = userData as AxiosResponse<Partial<IUserModel>>;
  const onSubmit = async (
    data: Partial<IGrantFormModel['applicant_information']>
  ) => {
    try {
      grantUpdateParams.id = _userData.data.profile?.grant_information?.id;
      grantUpdateParams.data = {
        ..._userData?.data?.profile?.grant_information,
        applicant_information: {
          ..._userData?.data?.profile?.grant_information?.applicant_information,
          ...data,
        },
      };
      await updateGrantInformation(grantUpdateParams);

      navigate(
        _userData?.data?.skipped_advanced_training == false
          ? '/grants/businessplan/project_information'
          : '/grants/businessplan/project_information_2'
      );
    } catch (e) {
      logError(e);
    }
  };

  return {
    control,
    watch,
    handleSubmit,
    formState,
    onSubmit,
    userData: _userData,
    isLoading: userLoading,
  };
};
