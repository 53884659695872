  import constant from "src/utils/constants/constants";
import { toast } from "react-toastify";
import {
  handleErrorMessage,
  setAccessToken,
} from "src/utils/helpers/functions";
import axios from "axios";

const errorAlert = (msg) => toast.error(msg);
// const successAlert = (msg) => toast.success(msg)


export function createAccount(data) {
  return  axios.post(`${constant.BASE_API}/client/auth/create-account`, data);
}

export function getSecretCode(data) {
  setAccessToken();
  return  axios.post(`${constant.BASE_API}/client/auth/account/get_secret_code`, data);
}

export function getVerificationCode(data) {
  setAccessToken();
  return  axios.post(`${constant.BASE_API}/client/auth/verification-code`, data);
}

export function verifyOtpCode(data) {
  setAccessToken();
  return  axios.post(`${constant.BASE_API}/client/auth/verification-code`, data);
}

// export const createAccount = (data, otpModal) => async (dispatch) => {
//   dispatch({ type: "PROCESS" });

//   await axios
//     .post(`${constant.BASE_API}/client/auth/create-account`, data)
//     .then(function (response) {
//       window.localStorage.setItem("user", JSON.stringify(response?.data));
//       window.localStorage.setItem(
//         "accessToken",
//         response.headers?.["set-auth-token"]
//       );
//       dispatch({
//         type: "CREATE_USER_SUCCESS",
//         payload: response?.data,
//         success: true,
//       });
//     })
//     .then(function () {
//       otpModal(true);
//     })
//     .catch(function (error) {
//       let errorMessage = handleErrorMessage(error?.response);

//       errorAlert(errorMessage);
//       dispatch({ type: "CREATE_USER_FAILED", error: errorMessage });
//     });
// };

export const verifyPhoneNumber = (data, redirectUrl) => async (dispatch) => {
  dispatch({ type: "PROCESS" });
  setAccessToken();

  await axios
    .post(`${constant.BASE_API}/client/auth/account/change_phone_number`, data)
    .then(function (response) {
      dispatch({
        type: "VERIFY_PHONE_NUMBER_SUCCESS",
        payload: response?.data,
        success: true,
      });
    })
    .then(function () {
      if(redirectUrl){
        window.location.replace(redirectUrl);
      }else{
        return
      }
    })
    .catch(function (error) {
      let errorMessage = handleErrorMessage(error?.response);
      errorAlert(errorMessage);
      dispatch({ type: "VERIFY_PHONE_NUMBER_FAILED", error: errorMessage });
    });
};



export const LoginVerificationCode = (data, otpModal) => async (dispatch) => {
  dispatch({ type: "PROCESS" });

  await axios
    .post(`${constant.BASE_API}/client/auth/login-verification-code`, data)
    .then(function (response) {
      // window.localStorage.setItem('user', JSON.stringify(response?.data));
      // window.localStorage.setItem('accessToken', response.headers?.['set-auth-token']);
      dispatch({
        type: "LOGIN_CODE_SUCCESS",
        payload: response?.data,
        success: true,
      });
    })
    .then(function () {
      otpModal(true);
    })
    .catch(function (error) {
      var errorMessage =
        error?.response?.data[0] || handleErrorMessage(error?.response);
      errorAlert(errorMessage);
      dispatch({ type: "LOGIN_CODE_FAILED", error: errorMessage });
    });
};

export function optLogin(data) {
  return  axios.post(`${constant.BASE_API}/client/auth/account/login`, data);
}

export function getOtp(data) {
  return axios.post(`${constant.BASE_API}/client/auth/login-verification-code`,data);
}

export const optLoginOld = (data) => async (dispatch) => {
  dispatch({ type: "PROCESS" });

  await axios
    .post(`${constant.BASE_API}/client/auth/account/login`, data)
    .then(function (response) {
      window.localStorage.setItem("user", JSON.stringify(response?.data));
      window.localStorage.setItem(
        "accessToken",
        response.headers?.["set-auth-token"]
      );
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: response?.data,
        success: true,
      });
    })
    .then(function () {
      window.location.replace("/dashboard");
    })
    .catch(function (error) {
      var errorMessage =
        error?.response?.data[0] || handleErrorMessage(error?.response);
      errorAlert(errorMessage);
      dispatch({ type: "LOGIN_FAILED", error: errorMessage });
    });
};

export const updateProfile = (data, redirectUrl) => async (dispatch) => {
  dispatch({ type: "PROCESS" });
  setAccessToken();

  const userId = data?.profile?.id;
  await axios
    .patch(`${constant.BASE_API}/client/auth/profile/${userId}`, data)
    .then(function (response) {
      window.localStorage.setItem('user', JSON.stringify(response?.data));
      dispatch({
        type: "UPDATE_PROFILE_SUCCESS",
        payload: response?.data,
        success: true,
      });
    })
    .then(function () {
      if(redirectUrl){
        window.location.replace(redirectUrl);
      }else{
        return
      }
    })
    .catch(function (error) {
      var errorMessage =
        error?.response?.data[0] || handleErrorMessage(error?.response);
      errorAlert(errorMessage);
      dispatch({ type: "UPDATE_PROFILE_FAILED", error: errorMessage });
    });
};

export const logOut = async () => {
  setAccessToken();

  await axios
    .post(`${constant.BASE_API}/client/auth/account/logout`)
    .then(function () {
      window.localStorage.clear();
    })
    .then(function () {
      window.location.replace("/login");
    })
    .catch(function () {
      localStorage.clear();
      window.location.replace("/login");
    });
};
