import { useQuery } from "react-query";
import { getCertificates } from "src/api/User/User";



export function useGetCertificates () 
{
  const {error,data,isLoading} = useQuery("get-certificates",getCertificates);
  const certificates = data?.data;
  return {certificates,error,isLoading};
}


export default function useGetProfileInfo(infoType, data) {


    var fullData = {
        personal_information:{
            marital_status:data?.profile?.marital_status,
            date_of_birth:data?.profile?.date_of_birth,
            has_children_below_age_12:data?.profile?.has_children_below_age_12,
            country:data?.profile?.country,
            is_bread_winner:data?.profile?.is_bread_winner,
            id_type:data?.profile?.id_type,
            id_number:data?.profile?.id_number,
            region:data?.profile?.region?.name,
            district:data?.profile?.district?.name,
            community:data?.profile?.address?.community,
            postal_address:data?.profile?.address?.zip,
            residential_address:data?.profile?.address?.address_line_1,
            digital_address:data?.profile?.digital_address,
            disability:data?.profile?.disability,
            languages_spoken:data?.profile?.languages_spoken,
        },
        education_information:{
            educational_level:data?.profile?.educational_level,
            previous_vocational_skills:data?.profile?.previous_vocational_skills,
            owns_a_business:data?.profile?.owns_a_business,
            business_sector:data?.business_profile?.industry?.name,
        },
        training_information:{
            desires_training_to_improve_business:data?.training_requests[0]?.desires_training_to_improve_business,
            training_needed:data?.training_requests[0]?.category?.name,
            has_previous_entrepreneurial_training:data?.training_requests[0]?.has_previous_entrepreneurial_training,
            previous_vocational_skills:data?.training_requests[0]?.previous_vocational_skills,
            previous_vocational_master_name:data?.training_requests[0]?.previous_vocational_master_name,
            previous_vocational_master_location:data?.training_requests[0]?.previous_vocational_master_location,
            has_vocational_certificate:data?.training_requests[0]?.previous_vocational_certificate ? true : false,
            previous_vocational_certificate:data?.training_requests[0]?.previous_vocational_certificate,
            desires_employment:data?.training_requests[0]?.desires_employment,
            can_start_business_without_support:data?.training_requests[0]?.can_start_business_without_support,
            business_support_required:data?.training_requests[0]?.business_support_required,
           
        },
        business_accumen:{
            desires_training_to_start_business:data?.training_requests[0]?.desires_training_to_start_business,
            can_evaluate_business_idea:data?.training_requests[0]?.can_evaluate_business_idea,
            tried_to_start_business:data?.training_requests[0]?.tried_to_start_business,
            tried_to_start_business_industry:data?.training_requests[0]?.tried_to_start_business_industry,
            what_happened_to_business:data?.training_requests[0]?.what_happened_to_business,
            operated_non_family_business:data?.training_requests[0]?.operated_other_business,
            parents_owned_business:data?.training_requests[0]?.parents_owned_business,
            worked_for_family_business:data?.training_requests[0]?.worked_for_family_business,
            people_you_know_who_own_business:data?.training_requests[0]?.people_you_know_who_own_business,
            has_previous_entrepreneurial_training:data?.training_requests[0]?.has_previous_entrepreneurial_training,
            able_to_receive_entrepreneurial_training:data?.training_requests[0]?.able_to_receive_entrepreneurial_training,
            has_business_plan:data?.training_requests[0]?.has_business_plan,
           
        },
        financial_literacy:{
            amount_needed_to_start_business:data?.training_requests[0]?.amount_needed_to_start_business,
            amount_you_can_contribute:data?.training_requests[0]?.amount_you_can_contribute,
            gift_expenditure_of_GHS_10_000_00:data?.training_requests[0]?.gift_expenditure,
            owns_bank_account:data?.training_requests[0]?.owns_bank_account,
            understands_tax_requirements:data?.training_requests[0]?.understands_tax_requirements,
            has_previous_financial_training:data?.training_requests[0]?.has_previous_financial_training,
            ever_created_business_plan_budget:data?.training_requests[0]?.ever_created_business_plan_budget,
            has_business_plan:data?.training_requests[0]?.has_business_plan,
            desires_training_to_create_plan_budget:data?.training_requests[0]?.desires_training_to_create_plan_budget,
           
        },
        other_information:{
            'How did you hear about Ghana Jobs and Skills Project':data?.profile?.recommending_media_channels,
           
        }
    }


  return fullData[infoType];
}
