
import React from "react";
import LoanApplicationLayout from "@components/layout/layouts/LoanApplicationLayout/LoanApplicationLayout";
import LoanSuccessPage from "@components/forms/Loan/LoanSuccessPage";


export default function LoanSuccess() {
    
    return(
        <LoanApplicationLayout page={6}>
            <LoanSuccessPage/>
        </LoanApplicationLayout>              
    )

}


