/*eslint-disable*/

import React from 'react';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import { Row, Col } from 'react-bootstrap';
import {
  successionPlanOptions,
  useGetImpactForm2,
} from '../../../services/ImpactForm.service';
import ControlledRadioButtonGroup from '@/components/forms/FormInput/ControlledRadioButton';
import GrantFormBaseLayout from '../../../GrantFormBaseLayout';
import { IGrantFormStage } from '@/views/GrantForm/GrantForm.interfaces';

const ImpactForm2 = ({ isPreview, isPrint }: IGrantFormStage) => {
  const {
    watch,
    control,
    handleSubmit,
    formState,
    onSubmit,
    userData,
    isLoading,
  } = useGetImpactForm2();
  return (
    <GrantFormBaseLayout
      page={8}
      title={'Impact'}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      isLoading={isLoading}
      stage={'impact2'}
      preview={isPreview}
      print={isPrint}
    >
      <Row>
        <Col md={12} xs={12}>
          <ControlledRadioButtonGroup
            options={successionPlanOptions}
            control={control}
            name={'has_succession_plan'}
            title={'Is there a succession plan for the business?'}
            rules={{
              required:
                !userData?.data?.profile?.grant_information
                  ?.has_succession_plan,
            }}
            defaultValue={
              userData?.data?.profile?.grant_information?.has_succession_plan ==
              true
                ? 'Y'
                : 'N'
            }
            isDisabled={isPreview == true}
          />
        </Col>
        <Col md={12} xs={12}>
          <ControlledTextInput
            control={control}
            name={'succession_plan'}
            as={'textarea'}
            title={
              'What plans are put in place to sustain operations after expiry of the grant support?'
            }
            defaultValue={
              userData?.data?.profile?.grant_information?.succession_plan
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information?.succession_plan,
            }}
            placeholder="What plans are put in place to sustain operations after expiry of the grant support?"
            isDisabled={isPreview == true}
          />
        </Col>
      </Row>
    </GrantFormBaseLayout>
  );
};

export default ImpactForm2;
