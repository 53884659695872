import React from "react";
import propTypes from "prop-types";
import { NavLink } from "react-router-dom";

export default function MainNavItem({ title, isTransparent, link }) {
  const style = ({ isActive }) => {
    return {
      fontSize: "15px",
      color: isTransparent ? "#FFF" : "black",
      marginLeft: "36px",
      martginTop: "12px",
      textDecoration: "none",
      textAlign: "center",
      lineHeight: "40px",
      borderBottom: isActive ? "2px solid #2a3b8f" : "none",
      fontWeight:'600',
    };
  };
  return (
    <NavLink className={'raleway'} style={style} href="#" to={link}>
      {title}
    </NavLink>
  );
}

MainNavItem.propTypes = {
  icon: propTypes.string,
  title: propTypes.string.isRequired,
  link: propTypes.string,
  isTransparent: propTypes.bool,
};
