/*eslint-disable*/
import React from 'react';
import { IPerformanceIndicator } from '../GrantForm.interfaces';
import { Row, Col } from 'react-bootstrap';
import GrantAccordionBase from './GrantAccordionBase';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import TableInputSaveButton from './TableInputSaveButton';
import { number } from 'yup';

const PerformanceIndicatorWrapper = styled.div`
  margin-bottom: 20px;
`;

const PerformanceIndicatorComponent = ({
  control,
  indicatorProps,
  defaultFieldMapping,
  type,
  id,
  deletable,
  disabled,
  defaultOpen,
  isPrint,
  isPreview,
  valueType,
  placeholder,
  onDelete,
  onEdit,
}: IPerformanceIndicator) => {
  const { control: defaultControl, watch } = useForm();
  const [editState, setEditState] = React.useState(false);
  return (
    <PerformanceIndicatorWrapper>
      <GrantAccordionBase
        expandable
        defaultOpen={
          defaultFieldMapping == undefined || defaultOpen ? true : false
        }
        title={
          type == 'readonly'
            ? defaultFieldMapping?.name_of_indicator || ''
            : indicatorProps?.title || ''
        }
        actions={deletable ? ['delete'] : undefined}
        onDelete={onDelete}
      >
        {type == 'readonly' && (
          <>
            <Row style={{ marginBottom: '20px' }}>
              <Col xs={12} md={12}>
                {!isPreview && !isPrint && (
                  <TableInputSaveButton
                    isEditing={editState}
                    setIsEditing={setEditState}
                    onEdit={onEdit}
                    params={{
                      id: id,
                      name_of_indicator: watch(
                        defaultFieldMapping.name_of_indicator
                      ),
                      pre_grant_state: watch(
                        defaultFieldMapping.pre_grant_state
                      ),
                      post_grant_state: watch(
                        defaultFieldMapping.post_grant_state
                      ),
                      details_of_benefits: watch(
                        defaultFieldMapping.details_of_benefits
                      ),
                    }}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <ControlledTextInput
                  control={control || defaultControl}
                  name={indicatorProps?.name_of_indicator || ''}
                  defaultValue={defaultFieldMapping?.name_of_indicator}
                  rules={{ required: true }}
                  title={'Name of Indicator '}
                  placeholder="Name of Indicator"
                  isDisabled={
                    (type == 'readonly'
                      ? editState == true
                        ? false
                        : true
                      : false) || disabled == true
                  }
                />
              </Col>
            </Row>
          </>
        )}

        <Row>
          <Col xs={12} md={12}>
            {valueType == 'number' && (
              <ControlledTextInput
                control={control || defaultControl}
                name={indicatorProps?.pre_grant_state || ''}
                defaultValue={defaultFieldMapping?.pre_grant_state}
                rules={{ required:true }}
                title={'Before grant support (for existing businesses) '}
                type="number"
                placeholder={
                  placeholder ||
                  'Your response should not be more than 20 words'
                }
                isDisabled={
                  (type == 'readonly'
                    ? editState == true
                      ? false
                      : true
                    : false) || disabled == true
                }
              />
            )}
            {(valueType == 'alphanumeric' || valueType !== 'number') && (
              <ControlledTextInput
                control={control || defaultControl}
                name={indicatorProps?.pre_grant_state || ''}
                defaultValue={defaultFieldMapping?.pre_grant_state}
                rules={{ required: true }}
                title={'Before grant support (for existing businesses) '}
                as="textarea"
                placeholder={
                  placeholder ||
                  'Your response should not be more than 20 words'
                }
                isDisabled={
                  (type == 'readonly'
                    ? editState == true
                      ? false
                      : true
                    : false) || disabled == true
                }
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            {valueType == 'number' && (
              <ControlledTextInput
                control={control || defaultControl}
                name={indicatorProps?.post_grant_state || ''}
                defaultValue={defaultFieldMapping?.post_grant_state}
                rules={{ required: true}}
                title={'After grant support (expected incremental changes)'}
                type="number"
                placeholder={
                  placeholder ||
                  'Your response should not be more than 20 words'
                }
                isDisabled={
                  (type == 'readonly'
                    ? editState == true
                      ? false
                      : true
                    : false) || disabled == true
                }
              />
            )}
            {(valueType == 'alphanumeric' || valueType !== 'number') && (
              <ControlledTextInput
                control={control || defaultControl}
                name={indicatorProps?.post_grant_state || ''}
                defaultValue={defaultFieldMapping?.post_grant_state}
                rules={{ required: true }}
                title={'After grant support (expected incremental changes)'}
                type="text"
                as="textarea"
                placeholder={
                  placeholder ||
                  'Your response should not be more than 20 words'
                }
                isDisabled={
                  (type == 'readonly'
                    ? editState == true
                      ? false
                      : true
                    : false) || disabled == true
                }
              />
            )}
          </Col>
        </Row>
        {/* <Row>
          <Col xs={12} md={12}>
            <ControlledTextInput
              control={control || defaultControl}
              name={indicatorProps?.details_of_benefits || ''}
              defaultValue={defaultFieldMapping?.details_of_benefits}
              title={
                'Explain how grant support will translate into the expected incremental changes'
              }
              rules={{ required: !defaultFieldMapping?.details_of_benefits }}
              as={'textarea'}
              placeholder="Your response should not be more than 20 words"
              isDisabled={type == 'readonly' || disabled == true}
            />
          </Col>
        </Row> */}
      </GrantAccordionBase>
    </PerformanceIndicatorWrapper>
  );
};

export default PerformanceIndicatorComponent;
