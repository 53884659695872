/*eslint-disable*/
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { IRevenueSubform } from '../../../GrantForm.interfaces';
import React from 'react';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import RevenueInput from '../../RevenueInput';

const SubFormRow = styled(Row)`
  margin-bottom: 10px;
`;

const RevenueSubform = ({
  control,
  fieldNames,
  defaultValueMapping,
  isDisabled,
  watch,
}: IRevenueSubform) => {
  return (
    <Row style={{ paddingTop: '1rem' }}>
      <SubFormRow>
        <Col md={6} xs={12}>
          <RevenueInput
            control={control}
            name={fieldNames[0]}
            placeholder={'Projected Sales/Turnover'}
            rules={{ required: !defaultValueMapping?.projected_turnover }}
            type="number"
            title={'Projected Sales/Turnover'}
            defaultValue={defaultValueMapping?.projected_turnover}
            isDisabled={isDisabled == true}
          />
        </Col>
        <Col md={6} xs={12}>
          <RevenueInput
            control={control}
            name={fieldNames[1]}
            placeholder={'Projected Operating Cost'}
            rules={{ required: !defaultValueMapping?.projected_operating_cost }}
            title={'Projected Operating Cost'}
            type="number"
            defaultValue={defaultValueMapping?.projected_operating_cost}
            isDisabled={isDisabled == true}
          />
        </Col>
      </SubFormRow>
      <SubFormRow>
        <Col md={6} xs={12}>
          <RevenueInput
            control={control}
            name={fieldNames[2]}
            placeholder={'Projected Net Profit'}
            rules={{ required: false }}
            title={
              'Projected Net Profit (That is, Projected Sales/Turnover less Projected Operating Cost)'
            }
            type="number"
            defaultValue={defaultValueMapping?.projected_gross_profit}
            isDisabled={isDisabled == true}
            value={
              (parseInt(watch(fieldNames[0])) || 0) -
              (parseInt(watch(fieldNames[1])) || 0)
            }
          />
        </Col>
        {/* <Col md={6} xs={12}>
          <RevenueInput
            control={control}
            name={fieldNames[3]}
            placeholder={'Projected Net Cash Flow'}
            rules={{ required: !defaultValueMapping?.projected_net_cash_flow }}
            title={'Projected Net Cash Flow'}
            defaultValue={defaultValueMapping?.projected_net_cash_flow}
            isDisabled={isDisabled == true}
            type="number"
          />
        </Col> */}
      </SubFormRow>
    </Row>
  );
};

export default RevenueSubform;
