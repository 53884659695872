
import React from "react";
import LoanApplicationLayout from "@components/layout/layouts/LoanApplicationLayout/LoanApplicationLayout";
import BusinessSecondGuarantorForm from "@components/forms/Loan/BusinessSecondGuarantorForm";


export default function BusinessSecondGuarantor() {
    
    return(
        <LoanApplicationLayout page={5}>
            <BusinessSecondGuarantorForm/>
        </LoanApplicationLayout>              
    )

}


