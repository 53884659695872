import React from "react";
import { Card } from "react-bootstrap";
import propTypes from "prop-types";

export default function HowToApplyCard({ image, title, text }) {
  const cardStyle = {
    width: "100%",
    height: "auto",
    border: "none",
  };
  const cardImageStyle = {
    height: "50px",
    width: "auto",
  };
  return (
    <Card style={cardStyle}>
      {image ? (
        <Card.Img variant="top" src={image} style={cardImageStyle} />
      ) : null}
      <Card.Body>
        <Card.Title style={{fontWeight:'600'}} className="raleway">{title}</Card.Title>
        <Card.Text className="mt-3 lh-base big-label-2" style={{lineHeight:'2rem'}}>{text}</Card.Text>
      </Card.Body>
    </Card>
  );
}

HowToApplyCard.propTypes = {
  image: propTypes.string,
  title: propTypes.string,
  text: propTypes.string,
};
