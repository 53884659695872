/*eslint-disable*/
import React from 'react';
import BusinessFormBaseLayout from '@/views/GrantForm/BusinessFormBaseLayout';
import { Row, Col, Form } from 'react-bootstrap';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import { useGetApplicantInformation } from '@/views/GrantForm/services/BusinessPlan/ApplicantInformation.service';
import ControlledSelectInput from '@/components/forms/FormInput/ControlledSelectInput';
import { validateDigitalAddress } from '@/views/GrantForm/services/PersonalInformation.service';
import { Box } from '@mui/material';
import constant from '@/utils/constants/constants';
import { IBusinessPlanFormStage } from '@/views/GrantForm/GrantForm.interfaces';

const ApplicantInformation = ({
  isPreview,
  isPrint,
}: IBusinessPlanFormStage) => {
  const { control, handleSubmit, onSubmit, regions, userData, isLoading } =
    useGetApplicantInformation();
  return (
    <BusinessFormBaseLayout
      page={2}
      title={'Applicant Information'}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      isLoading={isLoading}
      formStage="application_information"
      preview={isPreview}
      print={isPrint}
    >
      <Row>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'first_name'}
            defaultValue={userData?.data?.user?.first_name}
            isDisabled={true}
            title={'First Name'}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'last_name'}
            title={'Last Name'}
            defaultValue={userData?.data?.user?.last_name}
            isDisabled={true}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'middle_name'}
            defaultValue={userData?.data?.user?.middle_name}
            isDisabled={true}
            title={'Other Name'}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'phone_number'}
            title={'Phone Number'}
            defaultValue={userData?.data?.user?.phone_number}
            isDisabled={true}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'enterprise_name'}
            title={'Enterprise Name'}
            placeholder={'Enterprise Name'}
            defaultValue={
              userData?.data?.profile?.grant_information?.enterprise_name
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information?.enterprise_name,
            }}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information?.enterprise_name
                : null
            }
            isDisabled={isPreview == true}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'id_number'}
            title={'ID Number'}
            placeholder={'ID Number'}
            defaultValue={userData?.data?.profile?.id_number}
            // rules={{
            //   required: {
            //     value:
            //       !userData?.data?.profile?.grant_information
            //         ?.applicant_information?.ghana_card_number,
            //     message: 'Field is required',
            //   },
            //   pattern: {
            //     value: constant.GHANA_CARD,
            //     message: 'Input a valid ID number',
            //   },
            // }}
            value={isPreview ? userData?.data?.profile?.id_number : null}
            isDisabled={true}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12}>
          <Form.Label style={{ fontWeight: '800' }}>
            Physical address of operating location/proposed operating location
          </Form.Label>
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <ControlledSelectInput
            control={control}
            name={'region'}
            title="Region"
            placeholder={'Region'}
            defaultValue={
              userData?.data?.profile?.grant_information?.applicant_information
                ?.region
            }
            rules={{
              required: {
                message: 'Other Name is Required',
                value:
                  !userData?.data?.profile?.grant_information
                    ?.applicant_information?.region,
              },
            }}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information
                    ?.applicant_information?.region
                : null
            }
            options={regions}
            isDisabled={isPreview == true}
            isPrint={isPrint}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'city'}
            title={' '}
            placeholder={'City'}
            defaultValue={
              userData?.data?.profile?.grant_information?.applicant_information
                ?.city == '*'
                ? ''
                : userData?.data?.profile?.grant_information
                    ?.applicant_information?.city
            }
            rules={{
              required: {
                message: 'Last Name is Required',
                value: false,
              },
            }}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information
                    ?.applicant_information?.city
                : null
            }
            isDisabled={isPreview == true}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <ControlledTextInput
            name={'address_line_1'}
            title={''}
            control={control}
            placeholder={'Address 1'}
            defaultValue={
              userData?.data?.profile?.grant_information?.address_line_1 == '*'
                ? ''
                : userData?.data?.profile?.grant_information?.address_line_1
            }
            rules={{
              required: {
                message: 'Last Name is Required',
                value: false,
              },
            }}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information?.address_line_1 ==
                  '*'
                  ? ''
                  : userData?.data?.profile?.grant_information?.address_line_1
                : null
            }
            isDisabled={isPreview == true}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            name={'address_line_2'}
            title={''}
            control={control}
            placeholder={'Address 2'}
            defaultValue={
              userData?.data?.profile?.grant_information?.address_line_2 == '*'
                ? ''
                : userData?.data?.profile?.grant_information?.address_line_2
            }
            rules={{
              required: {
                message: 'Last Name is Required',
                value: false,
              },
            }}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information?.address_line_2 ==
                  '*'
                  ? ''
                  : userData?.data?.profile?.grant_information?.address_line_2
                : null
            }
            isDisabled={isPreview == true}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            title={'Digital Address'}
            name={'digital_address'}
            placeholder="XX-XXX-XXXX"
            defaultValue={userData?.data?.profile?.digital_address}
            // rules={{
            //   required: {
            //     message: 'Enter a valid digital address',
            //     value:
            //       !userData?.data?.profile?.digital_address
            //   },
            //   validate: {
            //     valid: (v) => {
            //       return validateDigitalAddress(
            //         v,
            //         userData?.data?.profile?.digital_address
            //       );
            //     },
            //   },
            // }}
            value={isPreview ? userData?.data?.profile?.digital_address : null}
            isDisabled={isPreview == true || true}
          />
          <Box>
            <span style={{ fontStyle: 'italic' }}>
              Format: XX-XXX-XXXX or XX-XXXX-XXXX or XX-XXXXX-XXXX
            </span>
          </Box>
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'contact_person_name'}
            title={'Contact Person Name'}
            type={'text'}
            defaultValue={
              userData?.data?.profile?.grant_information?.applicant_information
                ?.contact_person_name
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information
                  ?.applicant_information?.contact_person_name,
            }}
            placeholder="Contact Person Name"
            isDisabled={isPreview == true}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information
                    ?.applicant_information?.contact_person_name
                : null
            }
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'contact_person_telephone'}
            title={'Contact Person Telephone Number'}
            type={'number'}
            defaultValue={
              userData?.data?.profile?.grant_information?.applicant_information
                ?.contact_person_telephone
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information
                  ?.applicant_information?.contact_person_telephone,
              pattern: {
                value: constant.PHONE_NUMBER,
                message: 'Enter a valid phone number',
              },
            }}
            placeholder="Contact Person Telephone"
            isDisabled={isPreview == true}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information
                    ?.applicant_information?.contact_person_telephone
                : null
            }
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'contact_person_email'}
            title={'Contact Person email'}
            type={'text'}
            defaultValue={
              userData?.data?.profile?.grant_information?.applicant_information
                ?.contact_person_email
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information
                  ?.applicant_information?.contact_person_email,
            }}
            placeholder="Contact Person Email"
            isDisabled={isPreview == true}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information
                    ?.applicant_information?.contact_person_email
                : null
            }
          />
        </Col>
      </Row>
    </BusinessFormBaseLayout>
  );
};

export default ApplicantInformation;
