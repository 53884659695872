/*eslint-disable*/
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
export const useDisclaimer = () => {
  const { control, register, handleSubmit, watch, formState } = useForm();
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    navigate('/grants/businessplan/signature');
  };

  return { control, register, handleSubmit, onSubmit, watch, formState };
};

/**Should this grant application/request be approved, I agree:
To apply any grant funding received expressly for the approved purpose 
To refund in full, and on demand, any grant funding received, if, my company/firm is found to 	have provided misleading information, whether deliberate or accidental, on this application. 
To provide, throughout the grant period, progress updates on the use of the grant funds and the delivery of the purpose of the grant to GEA in the manner and frequency as GEA may direct.  */
