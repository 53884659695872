// import axios from 'axios';
// import { toast } from 'react-toastify';

const INITIAL_STATE = {
    loading: false,
    user: JSON.parse(window.localStorage.getItem('user')),
    error: null,
    success:null,
};

const User = (state = INITIAL_STATE, action) => {
    const { type, payload, error, success} = action;
    switch (type) {
        case 'USER_PROCESS':
            return { ...state, loading: true };
        case 'UPDATE_USER_SUCCESS':
            return { user: payload, success:success, loading:false};
        case 'UPDATE_USER_FAILED':
            return { ...state, error:error, loading:false};
        default:
            return state;
    }
};

export default User;


