import React from "react";
import propTypes from "prop-types";

export default function LoanIncompleteCard({ children }) {
  const style = {
    background: "white",
    borderRadius: "5px",
  };
  return (
    <div
      className="d-flex p-4 justify-content-between small-shadow"
      style={style}
    >
      <div className="my-auto">
        <h6 className="fw-bold">Your application is incomplete</h6>
        <p className="mt-3">You have to complete the application</p>
      </div>
      <div className="my-auto">{children}</div>
    </div>
  );
}

LoanIncompleteCard.propTypes = {
  children: propTypes.node,
};
