/*eslint-disable*/
import React, { Fragment } from 'react';
import propTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import { IControlledSelectInput } from '../../../utils/constants/globals.interfaces';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';

const ErrorMessageWrapper = styled.div`
  font-size: 15px;
  color: red;
  margin-top: 5px;
`;

const ControlledSelectInput = ({
  name,
  title,
  isRequired,
  isDisabled,
  placeholder,
  defaultValue,
  options,
  control,
  value,
  isPrint,
  ...props
}: IControlledSelectInput) => {
  return (
    <Fragment>
      <Form.Label className="controlled-input-label">
        {title || ''}{' '}
        {isRequired && <b className="text-danger fs-6 fw-bold">*</b>}
      </Form.Label>

      <Controller
        name={name}
        control={control}
        {...props}
        render={({
          field: { name, onChange, value },
          fieldState: { error },
        }) => {
          return (
            <div className="controlled-input-wrapper">
              {isPrint && (
                <Form.Control
                  name={name}
                  defaultValue={
                    options.filter((option) => {
                      return (
                        option.value == value || option.value == defaultValue
                      );
                    })?.[0]?.label
                  }
                  value={
                    options.filter((option) => {
                      return (
                        option.value == value || option.value == defaultValue
                      );
                    })?.[0]?.label
                  }
                  style={{
                    border: '1px solid #DFE2E5',
                  }}
                  disabled={true}
                />
              )}
              {!isPrint && (
                <Form.Select
                  name={name}
                  required={isRequired}
                  placeholder={placeholder}
                  disabled={isDisabled}
                  defaultValue={defaultValue}
                  value={value}
                  onChange={onChange}
                >
                  <option value={undefined} selected={value == undefined}>
                    {''}
                  </option>
                  {options &&
                    options.map((o, idx) => (
                      <option key={idx} value={o.value}>
                        {o.label}
                      </option>
                    ))}
                </Form.Select>
              )}

              <ErrorMessageWrapper>
                {error && '*This field is required'}
              </ErrorMessageWrapper>
            </div>
          );
        }}
      />
    </Fragment>
  );
};

export default ControlledSelectInput;
