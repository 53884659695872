import React from "react";
import Image from "react-bootstrap/Image";
// import youstartlogo from "@images/logo/Logo.svg";
// import youstartlogoDark from "@images/logo/Dark_logo.svg";
import yeep_logo_1 from "@images/logo/yeep_logo-1.svg";
import propTypes from "prop-types";
import gealogo from "@images/logo/gealogo_web_white.svg";
import gealogoDefault from "@images/logo/gealogo_web.svg";

const logoVariants = {
  normal: {
    height: "80px",
    width: "150px",
  },
  large: {
    // height: "120px",
    width: "7.8rem",
  },
};

const Logo = ({ className, type }) => {
  const logoStyle = type ? logoVariants[type] : logoVariants.normal;
  return <Image src={yeep_logo_1} className={className} style={logoStyle} />;
};
Logo.propTypes = {
  className: propTypes.string,
  type: propTypes.string,
};

export const LogoDark = ({ className, type }) => {
  const logoStyle = type ? logoVariants[type] : logoVariants.normal;
  return (
    <Image src={yeep_logo_1} className={className} style={type && logoStyle} />
  );
};

LogoDark.propTypes = {
  className: propTypes.string,
  type: propTypes.string,
};

export const Required = () => {
  return <b className="text-danger fs-6">*</b>;
};

export const ErrorText = ({ error }) => {
  return <span className="text-danger">{error}</span>;
};

ErrorText.propTypes = {
  error: propTypes.string,
};

export const GeaLogo = () => {
  const logoStyle = {
    height: "56px",
    width: "56px",
    margin: "12px",
  };
  return <Image src={gealogo} style={logoStyle} />;
};

export const GeaLogoDefault = () => {
  const logoStyle = {
    height: "56px",
    width: "56px",
    margin: "12px",
  };
  return <Image src={gealogoDefault} style={logoStyle} />;
};
export default Logo;
