import MainButton from '@components/buttons/Buttons';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getUserProfile } from 'src/api/User/User';
import BusniessAccumen from './Forms/BusinessAccumen';
import Education from './Forms/Education';
import FinancialLiteracy from './Forms/FinancialLiteracy';
import OtherInformationVerification from './Forms/OtherInformationVerification';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import PersonalInformation from './Forms/PersonalInformation';
import Training from './Forms/Training';

export default function ApplicationVerification() {
  const {
    error,
    data: responseData,
    isLoading,
  } = useQuery('user-data-verifcation', getUserProfile);

  const [tabState, setTabState] = React.useState(0);

  const navigate = useNavigate();
  const state = responseData?.data;
  const otherIndustry = responseData?.data?.business_profile?.other_industry;
  var owns_a_business = responseData?.data?.profile?.owns_a_business;
  const fullData = {
    ...state?.profile,
    other_industry: otherIndustry,
    business_profile: state?.business_profile,
  };

  if (isLoading) return <h1>Loading...</h1>;

  const disabled = true;
  if (error)
    return (
      <div>
        <h1>there was an error</h1>
      </div>
    );

  const training_requests = {
    ...state?.training_requests[0],
    owns_a_business: owns_a_business,
  };

  const goToButtonStyle = {
    color: 'white',
    padding: '1rem',
    borderRadius: '0.4rem',
    backgroundColor: '#2a3b8f',
    width: '150px',
    textAlign: 'center',
    fontSize: '0.9rem',
  };

  const handleTabChange = (event, value) => {
    setTabState(value);
  };

  // #2a3b8f

  return (
    <>
      <Col md={9}>
        {/* <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="profile" title="Profile">
          <Box className={'form-back-btn'} 
          onClick={()=>{navigate('/onboarding/personal_information')}}
          sx={goToButtonStyle}
          >Back to Form Page
          </Box>
          <PersonalInformation disabled={disabled} state={state?.profile} />
        </Tab>
        <Tab eventKey="education" title="Educational Information">
        <Box className={'form-back-btn'} 
        onClick={()=>{navigate('/onboarding/education')}}
        sx={goToButtonStyle}
        >Back to Form Page
        </Box>
          <Education disabled={disabled} state={fullData} />
        </Tab>
        <Tab eventKey="trianing" title="Training">
        <Box className={'form-back-btn'} 
        onClick={()=>{navigate('/onboarding/training')}}
        sx={goToButtonStyle}
        >Back to Form Page
        </Box>
          <Training disabled={disabled} state={training_requests} />
        </Tab>
        <Tab eventKey="business_accumen" title="Business Acumen">
        <Box className={'form-back-btn'} 
        onClick={()=>{navigate('/onboarding/accumen')}}
        sx={goToButtonStyle}
        >Back to Form Page
        </Box>
          <BusniessAccumen disabled={disabled} state={training_requests} />
        </Tab>
        <Tab eventKey="financial_literacy" title="Financial Literacy">
        <Box className={'form-back-btn'} 
        onClick={()=>{navigate('/onboarding/financial_literacy')}}
        sx={goToButtonStyle}
        >Back to Form Page
        </Box>
          <FinancialLiteracy disabled={disabled} state={training_requests} />
        </Tab>
        <Tab eventKey="other_information" title="Other Information">
        <Box className={'form-back-btn'} 
        onClick={()=>{navigate('/onboarding/other_information')}}
        sx={goToButtonStyle}
        >Back to Form Page
        </Box>
          <OtherInformationVerification disabled={disabled} state={state?.profile} />
        </Tab>
      </Tabs> */}
        <Tabs
          className="custom-tabs"
          value={tabState}
          onChange={handleTabChange}
          style={{ borderBottom: '0.5px solid #EBEFF2' }}
          aria-label="basic tabs example"
        >
          <Tab label="Profile" value={0} />
          <Tab label="Educational Information" value={1} />
          <Tab label="Training" value={2} />
          <Tab label="Business Acumen" value={3} />
          <Tab label="Financial Literacy" value={4} />
          <Tab label="Other Information" value={5} />
        </Tabs>

        {tabState == 0 && (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box
                className={'form-back-btn'}
                onClick={() => {
                  navigate('/onboarding/personal_information');
                }}
                sx={goToButtonStyle}
                style={{ marginRight: '1rem' }}
              >
                Edit Page Info
              </Box>
            </Box>
            <PersonalInformation disabled={disabled} state={state?.profile} />
          </>
        )}
        {tabState == 1 && (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box
                className={'form-back-btn'}
                onClick={() => {
                  navigate('/onboarding/education');
                }}
                sx={goToButtonStyle}
                style={{ marginRight: '1rem' }}
              >
                Edit Page Info
              </Box>
            </Box>
            <Education disabled={disabled} state={fullData} />
          </>
        )}
        {tabState == 2 && (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box
                className={'form-back-btn'}
                onClick={() => {
                  navigate('/onboarding/training');
                }}
                sx={goToButtonStyle}
                style={{ marginRight: '1rem' }}
              >
                Edit Page Info
              </Box>
            </Box>
            <Training disabled={disabled} state={training_requests} />
          </>
        )}
        {tabState == 3 && (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box
                className={'form-back-btn'}
                onClick={() => {
                  navigate('/onboarding/accumen');
                }}
                sx={goToButtonStyle}
                style={{ marginRight: '1rem' }}
              >
                Edit Page Info
              </Box>
            </Box>
            <BusniessAccumen disabled={disabled} state={training_requests} />
          </>
        )}

        {tabState == 4 && (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box
                className={'form-back-btn'}
                onClick={() => {
                  navigate('/onboarding/financial_literacy');
                }}
                sx={goToButtonStyle}
                style={{ marginRight: '1rem' }}
              >
                Edit Page Info
              </Box>
            </Box>
            <FinancialLiteracy disabled={disabled} state={training_requests} />
          </>
        )}
        {tabState == 5 && (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box
                className={'form-back-btn'}
                onClick={() => {
                  navigate('/onboarding/other_information');
                }}
                sx={goToButtonStyle}
                style={{ marginRight: '1rem' }}
              >
                Edit Page Info
              </Box>
            </Box>
            <OtherInformationVerification
              disabled={disabled}
              state={state?.profile}
            />
          </>
        )}

        <Row className="mt-3 mb-5">
          <Col md={4} className="mb-3">
            <Link
              to="/onboarding/other_information"
              className="text-decoration-none"
            >
              <MainButton
                text={'Previous'}
                type={'button'}
                variant="outline-secondary"
                size="lg"
                className="fs-6"
              />
            </Link>
          </Col>
          <Col md={{ span: 4, offset: 4 }}>
            <Link
              to="/onboarding/application_terms"
              className="text-decoration-none"
            >
              <MainButton
                text={'Save and Continue'}
                type={'button'}
                variant="primary"
                size="lg"
                className="fs-6"
              />
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
}
