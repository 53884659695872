/*eslint-disable */
import React from 'react';
import {
  ActivityTableColumns,
  monthsArray,
  useGetProjectInformation,
  yearsArray,
} from '@/views/GrantForm/services/BusinessPlan/ProjectInformation.service';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import BusinessFormBaseLayout from '@/views/GrantForm/BusinessFormBaseLayout';
import { Row, Col } from 'react-bootstrap';
import RevenueInput from '../../RevenueInput';
import GrantAccordionBase from '../../GrantAccordionBase';
import AddButton from '../../AddButton';
import ControlledSelectInput from '@/components/forms/FormInput/ControlledSelectInput';
import ActivityComponent from '../../ActivityComponent';
import { IBusinessPlanFormStage } from '@/views/GrantForm/GrantForm.interfaces';
import InstructionText from '../../InstructionText';
import FormLoader from '../../FormLoader';
import FormInputTable from '@/components/tables/FormInputTable';
import {
  FormTableInputOptions,
  validateInputLength,
} from '@/views/GrantForm/services/GrantFormBaseLayout.service';
import { Watch } from '@mui/icons-material';

const ProjectInformation = ({ isPreview, isPrint }: IBusinessPlanFormStage) => {
  const {
    activityControl,
    control,
    isRefetching,
    activityLoader,
    subActivityControl,
    userData,
    userLoading,
    onSubmitPage,
    activityHandleSubmit,
    mainFormWatch,
    subActivityHandleSubmit,
    onSubmitActivity,
    onSubmitSubActivity,
    moveToNext,
    onEditActivity,
    handleSubmit,
    onDelete,
    loadDisplayTableRows,
  } = useGetProjectInformation();
  return (
    <BusinessFormBaseLayout
      page={3}
      title={'Project Information'}
      isLoading={userLoading}
      formStage="project_information"
      preview={isPreview}
      print={isPrint}
      onSubmit={onSubmitPage}
      handleSubmit={handleSubmit}
    >
      <Row>
        <Col md={12} xs={12}>
          <InstructionText
            title={
              'Please provide details of your project: How will the proposed project activities be viably implemented, both technically and financially? '
            }
            subtitle="In particular, Outline the sequence of activities planned; using the work plan template provided below. Include additional rows and columns as necessary."
          />
        </Col>
        {!isPreview && (
          <Col md={12} xs={12}>
            <GrantAccordionBase expandable={true} title={'Create Activity'}>
              <form onSubmit={activityHandleSubmit(onSubmitActivity)}>
                <Row>
                  <Col md={12} xs={12}>
                    <ControlledTextInput
                      control={activityControl}
                      name={'activity_name'}
                      title={'Activity Name'}
                      placeholder="Activity Name"
                      defaultValue=""
                      rules={{ required: true }}
                      isDisabled={activityLoader}
                    />
                  </Col>
                  <Col md={12} xs={12}>
                    <ControlledTextInput
                      control={activityControl}
                      name={'detailed_activity_description'}
                      title={'Detailed output and activity description'}
                      placeholder="Detailed output and activity description"
                      as={'textarea'}
                      defaultValue={''}
                      rules={{ required: true }}
                      isDisabled={activityLoader}
                      isPrint={isPrint}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4} xs={12}>
                    <ControlledSelectInput
                      control={activityControl}
                      name={'year'}
                      title={'Year'}
                      placeholder="Activity Name"
                      defaultValue={undefined}
                      options={yearsArray}
                      rules={{ required: true }}
                    />
                  </Col>
                  <Col md={4} xs={12}>
                    <ControlledSelectInput
                      control={activityControl}
                      name={'start_month'}
                      title={'Start Month'}
                      defaultValue={undefined}
                      options={monthsArray}
                      rules={{ required: true }}
                    />
                  </Col>
                  <Col md={4} xs={12}>
                    <ControlledSelectInput
                      control={activityControl}
                      name={'end_month'}
                      title={'End Month'}
                      defaultValue={undefined}
                      placeholder="Activity Name"
                      options={monthsArray}
                      rules={{ required: true }}
                      isDisabled={true}
                    />
                  </Col>
                </Row>
              </form>
              {/* <Row>
              <Col md={12} xs={12}>
                <GrantAccordionBase
                  title={'Create Sub Activity'}
                  expandable={true}
                >
                  <form onSubmit={subActivityHandleSubmit(onSubmitSubActivity)}>
                    <Row>
                      <Col md={12} xs={12}>
                        <ControlledTextInput
                          name={'activity_name'}
                          title={'Activity Name'}
                          control={subActivityControl}
                          rules={{ required: true }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} xs={12}>
                        <ControlledTextInput
                          name={'activity_description'}
                          title={'Detailed output and activity description'}
                          control={subActivityControl}
                          rules={{ required: true }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} xs={12}>
                        <ControlledTextInput
                          name={'activity_date'}
                          title={'Activity Start Date'}
                          control={subActivityControl}
                          type="date"
                          rules={{ required: true }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} xs={12}>
                        <AddButton text={'Sub-Activity'} />
                      </Col>
                    </Row>
                  </form>
                </GrantAccordionBase>
              </Col>
            </Row> */}
            </GrantAccordionBase>
          </Col>
        )}
      </Row>
      {!isPreview && (
        <Row style={{ marginTop: '40px', marginBottom: '40px' }}>
          <Col md={12} xs={12}>
            <AddButton
              text={'Add Activity'}
              onClick={activityHandleSubmit(onSubmitActivity)}
            />
          </Col>
        </Row>
      )}
      {(activityLoader || isRefetching) && <FormLoader />}

      <Row style={{ marginTop: '20px' }}>
        <Col md={12} xs={12}>
          <ControlledTextInput
            control={control}
            name={'additional_marketing_information'}
            title={
              'Additional information about your marketing plan (not more than a 100 words)'
            }
            placeholder="Detailed output and activity description"
            as={'textarea'}
            rules={{
              required:{
                value:!userData?.data?.profile?.grant_information
                ?.additional_marketing_information|| mainFormWatch('additional_marketing_information') == '',
                message:'This field is required'
              },
                
              validate: {
                valid: (v) => {
                  return validateInputLength(v, 100);
                },
              },
            }}
            defaultValue={
              userData?.data?.profile?.grant_information
                ?.additional_marketing_information
            }
            isDisabled={isPreview === true}
            isPrint={isPrint}
          />
        </Col>
      </Row>

      <Row style={{ marginBottom: '20px' }}>
        {(isPreview || isPrint) && (
          <Col md={12} xs={12}>
            <FormInputTable
              title="Activities"
              columns={ActivityTableColumns}
              options={FormTableInputOptions}
              rows={loadDisplayTableRows(
                userData?.data?.profile?.grant_information?.activities
              )}
            />
          </Col>
        )}
      </Row>
      {!activityLoader && !isPreview && !isPrint && (
        <Row>
          {userData?.data?.profile?.grant_information?.activities?.reverse()?.map(
            (activity) => {
              return (
                <Col md={12} xs={12} key={Math.random()}>
                  <ActivityComponent
                    {...activity}
                    year={activity?.year?.toString()}
                    start_month={activity?.start_month?.toString()}
                    end_month={activity?.end_month?.toString()}
                    onDelete={() => {
                      onDelete?.(activity.id);
                    }}
                    onEdit={onEditActivity}
                    defaultOpen={isPreview}
                    isPrint={isPrint}
                    isPreview={isPreview}
                  />
                </Col>
              );
            }
          )}
        </Row>
      )}
    </BusinessFormBaseLayout>
  );
};

export default ProjectInformation;
