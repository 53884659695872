/*eslint-disable*/

import React from 'react';
import styled from 'styled-components';
import BusinessFormBaseLayout from '@/views/GrantForm/BusinessFormBaseLayout';
import { useGetConsent } from '@/views/GrantForm/services/BusinessPlan/Consent.service';
import { IBusinessPlanFormStage } from '@/views/GrantForm/GrantForm.interfaces';
import { Row, Col, Form } from 'react-bootstrap';

const ConditionTextWrapper = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-left: 15px;
  padding-right: 50px;
`;

const ConditionRow = styled.div`
  line-height: 21px;
`;

const ErrorText = styled.div`
  color: red;
`;

const Consent = ({ isPreview, isPrint, stage }: IBusinessPlanFormStage) => {
  const { onSubmit, handleSubmit, register, watch, formState } =
    useGetConsent();
  return (
    <BusinessFormBaseLayout
      page={10}
      title={'Consent Form'}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      formStage={'consent'}
      preview={isPreview}
      print={isPrint}
    >
      <Row>
        <Col md={12} xs={12} style={{ display: 'flex' }}>
          <Form.Check
            {...register('condition_1', {
              required: watch('condition_1') !== true,
            })}
            label={''}
            disabled={isPreview == true}
          />
          <ConditionTextWrapper>
            <ConditionRow>
              I hereby give my consent that data associated with my application
              be collected for monitoring and evaluation purposes and that my
              data may be shared with evaluation partners (example, the Ghana
              Jobs and Skills Project Coordinating Unit, the Ministry of
              Finance, and the World Bank research team).
            </ConditionRow>
            {formState.errors['condition_1'] && (
              <ErrorText>*Please Check this field to continue</ErrorText>
            )}
          </ConditionTextWrapper>
        </Col>
      </Row>
    </BusinessFormBaseLayout>
  );
};

export default Consent;
