/*eslint-disable*/

import {
  IGrantFormModel,
  IUserModel,
} from '@/utils/constants/userModel.interface';
import { AxiosResponse } from 'axios';
import {
  IGrantUpdateParams,
  IRevenueGeneration,
  IRevenueGeneration2,
  IUserUpdateParams,
} from '../GrantForm.interfaces';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { logError, useGetUserObject } from './GrantFormBaseLayout.service';
import { updateFullUserProfile, updateGrantInformation } from '@/api/User/User';

export const useGetRevenueGenerationForm = () => {
  const { control, watch, formState, handleSubmit } =
    useForm<IRevenueGeneration>();
  const { userData, userError, userLoading } = useGetUserObject();
  const _userData = userData as AxiosResponse<IUserModel>;
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<IRevenueGeneration> = async (data) => {
    try {
      let grantParams: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };
      let userUpdateParams: IUserUpdateParams = {
        id: undefined,
        data: undefined,
      };

      grantParams.id = _userData?.data?.profile?.grant_information?.id;
      //super hacky stuff right here....
      let _financial_projections: IGrantFormModel['financial_projections'] = [
        {
          year: 'year_one',
          projected_gross_profit: data['fiscalOneGrossProfit'],
          projected_net_cash_flow: data['fiscalOneNetCashFlow'],
          projected_operating_cost: data['fiscalOneOperatingCost'],
          projected_turnover: data['fiscalOneTurnover'],
        },
        {
          year: 'year_two',
          projected_gross_profit: data['fiscalTwoGrossProfit'],
          projected_net_cash_flow: data['fiscalTwoNetCashFlow'],
          projected_operating_cost: data['fiscalTwoOperatingCost'],
          projected_turnover: data['fiscalTwoTurnover'],
        },
        {
          year: 'year_three',
          projected_gross_profit: data['fiscalThreeGrossProfit'],
          projected_net_cash_flow: data['fiscalThreeNetCashFlow'],
          projected_operating_cost: data['fiscalThreeOperatingCost'],
          projected_turnover: data['fiscalThreeTurnover'],
        },

        {
          year: 'year_four',
          projected_gross_profit: data['fiscalFourGrossProfit'],
          projected_net_cash_flow: data['fiscalFourNetCashFlow'],
          projected_operating_cost: data['fiscalFourOperatingCost'],
          projected_turnover: data['fiscalFourTurnover'],
        },
        {
          year: 'year_five',
          projected_gross_profit: data['fiscalFiveGrossProfit'],
          projected_net_cash_flow: data['fiscalFiveNetCashFlow'],
          projected_operating_cost: data['fiscalFiveOperatingCost'],
          projected_turnover: data['fiscalFiveTurnover'],
        },
      ];
      grantParams.data = {
        ..._userData?.data?.profile?.grant_information,
        financial_projections: _financial_projections,
      };
      userUpdateParams.id = _userData?.data?.profile?.client;
      userUpdateParams.data = {
        grant_onboarding_data: {
          ..._userData?.data?.grant_onboarding_data,
          revenue_generation: true,
        },
      };
      userUpdateParams.data.id = _userData?.data?.profile?.client;

      await updateFullUserProfile(userUpdateParams.data);
      await updateGrantInformation(grantParams);
      navigate('/grants/revenue_generation_2');
    } catch (e) {
      logError(e);
    }
  };
  return {
    control,
    watch,
    formState,
    handleSubmit,
    onSubmit,
    userData: _userData,
    isLoading: userLoading,
  };
};

export const useGetRevenueGenerationForm2 = () => {
  const { control, watch, formState, handleSubmit } =
    useForm<IRevenueGeneration2>();
  const { userData, userError, userLoading } = useGetUserObject();
  const _userData = userData as AxiosResponse<IUserModel>;
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<IRevenueGeneration2> = async (data) => {
    try {
      let grantParams: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };
      let userUpdateParams: IUserUpdateParams = {
        id: undefined,
        data: undefined,
      };

      grantParams.id = _userData?.data?.profile?.grant_information?.id;
      grantParams.data = {
        ..._userData?.data?.profile?.grant_information,
        ...data,
      };
      userUpdateParams.id = _userData?.data?.profile?.client;
      userUpdateParams.data = {
        grant_onboarding_data: {
          ..._userData?.data?.grant_onboarding_data,
          revenue_generation_2: true,
        },
      };
      userUpdateParams.data.id = _userData?.data?.profile?.client;

      await updateFullUserProfile(userUpdateParams.data);
      await updateGrantInformation(grantParams);
      navigate('/grants/funding_requirements');
    } catch (e) {
      logError(e);
    }
  };

  return {
    control,
    watch,
    formState,
    handleSubmit,
    onSubmit,
    isLoding: userLoading,
    userData: _userData,
  };
};
