import React from "react";
import propTypes from "prop-types";

export default function SeeMoreTitle({ title }) {
  return (
    <div className="d-flex justify-content-between ">
      <h6>{title}</h6>
      <h6 className="blue-text">
        See All <i className="fa fa-solid fa-angle-right"></i>
      </h6>
    </div>
  );
}

SeeMoreTitle.propTypes = {
  title: propTypes.string,
};
