/*eslint-disable*/
import React from 'react';
import { Col } from 'react-bootstrap';
import { IProgressNumber } from '../GrantForm.interfaces';

export function ProgressNumber({
  number,
  active,
  description,
}: IProgressNumber) {
  return (
    <Col
      className="d-inline-flex"
      style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
    >
      <div className={`rounded-circle-icon ${active && 'active'}`}>
        {number}
      </div>
      <div style={{ fontSize: '14px', marginLeft: '5px' }}>{description}</div>
    </Col>
  );
}
