// import axios from 'axios';
// import { toast } from 'react-toastify';

const INITIAL_STATE = {
    loading: false,
    user: JSON.parse(window.localStorage.getItem('user')),
    error: null,
    success:null,
};

const AuthReducer = (state = INITIAL_STATE, action) => {
    const { type, payload, error, success} = action;
    switch (type) {
        case 'PROCESS':
            return { ...state, loading: true };
        case 'LOGIN_SUCCESS':
            return { user: payload, success:success, loading:false};
        case 'LOGIN_FAILED':
            return { ...state, error:error, loading:false};

        case 'LOG_OUT_SUCCESS':
            return { ...state, success:success};
        case 'LOG_OUT_FAILED':
            return { ...state, error:error};
        
        case 'CREATE_USER_SUCCESS':
            return { user: payload, success:success, loading:false};
        case 'CREATE_USER_FAILED':
            return { ...state, error:error, loading:false};


        case 'VERIFY_PHONE_NUMBER_SUCCESS':
            return { success:success, loading:false};
        case 'VERIFY_PHONE_NUMBER_FAILED':
            return { ...state, error:error, loading:false};

        case 'LOGIN_CODE_SUCCESS':
            return { success:success, loading:false};
        case 'LOGIN_CODE_FAILED':
            return { ...state, error:error, loading:false};

        case 'UPDATE_PROFILE_SUCCESS':
            return { success:success, loading:false};
        case 'UPDATE_PROFILE_FAILED':
            return { ...state, error:error, loading:false};

        default:
            return state;
    }
};

export default AuthReducer;


