/*eslint-disable */
import React from 'react';
import { useGetProjectInformation2 } from '@/views/GrantForm/services/BusinessPlan/ProjectInformation.service';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import BusinessFormBaseLayout from '@/views/GrantForm/BusinessFormBaseLayout';
import { Row, Col } from 'react-bootstrap';
import InstructionText from '../../InstructionText';
import { IBusinessPlanFormStage } from '@/views/GrantForm/GrantForm.interfaces';
import ControlledRadioButtonGroup from '@/components/forms/FormInput/ControlledRadioButton';
import { validateInputLength } from '@/views/GrantForm/services/GrantFormBaseLayout.service';

const ProjectInformation2 = ({
  isPreview,
  isPrint,
}: IBusinessPlanFormStage) => {
  const { control, handleSubmit, onSubmit, userData, isLoading } =
    useGetProjectInformation2();
  return (
    <BusinessFormBaseLayout
      page={3}
      title={'Project Information'}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      formStage={'project_information_2'}
      preview={isPreview}
      print={isPrint}
    >
      {userData?.data?.skipped_advanced_training == false && (
        <Row>
          <Col md={12} xs={12}>
            <Col md={12} xs={12}>
              <InstructionText
                title={
                  'Technical Capability:  What technical capacity do you have to implement the business plan (200 words)?'
                }
                subtitle={
                  'Tell us if you have the technical expertise required to deliver your product(s) or services. Where personal expertise is lacking, state clearly the expertise you will have brought onboard to help develop and consistently supply the product(s) or service. (Not more than 200 words).'
                }
              />
              <ControlledTextInput
                control={control}
                name="technical_capability"
                placeholder="Your response must not be more than 200 words"
                as={'textarea'}
                defaultValue={
                  userData?.data?.profile?.grant_information
                    ?.applicant_information?.technical_capability
                }
                rules={{
                  required:
                    !userData?.data?.profile?.grant_information
                      ?.applicant_information?.technical_capability,
                  validate: {
                    valid: (v) => {
                      return validateInputLength(v, 200);
                    },
                  },
                }}
                value={
                  isPreview
                    ? userData?.data?.profile?.grant_information
                        ?.applicant_information?.technical_capability
                    : null
                }
                isDisabled={isPreview === true}
                isPrint={isPrint}
              />
            </Col>
          </Col>
        </Row>
      )}

      {/* <Row>
        <Col md={12} xs={12}>
          <ControlledTextInput
            control={control}
            name="key_project_staff"
            placeholder="Your response must not be more than 50 words"
            as={'textarea'}
            title="Write the profile(s) of at most three key project staff"
            defaultValue={
              userData?.data?.profile?.grant_information?.applicant_information
                ?.key_project_staff
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information
                  ?.applicant_information?.key_project_staff,
            }}
            isDisabled={isPreview === true}
          />
        </Col>
      </Row> */}
      <Row>
        <Col md={12} xs={12}>
          <ControlledRadioButtonGroup
            control={control}
            name="challenges_during_business_registration"
            placeholder="Your response must not be more than 50 words"
            title="Have you registered your business or are you in the process of registering your business with the Office of the Registrar of Companies? "
            options={[
              { label: 'Yes, I have registered', value: 'Y' },
              { label: 'Yes, In the process', value: 'IN_THE_PROCESS' },
              { label: "No, I haven't registered", value: 'N' },
            ]}
            defaultValue={
              userData?.data?.profile?.grant_information?.applicant_information
                ?.challenges_during_business_registration
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information
                  ?.applicant_information
                  ?.challenges_during_business_registration,
              validate: {
                valid: (v) => {
                  return validateInputLength(v, 50);
                },
              },
            }}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information
                    ?.applicant_information
                    ?.challenges_during_business_registration
                : null
            }
            isDisabled={isPreview === true}
          />
        </Col>
      </Row>
    </BusinessFormBaseLayout>
  );
};

export default ProjectInformation2;
