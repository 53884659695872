import React from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import propTypes from "prop-types";
// import { TextField } from "@components/forms";
import constants from "@utils/constants/constants";

const MainButton = ({
  text,
  variant,
  size,
  className,
  onClick,
  isLoading,
  loadingVariant,
  isDisabled,
  type,
  dark,
  link,
  wrapperType='',
}) => {
  const style = dark
    ? {
        backgroundColor: `${constants.DEFAULT_COLOR}`,
        fontSize: "12px",
      }
    : {
      width:wrapperType==''?'unset':'100%'
    }


  
  const wrapperClass = wrapperType == ''?'d-grid gap-2':'d-grid gap-2 pdf-button-custom';

  return (
    <div className={wrapperClass}>
      <Button
        variant={variant}
        size={size}
        className={className}
        onClick={onClick}
        disabled={isLoading || isDisabled}
        type={type}
        style={style}
        href={link ? link : null}
      >
        {isLoading ? (
          <Spinner animation="border" size="sm" variant={loadingVariant} />
        ) : (
          text
        )}
      </Button>
    </div>
  );
};

MainButton.propTypes = {
  text: propTypes.string,
  variant: propTypes.string,
  size: propTypes.string,
  className: propTypes.string,
  onClick: propTypes.func,
  isLoading: propTypes.bool,
  loadingVariant: propTypes.string,
  isDisabled: propTypes.bool,
  type: propTypes.string,
  dark: propTypes.bool,
  link: propTypes.string,
  wrapperType:propTypes.string
};

export default MainButton;

export function ProgressBarNumber({ number, active, text }) {
  return (
    <Col className="d-inline-flex" style={{alignItems:'center',display:'flex',flexDirection:'row'}}>
      <div className={`rounded-circle-icon ${active && "active"}`}>
        {number}
      </div>
      <div style={{fontSize:'14px',marginLeft:'5px'}}>
        {text}
      </div>
    </Col>
  );
}
ProgressBarNumber.propTypes = {
  number: propTypes.string,
  active: propTypes.bool,
  text: propTypes.string,
};

export function ButtonGutter({ children }) {
  return (
    <Col className="d-flex  align-items-center h-100">
      <Col md={12}>
        <Row className="mt-5">{children}</Row>
      </Col>
    </Col>
  );
}
ButtonGutter.propTypes = {
  children: propTypes.node,
};
