/*eslint-disable */
import { useForm } from 'react-hook-form';
import {
  IDefaultPerformanceIndicator,
  IGetDefaultIndicatorState,
  IGetOtherIndicators,
  IGrantUpdateParams,
  IOtherIndicatorEditParams,
} from '../../GrantForm.interfaces';
import { useNavigate } from 'react-router-dom';
import { logError, useGetUserObject } from '../GrantFormBaseLayout.service';
import { AxiosResponse } from 'axios';
import {
  IGrantFormModel,
  IUserModel,
} from '@/utils/constants/userModel.interface';
import { updateGrantInformation } from '@/api/User/User';
import { messageAlert } from '@/utils/helpers/functions';
import React from 'react';

export const DEFAULT_KEYS = [
  'sales_default',
  'employees_default',
  'production_default',
  'exports_default',
  'volume_default',
  'environmental_impact_default',
  'social_impact_default',
];

export const DefaultPerformanceIndicatorsFieldState: Partial<IDefaultPerformanceIndicator>[] =
  [
    {
      title: 'Sales',
      name_of_indicator: 'sales_default',
      pre_grant_state: 'sales_default_pre',
      post_grant_state: 'sales_default_post',
      details_of_benefits: 'sales_default_explanation',
      type: 'number',
      placeholder: 'Sales (GHC)',
    },
    {
      title: 'Employees',
      name_of_indicator: 'employees_default',
      pre_grant_state: 'employees_default_pre',
      post_grant_state: 'employees_default_post',
      details_of_benefits: 'employees_default_explanation',
      type: 'number',
      placeholder: 'Number of Employees',
    },
    // {
    //   title: 'Production',
    //   name_of_indicator: 'production_default',
    //   pre_grant_state: 'production_default_pre',
    //   post_grant_state: 'production_default_post',
    //   details_of_benefits: 'production_default_explanation',
    // },
    {
      title: 'Exports (if applicable)',
      name_of_indicator: 'exports_default',
      pre_grant_state: 'exports_default_pre',
      post_grant_state: 'exports_default_post',
      details_of_benefits: 'exports_default_explanation',
      type: 'number',
      placeholder: 'Number of Exports',
    },
    {
      title: 'Volume of Production',
      name_of_indicator: 'volume_default',
      pre_grant_state: 'volume_default_pre',
      post_grant_state: 'volume_default_post',
      details_of_benefits: 'volume_default_explanation',
      type: 'alphanumeric',
      placeholder: 'Volume of Production',
    },
    {
      title: 'Environmental Impact',
      name_of_indicator: 'environmental_impact_default',
      pre_grant_state: 'environmental_impact_default_pre',
      post_grant_state: 'environmental_impact_default_post',
      details_of_benefits: 'environmental_impact_default_explanation',
      type: 'alphanumeric',
      placeholder: 'Environmental Impact',
    },
    {
      title: 'Social Impact',
      name_of_indicator: 'social_impact_default',
      pre_grant_state: 'social_impact_default_pre',
      post_grant_state: 'social_impact_default_post',
      details_of_benefits: 'social_impact_default_explanation',
      type: 'alphanumeric',
      placeholder: 'Social Impact',
    },
  ];

export const getDefaultIndicatorState = ({
  keyname,
  indicatorArray,
}: IGetDefaultIndicatorState) => {
  let defaultIndicatorState = indicatorArray?.filter((indicator) => {
    return indicator.name_of_indicator == keyname;
  })[0];

  return defaultIndicatorState;
};

export const getOtherIndicators = ({
  indicatorArray,
}: IGetOtherIndicators): IGrantFormModel['performance_indicators'] => {
  return indicatorArray?.filter((indicator) => {
    return !DEFAULT_KEYS.includes(indicator?.name_of_indicator || '');
  });
};

export const useGetExpectedResults = () => {
  const { control, watch, handleSubmit, formState } = useForm();
  const {
    control: otherIndicatorControl,
    watch: otherIndicatorWatch,
    handleSubmit: otherIndicatorHandleSubmit,
    formState: otherIndicatorFormState,
    reset: otherIndicatorReset,
  } = useForm();
  const navigate = useNavigate();
  const { userData, refetch, isRefetching, userError, userLoading } =
    useGetUserObject();
  const [expectedResultsLoader, setExpectedResultsLoader] =
    React.useState(false);
  let _userData = userData as AxiosResponse<Partial<IUserModel>>;
  const onSubmit = async (data: any) => {
    try {
      let grantParams: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };
      let defaultIndicatorData: IGrantFormModel['performance_indicators'] = [];
      let otherIndicatorData: IGrantFormModel['performance_indicators'] = [];

      DefaultPerformanceIndicatorsFieldState.forEach((indicator) => {
        defaultIndicatorData?.push({
          name_of_indicator: indicator.name_of_indicator,
          pre_grant_state: data[indicator?.pre_grant_state || ''],
          post_grant_state: data[indicator?.post_grant_state || ''],
          details_of_benefits: data[indicator?.details_of_benefits || ''],
        });
      });

      otherIndicatorData =
        _userData?.data?.profile?.grant_information?.performance_indicators?.filter(
          (indicator) => {
            return !DEFAULT_KEYS.includes(indicator?.name_of_indicator || '');
          }
        );

      grantParams.id = _userData?.data?.profile?.grant_information?.id;
      grantParams.data = {
        ..._userData?.data?.profile?.grant_information,
        performance_indicators: [
          ...(defaultIndicatorData || []),
          ...(otherIndicatorData || []),
        ],
      };
      await updateGrantInformation(grantParams);
      navigate('/grants/businessplan/sustainability');
    } catch (e) {
      logError(e);
    }
  };
  const onOtherIndicatorSubmit = async (data: any) => {
    setExpectedResultsLoader(true);
    try {
      let grantParams: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };

      grantParams.id = _userData?.data?.profile?.grant_information?.id;
      grantParams.data = {
        ..._userData?.data?.profile?.grant_information,
        performance_indicators: [
          ...(_userData?.data?.profile?.grant_information
            ?.performance_indicators || []),
          {
            name_of_indicator: data?.['name_of_indicator'],
            details_of_benefits: data?.['details_of_benefits'],
            post_grant_state: data?.['post_grant_state'],
            pre_grant_state: data?.['pre_grant_state'],
          },
        ],
      };
      await updateGrantInformation(grantParams);
      otherIndicatorReset({
        name_of_indicator: '',
        pre_grant_state: '',
        post_grant_state: '',
      });
      messageAlert('success', 'Indicator Added');
      refetch();
    } catch (e) {
      logError(e);
    }
    setExpectedResultsLoader(false);
  };

  const onIndicatorDelete = async (id: number | string | undefined) => {
    setExpectedResultsLoader(true);
    try {
      let grantParams: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };
      grantParams.id = _userData?.data?.profile?.grant_information?.id;
      let indicatorArray =
        _userData?.data?.profile?.grant_information?.performance_indicators?.filter(
          (indicator) => {
            return indicator.id !== id;
          }
        );
      grantParams.data = {
        ..._userData?.data?.profile?.grant_information,
        performance_indicators: indicatorArray,
      };
      await updateGrantInformation(grantParams);
      messageAlert('success', 'Indicator Deleted');
      refetch();
    } catch (e) {
      logError(e);
    }
    setExpectedResultsLoader(false);
  };

  const onOtherIndicatorEdit = async (params: IOtherIndicatorEditParams) => {
    try {
      setExpectedResultsLoader(true);
      let indicatorArray = [
        ..._userData?.data?.profile?.grant_information?.performance_indicators,
      ];

      const editIndex = indicatorArray.findIndex((indicator) => {
        return indicator.id === params.id;
      });
      if (editIndex !== -1) {
        let { details_of_benefits, ..._params } = indicatorArray[editIndex];
        indicatorArray[editIndex] = { ..._params };
      }
      let grantParams: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };
      grantParams.id = _userData?.data?.profile?.grant_information?.id;
      grantParams.data = {
        ..._userData?.data?.profile?.grant_information,
        performance_indicators: indicatorArray,
      };
      messageAlert('success', 'Indicator Edited');
      await updateGrantInformation(grantParams);
      refetch();
    } catch (e) {
      logError(e);
    }
    setExpectedResultsLoader(false);
  };

  const loadIndicatorTables = (
    rowData: IUserModel['profile']['grant_information']['performance_indicators']
  ) => {
    try {
      let rows: any[][] = [];
      let sales_default_object = getDefaultIndicatorState({
        keyname: 'sales_default',
        indicatorArray: rowData,
      });
      let employees_default_object = getDefaultIndicatorState({
        keyname: 'employees_default',
        indicatorArray: rowData,
      });
      let production_default_object = getDefaultIndicatorState({
        keyname: 'production_default',
        indicatorArray: rowData,
      });
      let exports_default_object = getDefaultIndicatorState({
        keyname: 'exports_default',
        indicatorArray: rowData,
      });
      let volume_of_production_default = getDefaultIndicatorState({
        keyname: 'volume_default',
        indicatorArray: rowData,
      });
      let environmental_impact_default = getDefaultIndicatorState({
        keyname: 'environmental_impact_default',
        indicatorArray: rowData,
      });
      let social_impact_default = getDefaultIndicatorState({
        keyname: 'social_impact_default',
        indicatorArray: rowData,
      });

      [
        sales_default_object,
        employees_default_object,
        production_default_object,
        exports_default_object,
        volume_of_production_default,
        environmental_impact_default,
        social_impact_default,
      ].forEach((value) => {
        if (value) {
          let { pre_grant_state, post_grant_state, name_of_indicator } = value;
          let indicator_name = DefaultPerformanceIndicatorsFieldState.find(
            (value) => {
              return value.name_of_indicator == name_of_indicator;
            }
          ).title;
          rows.push([indicator_name, pre_grant_state, post_grant_state]);
        }
      });

      getOtherIndicators({ indicatorArray: rowData }).forEach((indicator) => {
        rows.push([
          indicator.name_of_indicator,
          indicator.pre_grant_state,
          indicator.post_grant_state,
        ]);
      });

      return rows;
    } catch (e) {
      return [];
    }
  };

  return {
    control,
    formState,
    otherIndicatorControl,
    userData: _userData,
    isLoading: userLoading,
    otherIndicatorFormState,
    expectedResultsLoader,
    isRefetching,
    loadIndicatorTables,
    watch,
    handleSubmit,
    onOtherIndicatorSubmit,
    onSubmit,
    otherIndicatorWatch,
    otherIndicatorHandleSubmit,
    onIndicatorDelete,
    onOtherIndicatorEdit,
  };
};

export const ExpectedResultColumns: string[] = [
  'Performance Indicators',
  'Before grant support',
  'After grant support',
];
