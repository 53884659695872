/*eslint-disable*/
import constant from '@/utils/constants/constants';
import { OptionType } from '@/utils/constants/globals.interfaces';
import { useMutation, useQuery } from 'react-query';
import {
  getGrantBusinessExpenditures,
  getUserGrantInformation,
  getUserProfile,
  updateFullUserProfile,
  updateGrantInformation,
} from '@/api/User/User';
import {
  IBusinessPlanFormStage,
  IGetDefaultFinancialProjectionProps,
  IGetRegionID,
  IGrantFormProgress,
  IGrantFormStage,
  IGrantUpdateParams,
  IHandleGrantUpdate,
  IRevenueSubform,
} from '../GrantForm.interfaces';
import {
  getIndustries,
  getRegions,
  handleErrorMessage,
  messageAlert,
} from '@/utils/helpers/functions';
import { geaTrainingOptions } from './PersonalInformation.service';
import { IUserModel } from '@/utils/constants/userModel.interface';
import { AxiosResponse } from 'axios';
import { MUIDataTableOptions } from 'mui-datatables';

const tableBodyHeight = `500px`;
const tableBodyMaxHeight = '';

export const getCountryOptions = (): OptionType<string, string>[] => {
  let optionArray: OptionType<string, string>[] = [];
  constant.COUNTRIES.forEach((country) => {
    optionArray.push({
      value: country?.alpha2.toUpperCase(),
      label: country?.name,
    });
  });
  return optionArray;
};

export const useGetUserObject = () => {
  const {
    error: userError,
    data: userData,
    isLoading: userLoading,
    refetch,
    isRefetching,
  } = useQuery('user-data-verifcation', getUserProfile, {
    staleTime: undefined,
  });

  return { userError, userData, userLoading, refetch, isRefetching };
};

export const useGetGrantObject = () => {
  const {
    error: getGrantError,
    data: getGrantData,
    isLoading: getGrantLoading,
    refetch,
  } = useQuery('user-grant-fetch', getUserGrantInformation);

  return { getGrantData, getGrantError, getGrantLoading, refetch };
};

export const useGetGrantExpenditureList = () => {
  const {
    data: expenditureList,
    error: expenditureError,
    isLoading: expenditureLoading,
    isRefetching: expenditureRefetching,
    refetch: refetchExpenditure,
  } = useQuery('fetch-grant-expenditures', getGrantBusinessExpenditures);
  return {
    expenditureList,
    expenditureError,
    expenditureLoading,
    expenditureRefetching,
    refetchExpenditure,
  };
};

export const useGetRegions = () => {
  const {
    error: getRegionsError,
    data: getRegionsData,
    isLoading: getRegionsLoading,
  } = useQuery('fetch-regions', getRegions, {
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
  return { getRegionsData, getRegionsError, getRegionsLoading };
};

export const useGetIndustries = () => {
  const {
    error: getIndustriesError,
    data: getIndustriesData,
    isLoading: getIndustriesLoading,
  } = useQuery('fetch-industries', getIndustries);
  return { getIndustriesError, getIndustriesData, getIndustriesLoading };
};

export const getGrantUpdateMutation = () => {
  const grantMutation = useMutation(updateGrantInformation);
  return { grantMutation };
};
export const getUserUpdateMutation = (params?: IGrantUpdateParams) => {
  let userMutation = useMutation((data) => updateFullUserProfile(data, params));
  return userMutation;
};

export const handleGrantUpdate = ({
  data,
  onErrorCallback,
  onSuccessCallback,
  mutation,
}: IHandleGrantUpdate) => {
  mutation.mutate(data, {
    onSuccess: onSuccessCallback,
    onError: onErrorCallback,
  });
};

export const logError = (error: any) => {
  let message = handleErrorMessage(error);
  messageAlert('error', message || 'Request Failed');
};

export const getRegionID = ({ name, regions }: IGetRegionID) => {
  if (name && regions) {
    let { id } = regions.filter((region) => {
      return region.name == name;
    })[0];
    return id;
  }
  return 1;
};

export const getDefaultFinancialProjectionValues = ({
  data,
  key,
}: IGetDefaultFinancialProjectionProps): IRevenueSubform['defaultValueMapping'] => {
  let financialProjections = data?.filter((value) => {
    return value.year == key;
  })?.[0];

  if (financialProjections) {
    return financialProjections;
  }
  return {
    year: undefined,
    projected_turnover: undefined,
    projected_gross_profit: undefined,
    projected_net_cash_flow: undefined,
  };
};

export const getMultiSelectDefault = (array: string[]) => {
  let defaultValues = [];
  array?.forEach((value) => {
    let label = geaTrainingOptions.filter((option) => {
      return option.value == value;
    })[0]?.label;
    defaultValues.push({ label: label, value: value });
  });
  return defaultValues;
};

export const getPreviousRoute = ({
  stage,
}: IGrantFormStage): IGrantFormStage['stage'] => {
  switch (stage) {
    case 'personal_information':
      return 'dashboard';
    case 'personal_information_2':
      return 'personal_information';
    case 'business_form':
      return 'personal_information_2';
    case 'business_form_2':
      return 'business_form';
    case 'product_information':
      return 'business_form_2';
    case 'product_information_2':
      return 'product_information';
    case 'technical_capacity':
      return 'product_information_2';
    case 'product_marketing':
      return 'technical_capacity';
    case 'revenue_generation':
      return 'product_marketing';
    case 'revenue_generation_2':
      return 'revenue_generation';
    case 'funding_requirements':
      return 'revenue_generation_2';
    case 'impact':
      return 'funding_requirements';
    case 'impact2':
      return 'impact';
    case 'preview_grant_form':
      return 'impact2';
    default:
      return 'dashboard';
  }
};

export const getPreviousBusinessPlanRoute = ({
  stage,
}: IBusinessPlanFormStage): IBusinessPlanFormStage['stage'] => {
  const user = JSON.parse(window.localStorage.getItem('user')) as IUserModel;
  console.log(user);
  switch (stage) {
    case 'guidance':
      return 'dashboard';
    case 'application_summary':
      return 'guidance';
    case 'application_information':
      return 'application_summary';
    case 'application_information_2':
      return 'application_information';
    case 'application_information_3':
      return 'application_information_2';
    case 'application_information_4':
      return 'application_information_3';
    case 'project_information':
      return 'application_information_4';
    case 'project_information_2':
      return user?.skipped_advanced_training == false
        ? 'project_information'
        : 'application_information_4';
    case 'project_information_3':
      return 'project_information_2';
    case 'project_information_4':
      return 'project_information_3';
    case 'grant_request':
      return user?.skipped_advanced_training == false
        ? 'project_information_4'
        : 'project_information_3';
    case 'grant_request_2':
      return 'grant_request';
    case 'expected_result':
      return 'grant_request_2';
    case 'sustainability':
      return 'expected_result';
    case 'preview':
      return 'sustainability';
    case 'disclaimer':
      return 'preview';
    case 'signature':
      return 'disclaimer';
    case 'consent':
      return 'signature';
    default:
      return 'dashboard';
  }
};

export const navigateNextRoute = (route: string) => {};

export const parseDbNumber = (number: string) => {
  if (number) {
    return parseInt(`0${number.slice(4)}`);
  }
};

export const setFormProgress = (
  currentStage: IGrantFormStage,
  applicantObject: AxiosResponse<Partial<IUserModel>>
): IGrantFormProgress => {
  return {};
};

export const FormTableInputOptions: MUIDataTableOptions = {
  print: false,
  selectableRowsHideCheckboxes: true,
  search: true,
  viewColumns: false,
  download: false,
  filter: false,
  filterType: 'dropdown',
  responsive: 'vertical',
  rowsPerPage: 10,
  tableBodyHeight,
  tableBodyMaxHeight,
  sortOrder: {
    name: 'Role',
    direction: 'asc',
  },
};

export const validateInputLength = (value: string, length: number) => {
  let charArray = value?.split(' ');

  return charArray?.length < length
    ? true
    : `Maximum of ${length} words allowed`;
};
