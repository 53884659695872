/*eslint-disable*/
import React, { useRef } from 'react';
import { OptionType } from '@/utils/constants/globals.interfaces';
import html2pdf from 'html2pdf.js';

interface FormStages {
  label: string;
  value: number;
  url?: string;
}
export const formStages: FormStages[] = [
  {
    label: 'Application Summary',
    value: 0,
    url: '/grants/businessplan/application_summary',
  },
  {
    label: 'Application Information',
    value: 1,
    url: '/grants/businessplan/application_information',
  },
  {
    label: 'Application Information II',
    value: 2,
    url: '/grants/businessplan/application_information_2',
  },
  {
    label: 'Application Information III',
    value: 3,
    url: '/grants/businessplan/application_information_3',
  },
  {
    label: 'Application Information IV',
    value: 4,
    url: '/grants/businessplan/application_information_4',
  },
  {
    label: 'Project Information',
    value: 5,
    url: '/grants/businessplan/project_information',
  },
  {
    label: 'Project Information II',
    value: 6,
    url: '/grants/businessplan/project_information_2',
  },
  {
    label: 'Project Information III',
    value: 7,
    url: '/grants/businessplan/project_information_3',
  },
  {
    label: 'Project Information IV',
    value: 8,
    url: '/grants/businessplan/project_information_4',
  },
  {
    label: 'Grant Request',
    value: 9,
    url: '/grants/businessplan/grant_request',
  },
  {
    label: 'Grant Request II',
    value: 10,
    url: '/grants/businessplan/grant_request_2',
  },
  {
    label: 'Expected Results',
    value: 11,
    url: '/grants/businessplan/expected_result',
  },
  {
    label: 'Sustainability ',
    value: 12,
    url: '/grants/businessplan/sustainability',
  },
];
export const useGetPreviewBusinessPlan = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const [autoPrint, setAutoPrint] = React.useState(false);
  const [printState, setPrintState] =
    React.useState<'preview' | 'print'>('preview');
  const applicationSummaryRef = useRef(null);
  const applicantInformationRef = useRef(null);
  const applicantInformation2Ref = useRef(null);
  const applicantInformation3Ref = useRef(null);
  const projectInformationRef = useRef(null);
  const projectInformation2Ref = useRef(null);
  const grantRequestRef = useRef(null);
  const sustainabilityRef = useRef(null);
  const applicantInformation4Ref = useRef(null);
  const disclaimerRef = useRef(null);
  const grantRequest2Ref = useRef(null);
  const projectInformation3Ref = useRef(null);
  const projectInformation4Ref = useRef(null);
  const expectedResultsRef = useRef(null);
  const signatureRef = useRef(null);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const printForm = () => {
    const combinedForm = document.createElement('div');
    combinedForm.style.padding = '20px';
    const printElements = document.querySelectorAll('.html2pdf__page-break');
    printElements.forEach((element) => {
      combinedForm.appendChild(element.cloneNode(true));
    });
    const options = {
      filename: 'form.pdf', // Set the desired filename
      image: { type: 'jpeg', quality: 0.98 }, // Optional: Set image options
      html2canvas: { scale: 2 }, // Optional: Set html2canvas options
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }, // Optional: Set jsPDF options
    };
    html2pdf().set(options).from(combinedForm).save();
    setPrintState('preview');
  };

  React.useEffect(() => {
    if (printState === 'print') {
      printForm();
    }
  }, [printState]);
  return {
    tabValue,
    applicationSummaryRef,
    applicantInformationRef,
    applicantInformation2Ref,
    applicantInformation3Ref,
    applicantInformation4Ref,
    projectInformationRef,
    projectInformation2Ref,
    projectInformation3Ref,
    projectInformation4Ref,
    signatureRef,
    grantRequestRef,
    grantRequest2Ref,
    sustainabilityRef,
    disclaimerRef,
    expectedResultsRef,
    formStages,
    printState,
    autoPrint,
    setAutoPrint,
    printForm,
    setPrintState,
    setTabValue,
    handleTabChange,
  };
};
