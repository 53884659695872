/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { TextField } from '../../';
import FormInput from '../../FormInput/FormInput';
import InputSelectCountries, { InputSelect } from '@components/select/Select';
import constant from 'src/utils/constants/constants';
import propTypes from 'prop-types';
import { getDistricts, getRegions } from '@utils/helpers/functions';
import { Controller, useForm } from 'react-hook-form';
import { ErrorText, Required } from '@components/utils';
import MainButton from '@components/buttons/Buttons';
import { Box } from '@mui/material';
import { checkIfBlankNumb } from '@utils/helpers/functions';
import moment from 'moment';

export default function PersonalInformation({
  disabled = false,
  state,
  colSize,
  onSubmit,
  loading,
  userData,
}) {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const errorStyle = {
    color: 'red',
  };

  // const [controlState, setControlState] = useState(state);

  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    (async () => {
      let regions = await getRegions();
      setRegions(regions?.data);
      handleGetDistricts(state?.region?.id);
    })();
  }, [state?.region]);

  async function handleGetDistricts(region) {
    let districts = await getDistricts(region);
    setDistricts(districts?.data);
  }

  const evaluateVal = () => {
    let value = watch('id_type') ? watch('id_type') : state?.id_type;
    if (value == null || value == undefined) {
      return constant.BLANK_SPACES;
    } else {
      let regex = '';
      switch (value) {
        case 'ghana_card':
          regex = constant.GHANA_CARD;
          break;
        case 'voter_id':
          regex = constant.VOTERS_ID;
          break;
        case 'passport':
          regex = constant.PASSPORT;
          break;
        case 'driving_license':
          regex = constant.DRIVERS_LICENSE;
          break;
        default:
          regex = constant.BLANK_SPACES;
      }

      return regex;
    }
  };

  const returnPlaceholder = () => {
    let value = watch('id_type') ? watch('id_type') : state?.id_type;
    if (value == null || value == undefined) {
      return 'ID Number';
    } else {
      switch (value) {
        case 'ghana_card':
          return 'GHA-123456789-1';
        case 'driving_license':
          return 'ABC-00000000-00000';
        case 'passport':
          return 'A1234567';
        case 'voter_id':
          return '0000000000';
        default:
          return 'ID Number';
      }
    }
  };

  const validateGhanaCard = (_value) => {
    let value = _value == undefined ? state?.digital_address : _value;
    if (value == undefined || value == null) {
      return false;
    } else {
      let status = false;
      [
        constant.DIGITAL_ADDRESS,
        constant.DIGITAL_ADDRESS_2,
        constant.DIGITAL_ADDRESS_3,
      ].every((pattern) => {
        if (pattern.test(value) == false) {
          status = false;
          return false;
        }
        return true;
      });

      return status;
    }
  };

  console.log(userData);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="d-flex  align-items-center h-100">
        <Col md={colSize ? colSize : 12}>
          <TextField
            text={'Personal Information'}
            className={'fs-5 fw-bold mb-5 text-center'}
          />
          <Row className="mb-5">
            <Form.Group as={Col} md="12">
              <Controller
                name="date_of_birth"
                control={control}
                rules={{ required: true }}
                defaultValue={userData?.user?.date_of_birth}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    title="Date of birth"
                    name="date_of_birth"
                    type="date"
                    placeholder="Date of Birth"
                    isRequired={true}
                    defaultValue={userData?.user?.date_of_birth}
                    maxDate={moment().subtract(18, 'y').format('YYYY-MM-DD')}
                  />
                )}
              ></Controller>
            </Form.Group>
          </Row>

          <Row className="mb-5">
            <Form.Group as={Col} md="6" className="mb-3">
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <InputSelectCountries
                    {...field}
                    options={constant.COUNTRIES}
                    title="Nationality"
                    name="country"
                    isRequired={true}
                    isDisabled={disabled}
                    defaultSelect={state?.country}
                    defaultValue={state?.country}
                    error={errors?.country?.message}
                  />
                )}
              />
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label style={{ fontSize: '15px' }}>
                Are you the sole bread winner?
                <b className="text-danger fs-6 fw-bold">*</b>
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    {...register('is_bread_winner')}
                    value={true}
                    required
                    inline
                    label="Yes"
                    name="is_bread_winner"
                    disabled={disabled}
                    type="radio"
                    id={`inline-radio-1`}
                    defaultChecked={state?.is_bread_winner === true}
                  />
                  <Form.Check
                    {...register('is_bread_winner')}
                    value={false}
                    required
                    inline
                    label="No"
                    name="is_bread_winner"
                    disabled={disabled}
                    type="radio"
                    id={`inline-radio-2`}
                    defaultChecked={state?.is_bread_winner === false}
                  />
                  {errors?.is_bread_winner?.message && (
                    <ErrorText error={errors?.is_bread_winner?.message} />
                  )}
                </Col>
              </Row>
            </Form.Group>
          </Row>
          <Row className="mb-5">
            <Form.Group as={Col} md="6" className="mb-3">
              <Controller
                name="id_type"
                control={control}
                render={({ field }) => (
                  <InputSelect
                    {...field}
                    isRequired={true}
                    defaultSelect={state?.id_type}
                    defaultValue={state?.id_type}
                    isDisabled={disabled}
                    title="Type of ID card"
                    name="id_type"
                    options={constant.ID_TYPES}
                  />
                )}
              />
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Controller
                name="id_number"
                control={control}
                rules={{
                  required: {
                    value: state?.id_number ? false : true,
                    message: 'Required Field',
                  },
                  pattern: {
                    value: evaluateVal(),
                    message: 'Input a valid ID Number',
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    isRequired={true}
                    defaultValue={state?.id_number}
                    title="ID Number"
                    name="id_number"
                    type="text"
                    placeholder={returnPlaceholder()}
                    isDisabled={disabled}
                    length={'20'}
                    error={errors?.id_number?.message}
                  />
                )}
              />
            </Form.Group>
          </Row>
          <Row className="mb-5">
            <Form.Group as={Col} md="6" className="mb-3">
              <Controller
                {...register('region', {
                  required: {
                    value: state?.region?.name ? false : true,
                    message: 'Field is required',
                  },
                  onChange: (e) => {
                    var region =
                      e?.target?.value && JSON.parse(e?.target?.value);
                    handleGetDistricts(region?.id);
                  },
                })}
                name="region"
                control={control}
                render={({ field }) => (
                  <InputSelect
                    {...field}
                    defaultSelect={state?.region?.name}
                    defaultValue={JSON.stringify(state?.region)}
                    isDisabled={disabled}
                    title="Region"
                    name="region"
                    isRequired={true}
                    options={regions}
                    error={errors.region?.message}
                  />
                )}
              />
              <div>
                {errors?.region && (
                  <span style={errorStyle}>*{errors.region?.message}</span>
                )}
              </div>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Controller
                name="district"
                control={control}
                rules={{
                  required: {
                    value: state?.district?.name ? false : true,
                    message: 'Field is required',
                  },
                }}
                render={({ field }) => (
                  <InputSelect
                    {...field}
                    defaultSelect={state?.district?.name}
                    defaultValue={JSON.stringify(state?.district)}
                    title="District"
                    isDisabled={disabled}
                    name="district"
                    isRequired={true}
                    options={districts}
                    error={errors.district?.message}
                  />
                )}
              />
              <div>
                {errors?.district && (
                  <span style={errorStyle}>*{errors.district?.message}</span>
                )}
              </div>
            </Form.Group>
          </Row>
          <Row className="mb-5">
            <Form.Group as={Col} md="6" className="mb-3">
              <Controller
                name="community"
                control={control}
                rules={{
                  required: {
                    value: state?.address?.community ? false : true,
                    message: 'Required Field',
                  },
                  pattern: {
                    value: checkIfBlankNumb?.(watch('community') || ''),
                    message: 'Address must have at least one character.',
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    title="Community"
                    name="community"
                    defaultValue={state?.address?.community}
                    isDisabled={disabled}
                    type="text"
                    placeholder="Community"
                    isRequired={true}
                    error={errors?.community?.message}
                  />
                )}
              />
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Controller
                name="zip"
                control={control}
                rules={{
                  required: {
                    value: state?.address?.zip ? false : true,
                    message: 'Required Field',
                  },
                  pattern: {
                    value: checkIfBlankNumb?.(watch('zip' || '')),
                    message: 'Address must have at least one character.',
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    title="Postal Address"
                    name="zip"
                    type="text"
                    isDisabled={disabled}
                    placeholder="Postal Address"
                    isRequired={true}
                    defaultValue={state?.address?.zip}
                    error={errors?.zip?.message}
                  />
                )}
              />
            </Form.Group>
          </Row>
          <Row className="mb-5">
            <Form.Group as={Col} md="6" className="mb-3">
              <Controller
                name="address_line_1"
                control={control}
                rules={{
                  required: {
                    value: state?.address?.address_line_1 ? false : true,
                    message: 'Required Field',
                  },
                  pattern: {
                    value: checkIfBlankNumb?.(watch('address_line_1') || ''),
                    message: 'Address must have at least one character.',
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    title="Residential Address"
                    name="address_line_1"
                    type="text"
                    isDisabled={disabled}
                    placeholder="Residential Address"
                    defaultValue={state?.address?.address_line_1}
                    isRequired={true}
                    error={errors?.address_line_1?.message}
                  />
                )}
              />
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Controller
                name="digital_address"
                control={control}
                rules={{
                  required: {
                    value: state?.digital_address ? false : true,
                    message: 'Please input a value',
                  },
                  validate: {
                    valid: (v) => {
                      v = v ? v : state?.digital_address;
                      let status = false;
                      if (
                        constant.DIGITAL_ADDRESS.test(v) == true ||
                        constant.DIGITAL_ADDRESS_2.test(v) == true ||
                        constant.DIGITAL_ADDRESS_3.test(v) == true
                      ) {
                        if (
                          constant.DIGITAL_ADDRESS.test(v) == false &&
                          constant.DIGITAL_ADDRESS_2.test(v) == false &&
                          constant.DIGITAL_ADDRESS_3.test(v) == false
                        ) {
                          status = false;
                        } else {
                          status = true;
                        }
                      }
                      return status || 'Enter a valid Digital Address';
                    },
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    title="Digital Address"
                    name="digital_address"
                    type="text"
                    isDisabled={disabled}
                    placeholder="XX-XXX-XXXX"
                    isRequired={true}
                    defaultValue={state?.digital_address}
                    length={'15'}
                    error={errors?.digital_address?.message}
                  />
                )}
              />
              <Box style={{ marginTop: '1rem' }}>
                <span style={{ fontStyle: 'italic', marginTop: '1rem' }}>
                  Format: XX-XXX-XXXX or XX-XXXX-XXXX or XX-XXXXX-XXXX
                </span>
              </Box>
            </Form.Group>
          </Row>
          <Row className="mb-5">
            <Form.Group as={Col} md="6" className="mb-3">
              <Controller
                name="marital_status"
                control={control}
                required
                render={({ field }) => (
                  <InputSelect
                    {...field}
                    defaultSelect={state?.marital_status}
                    defaultValue={state?.marital_status}
                    title="Marital Status"
                    name="marital_status"
                    isDisabled={disabled}
                    options={constant.MARITAL_STATUS}
                    readOnly
                    isRequired={true}
                    error={errors?.marital_status?.message}
                  />
                )}
              />
              {watch('marital_status') === 'other' && (
                <Controller
                  name="other_marital_status"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      title={' '}
                      name="other_marital_status"
                      isDisabled={disabled}
                      type="text"
                      placeholder="Other marital status"
                      defaultValue={state?.other_marital_status}
                    />
                  )}
                />
              )}
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label style={{ fontSize: '15px' }}>
                Do you have children below 12 years?
                <Required />
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    {...register('has_children_below_age_12')}
                    defaultChecked={state?.has_children_below_age_12 === true}
                    value={true}
                    required
                    inline
                    label="Yes"
                    disabled={disabled}
                    name="has_children_below_age_12"
                    type="radio"
                    id={`inline-radio-1`}
                  />
                  <Form.Check
                    {...register('has_children_below_age_12')}
                    defaultChecked={state?.has_children_below_age_12 === false}
                    value={false}
                    required
                    inline
                    label="No"
                    disabled={disabled}
                    name="has_children_below_age_12"
                    type="radio"
                    id={`inline-radio-2`}
                  />
                  {errors?.has_children_below_age_12?.message && (
                    <ErrorText
                      error={errors?.has_children_below_age_12?.message}
                    />
                  )}
                </Col>
              </Row>
            </Form.Group>
            {/* <Form.Group as={Col} md="6">
            <Controller
                name="date_of_birth"
                control={control}
                render={({ field }) =>(
                <FormInput
                  {...field}
                  title="Date of birth"
                  name="date_of_birth"
                  type="date"
                  defaultValue={state.date_of_birth}
                  placeholder="Date of Birth"
                  isRequired={true}
                  maxDate={maxDate(18)}
                  error={errors?.date_of_birth?.message}
                />
              )}/>
          </Form.Group> */}
          </Row>
          <Row className="mb-5">
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label style={{ fontSize: '15px' }}>
                Person With Disability (PWDs)?
                <Required />
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    {...register('disability')}
                    value={true}
                    required
                    inline
                    label="Yes"
                    name="disability"
                    disabled={disabled}
                    type="radio"
                    id={`inline-radio-1`}
                    defaultChecked={state?.disability !== null}
                    // onChange={handleChange}
                  />
                  <Form.Check
                    {...register('disability')}
                    value={false}
                    required
                    inline
                    label="No"
                    name="disability"
                    disabled={disabled}
                    type="radio"
                    id={`inline-radio-2`}
                    defaultChecked={state?.disability === null}
                    // onChange={handleChange}
                  />
                  {errors?.is_bread_winner?.message && (
                    <ErrorText error={errors?.disability?.message} />
                  )}
                </Col>
              </Row>
            </Form.Group>
            {watch('disability') == 'true' && (
              <Form.Group as={Col} md="6">
                <Form.Label style={{ fontSize: '15px' }}>
                  Person with disability (check appropriately)
                </Form.Label>
                <Row>
                  <Col>
                    {constant.DISABILIIY_TYPES.map((disability, idx) => (
                      <Form.Check
                        key={idx}
                        {...register(disability?.value)}
                        value={disability?.value}
                        label={disability?.label}
                        name={disability?.value}
                        type="checkbox"
                        id={`inline-radio-1`}
                        disabled={
                          watch('disability') == 'false' ||
                          (state?.disability == null &&
                            watch('disability') == undefined)
                        }
                        // disabled={disabled || ['false', ''].includes(controlState?.disability)}
                        defaultChecked={state?.disability
                          ?.split(',')
                          ?.includes(disability?.value)}
                      />
                    ))}

                    {watch('other_disability_check') ===
                      'other_disability_check' && (
                      <Controller
                        name="other_disability"
                        control={control}
                        render={({ field }) => (
                          <FormInput
                            {...field}
                            name="other_disability"
                            isDisabled={
                              watch('disability') == 'false' ||
                              (state?.disability == null &&
                                watch('disability') == undefined)
                            }
                            type="text"
                            placeholder="Specify"
                            defaultValue={state?.other_disability}
                          />
                        )}
                      />
                    )}
                  </Col>
                </Row>
              </Form.Group>
            )}
          </Row>
          <Row className="mb-4">
            <Form.Group as={Col} md="6">
              <Form.Label style={{ fontSize: '15px' }}>
                Languages Spoken
                <Required />
              </Form.Label>
              <Row>
                <Col>
                  {constant.LANGUAGE_TYPES.map((language, idx) => (
                    <Form.Check
                      key={idx}
                      {...register(language?.value)}
                      value={language?.value}
                      label={language?.label}
                      name={language?.value}
                      type="checkbox"
                      disabled={disabled}
                      id={`inline-radio-1`}
                      defaultChecked={state?.languages_spoken?.includes(
                        language?.value
                      )}
                    />
                  ))}
                </Col>
              </Row>
            </Form.Group>

            <Form.Group as={Col} md="6">
              {watch('other_language_check') === 'other_language_check' && (
                <Controller
                  name="other_languages_spoken"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      title="Other languages"
                      name="other_languages_spoken"
                      isDisabled={disabled}
                      type="text"
                      placeholder="Specify"
                      defaultValue={state?.other_languages_spoken?.toString()}
                    />
                  )}
                />
              )}
            </Form.Group>

            {onSubmit && (
              <div className="row mt-5 mb-3">
                <Col md={4} className="mb-3">
                  <MainButton
                    text={'Previous'}
                    type={'button'}
                    variant="outline-secondary"
                    size="lg"
                    isDisabled={true}
                    className="fs-6"
                  />
                </Col>
                <Col md={{ span: 4, offset: 4 }}>
                  <MainButton
                    text={'Save and Continue'}
                    type={'submit'}
                    variant="primary"
                    size="lg"
                    className="fs-6"
                    isLoading={loading}
                  />
                </Col>
              </div>
            )}
          </Row>
        </Col>
      </Row>
    </Form>
  );
}

PersonalInformation.propTypes = {
  disabled: propTypes.bool,
  state: propTypes.object,
  colSize: propTypes.number,
  onSubmit: propTypes.func,
  loading: propTypes.bool,
  data: propTypes.object,
};
