/*eslint-disable*/
import React from 'react';
import BusinessFormBaseLayout from '@/views/GrantForm/BusinessFormBaseLayout';
import { useGetApplicationSummary } from '@/views/GrantForm/services/BusinessPlan/ApplicationSummary.service';
import { Row, Col } from 'react-bootstrap';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import RevenueInput from '../../RevenueInput';
import { IBusinessPlanFormStage } from '@/views/GrantForm/GrantForm.interfaces';

const ApplicationSummary = ({ isPreview, isPrint }: IBusinessPlanFormStage) => {
  const {
    control,
    watch,
    handleSubmit,
    refetch,
    formState,
    onSubmit,
    userData,
    isLoading,
  } = useGetApplicationSummary();

  return (
    <BusinessFormBaseLayout
      page={1}
      title={'Application Summary'}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      isLoading={isLoading}
      formStage="application_summary"
      preview={isPreview}
      print={isPrint}
      key={0}
    >
      <Row>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'applicant_reference_number'}
            title={'Applicant reference number'}
            placeholder={'Applicant Reference'}
            defaultValue={userData?.data?.project_application_code}
            value={isPreview ? userData?.data?.project_application_code : null}
            rules={{
              required: false,
            }}
            isDisabled={true}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'business_plan_title'}
            title={'Business plan title'}
            placeholder={'Business Plan Title'}
            defaultValue={
              userData?.data?.profile?.grant_information?.business_plan
                ?.business_plan_title
            }
            value={
              isPreview
                ? userData?.data?.profile?.grant_information?.business_plan
                    ?.business_plan_title
                : null
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information?.business_plan
                  ?.business_plan_title,
            }}
            isDisabled={isPreview == true}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12}>
          <ControlledTextInput
            control={control}
            name={'business_plan_summary'}
            title={'Business Plan Summary'}
            type={'text'}
            as={'textarea'}
            placeholder="Business Plan Summary"
            defaultValue={
              userData?.data?.profile?.grant_information?.business_plan
                ?.business_plan_summary
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information?.business_plan
                  ?.business_plan_summary,
            }}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information?.business_plan
                    ?.business_plan_summary
                : null
            }
            isDisabled={isPreview == true}
            isPrint={isPrint}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'expected_start_date'}
            title={'Expected start date'}
            type={'date'}
            defaultValue={
              userData?.data?.profile?.grant_information?.business_plan
                ?.expected_start_date
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information?.business_plan
                  ?.expected_start_date,
            }}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information?.business_plan
                    ?.expected_start_date
                : null
            }
            isDisabled={isPreview == true}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'expected_duration_in_months'}
            title={'Expected Duration (In Months)'}
            type={'number'}
            placeholder="Expected Duration"
            defaultValue={
              userData?.data?.profile?.grant_information?.business_plan
                ?.expected_duration_in_months
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information?.business_plan
                  ?.expected_duration_in_months,
            }}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information?.business_plan
                    ?.expected_duration_in_months
                : null
            }
            isDisabled={isPreview == true}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <RevenueInput
            control={control}
            name={'total_funding_requested'}
            type={'number'}
            title="Total funding requested (in GHS)"
            placeholder="Enter Amount"
            defaultValue={
              userData?.data?.profile?.grant_information?.business_plan
                ?.total_funding_requested
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information?.business_plan
                  ?.total_funding_requested,
            }}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information?.business_plan
                    ?.total_funding_requested
                : null
            }
            isDisabled={isPreview == true}
          />
        </Col>
      </Row>
    </BusinessFormBaseLayout>
  );
};

export default ApplicationSummary;
