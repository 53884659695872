// import { MainNavbar } from "@components/layout";
import MainBottomNav from "@components/layout/navigation/bottomNavbar/MainBottomNav";
import NavbarTransparent from "@components/layout/navigation/navbar/NavbarTransparent";
import { Outlet, useLocation } from "react-router-dom";
import React from "react";
import propTypes from "prop-types";

export default function StaticPagesBaseLayout() {
  const location = useLocation();
  const pathname = location.pathname;
  const isHome = pathname.includes("home") || pathname === "/" ? true : false;
  return (
    <>
      <NavbarTransparent homePage={isHome} />
      <section className="mb-4">
        <Outlet />
      </section>
      <MainBottomNav />
    </>
  );
}

StaticPagesBaseLayout.propTypes = {
  history: propTypes.func,
};
