import React, { Fragment } from "react";
import propTypes from "prop-types";
import { Row, Col, Form } from "react-bootstrap";
import TextField from "@components/forms/TextField/TextField";
import { Controller, useForm } from "react-hook-form";
import MainButton from "@components/buttons/Buttons";
import { Required } from "@components/utils";
import constant from "@utils/constants/constants";
import FormInput from "@components/forms/FormInput/FormInput";
import { InputSelect } from "@components/select/Select";


export default function BusniessAccumen({
  disabled = false,
  state,
  colSize,
  onSubmit,
  loading,
  onPreviousButtonClicked,
}) {
  const { register, control, watch, handleSubmit,formState:{errors} } = useForm();


  return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="d-flex  align-items-center h-100">
          <Col md={colSize ? colSize : 12}>
            <TextField
              text="Entrepreneurial/Business Acumen"
              className={"fs-5 fw-bold mb-5 text-center"}
            />

            <Row className="mb-5">
            {state?.owns_a_business === false && 
              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label className="big-label">
                  Are you interested in learning practical or non-practical
                  skills that will help you to start your own business?
                  <b className="text-danger fs-6">*</b>
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Check
                      value={true}
                      defaultChecked={
                        state?.desires_training_to_start_business === true
                      }
                      inline
                      required
                      {...register("desires_training_to_start_business")}
                      name="desires_training_to_start_business"
                      label="Yes"
                      type="radio"
                      disabled={disabled}
                      id={`inline-radio-1`}
                    />

                    <Form.Check
                      value={false}
                      defaultChecked={
                        state?.desires_training_to_start_business === false
                      }
                      inline
                      required
                      {...register("desires_training_to_start_business")}
                      name="desires_training_to_start_business"
                      label="No"
                      type="radio"
                      disabled={disabled}
                      id={`inline-radio-1`}
                    />
                  </Col>
                </Row>
              </Form.Group>
              }
              <Form.Group as={Col} md="6">
                <Form.Label className="big-label">
                  Do you know how to evaluate your business idea?
                  <b className="text-danger fs-6">*</b>
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Check
                      defaultChecked={
                        state?.can_evaluate_business_idea === true
                      }
                      value={true}
                      disabled={disabled}
                      inline
                      required
                      label="Yes"
                      {...register("can_evaluate_business_idea")}
                      name="can_evaluate_business_idea"
                      type="radio"
                      id={`inline-radio-1`}
                    />
                    <Form.Check
                      defaultChecked={
                        state?.can_evaluate_business_idea === false
                      }
                      value={false}
                      inline
                      required
                      disabled={disabled}
                      label="No"
                      {...register("can_evaluate_business_idea")}
                      name="can_evaluate_business_idea"
                      type="radio"
                      id={`inline-radio-2`}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Row>
            {!state?.owns_a_business && 
            <Fragment>
            <Row className="mb-5">
              <Form.Group as={Col} md="6">
                <Form.Label className="big-label">
                Have you ever tried to start your own business?
                  <b className="text-danger fs-6">*</b>
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Check
                      defaultChecked={
                        state?.tried_to_start_business === true
                      }
                      value={true}
                      disabled={disabled}
                      inline
                      required
                      label="Yes"
                      {...register("tried_to_start_business")}
                      name="tried_to_start_business"
                      type="radio"
                      id={`inline-radio-1`}
                    />
                    <Form.Check
                      defaultChecked={
                        state?.tried_to_start_business === false
                      }
                      value={false}
                      inline
                      required
                      disabled={disabled}
                      label="No"
                      {...register("tried_to_start_business")}
                      name="tried_to_start_business"
                      type="radio"
                      id={`inline-radio-2`}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group as={Col} md="6">
              <Form.Label className="big-label">
              If yes, what kind of business?
              </Form.Label>
              <Row>
                <Col>

                {constant.BUSINESS_TYPES?.map((data, index)=>(
                    <Form.Check
                      {...register(data.value)}
                      key={index}
                      inline
                      value={data.value}
                      label={data.label}
                      name={data.value}
                      defaultChecked={state?.tried_to_start_business_industry?.split(',')?.includes(data.value)}
                      type="checkbox"
                      id={`inline-radio-1`}
                      disabled={
                        disabled || watch('tried_to_start_business') !== 'true'
                      }
                    />
                  ))}
                </Col>
              </Row>

              <Form.Group as={Col} md="6" className="mt-2">
                <Controller
                    name="other_business"
                    control={control}
                    render={({ field }) =>(
                <FormInput
                  {...field}
                  title="Other business"
                  name="other_business"
                  isDisabled={disabled || watch('tried_to_start_business') !== 'true'}
                  type="text"
                  placeholder="Specify"
                />
              )}/>
              </Form.Group>
              
            </Form.Group>
            </Row>

            <Row className="mb-5">
              <Form.Group as={Col} md="6" className="mt-2">
                  <Controller
                        name="what_happened_to_business"
                        control={control}
                        render={({ field }) =>(
                        <InputSelect
                          {...field}
                          isRequired={true}
                          defaultSelect={state?.what_happened_to_business}
                          defaultValue={state?.what_happened_to_business}
                          isDisabled={disabled}
                          title="If yes, what happened to the business?"
                          name="what_happened_to_business"
                          options={constant.WHAT_HAPPENED_TO_BUSINESS}
                        />
                  )}/>
                  {/* <Controller
                      name="what_happened_to_business"
                      control={control}
                      render={({ field }) =>(
                  <FormInput
                    {...field}
                    title=" If yes, what happened to the business?"
                    name="what_happened_to_business"
                    isDisabled={disabled || watch('tried_to_start_business') !== 'true'}
                    type="text"
                    defaultValue={state.what_happened_to_business}
                    placeholder="Specify"
                  />
                )}/> */}
                </Form.Group>

            </Row>
            </Fragment>
            }

            <Row className="mb-5">
              {/* <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label className="big-label">
                  Have you operated your own busisness before?
                  <b className="text-danger fs-6">*</b>
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Check
                      {...register("operated_own_business")}
                      defaultChecked={state?.operated_own_business === true}
                      value={true}
                      inline
                      required
                      label="Yes"
                      
                      name="operated_own_business"
                      type="radio"
                      disabled={disabled}
                      id={`inline-radio-1`}
                    />
                    <Form.Check
                      {...register("operated_own_business")}
                      defaultChecked={state?.operated_own_business === false}
                      value={false}
                      disabled={disabled}
                      inline
                      required
                      label="No"
                      name="operated_own_business"
                      type="radio"
                      id={`inline-radio-2`}
                    />
                  </Col>
                </Row>
              </Form.Group> */}
              <Form.Group as={Col} md="6">
                <Form.Label className="big-label">
                  Have you worked or operated in a non-family business before?
                  <b className="text-danger fs-6">*</b>
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Check
                      defaultChecked={state?.operated_other_business === true}
                      value={true}
                      inline
                      required
                      label="Yes"
                      disabled={disabled}
                      {...register("operated_other_business")}
                      name="operated_other_business"
                      type="radio"
                      id={`inline-radio-1`}
                    />
                    <Form.Check
                      defaultChecked={state?.operated_other_business === false}
                      value={false}
                      inline
                      required
                      label="No"
                      disabled={disabled}
                      {...register("operated_other_business")}
                      name="operated_other_business"
                      type="radio"
                      id={`inline-radio-2`}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Row>
         
            <Row className="mb-5">
              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label className="big-label">
                Did your close relative own a business?
                 
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Check
                      {...register("parents_owned_business")}
                      defaultChecked={state?.parents_owned_business === true}
                      value={true}
                      inline
                      required
                      label="Yes"
                      
                      name="parents_owned_business"
                      type="radio"
                      disabled={disabled}
                      id={`inline-radio-1`}
                    />
                    <Form.Check
                      {...register("parents_owned_business")}
                      defaultChecked={state?.parents_owned_business === false}
                      value={false}
                      disabled={disabled}
                      inline
                      required
                      label="No"
                      name="parents_owned_business"
                      type="radio"
                      id={`inline-radio-2`}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label className="big-label">
                If yes, did you work in the family business?
                  
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Check
                      defaultChecked={state?.worked_for_family_business === true}
                      value={true}
                      inline
                      required
                      label="Yes"
                      disabled={disabled || watch('parents_owned_business') !=='true'}
                      {...register("worked_for_family_business")}
                      name="worked_for_family_business"
                      type="radio"
                      id={`inline-radio-1`}
                    />
                    <Form.Check
                      defaultChecked={state?.worked_for_family_business === false}
                      value={false}
                      inline
                      required
                      label="No"
                      disabled={disabled || watch('parents_owned_business') !=='true'}
                      {...register("worked_for_family_business")}
                      name="worked_for_family_business"
                      type="radio"
                      id={`inline-radio-2`}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Row>
           
            
            <Row className="mb-5">
              <Form.Group as={Col} md="6" className="mt-2">
                    <Controller
                        name="people_you_know_who_own_business"
                        control={control}
                        rules={{required:{
                          value:state?.people_you_know_who_own_business?false:true,
                          message:'Please Enter a value'
                        }}}
                        render={({ field }) =>(
                          <FormInput
                          {...field}
                          isRequired={true}
                          defaultValue={state?.people_you_know_who_own_business}
                          type="number"
                          placeholder="People You Know"
                          min="0"
                          isDisabled={disabled}
                          title={'How many people do you know who own a business?'}
                          error={errors?.people_you_know_who_own_business?.message}
                          />

                        // <InputSelect
                        //   {...field}
                        //   isRequired={true}
                        //   defaultSelect={state?.people_you_know_who_own_business}
                        //   defaultValue={state?.people_you_know_who_own_business}
                        //   isDisabled={disabled}
                        //   title="How many people do you know well personally who own their business?"
                        //   name="people_you_know_who_own_business"
                        //   options={constant.BUSINESS_PERSONALLY_KNOWNED}
                        // />
                  )}/>
                  </Form.Group>
            <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label className="big-label">
                Have you had training in entrepreneurship?
                  <b className="text-danger fs-6">*</b>
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Check
                      defaultChecked={
                        state?.has_previous_entrepreneurial_training === true
                      }
                      value={true}
                      required
                      inline
                      label="Yes"
                      {...register("has_previous_entrepreneurial_training")}
                      name="has_previous_entrepreneurial_training"
                      type="radio"
                      disabled={disabled}
                      id={`inline-radio-1`}
                    />
                    <Form.Check
                      defaultChecked={
                        state?.has_previous_entrepreneurial_training === false
                      }
                      value={false}
                      required
                      inline
                      disabled={disabled}
                      label="No"
                      {...register("has_previous_entrepreneurial_training")}
                      name="has_previous_entrepreneurial_training"
                      type="radio"
                      id={`inline-radio-2`}
                    />
                  </Col>
                </Row>
              </Form.Group>

              

            </Row>
            <Row className="mb-5">
            <Form.Group as={Col} md="6">
                <Form.Label className="big-label">
                Are you willing and able to dedicate time to go through the entrepreneurship training programme?
                <Required/>
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Check
                      defaultChecked={
                        state?.able_to_receive_entrepreneurial_training === true
                      }
                      value={true}
                      required
                      inline
                      label="Yes"
                      {...register("able_to_receive_entrepreneurial_training")}
                      name="able_to_receive_entrepreneurial_training"
                      type="radio"
                      disabled={disabled}
                      id={`inline-radio-1`}
                    />
                    <Form.Check
                      defaultChecked={
                        state?.able_to_receive_entrepreneurial_training === false
                      }
                      value={false}
                      required
                      inline
                      disabled={disabled}
                      label="No"
                      {...register("able_to_receive_entrepreneurial_training")}
                      name="able_to_receive_entrepreneurial_training"
                      type="radio"
                      id={`inline-radio-2`}
                    />
                  </Col>
                </Row>
              </Form.Group>
            

            </Row>

         
              
              {onSubmit ? (
                <div className="row mt-5 mb-3">
                  <Col md={4} className="mb-3">
                    <MainButton
                      text={"Previous"}
                      type={"button"}
                      onClick={onPreviousButtonClicked}
                      variant="outline-secondary"
                      size="lg"
                      className="fs-6"
                    />
                  </Col>
                  <Col md={{ span: 4, offset: 4 }}>
                    <MainButton
                      text={"Save and Continue"}
                      type={"submit"}
                      isLoading={loading}
                      variant="primary"
                      size="lg"
                      className="fs-6"
                    />
                  </Col>
                </div>
              ) : null}
          </Col>
        </Row>
      </Form>
  );
}

BusniessAccumen.propTypes = {
  disabled: propTypes.bool,
  state: propTypes.object,
  colSize: propTypes.number,
  onSubmit: propTypes.func,
  onPreviousButtonClicked: propTypes.func,
  loading: propTypes.bool,
};
