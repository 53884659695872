/*eslint-disable*/
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import GrantAccordionBase from './GrantAccordionBase';
import { IShareholderComponent } from '../GrantForm.interfaces';
import { useForm } from 'react-hook-form';

const ShareholderComponent = ({
  shareholderData,
  onDelete,
  defaultOpen,
}: IShareholderComponent) => {
  const { control: defaultControl } = useForm();
  return (
    <GrantAccordionBase
      title={shareholderData?.name || ''}
      expandable={true}
      defaultOpen={defaultOpen || false}
      actions={['delete']}
      onDelete={onDelete}
    >
      <Row>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={defaultControl}
            name={'name'}
            placeholder="Shareholder Name"
            defaultValue={shareholderData?.name}
            rules={{
              required: {
                message: 'This field is required',
                value: false,
              },
            }}
            isDisabled={true}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={defaultControl}
            name={'share_holding'}
            type="number"
            placeholder="Percentage (%)"
            defaultValue={shareholderData?.share_holding}
            rules={{
              required: {
                message: 'This field is required',
                value: false,
              },
            }}
            isDisabled={true}
          />
        </Col>
      </Row>
    </GrantAccordionBase>
  );
};

export default ShareholderComponent;
