import React, { Fragment, useState } from 'react';
import MainButton from '@components/buttons/Buttons';
import ModalDiv from '@components/modals/Modal';
import { Form, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { TextField } from '..';
import { PhoneNumberInput } from '../FormInput/FormInput';
import { getOtp, optLogin } from 'src/api/Auth/Auth';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import {
  handleErrorMessage,
  handleLoginRedirect,
  messageAlert,
  storeLocalStorage,
} from '@utils/helpers/functions';
import OTPLogin from './OtpLogin';
import propType from 'prop-types';
// import {SMSInput,FormInput} from '../FormInput/FormInput';

const validationSchema = yup.object({
  phone_number: yup.string().required('Required'),
});

const ResendForm = ({ id }) => {
  const navigate = useNavigate();

  const {
    control,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [otpModal, setOtpModal] = useState(false);
  const [otp, setOtp] = useState('');
  const handleOtpChange = (otp) => setOtp(otp);
  const [processState, setProcessState] = useState(0);
  const subTitle =
    'Enter the GEA Secret login pin that was sent to your SMS to proceed to your account dashboard.';

  const mutation = useMutation(optLogin);
  const mutation_get_otp = useMutation(getOtp);
  const handleLogin = () => {
    var data = { code: otp, ...getValues() };
    mutation.mutate(data, {
      onSuccess: (response) => {
        storeLocalStorage('user', JSON.stringify(response?.data));
        storeLocalStorage('accessToken', response.headers?.['set-auth-token']);

        if (response?.data?.profile?.is_application_complete) {
          navigate('/dashboard');
        } else {
          navigate(handleLoginRedirect(response?.data?.onboarding_data));
        }
      },
      onError: (error) => {
        var erroMesaage = handleErrorMessage(error?.response);

        messageAlert('error', erroMesaage);
      },
    });
  };

  const handleGetOtp = (data) => {
    // setOtpModal(true);
    data.verification_type = 'phone_number';
    data.verification_context = id == 'sms' ? 'sms_code' : 'id_number';
    data.verification_action = 'login';
    mutation_get_otp.mutate(data, {
      onSuccess: () => {
        // setOtpModal(true);
        setProcessState(1);
      },
      onError: (error) => {
        var erroMesaage = handleErrorMessage(error?.response);

        messageAlert('error', erroMesaage);

        // if (error?.response?.status == 400)
        // {

        //     messageAlert('error',error?.response?.data?.non_field_errors?.[0]);
        //     return;

        // }
      },
    });
  };

  const returnToLogin = () => {
    navigate('/login');
  };

  return (
    <>
      {processState == 1 && (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Row>
            <Col sm={12} md={12} lg={12}>
              <TextField
                text={`${id == 'sms' ? 'SMS Code Sent' : 'ID Number Sent'}`}
                className={' fw-bold mb-3 text-center resend-header'}
              />
              <TextField
                text={`${
                  id == 'sms'
                    ? 'Your SMS secret PIN has been sent to your phone number'
                    : 'Your ID Number has been sent to your phone number'
                }`}
                className={'mb-5 text-center resend-text'}
              />
            </Col>
            <div
              className="d-grid gap-2 mb-5"
              style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
            >
              <MainButton
                variant="primary"
                size="md"
                text="Return to Login"
                loadingVariant="light"
                onClick={returnToLogin}
              />
            </div>
          </Row>
        </div>
      )}
      {processState == 0 && (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Form
            style={{ padding: '1rem' }}
            className="mt-2 col-md-4"
            onSubmit={handleSubmit(handleGetOtp)}
          >
            <TextField
              text={`${
                id == 'sms'
                  ? 'Resend SMS Code'
                  : 'Resend ID Number Used For Registration'
              }`}
              className={' fw-bold mb-3 text-center resend-header'}
            />
            <TextField
              text={`${
                id == 'sms'
                  ? 'Enter the phone number you used to create your account. The SMS code used for registration will be sent to that number.'
                  : 'Enter the phone number you used to create your account. The ID Number used for registration will be sent to that number'
              }`}
              className={'mb-5 text-center resend-text'}
            />

            <Form.Group
              as={Col}
              className="mb-4"
              style={{
                paddingLeft: '2rem',
                paddingRight: '2rem',
                paddingBottom: '2rem',
              }}
            >
              <Controller
                name="phone_number"
                control={control}
                render={({ field }) => (
                  <PhoneNumberInput
                    {...field}
                    title="Phone number"
                    placeholder="Phone number"
                    isRequired={true}
                    error={errors?.phone_number?.message}
                  />
                )}
              />
            </Form.Group>
            {/* <Form.Group as ={Col} classname="mb-5" style={{textAlign:'right',marginBottom:'2rem',paddingLeft:'2rem',paddingRight:'2rem'}}>
                    <Link to="/" className="text-decoration-none mx-1 custom-link" style={{fontSize:'16px'}}>{authType==0?'Forgot SMS code?':'Forgot ID Number?'}</Link>
                </Form.Group> */}

            <div
              className="d-grid gap-2 mb-5"
              style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
            >
              <MainButton
                variant="primary"
                size="md"
                type="submit"
                isLoading={mutation_get_otp.isLoading}
                text="Submit"
                loadingVariant="light"
              />
            </div>
            <Row>
              <Col>
                <TextField
                  text={
                    <Fragment>
                      Return to
                      <span
                        className="text-decoration-none mx-1 custom-link"
                        onClick={returnToLogin}
                      >
                        {<>Login</>}
                      </span>
                    </Fragment>
                  }
                  className="py-5 px-5 text-center fw-bold mb-5"
                />
              </Col>
            </Row>
          </Form>
          <ModalDiv
            show={otpModal}
            onHide={() => setOtpModal(false)}
            title="Enter Your GEA Login Pin"
            subTitle={subTitle}
            body={
              <OTPLogin
                otp={otp}
                handleChange={handleOtpChange}
                requestCode={
                  'Forgot your code, please click the link to resend. '
                }
                phoneNumber={watch('phone_number')}
              />
            }
            actionButton={
              <MainButton
                text={'Send'}
                onClick={() => handleLogin()}
                isLoading={mutation.isLoading}
                isDisabled={otp?.length !== 6}
              />
            }
          />
        </div>
      )}
    </>
  );
};

ResendForm.propTypes = {
  id: propType.string,
};

export default ResendForm;
