/*eslint-disable*/
import { IControlledMultiSelectInput } from '@/utils/constants/globals.interfaces';
import React, { Fragment } from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

import styled from 'styled-components';

const ErrorMessageWrapper = styled.div`
  font-size: 15px;
  color: red;
  margin-top: 5px;
`;

const ControlledMultiSelect = ({
  name,
  title,
  isRequired,
  isDisabled,
  placeholder,
  defaultValue,
  options,
  control,
}: IControlledMultiSelectInput) => {
  return (
    <Fragment>
      <Form.Label className="controlled-input-label">
        {title || ''}{' '}
        {isRequired && <b className="text-danger fs-6 fw-bold">*</b>}
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <div className="controlled-input-wrapper">
                <Select
                  defaultValue={defaultValue}
                  {...field}
                  isMulti
                  name=""
                  options={options}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isDisabled={isDisabled}
                  
                />

                <ErrorMessageWrapper>
                  {error && '*This field is required'}
                </ErrorMessageWrapper>
              </div>
            );
          }}
        />
      </Form.Label>
    </Fragment>
  );
};

export default ControlledMultiSelect;
