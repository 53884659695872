import React, { useState } from "react";
import propTypes from "prop-types";
import { Row, Col, Form } from "react-bootstrap";
import TextField from "@components/forms/TextField/TextField";
import FormInput from "@components/forms/FormInput/FormInput";
import MainButton from "@components/buttons/Buttons";
import { useForm, Controller } from "react-hook-form";
// import { Required } from "@components/utils";
import constant from "@utils/constants/constants";
import { checkIfBlank } from "@utils/helpers/functions";

export default function OtherInformationVerification({
  disabled = false,
  state,
  colSize,
  onSubmit,
  onPreviousButtonClicked,
  loading,
}) {
  const { control,handleSubmit,register,watch,formState:{errors} } = useForm(); 
  const [controlState, setControlState] = useState({});
  function handleChange({ target }) {
    setControlState({ ...controlState, [target.name]:target.checked });
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="d-flex  align-items-center h-100">
        <Col md={colSize?colSize:12}>
          <TextField
            text={"Other Information"}
            className={"fs-5 fw-bold mb-5 text-center"}
          />

          <Row className="mb-5">
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label className="big-label">
                How did you hear about Ghana Jobs and Skills Project?
                {/* <Required/> */}
              </Form.Label>
              <Row>
                <Col>
                {constant.COMMUNICATION?.map((data, index)=>(
                  <Form.Check
                    key={index}
                    {...register(data.value, {
                      onChange: (e) => {handleChange(e)},
                    })}
                    value={data.value}
                    inline
                    label={data.label}
                    name={data.value}
                    required={false}
                    disabled={disabled}
                    type="checkbox"
                    id={`inline-checkbox-1`}
                    defaultChecked={state?.recommending_media_channels?.includes(data.value)}
                  />
                ))}
                </Col>
              </Row>
            </Form.Group>
            <Form.Group as={Col} md="6">
            <Controller
              name="other"
              control={control}
              defaultValue={state?.other_recommended_media_channels?.[0]}
              rules={{
              
                pattern:{
                  value:checkIfBlank(watch('other')||''),
                  message:'You cannot submit an empty space'
                }
              }}
              render={({ field }) => (
              <FormInput
              {...field}
                title="Other"
                defaultValue={state?.other_recommended_media_channels?.[0]}
                name="other"
                type="text"
                isDisabled={disabled}
                placeholder="specify"
                error={errors?.other?.message}
              />)}
              />
            </Form.Group>
            {onSubmit && (
              <div className="row mt-5 mb-3">
                <Col md={4} className="mb-3">
                  <MainButton
                    text={"Previous"}
                    type={"button"}
                    onClick={onPreviousButtonClicked}
                    variant="outline-secondary"
                    size="lg"
                    className="fs-6"
                  />
                </Col>
                <Col md={{ span: 4, offset: 4 }}>
                  <MainButton
                    text={"Save and Continue"}
                    type={"submit"}
                    variant="primary"
                    size="lg"
                    className="fs-6"
                    isLoading={loading}
                  />
                </Col>
              </div>
            )}
          </Row>
        </Col>
      </Row>
    </Form>
  );
}

OtherInformationVerification.propTypes = {
  disabled: propTypes.bool,
  state: propTypes.object,
  colSize:propTypes.number,
  onSubmit: propTypes.func,
  onPreviousButtonClicked: propTypes.func,
  loading:propTypes.bool
};
