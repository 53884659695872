import React, { Fragment } from "react";
import propTypes from "prop-types";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import { Box } from "@mui/material";

export const SMSInput = ({
  title,
  name,
  isRequired,
  isDisabled,
  type,
  placeholder,
  onChange,
  defaultValue,
  error,
  maxDate,
  accept,
  minDate,
  min,
}) => {

  const smsStyle = {
    borderRadius:'0.5rem',
    border:'1px solid #DFE2E5',
    display:'flex',
    alignItems:'center',
    paddingTop:'0.5rem',
    paddingBottom:'0.5rem',
    paddingLeft:'0.5rem'
  }
  return (
    <Fragment>
      {title && (
        <Form.Label style={{fontSize:'14px',fontWeight:'600',color:'#4F4F4F'}}>
          {title}{" "}
          {isRequired && <b className="text-danger fs-6 fw-bold">*</b>}
        </Form.Label>
      )}
      <Box style={smsStyle}>
        <Box style={{fontSize:'14px',letterSpacing:'2px'}}>GEA-</Box>
        <Form.Control
          name={name}
          required={isRequired}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          disabled={isDisabled}
          defaultValue={defaultValue}
          max={maxDate}
          accept={accept}
          minDate={minDate}
          className={`sms-input ${error && "is-invalid"}`}
          maxLength={6}
          min={min}
          style={{paddingLeft:'0px',paddingRight:'0px',border:'0px',fontSize:'14px',letterSpacing:'2px',width:'67px',textAlign:'center'}}
        />
        <Box style={{fontSize:'14px',letterSpacing:'2px'}}>-XXXX</Box>
      </Box>
      <div className="invalid-feedback">{error}</div>
    </Fragment>
  );
};

SMSInput.propTypes = {
  title: propTypes.string,
  name: propTypes.string,
  isRequired: propTypes.bool,
  isDisabled: propTypes.bool,
  type: propTypes.string,
  placeholder: propTypes.string,
  onChange: propTypes.func,
  defaultValue: propTypes.string,
  error: propTypes.any,
  maxDate: propTypes.string,
  accept: propTypes.string,
  minDate:propTypes.string,
  length:propTypes.string,
  min: propTypes.string
};


export const FormInput = ({
  title,
  name,
  isRequired,
  isDisabled,
  type,
  placeholder,
  onChange,
  defaultValue,
  error,
  maxDate,
  accept,
  minDate,
  length,
  min,
}) => {
  return (
    <Fragment>
      {title && (
        <Form.Label style={{fontSize:'14px',fontWeight:'600',color:'#4F4F4F'}}>
          {title}{" "}
          {isRequired && <b className="text-danger fs-6 fw-bold">*</b>}
        </Form.Label>
      )}
      <Form.Control
        name={name}
        required={isRequired}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        disabled={isDisabled}
        defaultValue={defaultValue}
        max={maxDate}
        accept={accept}
        minDate={minDate}
        className={`form-text-input ${error && "is-invalid"}`}
        maxLength={length}
        min={min}
        style={{border:'1px solid #DFE2E5'}}
      />
      <div className="invalid-feedback">{error}</div>
    </Fragment>
  );
};
FormInput.propTypes = {
  title: propTypes.string,
  name: propTypes.string,
  isRequired: propTypes.bool,
  isDisabled: propTypes.bool,
  type: propTypes.string,
  placeholder: propTypes.string,
  onChange: propTypes.func,
  defaultValue: propTypes.string,
  error: propTypes.any,
  maxDate: propTypes.string,
  accept: propTypes.string,
  minDate:propTypes.string,
  length:propTypes.string,
  min: propTypes.string
};

export const PhoneNumberInput = ({
  title,
  isRequired,
  placeholder,
  value,
  onChange,
  error,
}) => {
  return (
    <Fragment>
      {title && (
        <Form.Label style={{fontSize:'15px',fontWeight:'600',color:'#4F4F4F'}}>
          {title}{" "}
          {isRequired && <b className="text-danger fs-6 fw-bold">*</b>}
        </Form.Label>
      )}
      <PhoneInput
      style={{border:'1px solid #DFE2E5',borderRadius:'0.5rem',paddingLeft:'0.5rem'}}
        maxLength={12}
        placeholder={placeholder}
        className={` phone-input ${error && "is-invalid"} `}
        value={value}
        defaultCountry="GH"
        international={false}
        countryCallingCodeEditable={false}
        onChange={onChange}
      />
      <div className="invalid-feedback">{error}</div>
    </Fragment>
  );
};
PhoneNumberInput.propTypes = {
  title: propTypes.string,
  isRequired: propTypes.bool,
  placeholder: propTypes.string,
  value: propTypes.string,
  onChange: propTypes.func,
  error: propTypes.string,
};

export default FormInput;
