/*eslint-disable */
import React from 'react';
import {
  FiscalProjectionTableColumns,
  useGetProjectInformation4,
} from '@/views/GrantForm/services/BusinessPlan/ProjectInformation.service';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import BusinessFormBaseLayout from '@/views/GrantForm/BusinessFormBaseLayout';
import { Row, Col } from 'react-bootstrap';
import GrantAccordionBase from '../../GrantAccordionBase';
import RevenueSubform from '../GrantForm/RevenueSubform';
import { RevenueRow } from '../GrantForm/RevenueGeneration';
import {
  FormTableInputOptions,
  getDefaultFinancialProjectionValues,
} from '@/views/GrantForm/services/GrantFormBaseLayout.service';
import { IBusinessPlanFormStage } from '@/views/GrantForm/GrantForm.interfaces';
import InstructionText from '../../InstructionText';
import FormInputTable from '@/components/tables/FormInputTable';

const ProjectInformation4 = ({
  isPreview,
  isPrint,
}: IBusinessPlanFormStage) => {
  const {
    control,
    userData,
    isLoading,
    loadTableData,
    watch,
    handleSubmit,
    onSubmit,
  } = useGetProjectInformation4();
  return (
    <BusinessFormBaseLayout
      page={3}
      title={'Project Information'}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      formStage={'project_information_4'}
      preview={isPreview}
      print={isPrint}
    >
      <>
        {(isPreview || isPrint) && (
          <Row style={{ marginBottom: '20px' }}>
            {(isPreview || isPrint) && (
              <Col md={12} xs={12}>
                <FormInputTable
                  title="Fiscal Projections"
                  columns={FiscalProjectionTableColumns}
                  options={FormTableInputOptions}
                  rows={loadTableData(
                    userData?.data?.profile?.grant_information
                      ?.financial_projections
                  )}
                />
              </Col>
            )}
          </Row>
        )}
        {!isPreview && !isPrint && (
          <>
            <Row>
              <Col md={12} xs={12}>
                <InstructionText
                  title={' Outline the projections for the next three years'}
                  subtitle="Fill in the forms below to outline your fiscal projections for the next 3 years."
                  isInfo={true}
                />
              </Col>
            </Row>
            <RevenueRow>
              <Col md={12} xs={12}>
                <GrantAccordionBase
                  title="Fiscal Year One"
                  expandable={true}
                  defaultOpen={isPrint || isPreview}
                >
                  <RevenueSubform
                    control={control}
                    fieldNames={[
                      'fiscalOneTurnover',
                      'fiscalOneOperatingCost',
                      'fiscalOneGrossProfit',
                      'fiscalOneNetCashFlow',
                    ]}
                    defaultValueMapping={getDefaultFinancialProjectionValues({
                      data: userData?.data?.profile?.grant_information
                        ?.financial_projections,
                      key: 'year_one',
                    })}
                    isDisabled={isPreview === true}
                    watch={watch}
                  />
                </GrantAccordionBase>
              </Col>
            </RevenueRow>
            <RevenueRow>
              <Col md={12} xs={12}>
                <GrantAccordionBase
                  title="Fiscal Year Two"
                  expandable={true}
                  defaultOpen={isPrint || isPreview}
                >
                  <RevenueSubform
                    control={control}
                    fieldNames={[
                      'fiscalTwoTurnover',
                      'fiscalTwoOperatingCost',
                      'fiscalTwoGrossProfit',
                      'fiscalTwoNetCashFlow',
                    ]}
                    defaultValueMapping={getDefaultFinancialProjectionValues({
                      data: userData?.data?.profile?.grant_information
                        ?.financial_projections,
                      key: 'year_two',
                    })}
                    isDisabled={isPreview === true}
                    watch={watch}
                  />
                </GrantAccordionBase>
              </Col>
            </RevenueRow>
            <RevenueRow>
              <Col md={12} xs={12}>
                <GrantAccordionBase
                  title="Fiscal Year Three"
                  expandable={true}
                  defaultOpen={isPrint || isPreview}
                >
                  <RevenueSubform
                    control={control}
                    fieldNames={[
                      'fiscalThreeTurnover',
                      'fiscalThreeOperatingCost',
                      'fiscalThreeGrossProfit',
                      'fiscalThreeNetCashFlow',
                    ]}
                    defaultValueMapping={getDefaultFinancialProjectionValues({
                      data: userData?.data?.profile?.grant_information
                        ?.financial_projections,
                      key: 'year_three',
                    })}
                    isDisabled={isPreview === true}
                    watch={watch}
                  />
                </GrantAccordionBase>
              </Col>
            </RevenueRow>
          </>
        )}
      </>

      {/* <RevenueRow>
        <Col md={12} xs={12}>
          <GrantAccordionBase
            title="Fiscal Year Four"
            expandable={true}
            defaultOpen={isPrint || false}
          >
            <RevenueSubform
              control={control}
              fieldNames={[
                'fiscalFourTurnover',
                'fiscalFourOperatingCost',
                'fiscalFourGrossProfit',
                'fiscalFourNetCashFlow',
              ]}
              defaultValueMapping={getDefaultFinancialProjectionValues({
                data: userData?.data?.profile?.grant_information
                  ?.financial_projections,
                key: 'year_four',
              })}
              isDisabled={isPreview === true}
            />
          </GrantAccordionBase>
        </Col>
      </RevenueRow>
      <RevenueRow>
        <Col md={12} xs={12}>
          <GrantAccordionBase
            title="Fiscal Year Five"
            expandable={true}
            defaultOpen={isPrint || false}
          >
            <RevenueSubform
              control={control}
              fieldNames={[
                'fiscalFiveTurnover',
                'fiscalFiveOperatingCost',
                'fiscalFiveGrossProfit',
                'fiscalFiveNetCashFlow',
              ]}
              defaultValueMapping={getDefaultFinancialProjectionValues({
                data: userData?.data?.profile?.grant_information
                  ?.financial_projections,
                key: 'year_five',
              })}
              isDisabled={isPreview === true}
            />
          </GrantAccordionBase>
        </Col>
      </RevenueRow> */}
      {/* <Row>
        <Col md={12} xs={12}>
          <ControlledTextInput
            control={control}
            name="key_assumptions_for_business"
            placeholder="Your response must not be more than 100 words"
            as={'textarea'}
            title="What are the key assumptions or justifications, or logic for your projections above?  Not more than 100 words"
            defaultValue={
              userData?.data?.profile?.grant_information
                ?.key_assumptions_for_business
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information
                  ?.key_assumptions_for_business,
            }}
            isDisabled={isPreview === true}
            length="100"
          />
        </Col>
      </Row> */}
    </BusinessFormBaseLayout>
  );
};

export default ProjectInformation4;
