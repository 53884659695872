/*eslint-disable*/
import {
  IGrantUpdateParams,
  IProductMarketing,
  IUserUpdateParams,
} from '../GrantForm.interfaces';
import { useForm, SubmitHandler } from 'react-hook-form';
import { IUserModel } from '@/utils/constants/userModel.interface';
import { AxiosResponse } from 'axios';
import { logError, useGetUserObject } from './GrantFormBaseLayout.service';
import { updateGrantInformation } from '@/api/User/User';
import { useNavigate } from 'react-router-dom';

export const useGetProductMarketingForm = () => {
  const { control, watch, formState, handleSubmit } =
    useForm<IProductMarketing>();
  const { userData, refetch, userError, userLoading } = useGetUserObject();
  const navigate = useNavigate();
  let _userData = userData as AxiosResponse<Partial<IUserModel>>;
  const onSubmit: SubmitHandler<IProductMarketing> = async (data) => {
    try {
      let grantParams: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };
      let userUpdateParams: IUserUpdateParams = {
        id: undefined,
        data: undefined,
      };
      grantParams.id = _userData?.data?.profile?.grant_information?.id;
      grantParams.data = {
        ..._userData?.data?.profile?.grant_information,
        ...data,
      };
      userUpdateParams.id = _userData?.data?.profile?.client;
      userUpdateParams.data = {
        grant_onboarding_data: {
          ..._userData?.data?.grant_onboarding_data,
          product_information_2: true,
        },
      };
      await updateGrantInformation(grantParams);
      navigate('/grants/revenue_generation');
    } catch (e) {
      logError(e);
    }
  };

  return {
    control,
    watch,
    formState,
    handleSubmit,
    onSubmit,
    userData: _userData,
    isLoading: userLoading,
  };
};
