import React from 'react';
import propTypes from "prop-types";
import { Drawer,Box,Button } from '@mui/material';
import logo from '../../images/logo/gealogo.png';
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { logOut } from "src/api/Auth/Auth";

const MobileDrawer = ({open ,setOpen}) => {

  const navigate = useNavigate();
  const drawerStyle = {
    width:`${window.innerWidth*0.8}px`,
    padding:'2rem',
    display:'flex',
    flexDirection:'column',
    backgroundColor:'#2A3B8F',
    height:'100%',
    color:'white'
  }

  const navItemStyle = {
    padding:'1rem',
    paddingLeft:'unset',
    color:'white',
    display:'flex',
    fontSize:'1rem',
    alignItems:'center'
  }
  return (
   <Drawer open = {open} onClose={()=>{setOpen(false)}}>
        <Box sx={drawerStyle}>
          

           <Box style={navItemStyle} sx={{marginBottom:'1rem'}}>
              <Box>
                <img src={logo} style={{width:'48px',height:'48px'}}></img>
              </Box>
              <Box sx={{marginBottom:'1rem',order:'1',marginLeft:'auto',marginTop:'1rem'}}>
                  <Button onClick={()=>{setOpen(false)}}>
                    <CloseIcon sx={{color:'white'}}/>
                  </Button>
              </Box>
           
           </Box>

           <Box style={navItemStyle} onClick={()=>{navigate('/dashboard')}}>
              <Box>
                <HomeIcon sx={{color:'white'}}/>
              </Box>
              <Box sx={{color:'white',fontSize:'1rem',marginLeft:'1rem'}}>
                Home
              </Box>
           </Box>

           <Box style={navItemStyle} onClick={()=>{navigate('/dashboard/profile')}}>
                <Box>
                    <AccountBoxIcon sx={{color:'white'}}/>
                </Box>
                <Box sx={{color:'white',fontSize:'1rem',marginLeft:'1rem'}}>
                    Profile
                </Box>
           </Box>

           <Box style={navItemStyle} onClick={()=>{navigate('/dashboard/training')}}>
                <Box>
                    <BorderColorIcon sx={{color:'white'}}/>
                </Box>
                <Box sx={{color:'white',fontSize:'1rem',marginLeft:'1rem'}}>
                    Training
                </Box>
           </Box>
           <Box style={navItemStyle} onClick={()=>{logOut()}}>
                <Box>
                    <LogoutIcon sx={{color:'white'}}/>
                </Box>
                <Box sx={{color:'white',fontSize:'1rem',marginLeft:'1rem'}}>
                    Logout
                </Box>
           </Box>

        </Box>
   </Drawer>
  )
}


MobileDrawer.propTypes  = {
    open: propTypes.bool,
    setOpen: propTypes.func
}
export default MobileDrawer