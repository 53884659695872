import React from 'react';
import { Box } from '@mui/material';
import MobileNavbar from './MobileNavbar';
import propTypes from "prop-types";

const MobileBaseLayout = (props) => {

  return (
    <Box>
        <MobileNavbar></MobileNavbar>
        {props.children}
    </Box>
  )
}

MobileBaseLayout.propTypes = {
    children:propTypes.node
}

export default MobileBaseLayout;