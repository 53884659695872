/*eslint-disable */
import React from 'react';
import {
  ExpenditureTypeOptions,
  GrantExpenditureTableColumns,
  useGetGrantRequest,
} from '@/views/GrantForm/services/BusinessPlan/GrantRequest.service';
import BusinessFormBaseLayout from '@/views/GrantForm/BusinessFormBaseLayout';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import { Row, Col, Form } from 'react-bootstrap';
import InstructionText from '../../InstructionText';
import GrantAccordionBase from '../../GrantAccordionBase';
import AddButton from '../../AddButton';
import RevenueInput from '../../RevenueInput';
import CustomFileUpload from '../../CustomFileUpload';
import ExpenditureComponent from '../../ExpenditureComponent';
import { IBusinessPlanFormStage } from '@/views/GrantForm/GrantForm.interfaces';
import FormLoader from '../../FormLoader';
import ControlledSelectInput from '@/components/forms/FormInput/ControlledSelectInput';
import {
  ClearButton,
  ClearButtonText,
} from '../../styledcomponents/BusinessPlanComponents';
import { Close } from '@mui/icons-material';
import FormInputTable from '@/components/tables/FormInputTable';
import { FormTableInputOptions } from '@/views/GrantForm/services/GrantFormBaseLayout.service';
import { Box } from '@mui/material';

const GrantRequest = ({ isPreview, isPrint }: IBusinessPlanFormStage) => {
  const {
    control,
    itemControl,
    expenditureList,
    file,
    userLoading,
    userData,
    isRefetchingExpenditure,
    isFileUploading,
    clearFile,
    getTrainingDescriptionPlaceholder,
    loadTableData,
    getTotalExpenditures,
    handleItemSubmit,
    onItemSubmit,
    handleSubmit,
    onSubmit,
    handleFileChange,
    watch,
    itemWatch,
    onExpenditureDelete,
    onExpenditureEdit,
    clearFiles,
  } = useGetGrantRequest();
  return (
    <BusinessFormBaseLayout
      page={4}
      title={'Grant Request'}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      isLoading={userLoading}
      formStage={'grant_request'}
      preview={isPreview}
      print={isPrint}
    >
      <Row>
        <Col md={12} xs={12} style={{ marginBottom: '15px' }}>
          <RevenueInput
            name={'total_expected_expenditure'}
            title={'Total expected expenditure'}
            control={control}
            placeholder="Enter Amount"
            defaultValue={
              userData?.data?.profile?.grant_information?.business_plan
                ?.total_funding_requested
            }
            value={getTotalExpenditures(expenditureList?.data)}
            rules={{
              required:
                !userData?.data?.profile?.grant_information
                  ?.planned_total_expenditure,
            }}
            isDisabled={isPreview === true}
          />
          <Box style={{ marginTop: '13px' }}>
            <span style={{ fontStyle: 'bold', fontWeight: '700' }}>
              Grant Amount Requested:{' '}
            </span>{' '}
            <span
              style={{
                fontWeight: '700',
                color:
                  parseInt(
                    userData?.data?.profile?.grant_information?.business_plan
                      ?.total_funding_requested || '0'
                  ) -
                    (getTotalExpenditures(expenditureList?.data) || 0) ==
                  0
                    ? 'green'
                    : 'red',
              }}
            >
              GHC{' '}
              {
                userData?.data?.profile?.grant_information?.business_plan
                  ?.total_funding_requested
              }
            </span>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12}>
          <InstructionText
            title={'Expected Grants Expenditure:'}
            subtitle={`NB: For all proposed capital expenditure items exceeding GHS10,000 such as machinery and equipment, applicants MUST submit/upload THREE (3) proforma invoices from separate vendors. 
              Where applicant is procuring raw materials, at least ONE proforma invoice must be submitted to support the Expected Grant Expenditure. Important: To proceed, you must ensure that your planned total expenditure is equal to the total grant amount requested.`}
            isInfo={true}
          />
        </Col>
      </Row>
      {!isPreview && (
        <Row style={{ marginBottom: '20px' }}>
          <Col md={12} xs={12}>
            <GrantAccordionBase expandable title={'Create Item'}>
              <Row>
                <Col md={12} xs={12}>
                  <ControlledSelectInput
                    name="expenditure_type"
                    title="Expenditure Type"
                    control={itemControl}
                    options={ExpenditureTypeOptions}
                    rules={{ required: true }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} xs={12}>
                  <ControlledTextInput
                    control={itemControl}
                    name="item_name"
                    title={'Item Description'}
                    placeholder={getTrainingDescriptionPlaceholder(
                      itemWatch('expenditure_type')
                    )}
                    rules={{ required: true }}
                    isDisabled={isPreview === true || isFileUploading == true}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <ControlledTextInput
                    control={itemControl}
                    name="item_quantity"
                    title={'Quantity'}
                    type="number"
                    rules={{ required: true }}
                    placeholder="Enter Amount"
                    isDisabled={isPreview === true || isFileUploading == true}
                    min={'0'}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} xs={12}>
                  <RevenueInput
                    control={itemControl}
                    name="unit_cost"
                    title="Unit Cost"
                    placeholder="Enter Amount"
                    rules={{ required: true }}
                    isDisabled={isPreview === true || isFileUploading == true}
                    min={'0'}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <RevenueInput
                    control={itemControl}
                    name="amount"
                    title="Amount(GHS)"
                    placeholder="Enter Amount"
                    value={
                      (parseInt(itemWatch('item_quantity')) || 0) *
                      (parseInt(itemWatch('unit_cost')) || 0)
                    }
                    rules={{ required: false }}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col md={12} xs={12}>
                  <Form.Label className="controlled-input-label">
                    Upload Invoice
                  </Form.Label>
                </Col>

                <Col md={12} xs={12}>
                  <CustomFileUpload
                    files={file}
                    onFileChange={handleFileChange}
                    isMulti={
                      itemWatch('expenditure_type') == 'capital_expenditure'
                        ? true
                        : false
                    }
                    onFileRemove={clearFile}
                  />
                </Col>
                <Col md={12} xs={12} style={{ marginTop: '10px' }}>
                  <InstructionText title="Allowed File types: .pdf, .jpg, .png (No Excel files)" />
                </Col>
              </Row>
              <Row style={{ marginTop: '15px' }}>
                <Col md={12} xs={12}>
                  <AddButton
                    disabled={isFileUploading}
                    text="Add Grant Expenditure Item"
                    onClick={handleItemSubmit(onItemSubmit)}
                  />
                </Col>
              </Row>
            </GrantAccordionBase>
          </Col>
        </Row>
      )}

      <Row style={{ marginBottom: '20px' }}>
        {(isPreview || isPrint) && (
          <Col md={12} xs={12}>
            <FormInputTable
              title="Grant Expenditure Table"
              columns={GrantExpenditureTableColumns}
              options={FormTableInputOptions}
              rows={loadTableData(expenditureList?.data)}
            />
          </Col>
        )}
      </Row>

      {!isPreview && !isPrint && (
        <Row
          style={{
            height: !isPreview ? '300px' : 'initial',
            overflowY: 'auto',
          }}
        >
          {isRefetchingExpenditure == true ||
            (isFileUploading == true && <FormLoader />)}
          {!isRefetchingExpenditure && (
            <>
              {expenditureList?.data?.map((expenditure) => {
                return (
                  <Col md={12} xs={12} key={`${expenditure.id}`}>
                    <ExpenditureComponent
                      id={expenditure.id}
                      {...expenditure}
                      onDelete={() => {
                        onExpenditureDelete(expenditure?.id);
                      }}
                      onEdit={onExpenditureEdit}
                      quantity={expenditure?.quantity?.toString()}
                      total_amount_spent={expenditure?.total_amount_spent?.toString()}
                      unit_cost={expenditure?.unit_cost?.toString()}
                      isPreview={isPreview}
                      isPrint={isPrint}
                    />
                  </Col>
                );
              })}
            </>
          )}
        </Row>
      )}
    </BusinessFormBaseLayout>
  );
};

export default GrantRequest;
