/*eslint-disable*/
import React from 'react';
import GrantFormBaseLayout from '@views/GrantForm/GrantFormBaseLayout';
import { Row, Col, Form } from 'react-bootstrap';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import { useGetBusinessForm2 } from '../../../services/BusinessForm.service';
import GrantAccordionBase from '../../GrantAccordionBase';
import AddButton from '../../AddButton';
import styled from 'styled-components';
import ShareholderComponent from '../../ShareholderComponent';
import { IGrantFormStage } from '@/views/GrantForm/GrantForm.interfaces';

const BreakdownLabelWrapper = styled.div`
  margin-bottom: 20px;
  margin-right: 10px;
  align-items: center;
`;

const BusinessForm2 = ({ isPreview, isPrint }: IGrantFormStage) => {
  const {
    control,
    handleSubmit,
    onSubmit,
    shareholderControl,
    shareholderHandleSubmit,
    onShareholderSubmit,
    userLoading,
    userData,
    onShareholderDelete,
  } = useGetBusinessForm2();
  return (
    <GrantFormBaseLayout
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      page={2}
      title={'Business Information'}
      isLoading={userLoading}
      stage={'business_form_2'}
      preview={isPreview}
      print={isPrint}
    >
      <Row>
        <Col md={6} xs={12}>
          <Col md={12} xs={12}>
            <Form.Label className={'controlled-input-label'}>
              How many full-time employees does the business have?
            </Form.Label>
          </Col>
          <Row>
            <Col
              md={4}
              sm={12}
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <BreakdownLabelWrapper>Males:</BreakdownLabelWrapper>
              <ControlledTextInput
                control={control}
                name={'full_time_male_employees'}
                type={'number'}
                rules={{
                  required: {
                    message: 'First Name is Required',
                    value:
                      !userData?.data?.profile?.grant_information
                        ?.full_time_male_employees,
                  },
                }}
                defaultValue={
                  userData?.data?.profile?.grant_information
                    ?.full_time_male_employees
                }
                isDisabled={isPreview == true}
              />
            </Col>
            <Col
              md={4}
              sm={12}
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <BreakdownLabelWrapper>Females:</BreakdownLabelWrapper>
              <ControlledTextInput
                control={control}
                name={'full_time_female_employees'}
                type={'number'}
                rules={{
                  required: {
                    message: 'First Name is Required',
                    value:
                      !userData?.data?.profile?.grant_information
                        ?.full_time_female_employees,
                  },
                }}
                defaultValue={
                  userData?.data?.profile?.grant_information
                    ?.full_time_female_employees
                }
                isDisabled={isPreview == true}
              />
            </Col>
          </Row>
        </Col>

        <Col md={6} xs={12}>
          <Col md={12} xs={12}>
            <Form.Label className={'controlled-input-label'}>
              How many part-time/casual employees does the business have?
            </Form.Label>
          </Col>
          <Row>
            <Col
              md={4}
              sm={12}
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <BreakdownLabelWrapper>Males:</BreakdownLabelWrapper>
              <ControlledTextInput
                control={control}
                name={'part_time_male_employees'}
                type={'number'}
                rules={{
                  required: {
                    message: 'First Name is Required',
                    value:
                      !userData?.data?.profile?.grant_information
                        ?.part_time_male_employees,
                  },
                }}
                defaultValue={
                  userData?.data?.profile?.grant_information
                    ?.part_time_male_employees
                }
                isDisabled={isPreview == true}
              />
            </Col>
            <Col
              md={4}
              sm={12}
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <BreakdownLabelWrapper>Females:</BreakdownLabelWrapper>
              <ControlledTextInput
                control={control}
                name={'part_time_female_employees'}
                type={'number'}
                rules={{
                  required: {
                    message: 'First Name is Required',
                    value:
                      !userData?.data?.profile?.grant_information
                        ?.part_time_female_employees,
                  },
                }}
                defaultValue={
                  userData?.data?.profile?.grant_information
                    ?.part_time_female_employees
                }
                isDisabled={isPreview == true}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Form.Label className={'controlled-input-label'}>
          Provide details of the business owners and their shareholding
          structure*
        </Form.Label>
        <form
          onSubmit={shareholderHandleSubmit(onShareholderSubmit)}
          id="shareholder-form"
        >
          <Col md={12} xs={12}>
            <GrantAccordionBase title={'Shareholder Form'} expandable={false}>
              <Row>
                <Col md={6} xs={12}>
                  <ControlledTextInput
                    control={shareholderControl}
                    name={'name'}
                    placeholder="Shareholder Name"
                    rules={{
                      required: {
                        message: 'This field is required',
                        value: true,
                      },
                    }}
                    isDisabled={isPreview == true}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <ControlledTextInput
                    control={shareholderControl}
                    name={'share_holding'}
                    type="number"
                    placeholder="Percentage"
                    rules={{
                      required: {
                        message: 'This field is required',
                        value: true,
                      },
                    }}
                    isDisabled={isPreview == true}
                  />
                </Col>
              </Row>
            </GrantAccordionBase>
          </Col>
          <Col md={12} xs={12} style={{ marginTop: '15px' }}>
            <AddButton
              text={'Add Owner'}
              onClick={shareholderHandleSubmit(onShareholderSubmit)}
              disabled={isPreview == true}
            />
          </Col>
        </form>
      </Row>
      <Row>
        {userData?.data?.profile?.grant_information?.shareholders?.map(
          (shareholder, index) => {
            return (
              <Col md={12} xs={12} style={{ marginTop: '20px' }}>
                <ShareholderComponent
                  key={`${shareholder?.id}-${index}`}
                  shareholderData={shareholder}
                  onDelete={() => {
                    onShareholderDelete(shareholder.id);
                  }}
                  defaultOpen={index == 0 ? true : false}
                />
              </Col>
            );
          }
        )}
      </Row>
    </GrantFormBaseLayout>
  );
};

export default BusinessForm2;
