import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import personal_files from "@images/bg/personal_files.svg";
import { useQuery } from "react-query";
import { getUserProfile } from "src/api/User/User";

export default function LoanPayment() {
  const {
    error,
    data: responseData,
    isLoading,
  } = useQuery("user-data", getUserProfile);

  const data = responseData?.data;

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  if (error) {
    return <h1>Something Went Wrong...</h1>;
  }

  return (
    <Container>
      <Row className="mx-4 my-4">
        <Col className="d-flex justify-content-center">
          {data && data.is_approved_for_grant ? (
            <span>all done</span>
          ) : (
            <div>
              <h5 className="text-center">
                You do not have any approved application
              </h5>
              <img
                src={personal_files}
                style={{ width: "550px", height: "550px" }}
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}
