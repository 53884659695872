/*eslint-disable*/
import React from 'react';
import GrantFormBaseLayout from '../../../GrantFormBaseLayout';
import { Row, Col } from 'react-bootstrap';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import { useGetImpactForm } from '../../../services/ImpactForm.service';
import { IGrantFormStage } from '@/views/GrantForm/GrantForm.interfaces';

const ImpactForm = ({ isPreview, isPrint }: IGrantFormStage) => {
  const {
    watch,
    control,
    handleSubmit,
    formState,
    onSubmit,
    isLoading,
    userData,
  } = useGetImpactForm();
  return (
    <GrantFormBaseLayout
      page={8}
      title={'Impact'}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      stage={'impact'}
      preview={isPreview}
      print={isPrint}
    >
      <form>
        <Row>
          <Col md={12} xs={12}>
            <ControlledTextInput
              control={control}
              title={
                'What results do you expect to achieve one year after the grants facility?'
              }
              name={'results_expected_after_a_year_of_funding'}
              as={'textarea'}
              defaultValue={
                userData?.data?.profile?.grant_information
                  ?.results_expected_after_a_year_of_funding
              }
              rules={{
                required:
                  !userData?.data?.profile?.grant_information
                    ?.results_expected_after_a_year_of_funding,
              }}
              placeholder="Expected results after one year"
              isDisabled={isPreview == true}
            />
          </Col>
        </Row>
      </form>
    </GrantFormBaseLayout>
  );
};

export default ImpactForm;
