import React, { Fragment, useState } from 'react';
import MainButton from '@components/buttons/Buttons';
import ModalDiv from '@components/modals/Modal';
import { Form, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './style.css';
import { TextField } from '..';
import { PhoneNumberInput } from '../FormInput/FormInput';
import { getOtp, optLogin } from 'src/api/Auth/Auth';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import {
  handleErrorMessage,
  handleLoginRedirect,
  messageAlert,
  storeLocalStorage,
} from '@utils/helpers/functions';
import OTPLogin from './OtpLogin';
import { SMSInput, FormInput } from '../FormInput/FormInput';

const validationSchema = yup.object({
  phone_number: yup.string().required('Required'),
});

const LoginForm = () => {
  const navigate = useNavigate();

  const {
    control,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [otpModal, setOtpModal] = useState(false);
  const [authType, setAuthType] = useState(0);
  const [otp, setOtp] = useState('');
  const handleOtpChange = (otp) => setOtp(otp);
  const subTitle =
    'Enter the GEA Secret login pin that was sent to your SMS to proceed to your account dashboard.';

  const mutation = useMutation(optLogin);
  const mutation_get_otp = useMutation(getOtp);
  const handleLogin = () => {
    var data = { code: otp, ...getValues() };
    let finalData = {};
    if (authType == 0) {
      finalData = { code: data.secret_pin, phone_number: data?.phone_number };
    } else {
      finalData = {
        id_number: data?.id_number,
        phone_number: data?.phone_number,
      };
    }

    mutation.mutate(finalData, {
      onSuccess: (response) => {
        storeLocalStorage('user', JSON.stringify(response?.data));
        storeLocalStorage('accessToken', response.headers?.['set-auth-token']);

        if (response?.data?.profile?.is_application_complete) {
          navigate('/dashboard');
        } else {
          navigate(handleLoginRedirect(response?.data?.onboarding_data));
        }
      },
      onError: (error) => {
        var erroMesaage = handleErrorMessage(error?.response);
        console.log(erroMesaage);
        messageAlert('error', erroMesaage);
      },
    });
  };

  const handleGetOtp = (data) => {
    console.log(data);
    handleLogin();
    // setOtpModal(true);
    // data.verification_type = 'phone_number';
    // data.verification_action = 'login';
    // mutation_get_otp.mutate(data, {
    //     onSuccess: () => {
    //         setOtpModal(true);
    //     },
    //     onError: (error) => {
    //         console.log(error?.response,'error goes here')
    //         var erroMesaage = handleErrorMessage(error?.response)
    //         console.log(erroMesaage)
    //         messageAlert('error', erroMesaage)

    //         // if (error?.response?.status == 400)
    //         // {

    //         //     messageAlert('error',error?.response?.data?.non_field_errors?.[0]);
    //         //     return;

    //         // }

    //     }
    // })
  };

  const switchType = () => {
    if (authType == 0) {
      setAuthType(1);
    } else {
      setAuthType(0);
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center h-100"
      style={{ marginTop: '1rem' }}
    >
      <Form className="mt-2 col-md-8" onSubmit={handleSubmit(handleGetOtp)}>
        <TextField
          text={'Login to your account'}
          className={'fs-4 fw-bold mb-3'}
        />
        <TextField
          text={
            'Enter your phone number and unique code / ID Number to access your account'
          }
          className={'mb-5 login-subtext'}
        />

        <Form.Group as={Col} className="mb-4">
          <Controller
            name="phone_number"
            control={control}
            rules={{
              required: { value: true, message: 'Phone Number is required' },
            }}
            render={({ field }) => (
              <PhoneNumberInput
                {...field}
                title="Phone number"
                placeholder="Phone number"
                isRequired={true}
                error={errors?.phone_number?.message}
              />
            )}
          />
        </Form.Group>
        {authType == 0 && (
          <Form.Group as={Col} className="mb-3">
            <Controller
              name="secret_pin"
              control={control}
              rules={{
                required: { value: true, message: 'Pin Number is required' },
              }}
              render={({ field }) => (
                <SMSInput
                  {...field}
                  title="SMS Secret Pin"
                  placeholder="000000"
                  isRequired={true}
                  error={errors?.secret_pin?.message}
                />
              )}
            />
          </Form.Group>
        )}
        {authType == 1 && (
          <Form.Group as={Col} className="mb-3">
            <Controller
              name="id_number"
              control={control}
              rules={{
                required: { value: true, message: 'ID Number is required' },
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  title="ID Number Used to Register"
                  placeholder="ID Number"
                  isRequired={true}
                  length={20}
                  error={errors?.id_number?.message}
                />
              )}
            />
          </Form.Group>
        )}
        <Form.Group
          as={Col}
          classname="mb-5"
          style={{ textAlign: 'right', marginBottom: '2rem' }}
        >
          <Link
            to={`/resend/${authType == 0 ? 'sms' : 'id'}`}
            className="text-decoration-none mx-1 custom-link"
            style={{ fontSize: '16px' }}
          >
            {authType == 0 ? 'Forgot SMS code?' : 'Forgot ID Number?'}
          </Link>
        </Form.Group>

        <div className="d-grid gap-2 mb-5">
          <MainButton
            variant="primary"
            size="md"
            type="submit"
            isLoading={mutation_get_otp.isLoading}
            text="Login"
            loadingVariant="light"
          />
        </div>
        <Row>
          <Col>
            <TextField
              text={
                <Fragment>
                  Login with your
                  <span
                    className="text-decoration-none mx-1 custom-link"
                    onClick={switchType}
                  >
                    {authType == 0 ? <>ID Number</> : <>SMS Secret Pin</>}
                  </span>
                </Fragment>
              }
              className="py-5 px-5 text-center fw-bold mb-5"
            />
          </Col>
        </Row>
      </Form>
      <ModalDiv
        show={otpModal}
        onHide={() => setOtpModal(false)}
        title="Enter Your GEA Login Pin"
        subTitle={subTitle}
        body={
          <OTPLogin
            otp={otp}
            handleChange={handleOtpChange}
            requestCode={'Forgot your code, please click the link to resend. '}
            phoneNumber={watch('phone_number')}
          />
        }
        actionButton={
          <MainButton
            text={'Send'}
            onClick={() => handleLogin()}
            isLoading={mutation.isLoading}
            isDisabled={otp?.length !== 6}
          />
        }
      />
    </div>
  );
};

export default LoginForm;
