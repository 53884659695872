import React, { useState } from "react";
import { Col, Form, Row, Stack } from "react-bootstrap";
import { TextField } from "..";
import propTypes from "prop-types";

import MainButton, { ButtonGutter } from "@components/buttons/Buttons";
import { connect } from "react-redux";
import { updateUserProfile } from "src/api/User/User";
import { pickFieldValues } from "src/utils/helpers/functions";
import { Link } from "react-router-dom";


function LoanBusinessDeclarationForm({updateUserProfile, loading}){
    const user = JSON.parse(window.localStorage.getItem('user'))
    const [state, setState] = useState(user?.business_profile)
    function handleChange({target}){
        setState({...state, [target.name]:target.value})
    }

    function handleSubmit(e){
        e.preventDefault();
        const userProfile ={}
        if(user){
            userProfile.business_profile = pickFieldValues(['operational_stage', 'other_association', 'other_industry'], state)
            userProfile.onboarding_data = {loan:{...user?.onboarding_data?.loan, compeleted:true}}
            updateUserProfile(user?.user?.id, userProfile, '/loan_application/success')
        }
    }
    
    return(
        <Form as={Col} md={12}>
            <Row className="mb-4">
                <Col md={8} className="text-center">
                    <TextField text="Declaration" className={'fs-5 fw-bold mb-3'}/>
                    <TextField text={'Have you received support from NEIP or GEA before '}/>
                </Col>
            </Row>
            <Row >
                <Col xs={12}>
                    <Stack gap={4}>
                        <Col className="d-inline-flex">
                            <Form.Check aria-label="option 1" name="decleared1" className="me-3" onChange={handleChange}/> I certify that my answers are true and complete to the best of my knowledge*
                        </Col>
                        <Col className="d-inline-flex">
                        <Form.Check aria-label="option 1" name="decleared2" className="me-3" onChange={handleChange}/> I understand that any false or misleading information, documents or submissions in my application may result in criminal prosecution for fraud*
                        </Col>
                        <Col className="d-inline-flex">
                            <Form.Check aria-label="option 1" name="decleared3" className="me-3" onChange={handleChange}/> 
                            I understand that by submitting this application, I expressly give GEA/NEIP and Participating Financial Institution (PFI) permission to*;
                        </Col>
                        <Col>
                                <p>
                                            - Request and share my financial records from the commercial banks and the Credit Reference Bureau.
                                            </p>
                                            
                                        <p>- Validate and verify my identity documents with the specified issuing agency.</p>
                                        <p>- Validate and verify my company registration details with the Registrar General’s Department of Ghana.</p>
                                        <p>- Validate and verify my business and personal National Identification Number/Tax 
                                        Identification Number (TIN) with the Ghana Revenue Authority (GRA) and National Identification Authority</p>
                                        <p>- Validate and verify my business and personal address.</p>
                                        <p>- Contact me at any time during and after the processing of this application using the    information I have submitted in this application.</p>
                       </Col>
                    </Stack>
                </Col>
                
            </Row>
            <Row>
                <ButtonGutter>
                    <Col md={4}>
                        <Link to="/loan_application/business_guarantor_2" className="text-decoration-none">
                            <MainButton text={'Previous'} type={'submit'} variant="outline-secondary" size="lg" className="fs-6"/>
                        </Link>
                    </Col>
                    <Col md={{ span: 4, offset: 4 }}>
                        <MainButton text={'Next'} type={'submit'} onClick={handleSubmit} isLoading={loading} variant="primary" size="lg"  className="fs-6" isDisabled={!(state?.decleared1 && state?.decleared2 && state?.decleared3)}/>
                    </Col>
                </ButtonGutter>   
            </Row>
        </Form>
    )
}
LoanBusinessDeclarationForm.propTypes = {
    handleChange:propTypes.func,
    updateUserProfile:propTypes.func,
    loading:propTypes.bool
}

function mapStateToProps({user}){
    return {loading:user.loading};

}

export default connect(mapStateToProps, {updateUserProfile})(LoanBusinessDeclarationForm);