
import React from "react";
import LoanApplicationLayout from "@components/layout/layouts/LoanApplicationLayout/LoanApplicationLayout";
import BusinessDeclarationForm from "@components/forms/Loan/BusinessDeclarationForm";


export default function BusinessDeclaration() {
    
    return(
        <LoanApplicationLayout page={6}>
            <BusinessDeclarationForm/>
        </LoanApplicationLayout>              
    )

}


