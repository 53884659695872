import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import propTypes from "prop-types";

const InputSelectCountries = ({
  options,
  name,
  title,
  defaultSelect,
  defaultValue,
  isRequired,
  onChange,
  isDisabled,
}) => {
  return (
    <Fragment>
      {title && (
        <Form.Label className="big-label">
          {title} {isRequired && <b className="text-danger fs-6 fw-bold">*</b>}
        </Form.Label>
      )}
      <Form.Select name={name} onChange={onChange} disabled={isDisabled}>
        {!defaultSelect && <option value={defaultValue}>Select Country</option>}
        {options?.map((country, index) => (
          <option
            selected={defaultSelect === country?.alpha2.toUpperCase()}
            value={country?.alpha2.toUpperCase()}
            key={index}
          >
            {country?.name}
          </option>
        ))}
      </Form.Select>
    </Fragment>
  );
};

InputSelectCountries.propTypes = {
  options: propTypes.array,
  name: propTypes.string,
  title: propTypes.string,
  isRequired: propTypes.bool,
  onChange: propTypes.func,
  defaultSelect: propTypes.string,
  defaultValue: propTypes.string,
  isDisabled: propTypes.bool,
};

export const InputSelect = ({
  options,
  name,
  title,
  defaultSelect,
  defaultValue,
  isRequired,
  onChange,
  isDisabled,
}) => {
  return (
    <Fragment>
      {title && (
        <Form.Label style={{fontSize:'15px'}}>
          {title} {isRequired && <b className="text-danger fs-6 fw-bold">*</b>}
        </Form.Label>
      )}
      <Form.Select
        name={name}
        onChange={onChange}
        disabled={isDisabled}
        required={isRequired}
      >
        {!defaultSelect && <option value={defaultValue}>Select Option</option>}
        {options?.map((option, index) =>
          [
            "region",
            "district",
            "association",
            "industry",
            "category",
          ].includes(name) ? (
            <option
              selected={defaultSelect === (option?.value || option?.name)}
              key={index}
              value={JSON.stringify(option)}
            >
              {option?.name}
            </option>
          ) : (
            <option
              selected={defaultSelect === (option?.value || option?.name)}
              value={option?.value || option?.name}
              key={index}
            >
              {option?.label ||option?.name}
            </option>
          )
        )}
      </Form.Select>
    </Fragment>
  );
};

InputSelect.propTypes = {
  options: propTypes.array,
  name: propTypes.string,
  title: propTypes.string,
  defaultSelect: propTypes.string,
  defaultValue: propTypes.string,
  isRequired: propTypes.bool,
  onChange: propTypes.func,
  isDisabled: propTypes.bool,
};

export default InputSelectCountries;
