import React, { Fragment } from "react";
import { TextField } from "@components/forms";
import AuthLayout from "@components/layout/AuthLayout";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import GetStartedForm from "@components/forms/Auth/GetStaredForm";

const GetStarted = () => {
  return (
    <AuthLayout>
      <Col sm={6}>
        <Row className="mb-5">
          <Col>
            <TextField
              text={
                <Fragment>
                  Already have an account?
                  <Link to="/login" className="text-decoration-none mx-1 custom-link">
                    {" "}
                    Login
                  </Link>
                </Fragment>
              }
              className="py-5 px-5 float-end fw-bold mb-5"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <GetStartedForm />
          </Col>
        </Row>
      </Col>
    </AuthLayout>
  );
};

export default GetStarted;
