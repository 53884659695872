/*eslint-disable */
import React from 'react';
import {
  BankBranchList,
  BankList,
  useGetGrantRequest2,
} from '@/views/GrantForm/services/BusinessPlan/GrantRequest.service';
import BusinessFormBaseLayout from '@/views/GrantForm/BusinessFormBaseLayout';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import { Row, Col, Form } from 'react-bootstrap';
import InstructionText from '../../InstructionText';
import ControlledSelectInput from '@/components/forms/FormInput/ControlledSelectInput';
import { IBusinessPlanFormStage } from '@/views/GrantForm/GrantForm.interfaces';
import constant from '@/utils/constants/constants';

const GrantRequest2 = ({ isPreview, isPrint }: IBusinessPlanFormStage) => {
  const { control, handleSubmit, onSubmit, userData, isLoading } =
    useGetGrantRequest2();
  return (
    <BusinessFormBaseLayout
      page={4}
      title={'Grant Request'}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      isLoading={isLoading}
      formStage="grant_request_2"
      preview={isPreview}
      print={isPrint}
    >
      <Row>
        <Col md={12} xs={12}>
          <InstructionText
            title={'Bank account details'}
            subtitle={
              'Grant payment will be through either personal or enterprise bank  accounts. In a situation where the business is formalized, an enterprise bank account would be preferred.'
            }
            isInfo={true}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12}>
          <Row>
            <Col md={6} xs={12}>
              <ControlledTextInput
                control={control}
                name="bank_name"
                title="Bank Name"
                placeholder="Bank Name"
                rules={{
                  required:
                    !userData?.data?.profile?.grant_information?.bank
                      ?.bank_name,
                }}
                defaultValue={
                  userData?.data?.profile?.grant_information?.bank?.bank_name
                }
                value={
                  isPreview
                    ? userData?.data?.profile?.grant_information?.bank
                        ?.bank_name
                    : null
                }
                isDisabled={isPreview === true}
              />
            </Col>
            <Col md={6} xs={12}>
              <ControlledTextInput
                control={control}
                name="bank_branch"
                title="Bank Branch"
                placeholder="Bank Branch"
                rules={{
                  required:
                    !userData?.data?.profile?.grant_information?.bank
                      ?.bank_branch,
                }}
                defaultValue={
                  userData?.data?.profile?.grant_information?.bank?.bank_branch
                }
                value={
                  isPreview
                    ? userData?.data?.profile?.grant_information?.bank
                        ?.bank_branch
                    : null
                }
                isDisabled={isPreview === true}
              />
            </Col>
          </Row>
        </Col>
        <Col md={12} xs={12}>
          <Row>
            <Col md={6} xs={12}>
              <ControlledTextInput
                control={control}
                name="account_number"
                placeholder="Bank Account Number"
                title="Bank Account Number"
                type="number"
                rules={{
                  required:
                    !userData?.data?.profile?.grant_information?.bank
                      ?.bank_account_number,
                  pattern: {
                    value: constant.BANK_ACCOUNT_NUMBER,
                    message: 'Enter a valid bank account number',
                  },
                }}
                defaultValue={
                  userData?.data?.profile?.grant_information?.bank
                    ?.bank_account_number
                }
                value={
                  isPreview
                    ? userData?.data?.profile?.grant_information?.bank
                        ?.bank_account_number
                    : null
                }
                isDisabled={isPreview === true}
              />
            </Col>
            <Col md={6} xs={12}>
              <ControlledTextInput
                control={control}
                name="account_name"
                placeholder="Bank Account Name"
                title="Bank Account Name"
                rules={{
                  required:
                    !userData?.data?.profile?.grant_information?.bank
                      ?.bank_account_name,
                }}
                defaultValue={
                  userData?.data?.profile?.grant_information?.bank
                    ?.bank_account_name
                }
                value={
                  isPreview
                    ? userData?.data?.profile?.grant_information?.bank
                        ?.bank_account_name
                    : null
                }
                isDisabled={isPreview === true}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </BusinessFormBaseLayout>
  );
};

export default GrantRequest2;
