/*eslint-disable*/
import React from 'react';
import { Button, styled, ButtonProps } from '@mui/material';
import {
  defaultBlue,
  defaultLightBlue2,
} from '@/utils/constants/colors.constants';
import AddIcon from '@mui/icons-material/Add';

interface IAddButton extends ButtonProps {
  onClick?: () => void;
  text?: string;
  type?: 'button' | 'submit' | 'reset';
}

const CustomButton = styled(Button)({
  border: `2px dashed ${defaultBlue}`,
  backgroundColor: defaultLightBlue2,
  width: '100%',
  padding: '14px',
  textTransform: 'initial!important',
  fontSize: '18px',
});

const AddButton = (props: IAddButton) => {
  return (
    <CustomButton
      type={props?.type || 'button'}
      onClick={() => {
        props?.onClick?.();
      }}
      {...props}
    >
      <div style={{ color: defaultBlue, fontWeight: '600', fontSize: '14px' }}>
        {props.text}
      </div>
      <div>
        <AddIcon style={{ color: defaultBlue }} />
      </div>
    </CustomButton>
  );
};

export default AddButton;
