import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";

import OnboardProgress from "@components/layout/navigation/OnboardProgressNav";
import ApplicationVerification from "@components/forms/Auth/ApplicationVerification";
import OnboardTopNav from "@components/layout/OnboardTopNav";

export default function Verification() {
  return (
    <Fragment>
      <OnboardTopNav/>
      <Row>
      <OnboardProgress page={7} />
      <Col md={9} sm={12}>
        
        <ApplicationVerification />
          
      </Col>
      </Row>
    </Fragment>
  );
}
