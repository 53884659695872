import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';

import OnboardProgress from '@components/layout/navigation/OnboardProgressNav';
import { ToastContainer } from 'react-toastify';
import OtherInformationVerification from '@components/forms/Auth/Forms/OtherInformationVerification';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { getUserProfile, updateFullUserProfile } from 'src/api/User/User';
import {
  cleanArray,
  handleErrorMessage,
  messageAlert,
} from '@utils/helpers/functions';
import constant from '@utils/constants/constants';
import OnboardTopNav from '@components/layout/OnboardTopNav';

const OtherInfo = () => {
  let navigate = useNavigate();

  const mutation = useMutation(updateFullUserProfile);

  const {
    error,
    data: responseData,
    isLoading,
  } = useQuery('user-data-verifcation', getUserProfile);

  const state = responseData?.data?.profile;
  const onboardingState = responseData?.data?.onboarding_data;

  if (isLoading) return <h1>Loading...</h1>;

  if (error) return <h1>Error...</h1>;

  const submitData = (data) => {
    if (data) {
      var onboarding_data = { ...onboardingState, other_information: true };

      var channels = [];
      constant.COMMUNICATION.map((channel) =>
        channels.push(data?.[channel.value])
      );
      if (data?.other !== '' && data?.other !== undefined) {
        data.other_recommended_media_channels = [];
        data.other_recommended_media_channels[0] = data?.other;
      } else {
        data.other_recommended_media_channels = null;
      }

      data.recommending_media_channels =
        channels.length > 0
          ? cleanArray(channels)
          : state?.recommending_media_channels;
      mutation.mutate(
        {
          id: responseData?.data?.user?.id,
          profile: data,
          onboarding_data: onboarding_data,
        },
        {
          onSuccess: () => {
            navigate('/onboarding/verification');
          },
          onError: (error) => {
            var errorMessage = handleErrorMessage(error?.response);
            messageAlert('error', errorMessage);
          },
        }
      );
    }
  };
  const navigateToPreviousPage = () => {
    navigate('/onboarding/financial_literacy');
  };

  return (
    <Fragment>
      <ToastContainer />
      <OnboardTopNav />
      <Row>
        <OnboardProgress page={6} />
        <Col md={8} sm={12}>
          <OtherInformationVerification
            state={state}
            onSubmit={submitData}
            onPreviousButtonClicked={navigateToPreviousPage}
            loading={mutation.isLoading}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default OtherInfo;
