import HoverIcon from "@components/buttons/HoverIcon";
import useHover from "@utils/hooks/useHover";
import React, { useState } from "react";
import { Ratio, Modal } from "react-bootstrap";
export default function TrainingPreviewCard() {
  const [show, setShow] = useState(false);
  const { hover, setHover } = useHover();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const imageStyle = {
    width: "100%",
    height: "245px",
    backgroundImage: `linear-gradient(rgba(12, 12, 12, 0.397), rgba(12, 12, 12, 0.479)), url(
      "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
    )`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: "10px 10px 10px 10px",
    textAlign: "center",
    fontSize: "36px",
    verticalAlign: "middle",
  };

  return (
    <>
      <div
        className="d-flex flex-column justify-content-center"
        style={imageStyle}
        onClick={handleShow}
        onPointerOver={() => setHover(true)}
        onPointerOut={() => setHover(false)}
      >
        <div className="d-flex justify-content-between align-items-center mt-5">
          <HoverIcon
            icon="fa fa-solid fa-play me-2 white-text text-center fs-1 "
            hover={hover}
          />
        </div>
        <div className="d-flex justify-content-start align-items-center pt-4">
          <div className="ms-3 ">
            <p className="me-2 mb-2 white-text text-middle">
              Nailing your pitch presentation
            </p>
            <p className="me-2  white-text text-start">15min 23sec</p>
          </div>
        </div>
      </div>
      {show ? (
        <Modal show={show} onHide={handleClose} animation={false}>
          <Ratio aspectRatio="16x9">
            <iframe
              style={{
                borderRadius: "8px",
              }}
              width="500"
              height="450"
              src="https://www.youtube.com/embed/6aXPRhtyjug"
              title="YouTube video player"
              frameGorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Ratio>
        </Modal>
      ) : null}
    </>
  );
}
