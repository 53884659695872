
import React from "react";
import LoanApplicationLayout from "@components/layout/layouts/LoanApplicationLayout/LoanApplicationLayout";
import BusinessSupportHistoryForm from "@components/forms/Loan/BusinessSupportHistoryForm";


export default function BusinessSupportHistoryInfo() {
    
    return(
        <LoanApplicationLayout page={4}>
            <BusinessSupportHistoryForm/>
        </LoanApplicationLayout>              
    )

}


