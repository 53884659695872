import React from 'react';
import { Box, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// import Avatar from './Subcomponents/Avatar';
import logo from '../../images/logo/gealogo.png';
import MobileDrawer from './MobileDrawer';


const MobileNavbar = () => {

  const [drawer,setDrawer] = React.useState(false);
  const user = JSON.parse(window.localStorage.getItem("user"));


  return (
    <>
    <MobileDrawer
    open={drawer}
    setOpen={setDrawer}
    />
    <Box
    sx={{display:'flex',
    flexDirection:'row',justifyContent:'center',alignItems:'center',padding:'1rem',boxShadow:'0 4px 10px 2px #efefef'}}>
    <Box sx={{order:'0'}}>
        <Button
        onClick={()=>{setDrawer(!drawer)}}
        sx={{color:'black'}}><MenuIcon></MenuIcon></Button>
    </Box>
    <Box sx={{textAlign:'center',marginLeft:'auto'}}>
        <img src={logo} style={{width:'48px',height:'48px'}}></img>
    </Box>
    <Box sx={{marginLeft:'auto',order:'2',display:'flex',alignItems:'center'}}>
        <Box sx={{marginRight:'1rem'}}>
        {user?.first_name || user?.user?.first_name}
        </Box>
        {/* <Avatar/> */}
    </Box>

   </Box>
    
    </>
  
  )
}

export default MobileNavbar