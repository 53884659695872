import React from "react";
import propTypes from "prop-types";

const RoundedCircleUserName = ({ initials }) => {
  return (
    <div className="d-flex align-items-center">
      <div className="avatar-sq avatar-sm">
        <div className="rounded-circle-username  text-center small-text">
          {initials}
        </div>
      </div>
    </div>
  );
};

RoundedCircleUserName.propTypes = {
  initials: propTypes.string,
};

export default RoundedCircleUserName;
