/*eslint-disable*/
import React from 'react';
import {
  AccordionActionButton,
  AccordionActionText,
} from './styledcomponents/BusinessPlanComponents';
import {
  IActivityEditParams,
  IGrantExpenditureEditParams,
  IOtherIndicatorEditParams,
} from '../GrantForm.interfaces';
import { Save, Edit } from '@mui/icons-material';
import { defaultBlue } from '@/utils/constants/colors.constants';

interface ITableInputSaveButton<K> {
  isEditing: boolean;
  params:
    | IActivityEditParams
    | IGrantExpenditureEditParams
    | IOtherIndicatorEditParams;
  onEdit: (params: K) => void;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const TableInputSaveButton = ({
  onEdit,
  setIsEditing,
  isEditing,
  params,
}: ITableInputSaveButton<
  IActivityEditParams | IGrantExpenditureEditParams | IOtherIndicatorEditParams
>) => {
  return (
    <AccordionActionButton
      onClick={() => {
        if (isEditing) {
          onEdit(params);
        }
        setIsEditing(!isEditing);
      }}
    >
      {isEditing ? (
        <Save style={{ color: defaultBlue }} />
      ) : (
        <Edit style={{ color: defaultBlue }} />
      )}
      <AccordionActionText>
        {isEditing ? 'Save Changes' : 'Edit'}
      </AccordionActionText>
    </AccordionActionButton>
  );
};

export default TableInputSaveButton;
