/*eslint-disable*/
import React from 'react';
import BusinessFormBaseLayout from '@/views/GrantForm/BusinessFormBaseLayout';
import { useDisclaimer } from '@/views/GrantForm/services/BusinessPlan/Disclaimer.service';
import { Row, Col, Form } from 'react-bootstrap';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { IBusinessPlanFormStage } from '@/views/GrantForm/GrantForm.interfaces';

const ConditionTextWrapper = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-left: 15px;
  padding-right: 50px;
`;

const ConditionRow = styled.div`
  line-height: 21px;
`;

const ErrorText = styled.div`
  color: red;
`;

const Disclaimer = ({ isPreview, isPrint }: IBusinessPlanFormStage) => {
  const { control, register, handleSubmit, onSubmit, watch, formState } =
    useDisclaimer();
  return (
    <BusinessFormBaseLayout
      page={8}
      title={'Disclaimer'}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      formStage={'disclaimer'}
      preview={isPreview}
      print={isPrint}
    >
      <Row>
        <Col md={12} xs={12} style={{ display: 'flex' }}>
          <Form.Check
            {...register('condition_1', {
              required: watch('condition_1') !== true,
            })}
            label={''}
            disabled={isPreview == true}
          />
          <ConditionTextWrapper>
            <ConditionRow>
              I certify that my answers are true and complete to the best of my
              knowledge. 
            </ConditionRow>
            <ConditionRow>
              I understand that any false or misleading information, documents
              or submissions in my application may result in criminal
              prosecution for fraud.
            </ConditionRow>
            <ConditionRow>
              I understand that by submitting this application I expressly give
              the Ghana Enterprises Agency (GEA) permission to:
            </ConditionRow>
            <ul>
              <li>
                <ConditionRow>
                  Request my financial records from the commercial banks and the
                  Credit Reference Bureau.
                </ConditionRow>
              </li>
              <li>
                <ConditionRow>
                  Validate and verify my identity documents with the specified
                  issuing agency.
                </ConditionRow>
              </li>
              <li>
                <ConditionRow>
                  Validate and verify my company registration details with the
                  Registrar General’s Department of Ghana.
                </ConditionRow>
              </li>
              <li>
                <ConditionRow>
                  {' '}
                  Validate and verify my company registration details with the
                  Registrar General’s Department of Ghana.
                </ConditionRow>
              </li>
              <li>
                <ConditionRow>
                  Validate and verify my business and personal Tax
                  Identification Number (TIN) with the Ghana Revenue Authority
                  (GRA)
                </ConditionRow>
              </li>
              <li>
                <ConditionRow>
                  Validate and verify my Environmental Permit with Environmental
                  Protection Agency (EPA) Validate and verify my mobile money
                  records with the operator of my mobile money account.
                </ConditionRow>
              </li>
              <li>
                <ConditionRow>
                  {' '}
                  Validate and verify my business and personal address. Contact
                  me at any time during and after the processing of this
                  application using the information I have submitted in this
                  application.
                </ConditionRow>
              </li>
               
            </ul>
            {formState.errors['condition_1'] && (
              <ErrorText>*Please Check this field to continue</ErrorText>
            )}
          </ConditionTextWrapper>
        </Col>
      </Row>

      <Row style={{ marginTop: '5px' }}>
        <Col md={12} xs={12} style={{ display: 'flex' }}>
          <Form.Check
            {...register('condition_2', {
              required: watch('condition_2') != true,
            })}
            label={''}
            disabled={isPreview == true}
          />
          <ConditionTextWrapper>
            <ConditionRow>
              I certify that my answers are true and complete to the best of my
              knowledge. 
            </ConditionRow>

            <ol>
              <li>
                <ConditionRow>
                  To apply any grant funding received expressly for the approved
                  purpose 
                </ConditionRow>
              </li>
              <li>
                <ConditionRow>
                  To refund in full, and on demand, any grant funding received,
                  if, my company/firm is found to have provided misleading
                  information, whether deliberate or accidental, on this
                  application. 
                </ConditionRow>
              </li>
              <li>
                <ConditionRow>
                  To provide, throughout the grant period, progress updates on
                  the use of the grant funds and the delivery of the purpose of
                  the grant to GEA in the manner and frequency as GEA may
                  direct.
                </ConditionRow>
              </li>
            </ol>
            {formState.errors['condition_2'] && (
              <ErrorText>*Please Check this field to continue</ErrorText>
            )}
          </ConditionTextWrapper>
        </Col>
      </Row>

      <Row style={{ marginTop: '5px' }}>
        {' '}
        <Col md={12} xs={12} style={{ display: 'flex' }}>
          <Form.Check
            {...register('condition_3', {
              required: watch('condition_3') != true,
            })}
            label={''}
            disabled={isPreview === true}
          />
          <ConditionTextWrapper>
            <ConditionRow>
              I hereby give my consent that data associated with my application
              be collected for monitoring and evaluation purposes and that my
              data may be shared with evaluation partners (example, the Ghana
              Jobs and Skills Project Coordinating Unit, the Ministry of
              Finance, and the World Bank research team).
            </ConditionRow>
            {formState.errors['condition_3'] && (
              <ErrorText>*Please Check this field to continue</ErrorText>
            )}
          </ConditionTextWrapper>
        </Col>
      </Row>
    </BusinessFormBaseLayout>
  );
};

export default Disclaimer;
