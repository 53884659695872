/*eslint-disable*/
import React from 'react';
import { Form } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import { IRadioButtonGroup } from '../../../utils/constants/globals.interfaces';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';

const CustomCheck = styled(Form.Check)`
  display: flex;
  flex-direction: row;
  align-items: center;
  input {
    height: 1.5em !important;
    width: 1.5em !important;
  }
  label {
    margin-left: 1em;
  }
`;

const ErrorMessageWrapper = styled.div`
  font-size: 15px;
  color: red;
  margin-top: 5px;
`;

const ControlledRadioButtonGroup = ({
  title,
  name,
  options,
  defaultValue,
  control,
  isDisabled,
  value,
  ...props
}: IRadioButtonGroup) => {
  const [error, setIsError] = React.useState(false);

  return (
    <>
      {defaultValue !== undefined && (
        <Form.Group>
          <Row>
            <Form.Label className="controlled-input-label">{title}</Form.Label>
            <Row className="controlled-input-wrapper">
              {options.map((option) => {
                return (
                  <Col key={Math.random()}>
                    <Controller
                      name={name}
                      control={control}
                      {...props}
                      defaultValue={value || defaultValue}
                      render={({ field, fieldState: { error }, formState }) => {
                        setIsError(error ? true : false);

                        return (
                          <CustomCheck
                            {...field}
                            type="radio"
                            label={option.label}
                            value={option.value}
                            checked={
                              field.value === option.value ? true : false
                            }
                            disabled={isDisabled}
                          />
                        );
                      }}
                    />
                  </Col>
                );
              })}
            </Row>
          </Row>
          {error && (
            <Row>
              <Col>
                <ErrorMessageWrapper>{`*${
                  error && 'This field is required'
                }`}</ErrorMessageWrapper>
              </Col>
            </Row>
          )}
        </Form.Group>
      )}
    </>
  );
};
export default ControlledRadioButtonGroup;
