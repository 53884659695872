/*eslint-disable */
import BusinessFormBaseLayout from '@/views/GrantForm/BusinessFormBaseLayout';
import {
  FormFilledForOptions,
  useGetSignature,
} from '@/views/GrantForm/services/BusinessPlan/Signature.service';
import ControlledRadioButtonGroup from '@/components/forms/FormInput/ControlledRadioButton';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import styled from 'styled-components';
import moment from 'moment';
import { IBusinessPlanFormStage } from '@/views/GrantForm/GrantForm.interfaces';

const HeaderRow = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid #dfe2e5;
  margin-bottom: 20px;
`;

const Signature = ({ isPreview, isPrint }: IBusinessPlanFormStage) => {
  const {
    control,
    handleSubmit,
    watch,
    formState,
    onSubmit,
    isLoading,
    userData,
  } = useGetSignature();
  return (
    <BusinessFormBaseLayout
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      title="Signature"
      page={9}
      isLoading={isLoading}
      formStage="signature"
      preview={isPreview}
      print={isPrint}
    >
      <HeaderRow>
        <Col md={12} xs={12}>
          <ControlledRadioButtonGroup
            name={'who_was_form_filled_for'}
            options={FormFilledForOptions}
            defaultValue={
              userData?.data?.profile?.grant_information
                ?.who_was_form_filled_for
            }
            control={control}
            title={'Who did you fill the form for?'}
            rules={{
              required:
                !userData?.data?.profile?.grant_information
                  ?.who_was_form_filled_for,
            }}
            isDisabled={isPreview === true}
          />
        </Col>
      </HeaderRow>

      {(watch('who_was_form_filled_for') == 'myself' ||
        !watch('who_was_form_filled_for')) && (
        <Row>
          <Col md={6} xs={12}>
            <ControlledTextInput
              control={control}
              name="applicant_name"
              title="My Name"
              placeholder="My Name"
              defaultValue={
                userData?.data?.profile?.grant_information?.applicant_name
              }
              rules={{
                required:
                  !userData?.data?.profile?.grant_information?.applicant_name,
              }}
              isDisabled={isPreview === true}
            />
          </Col>
          <Col md={6} xs={12}>
            <ControlledTextInput
              control={control}
              name="todays_date"
              type="date"
              title={"Today's Date"}
              defaultValue={
                userData?.data?.profile?.grant_information?.todays_date ||
                moment().format('YYYY-MM-DD')
              }
              rules={{
                required: false,
              }}
              isDisabled={isPreview === true}
            />
          </Col>
        </Row>
      )}
      {watch('who_was_form_filled_for') == 'on_behlaf_of_owner' && (
        <>
          <Row>
            <Col md={6} xs={12}>
              <ControlledTextInput
                control={control}
                name="representative_name"
                title="My Name"
                placeholder="My Name"
                defaultValue={
                  userData?.data?.profile?.grant_information
                    ?.representative_name
                }
                rules={{
                  required:
                    !userData?.data?.profile?.grant_information
                      ?.representative_name,
                }}
                isDisabled={isPreview === true}
              />
            </Col>
            <Col md={6} xs={12}>
              <ControlledTextInput
                control={control}
                name="representative_address"
                title="My Address"
                placeholder="My Address"
                defaultValue={
                  userData?.data?.profile?.grant_information
                    ?.representative_address
                }
                rules={{
                  required:
                    !userData?.data?.profile?.grant_information
                      ?.representative_address,
                }}
                isDisabled={isPreview === true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <ControlledTextInput
                control={control}
                name="applicant_name"
                title="Applicant's Name"
                placeholder="Applicant's Name"
                defaultValue={
                  userData?.data?.profile?.grant_information?.applicant_name
                }
                rules={{
                  required:
                    !userData?.data?.profile?.grant_information?.applicant_name,
                }}
                isDisabled={isPreview === true}
              />
            </Col>
            <Col md={6} xs={12}>
              <ControlledTextInput
                control={control}
                name="form_language"
                title="Language in which form was read"
                placeholder="Language in which form was read"
                defaultValue={
                  userData?.data?.profile?.grant_information?.form_language
                }
                rules={{
                  required:
                    !userData?.data?.profile?.grant_information?.form_language,
                }}
                isDisabled={isPreview === true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <ControlledTextInput
                control={control}
                name="todays_date"
                type="date"
                defaultValue={
                  userData?.data?.profile?.grant_information?.todays_date ||
                  moment().format('YYYY-MM-DD')
                }
                rules={{
                  required: false,
                }}
                isDisabled={isPreview === true}
              />
            </Col>
          </Row>
        </>
      )}
    </BusinessFormBaseLayout>
  );
};

export default Signature;
