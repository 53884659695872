
import React from "react";
import { Col, Row, Stack} from "react-bootstrap";
// import bg1 from "../../../images/bg/bg1.jpg"
// import logo from "./logo.svg";
import './style.css'
// import {LogoDark} from "@components/utils";
import propTypes from "prop-types";
import { ProgressBarNumber } from "@components/buttons/Buttons";
// import { useNavigate } from "react-router-dom";

const OnboardProgress = ({page}) =>{
    
    // const navigate = useNavigate();
    // React.useEffect(()=>{
    //     navigate('/deadline');
    // },[])
    
    return(
        <Col sm={3} className="d-none d-lg-block">
            {/* <LogoDark type="large" className="px-5 w-75"/> */}
            <Row className="mt-1">
                <Stack  className="mb-5  px-5 gap-5">
                    <ProgressBarNumber number="1" text="Personal Information - I" active={true}/>
                    <ProgressBarNumber number="2" text="Educational Information - II" active={page >= '2'}/>
                    <ProgressBarNumber number="3" text="Training Information - III" active={page >= '3'} />
                    <ProgressBarNumber number="4" text="Entrepreneurial/Business Acumen - IV" active={page >= '4'} />
                    <ProgressBarNumber number="5" text="Financial Literacy - V" active={page >= '5'} />
                    <ProgressBarNumber number="6" text="Other Information - VI" active={page >= '6'} />
                    <ProgressBarNumber number="7" text="Verification - VII" active={page >= '7'} />
                    <ProgressBarNumber number="8" text="Disclaimer and Signature - VIII" active={page >= '8'} />
                </Stack>
            </Row>
        </Col>        
    )
}

OnboardProgress.propTypes ={
    page:propTypes.number
}


export default OnboardProgress