import React, { useState } from "react";
import { Navbar, Container, Nav, Dropdown,Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import MainNavItem from "./MainNavItem";
import DefaultButton from "@components/buttons/DefaultButton";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import useHover from "src/utils/hooks/useHover";
import { GeaLogoDefault } from "@components/utils";
import youstartLogo from '@images/logo/gjsp.svg';

export default function NavbarTransparent() {
  const [openDropDown, setOpenDropDown] = useState(false);
  // const [isTransparent, setIsTransparent] = useState(homePage);
  const navbarStyle = {
    transparent: {
      background: "transparent",
      color: "#fff",
      marginTop: "0px",
      marginLeft: "4rem",
    },
    white: {
      background: "white",
      color: "#4f4f4f",
      padding: "12px",
      webkitBoxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.11)",
      boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.11)",
    },
  };

  // function changeBackground() {
  //   if (window.scrollY >= 80) {
  //     setIsTransparent(false);
  //   } else {
  //     setIsTransparent(true);
  //   }
  // }

  // window.addEventListener("scroll", changeBackground);

  const showTransparent = false;
  const style = navbarStyle.white;

  return (
    <>
      <Container className="p-0">
        <Navbar
          style={style}
          collapseOnSelect
          fixed="top"
          expand="lg"
          className="m-0  p-0"
        >
          <Container className="">
            <Navbar.Brand
              style={{ color: "white" }}
              href="/home"
              className="p-0"
            >
              <GeaLogoDefault />
              <Image src={youstartLogo} width={150} height={100}/>

              {/* {showTransparent ? <Logo type="large" /> : <Logo type="normal" />} */}
            </Navbar.Brand>

            <div onClick={() => setOpenDropDown((val) => !val)}>
              <Navbar.Toggle disabled />

              {openDropDown ? (
                <Dropdown.Menu show align="end">
                  <DropDownLink name={"Home"} link="/home" />

                  <DropDownLink name={"FAQs"} link="/faqs" />
                  <DropDownLink name={"About"} link="/about" />
                  <DropDownLink name={"Terms and Conditions"} link="/terms" />
                  <DropDownLink name={"Login"} link="/login" />
                  <DropDownLink name={"Sign Up"} link="/login" />
                </Dropdown.Menu>
              ) : null}
            </div>

            <Navbar.Collapse className="justify-content-end">
              <Nav>
                <MainNavItem
                  isTransparent={showTransparent}
                  title="Home"
                  link="/home"
                />
                <MainNavItem
                  isTransparent={showTransparent}
                  title="About"
                  link="/about"
                />
                <MainNavItem
                  isTransparent={showTransparent}
                  title="FAQs"
                  link="/faqs"
                />
                <MainNavItem
                  isTransparent={showTransparent}
                  title="Terms and Conditions"
                  link="/terms"
                />
                <MainNavItem
                  isTransparent={showTransparent}
                  title="Login"
                  link="/login"
                />
                <div className="mt-1 ms-4">
                  <DefaultButton label="Sign Up" link="/get_started" />
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <br />
      </Container>
    </>
  );
}

function DropDownLink({ name, link }) {
  const { hover, setHover } = useHover();

  const linkStyle = () => ({
    display: "inline-block",
    width: "100%",
    backgroundColor: hover ? "#2a3b8f" : "#fff",
    textDecoration: "none",
    padding: "8px",
    color: hover ? "#FFF" : "#4F4F4F",
  });
  return (
    <Dropdown.Item
      style={linkStyle()}
      eventKey="1"
      onPointerOver={() => setHover(true)}
      onPointerOut={() => setHover(false)}
    >
      <div>
        <Link style={linkStyle()} to={link}>
          {name}
        </Link>
      </div>
    </Dropdown.Item>
  );
}

DropDownLink.propTypes = {
  name: propTypes.string,
  link: propTypes.string,
};

NavbarTransparent.propTypes = {
  homePage: propTypes.bool,
};
