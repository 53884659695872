import React from "react";
import { Row, Col } from "react-bootstrap";
import propTypes from "prop-types";

export default function HeroSection({ img, title }) {
  var sectionStyle = {
    backgroundImage: `linear-gradient(rgba(12, 12, 12, 0.397), rgba(12, 12, 12, 0.479)), url(${img})`,
    backgroundPosition: "0% 0%",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    height: "450px",
    color: "white",
  };
  return (
    <Row style={sectionStyle}>
      <Col className="d-flex flex-column align-items-center justify-content-center">
        <h3 className="white-text fw-bold">{title}</h3>
      </Col>
    </Row>
  );
}

HeroSection.propTypes = {
  img: propTypes.string,
  title: propTypes.string.isRequired,
};
