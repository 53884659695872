/*eslint-disable*/
import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
// import { Link } from "react-router-dom";
import { TextField } from '..';
import { InputSelect } from '@components/select/Select';
import './style.css';
import FormInput, { PhoneNumberInput } from '../FormInput/FormInput';
import 'react-phone-number-input/style.css';
import MainButton from '@components/buttons/Buttons';
import {
  createAccount,
  getVerificationCode,
  getSecretCode,
} from 'src/api/Auth/Auth';
import ModalDiv from '@components/modals/Modal';
import OTPForm from './OTPForm';
import { Controller, useForm } from 'react-hook-form';
import constant from 'src/utils/constants/constants';
// import constant from "@utils/constants/constants";
import { useMutation } from 'react-query';
import {
  handleErrorMessage,
  messageAlert,
  storeLocalStorage,
} from '@utils/helpers/functions';
import { useNavigate } from 'react-router-dom';
import { Required } from '@components/utils';
import moment from 'moment';
import { checkIfBlank } from '@utils/helpers/functions';

// const validationSchema = yup.object({
//   first_name: yup.string().required("Required"),
//   last_name: yup.string().required("Required"),
//   phone_number: yup.string().required("Required"),
//   gender: yup.string().required("Required"),
// });

const SignUpForm = () => {
  const navigate = useNavigate();

  const {
    control,
    register,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [otpModal, setOtpModal] = useState(false);
  const subTitle =
    'A 6-digit code has been sent to your phone number. Enter that code below to proceed to your account dashboard.';

  const [otp, setOtp] = useState('');
  const handleOtpChange = (otp) => setOtp(otp);

  const mutation = useMutation(createAccount);
  const onSubmit = () => {
    var data = {
      ...getValues(),
      temp_phone_number: getValues()?.phone_number,
      temp_email: getValues()?.email,
      password: 'Default13',
    };
    mutation.mutate(data, {
      onSuccess: (response) => {
        storeLocalStorage('user', JSON.stringify(response?.data));
        storeLocalStorage('accessToken', response.headers?.['set-auth-token']);
        setOtpModal(true);
        // navigate('/dashboard')
      },
      onError: (error) => {
        var erroMesaage = handleErrorMessage(error?.response);
        messageAlert('error', erroMesaage);
      },
    });
  };

  const secretCodeMutation = useMutation(getSecretCode);
  const handleSubmitOTP = () => {
    var phone_number = getValues()?.phone_number;
    var data = {
      code: otp,
      verification_type: 'phone_number',
      phone_number: phone_number,
      verification_action: 'create_account',
    };
    secretCodeMutation.mutate(data, {
      onSuccess: () => {
        navigate('/onboarding');
      },
      onError: (error) => {
        var erroMesaage = handleErrorMessage(error?.response);
        messageAlert('error', erroMesaage);
      },
    });
  };

  const verificationCodeMutation = useMutation(getVerificationCode);
  const requestCode = (data) => {
    verificationCodeMutation.mutate(data, {
      onSuccess: () => {
        messageAlert('success', 'Secret code sent successfully');
      },
      onError: () => {
        messageAlert('error', 'Sending secret code failed. Try again');
      },
    });
  };

  const handleRequestCode = (event) => {
    event.preventDefault();
    var phone_number = getValues()?.phone_number;
    var data = {
      verification_type: 'phone_number',
      phone_number: phone_number,
      verification_action: 'change_phone_number',
    };
    requestCode(data);
  };

  const evaluateVal = () => {
    let value = watch('id_type');
    if (value == null || value == undefined) {
      return constant.BLANK_SPACES;
    } else {
      let regex = '';
      switch (value) {
        case 'ghana_card':
          regex = constant.GHANA_CARD;
          break;
        case 'voter_id':
          regex = constant.VOTERS_ID;
          break;
        case 'passport':
          regex = constant.PASSPORT;
          break;
        case 'driving_license':
          regex = constant.DRIVERS_LICENSE;
          break;
        default:
          regex = constant.BLANK_SPACES;
      }

      return regex;
    }
  };

  const returnPlaceholder = () => {
    let value = watch('id_type');
    if (value == null || value == undefined) {
      return 'ID Number';
    } else {
      switch (value) {
        case 'ghana_card':
          return 'GHA-123456789-1';
        case 'driving_license':
          return 'ABC-00000000-00000';
        case 'passport':
          return 'A1234567';
        case 'voter_id':
          return '0000000000';
        default:
          return 'ID Number';
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex align-items-center">
        <Col md={8}>
          <TextField
            text={'Personal Information'}
            className={'fs-5 fw-bold mb-5 text-center'}
          />
          <Row className="mb-3">
            <Form.Group as={Col} md="6" className="mb-3">
              <Controller
                name="first_name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Field is required',
                  },
                  pattern: {
                    value: checkIfBlank(watch('first_name') || ''),
                    message: 'You cannot submit an empty space',
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    title="First name"
                    type="text"
                    placeholder="First Name"
                    isRequired={true}
                    error={errors?.first_name?.message}
                  />
                )}
              />
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Controller
                name="last_name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Field is required',
                  },
                  pattern: {
                    value: checkIfBlank(watch('last_name') || ''),
                    message: 'You cannot submit an empty space',
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    title="Last name"
                    type="text"
                    placeholder="Last Name"
                    isRequired={true}
                    error={errors?.last_name?.message}
                  />
                )}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" className="mb-3">
              <Controller
                name="middle_name"
                control={control}
                rules={
                  {
                    // pattern:{
                    //   value:checkIfBlank(watch('middle_name')),
                    //   message:'You cannot submit an empty space'
                    // }
                  }
                }
                render={({ field }) => (
                  <FormInput
                    {...field}
                    title="Other Names"
                    name="middle_name"
                    type="text"
                    placeholder="Other Names"
                    error={errors?.middle_name?.message}
                  />
                )}
              />
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label style={{ fontSize: '15px', marginTop: '1rem' }}>
                Gender <Required />
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    {...register('gender')}
                    value="M"
                    required
                    inline
                    label="Male"
                    name="gender"
                    type="radio"
                    id={`inline-radio-1`}
                    error={errors?.gender?.message}
                  />
                  <Form.Check
                    {...register('gender')}
                    value="F"
                    required
                    inline
                    label="Female"
                    name="gender"
                    type="radio"
                    id={`inline-radio-2`}
                    error={errors?.gender?.message}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Row>

          <Row className="mb-5">
            <Form.Group as={Col} md="6" className="mb-3">
              <Controller
                name="id_type"
                control={control}
                rules={{
                  required: { value: true, message: 'Field is required' },
                }}
                render={({ field }) => (
                  <InputSelect
                    {...field}
                    isRequired={true}
                    // defaultSelect={state?.id_type}
                    defaultValue={''}
                    // isDisabled={disabled}
                    title="Type of ID card"
                    name="id_type"
                    options={constant.ID_TYPES}
                  />
                )}
              />
              {errors?.id_type && (
                <div style={{ color: 'red', marginTop: '0.2rem' }}>
                  {errors?.id_type?.message}
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Controller
                name="id_number"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Required Field',
                  },
                  pattern: {
                    value: evaluateVal(),
                    message: 'Input a valid ID Number',
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    isRequired={true}
                    // defaultValue={state?.id_number}
                    title="ID Number"
                    name="id_number"
                    type="text"
                    placeholder={returnPlaceholder()}
                    // isDisabled={disabled}
                    length={'20'}
                    error={errors?.id_number?.message}
                  />
                )}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="6" className="mb-3">
              <Controller
                name="phone_number"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Field is required',
                  },
                  pattern: {
                    value: checkIfBlank(watch('phone_number') || ''),
                    message: 'You cannot submit an empty space',
                  },
                }}
                render={({ field }) => (
                  <PhoneNumberInput
                    {...field}
                    title="Phone number"
                    placeholder="Phone number"
                    isRequired={true}
                    error={errors?.phone_number?.message}
                  />
                )}
              />
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Controller
                name="email"
                control={control}
                rules={
                  {
                    // required:{
                    //   value:true,
                    //   message:'Field is required'
                    // },
                    // pattern:{
                    //   value:constant?.BLANK_SPACES,
                    //   message:'You cannot submit an empty space'
                    // }
                  }
                }
                render={({ field }) => (
                  <FormInput
                    {...field}
                    title="Email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    isRequired={false}
                    error={errors?.email?.message}
                  />
                )}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="6">
              <Controller
                name="date_of_birth"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    title="Date of birth"
                    name="date_of_birth"
                    type="date"
                    placeholder="Date of Birth"
                    isRequired={true}
                    maxDate={moment().subtract(18, 'y').format('YYYY-MM-DD')}
                  />
                )}
              ></Controller>
            </Form.Group>
          </Row>

          {/* <Row className="mb-5">
          <Form.Group as={Col} md="6">
            <Form.Label className="big-label">
              Password<sup className="text-danger">*</sup>
            </Form.Label>
            <InputGroup className="mb-3">
              <FormInput
                name="password"
                type={passwordInput}
                onChange={handleChange}
                placeholder="**********"
                isRequired={true}
                error={fieldError?.password}
              />
              <InputGroup.Text onClick={seePassword}>
                <i className="fa fa-eye" />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label className="big-label">
              Confirm Password <sup className="text-danger">*</sup>
            </Form.Label>
            <InputGroup className="mb-3">
              <FormInput
                name="confirm_password"
                type={passwordInput}
                onChange={handleChange}
                placeholder="**********"
                isRequired={true}
                error={fieldError?.password}
              />
              <InputGroup.Text onClick={seePassword}>
                <i className="fa fa-eye" />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Row> */}
          <Row>
            <Col md={12}>
              <Row className="mt-5">
                <Col md={4} className="mb-3">
                  <MainButton
                    text={'Previous'}
                    type={'submit'}
                    variant="outline-secondary"
                    size="lg"
                    className="fs-6"
                    isDisabled={true}
                  />
                </Col>
                <Col md={{ span: 4, offset: 4 }}>
                  <MainButton
                    text="Next"
                    type="submit"
                    variant="primary"
                    size="lg"
                    className="fs-6"
                    isLoading={mutation.isLoading}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </div>

      <ModalDiv
        show={otpModal}
        onHide={() => setOtpModal(false)}
        title={'Enter OTP'}
        subTitle={subTitle}
        body={
          <OTPForm
            otp={otp}
            handleChange={handleOtpChange}
            requestCode={handleRequestCode}
          />
        }
        actionButton={
          <MainButton
            text={'Send'}
            onClick={handleSubmitOTP}
            isLoading={secretCodeMutation.isLoading}
            isDisabled={otp?.length !== 6}
          />
        }
      />
    </Form>
  );
};

export default SignUpForm;
