import React from "react";
import propTypes from "prop-types";
import constant from "@utils/constants/constants";
export default function HoverIcon({ icon, hover }) {
  const style = {
    color: hover ? `${constant.DEFAULT_COLOR}` : "#FFF",
  };
  return (
    <div className=" mx-auto mb-4">
      <i className={icon} style={style}></i>
    </div>
  );
}

HoverIcon.propTypes = {
  icon: propTypes.string,
  hover: propTypes.bool,
};
