import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import propTypes from "prop-types"
import { Outlet } from "react-router-dom";
import { refreshToken } from "@utils/helpers/functions";

const OnboardLayout = ({ children }) =>{
    const [refresh, setRefresh] = useState(1);
    useEffect(() => {
      refreshToken();
    }, [refresh]);
    setTimeout(function () {
      setRefresh(refresh + 1);
    }, 240000);
    return(
        <Container fluid>
            <Row>
                { children }
                <Outlet/>
            </Row>
        </Container>
    )
}
OnboardLayout.propTypes = {
    children: propTypes.node,
  };


export default OnboardLayout