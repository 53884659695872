/*eslint-disable*/
import React from 'react';
import { useForm } from 'react-hook-form';
import { IGrantUpdateParams } from '../../GrantForm.interfaces';
import { IUserModel } from '@/utils/constants/userModel.interface';
import { AxiosResponse } from 'axios';
import { logError, useGetUserObject } from '../GrantFormBaseLayout.service';
import { useNavigate } from 'react-router-dom';
import { getUserProfile, updateGrantInformation } from '@/api/User/User';
import { useQuery } from 'react-query';

export const useGetApplicationSummary = () => {
  const { control, watch, handleSubmit, formState } = useForm();
  const {
    data: userData,
    error: userError,
    isLoading: userLoading,
    isRefetching,
    refetch,
  } = useQuery([], () => {
    return getUserProfile();
  });
  const _userData = userData as AxiosResponse<Partial<IUserModel>>;
  const navigate = useNavigate();
  const onSubmit = async (data: any) => {
    try {
      let grantParams: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };

      let { applicant_reference_number, ...rest } = data;
      grantParams.id = _userData?.data?.profile?.grant_information?.id;
      grantParams.data = {
        ..._userData?.data?.profile?.grant_information,
        business_plan: {
          ..._userData?.data?.profile?.grant_information?.business_plan,
          ...rest,
        },
      };
      await updateGrantInformation(grantParams);
      navigate('/grants/businessplan/application_information');
    } catch (e) {
      logError(e);
    }
  };

  React.useEffect(() => {
    refetch();
  }, []);

  return {
    control,
    watch,
    handleSubmit,
    refetch,
    formState,
    onSubmit,
    userData: _userData,
    isLoading: userLoading || isRefetching,
  };
};
