/*eslint-disable*/
import React from 'react';
import {
  geaTrainingOptions,
  useGetPersonalInformation2,
} from '@views/GrantForm/services/PersonalInformation.service';
import GrantFormBaseLayout from '@views/GrantForm/GrantFormBaseLayout';
import { Row, Col } from 'react-bootstrap';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import ControlledRadioButtonGroup from '@/components/forms/FormInput/ControlledRadioButton';
import ControlledSelectInput from '@/components/forms/FormInput/ControlledSelectInput';
import {
  hasBusinessPlanOptions,
  hasRegisteredBusinessOptions,
} from '../../../services/BusinessForm.service';
import { useGetBusinessForm } from '../../../services/BusinessForm.service';
import { parseDbNumber } from '@/views/GrantForm/services/GrantFormBaseLayout.service';
import { IGrantFormStage } from '@/views/GrantForm/GrantForm.interfaces';

const BusinessForm = ({ isPreview, isPrint }: IGrantFormStage) => {
  const {
    control,
    handleSubmit,
    onSubmit,
    watch,
    grantData,
    grantError,
    grantLoading,
    updateGrantLoading,
    _userData,
    regions,
    userLoading,
  } = useGetBusinessForm();

  return (
    <GrantFormBaseLayout
      page={2}
      title={'Business Information'}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      isLoading={grantLoading || updateGrantLoading || userLoading}
      stage={'business_form'}
      preview={isPreview}
      print={isPrint}
    >
      <Row>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'business_name'}
            title={'Business Name'}
            placeholder={'Business Name'}
            defaultValue={
              _userData?.data?.profile?.grant_information?.enterprise_name
            }
            rules={{
              required: { message: 'First Name is Required', value: true },
            }}
            isDisabled={isPreview == true}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'telephone'}
            title={'Business Phone Number'}
            placeholder={'Business Phone'}
            defaultValue={parseDbNumber(
              _userData?.data?.profile?.grant_information?.business_phone
            )}
            rules={{
              required: {
                message: 'First Name is Required',
                value:
                  !_userData?.data?.profile?.grant_information?.business_phone,
              },
            }}
            type="number"
            isDisabled={isPreview == true}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <ControlledSelectInput
            control={control}
            name={'region'}
            title={'Business Physical Location'}
            placeholder={'Region'}
            defaultValue={
              _userData?.data?.profile?.grant_information?.applicant_information
                ?.region
            }
            rules={{
              required: { message: 'Other Name is Required', value: true },
            }}
            options={regions}
            isDisabled={isPreview == true}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'city'}
            title={' '}
            placeholder={'City'}
            defaultValue={
              _userData?.data?.profile?.grant_information?.applicant_information
                ?.city
            }
            rules={{
              required: {
                message: 'Last Name is Required',
                value:
                  !_userData?.data?.profile?.grant_information
                    ?.applicant_information?.city,
              },
            }}
            isDisabled={isPreview == true}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <ControlledTextInput
            name={'address_line_1'}
            title={''}
            control={control}
            placeholder={'Address 1'}
            defaultValue={
              _userData?.data?.profile?.grant_information?.address_line_1
            }
            rules={{
              required: {
                message: 'Last Name is Required',
                value:
                  !_userData?.data?.profile?.grant_information?.address_line_1,
              },
            }}
            isDisabled={isPreview == true}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            name={'address_line_2'}
            title={''}
            control={control}
            placeholder={'Address 2'}
            defaultValue={
              _userData?.data?.profile?.grant_information?.address_line_2
            }
            rules={{
              required: {
                message: 'Last Name is Required',
                value:
                  !_userData?.data?.profile?.grant_information?.address_line_2,
              },
            }}
            isDisabled={isPreview == true}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <ControlledRadioButtonGroup
            control={control}
            name={'is_registered'}
            title={'Have you registered the business?'}
            options={hasRegisteredBusinessOptions}
            defaultValue={
              _userData?.data.business_profile?.is_registered == true
                ? 'Y'
                : 'N'
            }
            rules={{
              required:
                !_userData?.data.business_profile?.is_registered == true,
            }}
            isDisabled={isPreview == true}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledRadioButtonGroup
            control={control}
            name={'has_business_plan'}
            title={'Do you have a business plan?'}
            options={hasBusinessPlanOptions}
            defaultValue={
              _userData?.data?.profile?.grant_information?.has_business_plan ==
              true
                ? 'Y'
                : 'N'
            }
            isRequired
            rules={{
              required:
                !_userData?.data?.profile?.grant_information?.has_business_plan,
            }}
            isDisabled={isPreview == true}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'mission'}
            defaultValue={_userData?.data.profile?.grant_information?.mission}
            title={'State the Mission of your business'}
            placeholder="Business Mission"
            as={'textarea'}
            isRequired
            rules={{
              required: !_userData?.data.profile?.grant_information?.mission,
            }}
            isDisabled={isPreview == true}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'vision'}
            title={'State the Vision of your business'}
            placeholder="Business Vision"
            as={'textarea'}
            defaultValue={_userData?.data?.profile?.grant_information?.vision}
            isRequired
            rules={{
              required: !_userData?.data?.profile?.grant_information?.vision,
            }}
            isDisabled={isPreview == true}
          />
        </Col>
      </Row>
    </GrantFormBaseLayout>
  );
};

export default BusinessForm;
