/*eslint-disable*/
import { OptionType } from '@/utils/constants/globals.interfaces';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  IGrantProductProps,
  IGrantUpdateParams,
  IProductInformation,
  IProductInformation2,
  IUserUpdateParams,
} from '../GrantForm.interfaces';
import { useNavigate } from 'react-router-dom';
import { getIndustries } from '@/utils/helpers/functions';
import { useQuery } from 'react-query';
import { logError, useGetUserObject } from './GrantFormBaseLayout.service';
import { AxiosResponse } from 'axios';
import {
  IGrantFormModel,
  IUserModel,
} from '@/utils/constants/userModel.interface';
import { updateFullUserProfile, updateGrantInformation } from '@/api/User/User';
import _ from 'lodash';

export const getDefaultProductValue = ({
  products,
  key,
}: IGrantProductProps) => {
  let product = products?.filter((product) => {
    return product?.service_or_product == key;
  })[0];
  return product;
};

export const useGetProductInformationForm = () => {
  const { watch, control, handleSubmit, formState } =
    useForm<IProductInformation>();
  const navigate = useNavigate();

  const {
    data: industryData,
    error: industryError,
    isLoading: industryLoading,
  } = useQuery('get-industries', getIndustries);
  const { userData, refetch, userError, userLoading } = useGetUserObject();
  let _userData = userData as AxiosResponse<Partial<IUserModel>>;

  const onSubmit: SubmitHandler<IProductInformation> = async (data) => {
    try {
      let grantParams: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };

      let userUpdateParams: IUserUpdateParams = {
        id: undefined,
        data: undefined,
      };
      let productKeyArray = [
        'product1',
        'product2',
        'product3',
        'product4',
        'product5',
        'product6',
      ];

      let productArray: IGrantUpdateParams['data']['products'] = [];

      productKeyArray.forEach((key) => {
        if (data[key]) {
          productArray.push({ service_or_product: data[key] });
        }
      });

      grantParams.id = _userData?.data?.profile?.grant_information?.id;
      grantParams.data = {
        ..._userData?.data?.profile?.grant_information,
        products: productArray,
        industry: {
          name:
            data.industry ||
            _userData?.data?.profile?.grant_information?.industry?.name,
        },
        stage_of_development:
          data.stage_of_development ||
          _userData?.data?.profile?.grant_information?.stage_of_development,
        opportunity_identified_and_idea_mooted:
          data.opportunity_identified_and_idea_mooted,
      };

      userUpdateParams.id = _userData?.data?.profile?.client;
      userUpdateParams.data = {
        grant_onboarding_data: {
          ..._userData?.data?.grant_onboarding_data,
          product_information: true,
        },
      };
      userUpdateParams.data.id = _userData?.data?.profile?.client;

      await updateFullUserProfile(userUpdateParams.data);
      await updateGrantInformation(grantParams);
      navigate('/grants/product_information_2');
    } catch (e) {}
  };

  return {
    watch,
    control,
    handleSubmit,
    formState,
    onSubmit,
    industryData,
    isLoading: userLoading || industryLoading,
    userData: _userData,
  };
};

/**spacing things out
 *
 *
 *
 *
 */

export const useGetProductInformationForm2 = () => {
  const { watch, control, handleSubmit, formState } =
    useForm<IProductInformation2>();
  const navigate = useNavigate();
  const { userData, refetch, userError, userLoading } = useGetUserObject();
  let _userData = userData as AxiosResponse<Partial<IUserModel>>;

  const onSubmit: SubmitHandler<IProductInformation2> = async (data) => {
    try {
      let grantParams: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };
      let userUpdateParams: IUserUpdateParams = {
        id: undefined,
        data: undefined,
      };
      grantParams.id = _userData.data.profile?.grant_information?.id;
      grantParams.data = {
        ..._userData?.data?.profile?.grant_information,
        has_gsa_certification: data.has_gsa_certification == 'Y' ? true : false,
        has_fda_certification: data.has_fda_certification == 'Y' ? true : false,
      };
      userUpdateParams.id = _userData?.data?.profile?.client;
      userUpdateParams.data = {
        grant_onboarding_data: {
          ..._userData?.data?.grant_onboarding_data,
          product_information_2: true,
        },
      };
      userUpdateParams.data.id = _userData?.data?.profile?.client;
      await updateGrantInformation(grantParams);
      await updateFullUserProfile(userUpdateParams.data);
      navigate('/grants/technical_capacity');
    } catch (e) {
      logError(e);
    }
  };

  return {
    watch,
    control,
    handleSubmit,
    formState,
    onSubmit,
    isLoading: userLoading,
    userData: _userData,
  };
};

export const industryList: OptionType<string, string>[] = [
  { label: 'Farming', value: 'farming' },
];

export const developmentStageList: OptionType<string, string>[] = [
  { label: 'Ideation', value: 'ideation' },
  { label: 'Testing', value: 'testing' },
  { label: 'Prototyping', value: 'prototyping' },
  { label: 'Commercialisation', value: 'commercialisation' },
];

export const gsaCertficationOptions: OptionType<string, string>[] = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];

export const fdaCertficationOptions: OptionType<string, string>[] = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];
