import HeroSection from "@components/layout/layouts/StaticPagesBaseLayout/HeroSection";
import React from "react";
import { Container } from "react-bootstrap";
import hero_img from "@images/bg/jas_hero_6.jpg";
import propTypes from "prop-types";

export default function AboutGea() {
  return (
    <>
      <HeroSection img={hero_img} title="About GEA" />
      <Container className="mt-4">
        <p data-pm-slice="1 1 []">
          <strong>ABOUT GHANA ENTERPRISES AGENCY</strong>
        </p>
        <p>
          Ghana Enterprises Agency is the apex governmental body dedicated to
          the promotion and development of the Micro, Small and Medium
          Enterprises (MSMEs) Sector in Ghana. With the passage of the new Act
          1043 by the &nbsp; &nbsp; &nbsp;
        </p>
        <p>
          Parliament and assented to by the President, His Excellency, Nana Addo
          Dankwa Akufo-Addo in 2020, GEA is mandated to coordinate, implement
          and monitor the activities of the MSME Sector in Ghana.
        </p>
        <p>
          With its Head Office located in Accra, GEA operates sixteen (16)
          Regional Offices, one hundred and eighty-five (185) District Offices
          known as Business Advisory Centers (BACs) and thirty-seven (37)
          Business Resource Centres spread across the country.
        </p>
        <p>
          At the District level, GEA provides Business Development Services
          (BDS) to MSMEs, facilitate access to credit and also supports
          formalization of MSME sector in Ghana. GEA aims to lay the foundation
          for a world class MSME apex institution in Ghana which supports job
          and wealth creation as well as building globally competitive MSMEs.
        </p>
        <p>VISION:</p>
        <p>
          The vision of GEA is to be the apex MSME organization for building
          globally competitive businesses towards job and wealth creation.
        </p>
        <p>&nbsp;MISSION:</p>
        <p>
          To facilitate the creation of an enabling business environment,
          development of Financial and Non-financial Services, and the building
          of supportive institutional infrastructure.
        </p>
      </Container>
    </>
  );
}

function HeaderText({ title }) {
  return <span>{title} </span>;
}

HeaderText.propTypes = {
  title: propTypes.string,
};
