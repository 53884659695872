/*eslint-disable*/
import React from 'react';
import BusinessFormBaseLayout from '@/views/GrantForm/BusinessFormBaseLayout';
import { Row, Col, Form } from 'react-bootstrap';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import { useGetApplicantInformation4 } from '@/views/GrantForm/services/BusinessPlan/ApplicantInformation.service';
import InstructionText from '../../InstructionText';
import { IBusinessPlanFormStage } from '@/views/GrantForm/GrantForm.interfaces';
import { validateInputLength } from '@/views/GrantForm/services/GrantFormBaseLayout.service';
import { who_is_your_competition_and_how_unique_are_you_from_them } from '@/utils/constants/skippedapplicantquestions';

const ApplicantInformation4 = ({
  isPreview,
  isPrint,
}: IBusinessPlanFormStage) => {
  const {
    control,
    watch,
    handleSubmit,
    formState,
    onSubmit,
    userData,
    isLoading,
  } = useGetApplicantInformation4();
  return (
    <BusinessFormBaseLayout
      page={2}
      title={'Applicant Information'}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      isLoading={isLoading}
      formStage={'application_information_4'}
      preview={isPreview}
      print={isPrint}
    >
      <Row>
        <Col md={12} xs={12}>
          <ControlledTextInput
            control={control}
            name={'who_is_your_competition_and_how_unique_are_you_from_them'}
            title={
              userData?.data?.skipped_advanced_training == false
                ? 'Tell us about your key competitors. What will you do differently to set you apart from or separate your product/service from your key competitors?  (your responses must not be more than 500 words)'
                : who_is_your_competition_and_how_unique_are_you_from_them
            }
            defaultValue={
              userData?.data?.profile?.grant_information?.applicant_information
                ?.who_is_your_competition_and_how_unique_are_you_from_them
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information
                  ?.applicant_information
                  ?.who_is_your_competition_and_how_unique_are_you_from_them,
              validate: {
                valid: (v) => {
                  return validateInputLength(v, 500);
                },
              },
            }}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information
                    ?.applicant_information
                    ?.who_is_your_competition_and_how_unique_are_you_from_them
                : null
            }
            type={'text'}
            as={'textarea'}
            placeholder="Your response should not be more than 500 words"
            isDisabled={isPreview == true}
            isPrint={isPrint}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12}>
          <InstructionText
            title={
              'Provide details of the challenges your enterprise faces or your startup anticipates'
            }
            subtitle="Consider if these challenges are industry-wide or unique to your enterprise. Consider possible solutions to resolve challenges identified. (Your response should not be more than 500 words)"
          />
        </Col>
        <Col md={12} xs={12}>
          <ControlledTextInput
            control={control}
            name={'details_of_entreprise_challenges'}
            type={'text'}
            as={'textarea'}
            defaultValue={
              userData?.data?.profile?.grant_information?.applicant_information
                ?.details_of_entreprise_challenges
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information
                  ?.applicant_information?.details_of_entreprise_challenges,
              validate: {
                valid: (v) => {
                  return validateInputLength(v, 500);
                },
              },
            }}
            value={
              isPreview
                ? userData?.data?.profile?.grant_information
                    ?.applicant_information?.details_of_entreprise_challenges
                : null
            }
            placeholder="Your response should not be more than 50 words"
            isDisabled={isPreview == true}
            isPrint={isPrint}
          />
        </Col>
      </Row>
    </BusinessFormBaseLayout>
  );
};

export default ApplicantInformation4;
