/*eslint-disable*/
import React from 'react';
import GrantFormBaseLayout from '@views/GrantForm/GrantFormBaseLayout';
import { Row, Col, Form } from 'react-bootstrap';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import ControlledSelectInput from '@/components/forms/FormInput/ControlledSelectInput';
import GrantAccordionBase from '../../GrantAccordionBase';
import {
  developmentStageList,
  getDefaultProductValue,
  industryList,
} from '../../../services/ProductInformation.service';
import { useGetProductInformationForm } from '../../../services/ProductInformation.service';
import { OptionType } from '@/utils/constants/globals.interfaces';
import { IGrantFormStage } from '@/views/GrantForm/GrantForm.interfaces';

const ProductInformationForm = ({ isPreview, isPrint }: IGrantFormStage) => {
  const {
    control,
    handleSubmit,
    onSubmit,
    watch,
    industryData,
    userData,
    isLoading,
  } = useGetProductInformationForm();

  return (
    <GrantFormBaseLayout
      page={3}
      title={'Product/Services Information'}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      stage={'product_information'}
      preview={isPreview}
      print={isPrint}
    >
      <Row>
        <Form.Label className={'controlled-input-label'}>
          What are the business' products/services?
        </Form.Label>
        <Row style={{ marginBottom: '20px' }}>
          <form>
            <Col md={12} xs={12}>
              <GrantAccordionBase
                title={'Products/Services'}
                expandable={false}
              >
                <Row>
                  <Col md={4} xs={12}>
                    <ControlledTextInput
                      control={control}
                      name={'product1'}
                      placeholder="Product 1"
                      defaultValue={
                        userData?.data?.profile?.grant_information
                          ?.products?.[0]?.service_or_product
                        // getDefaultProductValue({
                        //   products:
                        //     userData?.data?.profile?.grant_information
                        //       ?.products,
                        //   key: 'product1',
                        // })?.service_or_product
                      }
                      rules={{
                        required: {
                          message: 'This field is required',
                          value: false,
                        },
                      }}
                      isDisabled={isPreview == true}
                    />
                  </Col>
                  <Col md={4} xs={12}>
                    <ControlledTextInput
                      control={control}
                      name={'product2'}
                      placeholder="Product 2"
                      defaultValue={
                        userData?.data?.profile?.grant_information
                          ?.products?.[1]?.service_or_product
                      }
                      rules={{
                        required: {
                          message: 'This field is required',
                          value: false,
                        },
                      }}
                      isDisabled={isPreview == true}
                    />
                  </Col>
                  <Col md={4} xs={12}>
                    <ControlledTextInput
                      control={control}
                      name={'product3'}
                      placeholder="Product 3"
                      defaultValue={
                        userData?.data?.profile?.grant_information
                          ?.products?.[2]?.service_or_product
                      }
                      rules={{
                        required: {
                          message: 'This field is required',
                          value: false,
                        },
                      }}
                      isDisabled={isPreview == true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4} xs={12}>
                    <ControlledTextInput
                      control={control}
                      name={'product4'}
                      placeholder="Product 4"
                      defaultValue={
                        userData?.data?.profile?.grant_information
                          ?.products?.[3]?.service_or_product
                      }
                      rules={{
                        required: {
                          message: 'This field is required',
                          value: false,
                        },
                      }}
                      isDisabled={isPreview == true}
                    />
                  </Col>
                  <Col md={4} xs={12}>
                    <ControlledTextInput
                      control={control}
                      name={'product5'}
                      placeholder="Product 5"
                      defaultValue={
                        userData?.data?.profile?.grant_information
                          ?.products?.[4]?.service_or_product
                      }
                      rules={{
                        required: {
                          message: 'This field is required',
                          value: false,
                        },
                      }}
                      isDisabled={isPreview == true}
                    />
                  </Col>
                  <Col md={4} xs={12}>
                    <ControlledTextInput
                      control={control}
                      name={'product6'}
                      placeholder="Product 6"
                      defaultValue={
                        userData?.data?.profile?.grant_information
                          ?.products?.[5]?.service_or_product
                      }
                      rules={{
                        required: {
                          message: 'This field is required',
                          value: false,
                        },
                      }}
                      isDisabled={isPreview == true}
                    />
                  </Col>
                </Row>
              </GrantAccordionBase>
            </Col>
          </form>
        </Row>

        <Row>
          <Col md={6} xs={12}>
            <ControlledSelectInput
              title={'What industry sector is the business in?'}
              name={'industry'}
              control={control}
              defaultValue={
                userData?.data?.profile?.grant_information?.industry?.name
              }
              options={
                industryData?.data?.map((industry: any) => {
                  return { label: industry.name, value: industry.name };
                }) as unknown as OptionType<string, string>[]
              }
              rules={{
                required: true,
              }}
              isDisabled={isPreview == true}
            />
          </Col>
          <Col md={6} xs={12}>
            <ControlledSelectInput
              title={
                'At what stage of development are the products /services development?'
              }
              name={'stage_of_development'}
              control={control}
              options={developmentStageList}
              defaultValue={
                userData?.data?.profile?.grant_information?.stage_of_development
              }
              rules={{
                required:
                  !userData?.data?.profile?.grant_information
                    ?.stage_of_development,
              }}
              isDisabled={isPreview == true}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <ControlledTextInput
              title={'How opportunity was identified and business idea mooted'}
              control={control}
              name={'opportunity_identified_and_idea_mooted'}
              as={'textarea'}
              placeholder="How was the opportunity identified and business idea mooted?"
              defaultValue={
                userData?.data?.profile?.grant_information
                  ?.opportunity_identified_and_idea_mooted
              }
              isDisabled={isPreview == true}
            />
          </Col>
        </Row>
      </Row>
    </GrantFormBaseLayout>
  );
};

export default ProductInformationForm;
