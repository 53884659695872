import React from "react";
import { Row, Col } from "react-bootstrap";
// import bg1 from "../../../images/bg/bg1.jpg"
// import logo from "./logo.svg";
import "./style.css";
import { GeaLogo as Logo } from "@components/utils";
import { TextField } from "@components/forms";
import { Link } from "react-router-dom";

const AuthLeftNav = () => {
  return (
    <Col md={6} className="d-none d-lg-block auth-left">
      <Row className="position-fixed w-50">
        <Col className="auth-sidebar">
      
          <Link to={"/home"}>
            <Logo type={"large"}/>
          </Link>
          <Col md={9} className="mt-50 py-5 px-5">
            <TextField
              text="Take the first steps to economic growth and social impact!"
              className="fw-bold text-white h2"
              fontSize={48}
            />
          </Col>
        </Col>
      </Row>
    </Col>
  );
};

export default AuthLeftNav;
