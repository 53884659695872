/*eslint-disable*/
import React from 'react';
import styled from 'styled-components';
import InfoIcon from '@mui/icons-material/Info';

interface IInstructionText {
  title?: string;
  subtitle?: string;
  isInfo?: boolean;
}

const InstructionWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
`;

const InstructionTitle = styled.div`
  font-family: inherit;
  font-weight: 800;
`;

const InstructionSubtitle = styled.div`
  font-weight: 400;
  font-family: inherit;
  margin-top: 10px;
`;

const InfoSubtitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InstructionText = ({ title, subtitle, isInfo }: IInstructionText) => {
  return (
    <InstructionWrapper>
      <InstructionTitle>{title}</InstructionTitle>
      {!isInfo && <InstructionSubtitle>{subtitle}</InstructionSubtitle>}
      {isInfo && (
        <InfoSubtitleWrapper>
          <InfoIcon style={{ color: '#7C8293', marginRight: '10px' }} />
          <InstructionSubtitle style={{ color: '#7C8293' }}>
            {subtitle}
          </InstructionSubtitle>
        </InfoSubtitleWrapper>
      )}
    </InstructionWrapper>
  );
};

export default InstructionText;
