/*eslint-disable*/

import React from 'react';
import GrantFormBaseLayout from '../../../GrantFormBaseLayout';
import { Row, Col } from 'react-bootstrap';
import { useGetRevenueGenerationForm } from '../../../services/RevenueGeneration.service';
import styled from 'styled-components';
import GrantAccordionBase from '../../GrantAccordionBase';
import RevenueSubform from './RevenueSubform';
import { getDefaultFinancialProjectionValues } from '@/views/GrantForm/services/GrantFormBaseLayout.service';
import { IGrantFormStage } from '@/views/GrantForm/GrantForm.interfaces';

export const RevenueRow = styled(Row)`
  margin-top: 20px;
  margin-bottom: 20px;
`;
const RevenueGeneration = ({ isPreview, isPrint }: IGrantFormStage) => {
  const {
    watch,
    control,
    handleSubmit,
    formState,
    onSubmit,
    userData,
    isLoading,
  } = useGetRevenueGenerationForm();
  return (
    <GrantFormBaseLayout
      page={6}
      title={'Revenue Generation'}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      stage={'revenue_generation'}
      preview={isPreview}
      print={isPrint}
    >
      <form>
        <RevenueRow>
          <Col md={12} xs={12}>
            <GrantAccordionBase title="Fiscal Year One" expandable={true}>
              <RevenueSubform
                control={control}
                fieldNames={[
                  'fiscalOneTurnover',
                  'fiscalOneOperatingCost',
                  'fiscalOneGrossProfit',
                  'fiscalOneNetCashFlow',
                ]}
                defaultValueMapping={getDefaultFinancialProjectionValues({
                  data: userData?.data?.profile?.grant_information
                    ?.financial_projections,
                  key: 'year_one',
                })}
                isDisabled={isPreview == true}
              />
            </GrantAccordionBase>
          </Col>
        </RevenueRow>
        <RevenueRow>
          <Col md={12} xs={12}>
            <GrantAccordionBase title="Fiscal Year Two" expandable={true}>
              <RevenueSubform
                control={control}
                fieldNames={[
                  'fiscalTwoTurnover',
                  'fiscalTwoOperatingCost',
                  'fiscalTwoGrossProfit',
                  'fiscalTwoNetCashFlow',
                ]}
                defaultValueMapping={getDefaultFinancialProjectionValues({
                  data: userData?.data?.profile?.grant_information
                    ?.financial_projections,
                  key: 'year_two',
                })}
                isDisabled={isPreview == true}
              />
            </GrantAccordionBase>
          </Col>
        </RevenueRow>
        <RevenueRow>
          <Col md={12} xs={12}>
            <GrantAccordionBase title="Fiscal Year Three" expandable={true}>
              <RevenueSubform
                control={control}
                fieldNames={[
                  'fiscalThreeTurnover',
                  'fiscalThreeOperatingCost',
                  'fiscalThreeGrossProfit',
                  'fiscalThreeNetCashFlow',
                ]}
                defaultValueMapping={getDefaultFinancialProjectionValues({
                  data: userData?.data?.profile?.grant_information
                    ?.financial_projections,
                  key: 'year_three',
                })}
                isDisabled={isPreview == true}
              />
            </GrantAccordionBase>
          </Col>
        </RevenueRow>
        <RevenueRow>
          <Col md={12} xs={12}>
            <GrantAccordionBase title="Fiscal Year Four" expandable={true}>
              <RevenueSubform
                control={control}
                fieldNames={[
                  'fiscalFourTurnover',
                  'fiscalFourOperatingCost',
                  'fiscalFourGrossProfit',
                  'fiscalFourNetCashFlow',
                ]}
                defaultValueMapping={getDefaultFinancialProjectionValues({
                  data: userData?.data?.profile?.grant_information
                    ?.financial_projections,
                  key: 'year_four',
                })}
                isDisabled={isPreview == true}
              />
            </GrantAccordionBase>
          </Col>
        </RevenueRow>
        <RevenueRow>
          <Col md={12} xs={12}>
            <GrantAccordionBase title="Fiscal Year Five" expandable={true}>
              <RevenueSubform
                control={control}
                fieldNames={[
                  'fiscalFiveTurnover',
                  'fiscalFiveOperatingCost',
                  'fiscalFiveGrossProfit',
                  'fiscalFiveNetCashFlow',
                ]}
                defaultValueMapping={getDefaultFinancialProjectionValues({
                  data: userData?.data?.profile?.grant_information
                    ?.financial_projections,
                  key: 'year_five',
                })}
                isDisabled={isPreview == true}
              />
            </GrantAccordionBase>
          </Col>
        </RevenueRow>
      </form>
    </GrantFormBaseLayout>
  );
};

export default RevenueGeneration;
