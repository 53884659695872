import BusinessFormBaseLayout from '@/views/GrantForm/BusinessFormBaseLayout';
import { useGetGuidanceNote } from '@/views/GrantForm/services/BusinessPlan/GuidanceNote.service';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import MainButton from '@/components/buttons/Buttons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { defaultBlue } from '@/utils/constants/colors.constants';

const GuidanceText = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-style: italic;
`;

const GuidanceNote = () => {
  const { onSubmit } = useGetGuidanceNote();
  const navigate = useNavigate();
  return (
    <BusinessFormBaseLayout
      page={0}
      title={'Guidance'}
      showBottomButtons={false}
    >
      <Row>
        <Col md={12} xs={12}>
          <GuidanceText>
            Please complete all sections even if some responses are the same as
            those submitted at the application stage. Applicants must disclose
            any circumstances, including personal, financial, and business
            activities, that will, or might, give rise to a conflict of interest
            by applying for funding or if selected as a grantee.
          </GuidanceText>
          <GuidanceText>
            The business plan template will be completed on the online portal
            through a link sent to successful trainees. You may be contacted by
            GEA to provide further information so please reconfirm that the
            contact email address (2.7) entered on the form is correct. The
            contact email address entered on the form will be the email address
            used for all communication once your grant funding request via this
            template has been processed.
          </GuidanceText>
          <GuidanceText>
            For any queries regarding your application please contact{' '}
            <a href="mailto:gjsp@gea.com">
              gjsp@gea.com
            </a>, or
            call <span style={{color:defaultBlue}}>0302747777</span> . Ensure all mandatory fields are completed before submitting the
            business plan template. Please click this link to view the form
            Template:{' '}
            <a href="/assets/template.pdf" target="_blank">
              Form PDF Template
            </a>{' '}
          </GuidanceText>
        </Col>
      </Row>
      <Row>
        <div className="d-flex  align-items-center h-100">
          <Col md={12}>
            <Row className="mt-5">
              <Col sm={12} md={4}>
                <MainButton
                  text={'Previous'}
                  type={'button'}
                  variant="outline-secondary"
                  size="lg"
                  className="fs-6"
                  onClick={() => {
                    navigate('/dashboard');
                  }}
                />
              </Col>
              <Col sm={12} md={{ span: 4, offset: 4 }}>
                <MainButton
                  text={'Next'}
                  type={'button'}
                  variant="primary"
                  size="lg"
                  className="fs-6"
                  onClick={() => {
                    onSubmit();
                  }}
                />
              </Col>
            </Row>
          </Col>
        </div>
      </Row>
    </BusinessFormBaseLayout>
  );
};

export default GuidanceNote;
