/*eslint-disable*/
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  IGrantUpdateParams,
  IPersonalInformation,
  IPersonalInformationII,
  IUserUpdateParams,
} from '../GrantForm.interfaces';
import { OptionType } from '@/utils/constants/globals.interfaces';
import { useNavigate } from 'react-router-dom';
import { useGetUserObject, logError } from './GrantFormBaseLayout.service';
import constant from '@/utils/constants/constants';
import { IUserModel } from '@/utils/constants/userModel.interface';
import { AxiosResponse } from 'axios';
import { updateFullUserProfile, updateGrantInformation } from '@/api/User/User';
import { messageAlert } from '@/utils/helpers/functions';

export const useGetPersonalInformation = () => {
  const navigate = useNavigate();
  const { userData, userError, userLoading } = useGetUserObject();
  const { control, watch, handleSubmit } = useForm<IPersonalInformation>();
  const _userData = userData as AxiosResponse<Partial<IUserModel>>;
  const onSubmit: SubmitHandler<IPersonalInformation> = async (data) => {
    try {
      if (!userError) {
        let params: IUserUpdateParams = {
          id: undefined,
          data: undefined,
        };
        params.id = _userData?.data?.profile?.client;
        params.data = {
          grant_onboarding_data: {
            ..._userData?.data?.grant_onboarding_data,
            personal_information: true,
          },
        };
        params.data.id = _userData?.data?.profile?.client;
        await updateFullUserProfile(params);
        navigate('/grants/personal_information_2');
      }
    } catch (e) {
      logError(e);
      messageAlert('error', 'Request failed');
    }
  };
  return { control, watch, handleSubmit, onSubmit, _userData, userLoading };
};

export const useGetPersonalInformation2 = () => {
  const navigate = useNavigate();
  const { userData, userError, userLoading } = useGetUserObject();
  const { control, watch, handleSubmit } = useForm<IPersonalInformationII>();
  const _userData = userData as AxiosResponse<Partial<IUserModel>>;
  const onSubmit: SubmitHandler<IPersonalInformationII> = async (data) => {
    try {
      if (!userError) {
        let userParams: IUserUpdateParams = {
          id: undefined,
          data: undefined,
        };

        let grantParams: IGrantUpdateParams = {
          id: undefined,
          data: undefined,
        };

        let trainingsParticipatedIn = [];

        if (data?.trainings_participated_in) {
          data?.trainings_participated_in?.forEach((data: any) => {
            trainingsParticipatedIn.push(data?.value);
          });
        }

        userParams.id = _userData.data.profile?.client;
        userParams.data = {
          grant_onboarding_data: {
            ..._userData?.data?.grant_onboarding_data,
            personal_information_2: true,
          },
        };
        userParams.data.id = _userData.data.profile?.client;

        grantParams.id = _userData.data.profile?.grant_information?.id;
        grantParams.data = {
          ..._userData.data.profile?.grant_information,
          trainings_participated_in:
            data?.trainings_participated_in == undefined
              ? _userData?.data?.profile?.grant_information
                  ?.trainings_participated_in
              : trainingsParticipatedIn,
          has_participated_in_grant_management_program:
            data.has_participated_in_grant_management_program == 'Y'
              ? true
              : false,
          has_undergone_mentorship:
            data.has_undergone_mentorship == 'Y' ? true : false,
          applicant_information: {
            ..._userData.data.profile?.grant_information?.applicant_information,
            owner_is_disabled: data.owner_is_disabled == 'Y' ? true : false,

            // level_of_training_under_this_program:
            //   data.level_of_training_under_this_program,
          },
        };

        await updateFullUserProfile(userParams);
        await updateGrantInformation(grantParams);
        navigate('/grants/business_form');
      }
    } catch (e) {
      logError(e);
    }
  };

  return {
    control,
    watch,
    handleSubmit,
    onSubmit,
    userData: _userData,
    isLoading: userLoading,
  };
};

export const validateDigitalAddress = (
  value?: string,
  defaultValue?: string
) => {
  value = value || defaultValue;
  if (value) {
    const isValidDigitalAddress =
      constant.DIGITAL_ADDRESS.test(value) ||
      constant.DIGITAL_ADDRESS_2.test(value) ||
      constant.DIGITAL_ADDRESS_3.test(value);

    return isValidDigitalAddress || 'Enter a valid Digital Address';
  }
  return false;
};

export const genderOptions: OptionType<string, string>[] = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
];

export const genderOptionsGrants: OptionType<string, string>[] = [
  { label: 'Male', value: 'MALE' },
  { label: 'Female', value: 'FEMALE' },
];

export const maritalStatusOptions: OptionType<string, string>[] = [
  { label: 'Single', value: 'single' },
  { label: 'Married', value: 'married' },
  { label: 'Divorced', value: 'divorced' },
  { label: 'Widowed', value: 'widowed' },
  { label: 'Other', value: 'other' },
];

export const pwdOptions: OptionType<string, string>[] = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];

export const geaTrainingOptions: OptionType<string, string>[] = [
  { label: 'Basic Training', value: 'basic_training' },
  { label: 'Intermediate Training', value: 'intermediate_training' },
  { label: 'Advanced Training', value: 'advanced_training' },
];

export const mentorshipOptions: OptionType<string, string>[] = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];

export const grantManagementOptions: OptionType<string, string>[] = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];
