/*eslint-disable */
import { OptionType } from '@/utils/constants/globals.interfaces';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { logError, useGetUserObject } from '../GrantFormBaseLayout.service';
import { IUserModel } from '@/utils/constants/userModel.interface';
import { AxiosResponse } from 'axios';
import {
  IGrantUpdateParams,
  IUserUpdateParams,
} from '../../GrantForm.interfaces';
import { updateFullUserProfile, updateGrantInformation } from '@/api/User/User';
export const useGetSignature = () => {
  const { control, watch, handleSubmit, formState } = useForm();
  const { userData, refetch, userError, userLoading } = useGetUserObject();
  let _userData = userData as AxiosResponse<Partial<IUserModel>>;

  const navigate = useNavigate();
  const onSubmit = async (data: any) => {
    try {
      let grantParams: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };
      // let userUpdateParams: Partial<IUserUpdateParams> = {
      //   id: undefined,
      //   data: undefined,
      // };
      grantParams.id = _userData?.data?.profile?.grant_information?.id;
      grantParams.data = {
        ..._userData?.data?.profile?.grant_information,
        ...data,
      };

      // userUpdateParams.data = {
      //   profile: {
      //     is_grant_application_complete: true,
      //     is_business_plan_application_complete: true,
      //   },
      // };

      // userUpdateParams.data.id = _userData?.data?.profile?.client;
      await updateGrantInformation(grantParams);
      // await updateFullUserProfile(userUpdateParams.data);
      navigate('/grants/businessplan/consent');
    } catch (e) {
      logError(e);
    }
  };

  return {
    control,
    watch,
    handleSubmit,
    formState,
    onSubmit,
    isLoading: userLoading,
    userData: _userData,
  };
};

export const FormFilledForOptions: OptionType<string, string>[] = [
  { label: 'Myself', value: 'myself' },
  { label: 'On behalf of owner', value: 'on_behlaf_of_owner' },
];
