
import React from "react";
import MainButton from "@components/buttons/Buttons";
import CheckIcon from "@components/buttons/CheckIcon";
import { TextField } from "@components/forms";

import { Col, Container, Image, Row, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoanSvg from '@images/logo/rafiki.svg'


export default function LoanHome() {
    return(
        <Container>
            <Row className="mx-5 my-5">
                    <Row className="d-flex align-items-center justify-content-center mt-5 py-5 px-5">
                        <Row className="mb-3">
                            <Col md={12} className="d-flex align-items-center justify-content-center mb-5">
                                <Image src={LoanSvg} style={{width:"15%"}}/>
                            </Col>
                            <Col className="text-center">
                                <TextField text="Tell Us More About Your Idea/Business" className="fw-bold"/>
                                <TextField text="You will need the following documents to continue" className="small mt-3 "/>
                            </Col>
                        </Row>
                        <Row className="px-5 py-4 d-flex  justify-content-center ms-5">
                            <Col xs={12} md={4} xl={4}>
                                <Stack gap={4} className="mb-5">
                                    <Col>
                                        <CheckIcon /> Tax Identification Number
                                    </Col>
                                    <Col>
                                        <CheckIcon /> Tax Identification Number
                                    </Col>
                                    <Col>
                                        <CheckIcon /> Tax Identification Number
                                    </Col>
                                </Stack>
                            </Col>
                            <Col xs={12} md={4} xl={4}>
                                <Stack gap={4} className="mb-5 ">
                                    <Col>
                                        <CheckIcon /> Digital Address
                                    </Col>
                                    <Col>
                                        <CheckIcon /> Ghana Card ID Number
                                    </Col>
                                    <Col>
                                        <CheckIcon /> Tax Identification Number
                                    </Col>
                                </Stack>
                            </Col>
                            <Col xs={12} md={4} xl={4}>
                                <Stack gap={4} className="mb-5 ">
                                    <Col>
                                        <CheckIcon /> Digital Address
                                    </Col>
                                    <Col>
                                        <CheckIcon /> Ghana Card ID Number
                                    </Col>
                                </Stack>
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col className="d-flex align-items-center justify-content-center">
                                <Col md={5}>
                                    <Link to="/loan_application/business_info">
                                        <MainButton text="Get Stared"/>
                                    </Link>
                                </Col>
                            </Col>
                        </Row>
                    </Row>
            </Row>
        </Container>
    )

}