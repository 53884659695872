/*eslint-disable*/
import React from 'react';
import ControlledRadioButtonGroup from '@/components/forms/FormInput/ControlledRadioButton';
import GrantFormBaseLayout from '../../../GrantFormBaseLayout';
import { Row, Col } from 'react-bootstrap';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import { useGetFundingRequirementsForm } from '../../../services/FundingRequirements.service';
import { IGrantFormStage } from '@/views/GrantForm/GrantForm.interfaces';
const FundingRequirement = ({ isPreview, isPrint }: IGrantFormStage) => {
  const {
    watch,
    control,
    handleSubmit,
    formState,
    onSubmit,
    userData,
    isLoading,
  } = useGetFundingRequirementsForm();
  return (
    <GrantFormBaseLayout
      page={7}
      title={'Funding Requirement'}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      isLoading={isLoading}
      stage={'funding_requirements'}
      preview={isPreview}
      print={isPrint}
    >
      <form>
        <Row>
          <Col md={6} xs={12}>
            <ControlledTextInput
              control={control}
              title={'How much funding is required?'}
              name={'amount_of_funding_required'}
              placeholder="Funding Required"
              type="number"
              rules={{
                required:
                  !userData?.data?.profile?.grant_information
                    ?.amount_of_funding_required,
              }}
              defaultValue={
                userData?.data?.profile?.grant_information
                  ?.amount_of_funding_required
              }
              isDisabled={isPreview}
            />
          </Col>
          <Col md={6} xs={12}>
            <ControlledTextInput
              control={control}
              title={'How do you intend using the funds?'}
              name={'intention_for_funds'}
              placeholder="Intention with funds"
              defaultValue={
                userData?.data?.profile?.grant_information?.intention_for_funds
              }
              rules={{
                required:
                  !userData?.data?.profile?.grant_information
                    ?.intention_for_funds,
              }}
              as={'textarea'}
              isDisabled={isPreview}
            />
          </Col>
        </Row>
      </form>
    </GrantFormBaseLayout>
  );
};

export default FundingRequirement;
