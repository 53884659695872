/*eslint-disable*/
import React from 'react';
import GrantAccordionBase, { IconWrapper } from './GrantAccordionBase';
import { IActivityComponent } from '../GrantForm.interfaces';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import ControlledSelectInput from '@/components/forms/FormInput/ControlledSelectInput';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  yearsArray,
  monthsArray,
} from '../services/BusinessPlan/ProjectInformation.service';
import Edit from '@mui/icons-material/Edit';
import { Save } from '@mui/icons-material';
import AddButton from './AddButton';
import { Button } from '@mui/material';
import {
  AccordionActionButton,
  AccordionActionText,
} from './styledcomponents/BusinessPlanComponents';
import { defaultBlue } from '@/utils/constants/colors.constants';
import ActivitySaveButton from './TableInputSaveButton';
import TableInputSaveButton from './TableInputSaveButton';

const ActivityComponent = ({
  year,
  start_month,
  end_month,
  activity_name,
  detailed_activity_description,
  defaultOpen,
  id,
  isPreview,
  isPrint,
  onEdit,
  onDelete,
}: IActivityComponent) => {
  const { control,setValue, watch } = useForm();
  const [editState, setEditState] = React.useState(false);

  React.useEffect(()=>{
    // check if this is a valid operation to do first or changing needs to be done 
   
    if (watch('start_month')){
      let indexPos = monthsArray.findIndex((month)=>{
        return month.value == watch('start_month')
      });
    
        let finalIndex = (indexPos + 3)%12;
        setValue('end_month',monthsArray[finalIndex].value)
    }

  },[watch('start_month')])

  return (
    <GrantAccordionBase
      expandable={true}
      title={activity_name || ''}
      defaultOpen={defaultOpen || false}
      actions={['delete']}
      onDelete={() => {
        onDelete(id);
      }}
    >
      <Row>
        <Col md={12} xs={12} style={{ marginBottom: '15px' }}>
          {!isPreview && !isPrint && (
            <TableInputSaveButton
              isEditing={editState}
              setIsEditing={setEditState}
              onEdit={onEdit}
              params={{
                id: id,
                activity_name: watch('activity_name'),
                detailed_activity_description: watch(
                  'detailed_activity_description'
                ),
                start_month: watch('start_month') || start_month,
                end_month: watch('end_month') || end_month,
                year: watch('year') || year,
              }}
            />
          )}
        </Col>
        <Col md={12} xs={12}>
          <ControlledTextInput
            control={control}
            name={'activity_name'}
            title={'Activity Name'}
            placeholder="Activity Name"
            defaultValue={activity_name}
            isDisabled={!editState}
          />
        </Col>
        <Col md={12} xs={12}>
          <ControlledTextInput
            control={control}
            name={'detailed_activity_description'}
            title={'Detailed output and activity description'}
            placeholder="Detailed output and activity description"
            as={'textarea'}
            defaultValue={detailed_activity_description}
            isDisabled={!editState}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4} xs={12}>
          <ControlledSelectInput
            control={control}
            name={'year'}
            title={'Year'}
            placeholder="Activity Name"
            options={yearsArray}
            defaultValue={year}
            isDisabled={!editState}
          />
        </Col>
        <Col md={4} xs={12}>
          <ControlledSelectInput
            control={control}
            name={'start_month'}
            title={'Start Month'}
            options={monthsArray}
            defaultValue={start_month}
            isDisabled={!editState}
          />
        </Col>
        <Col md={4} xs={12}>
          <ControlledSelectInput
            control={control}
            name={'end_month'}
            title={'End Month'}
            placeholder="Activity Name"
            options={monthsArray}
            defaultValue={end_month}
            isDisabled={true}
          />
        </Col>
      </Row>
    </GrantAccordionBase>
  );
};

export default ActivityComponent;
