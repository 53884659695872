import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import propTypes from 'prop-types';
import axios from 'axios';
import constant from '@utils/constants/constants';
import { handleErrorMessage, messageAlert } from '@utils/helpers/functions';

const OTPLogin = ({ otp, handleChange, requestCode, phoneNumber }) => {
  const [requestLinkActive, setRequestLinkActive] = React.useState(false);
  React.useEffect(() => {
    start();
  }, []);

  // const [requestLinkActive, setRequestLinkActive] = useState(false)

  var seconds = 60;
  var timer;
  function myFunction() {
    if (seconds < 60) {
      var countDown = document.getElementById('count-down');
      if (countDown) {
        countDown.innerHTML = '0:' + seconds;
      }
    }
    if (seconds > 0) {
      seconds--;
    } else {
      clearInterval(timer);
      setRequestLinkActive(true);
    }
  }

  function start() {
    setRequestLinkActive(false);
    if (!timer) {
      timer = window.setInterval(function () {
        myFunction();
      }, 1000);
    }
  }

  async function handleRequestOTP(e) {
    e.preventDefault();
    if (requestLinkActive) {
      start();
      try {
        let data = {
          phone_number: phoneNumber,
          verification_type: 'phone_number',
          verification_action: 'login',
        };
        await axios.post(
          `${constant.BASE_API}/client/auth/login-verification-code`,
          data
        );
      } catch (e) {
        var erroMesaage = handleErrorMessage(e?.response);

        messageAlert('error', erroMesaage);
      }
    }
  }

  return (
    <Fragment>
      <div className="row mt-4 mb-4 text-center ms-2">
        <Col
          md={1}
          className="d-flex align-items-center fw-bold otp-col-start xs-fs-6"
        >
          GEA-
        </Col>
        <Col md={8} className="d-flex align-items-center">
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            separator={''}
            inputStyle={'otp-input text-center me-2 form-control'}
            isInputSecure={true}
            isInputNum={true}
          />
        </Col>
        <Col
          md={2}
          className="d-flex align-items-center float-end fw-bold otp-col-end xs-fs-6"
        >
          -XXXX
        </Col>
      </div>
      {requestCode && (
        <Row className="mb-3">
          <Col>
            <div className="text-center big-label">
              Forgot your password or did not receive code? Please
              <Link
                to="#"
                onClick={handleRequestOTP}
                className={`text-decoration-none ${
                  !requestLinkActive && 'disable-cursor'
                }`}
              >
                {' '}
                request{' '}
              </Link>
              for a new code in{' '}
              <b
                id="count-down"
                style={{ fontSize: '20px', marginTop: '10px' }}
              >
                01:00
              </b>
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

OTPLogin.propTypes = {
  otp: propTypes.string,
  handleChange: propTypes.func,
  requestCode: propTypes.func,
  phoneNumber: propTypes.number,
};
export default OTPLogin;
