/*eslint-disable*/

import React from 'react';
import GrantFormBaseLayout from '../../../GrantFormBaseLayout';
import { Row, Col } from 'react-bootstrap';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import { useGetRevenueGenerationForm2 } from '../../../services/RevenueGeneration.service';
import { IGrantFormStage } from '@/views/GrantForm/GrantForm.interfaces';

const RevenueGeneration2 = ({ isPreview, isPrint }: IGrantFormStage) => {
  const { watch, control, handleSubmit, formState, onSubmit, userData } =
    useGetRevenueGenerationForm2();
  return (
    <GrantFormBaseLayout
      page={6}
      title={'Revenue Generation'}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      stage={'revenue_generation_2'}
      preview={isPreview}
      print={isPrint}
    >
      <form>
        <Row>
          <Col md={12} xs={12}>
            <ControlledTextInput
              control={control}
              title={'What are the key assumptions for the projection?'}
              name={'key_assumptions_for_business'}
              as={'textarea'}
              placeholder="State the key assumptions"
              rules={{
                required:
                  !userData?.data?.profile?.grant_information
                    ?.key_assumptions_for_business,
              }}
              defaultValue={
                userData?.data?.profile?.grant_information
                  ?.key_assumptions_for_business
              }
              isDisabled={isPreview == true}
            />
          </Col>
        </Row>
      </form>
    </GrantFormBaseLayout>
  );
};

export default RevenueGeneration2;
