import React from "react";
import propTypes from "prop-types";

export default function CheckIcon({ show = true }) {
  return show ? <i className="fa fa-check-circle primary-text me-2"></i> : null;
}

CheckIcon.propTypes = {
  show: propTypes.bool,
};
