/*eslint-disable*/
import React from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import '../../../components/layout/navigation/style.css';
import propTypes from 'prop-types';
import { IOnboardProgress } from '../GrantForm.interfaces';
import { ProgressNumber } from './ProgressNumber';

const OnboardProgressBusinessPlan = ({ page }: IOnboardProgress) => {
  // const navigate = useNavigate();
  // React.useEffect(()=>{
  //     navigate('/deadline');
  // },[])

  return (
    <Col className="d-none d-lg-block">
      {/* <LogoDark type="large" className="px-5 w-75" /> */}
      <Row className="mt-1">
        <Stack className="mb-5  px-5 gap-5">
          <ProgressNumber
            number={1}
            description="Application Summary"
            active={true}
          />
          <ProgressNumber
            number={2}
            description="Applicant Information"
            active={page >= 2}
          />
          <ProgressNumber
            number={3}
            description="Project Information"
            active={page >= 3}
          />
          <ProgressNumber
            number={4}
            description="Grant Request"
            active={page >= 4}
          />
          <ProgressNumber
            number={5}
            description="Expected Result and Impact of Grant Support"
            active={page >= 5}
          />
          <ProgressNumber
            number={6}
            description="Sustainability and Risks"
            active={page >= 6}
          />
          <ProgressNumber number={7} description="Preview" active={page >= 7} />
          <ProgressNumber
            number={8}
            description="Disclaimer"
            active={page >= 8}
          />
          <ProgressNumber
            number={9}
            description="Signature"
            active={page >= 9}
          />
          <ProgressNumber
            number={10}
            description="Consent"
            active={page >= 10}
          />
        </Stack>
      </Row>
    </Col>
  );
};

OnboardProgressBusinessPlan.propTypes = {
  page: propTypes.number,
};

export default OnboardProgressBusinessPlan;
