import React from "react";
import { Nav, Container, Row, Col } from "react-bootstrap";
import BottomNavItem from "./BottomNavItem";
export default function MainBottomNav() {
  const style = {
    background: "#435264",
    paddingTop: "60px",
    paddingBottom: "20px",
    zIndex: "1",
  };
  return (
    <>
      <BottomRow />
      <section style={style} className="position-relative">
        <Container>
          <Row className="justify-content-center">
            <Col md={3} sm={6}>
              <Nav
                defaultActiveKey="/home"
                className="flex-column justify-content-center align-items-middle text-align-middle"
              >
                <BottomNavItem title="Head Office" isHeader={true} />
                <BottomNavItem title="4 Abdul Gamel Nasser Avenue, Ridge, Accra" />
                <BottomNavItem title="+233 030 274 7777 (Ghana)" />
                <BottomNavItem title="+233 (0) 50 060 7238" />
                <BottomNavItem title="info@gea.gov.gh" />
              </Nav>
            </Col>
            <Col md={3} sm={6}>
              <Nav defaultActiveKey="/home" className="flex-column">
                <BottomNavItem title="Quick Links" isHeader={true} />
                <BottomNavItem title="About" link="/about" />
                <BottomNavItem title="FAQs" link="faqs" />
                <BottomNavItem title="Terms and Conditions" link="/terms" />
              </Nav>
            </Col>
            <Col md={3} sm={6}>
              <Nav defaultActiveKey="/home" className="flex-column">
                <BottomNavItem
                  title="About Gea"
                  isHeader={true}
                  link="about-gea"
                />
              </Nav>
            </Col>
            <Col md={3} sm={6}>
              <Nav defaultActiveKey="/home" className="flex-column">
                <BottomNavItem title="Our Socials" isHeader={true} />
                <BottomNavItem
                  icon="fa fa-facebook"
                  title="Ghana Enterprises Agency"
                  link="https://www.facebook.com/ghanaenterprisesagency?_rdc=1&_rdr"
                />
                <BottomNavItem
                  icon="fa fa-instagram "
                  title="ghanaenterprisesagency"
                  link="https://www.instagram.com/ghanaenterprisesagency/"
                />
                <BottomNavItem
                  icon="fa fa-twitter"
                  title="gea_ghana"
                  link="https://twitter.com/gea_ghana"
                />
                <BottomNavItem
                  icon="fa fa-youtube"
                  title="Ghana Enterprises Agency"
                  link="https://www.youtube.com/channel/UCXlG7Cneamhe5l7aFjMfS_Q"
                />
              </Nav>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

function BottomRow() {
  const style = {
    backgroundColor: "#0E1A2F",
    color: "white",
    width: "80%",
    zIndex: "99999",
    marginBottom: "-36px",
    marginTop: "36px",
  };

  return (
    <div className="d-flex justify-content-end">
      <div style={style} className="p-4">
        © 2022 GEA. All Rights Reserved
      </div>
    </div>
  );
}
