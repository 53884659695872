
import React from "react";
import MainButton, { ProgressBarNumber } from "@components/buttons/Buttons";

import { Col, Container, Image, Row, Stack } from "react-bootstrap";
import propTypes from "prop-types";
import youstartlogoDark from "@images/logo/Dark_logo.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import constant from "@utils/constants/constants";



export default function LoanApplicationLayout({children, page}) {
    const navigate = useNavigate();
    React.useEffect(()=>{
        if (constant.SHOW_FORM == false){
            navigate('/deadline');
        }
    },[])
    
    return(
        <Container>
            <Row className="my-5">
                <Col>
                    <Image src={youstartlogoDark}/>
                </Col>
                <Col>
                    <div className="px-4 float-end fw-bold">
                        <Link to="/dashboard" className="text-decoration-none">
                            <MainButton text={'Save and Continue Later'} variant="outline-primary" size="md"/>
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row className="mx-5 my-1">
                <Row className="px-5">
                    <Col xs={12} md={3} xl={3} className="d-none d-lg-block">
                        <Row className="mt-1">
                            <Stack className="custom-stack">
                                <ProgressBarNumber number="1" text="Business Infomation" active={true}/>
                                <ProgressBarNumber number="2" text="Location Information" active={page >= '2' && true}/>
                                <ProgressBarNumber number="3" text="Financial Information" active={page >= '3' && true}/> 
                                <ProgressBarNumber number="4" text="NEIP/GEA Support History" active={page >= '4' && true}/> 
                                <ProgressBarNumber number="5" text="Guarantor Information" active={page >= '5' && true}/> 
                                <ProgressBarNumber number="6" text="Declaration" active={page >= '6' && true}/>                                
                            </Stack>
                        </Row>
                    </Col>
                    <Col xs={12} md={9} xl={9}>
                        {children}
                    </Col>
                </Row>
            </Row>
        </Container>
    )

}
LoanApplicationLayout.propTypes={
    page:propTypes.number,
    children: propTypes.node
}



