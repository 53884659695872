import React from "react";
import { Container, Row } from "react-bootstrap";
import AuthLeftNav from "./navigation/AuthLeftNav";
import propTypes from "prop-types"
import { ToastContainer } from "react-toastify";


const AuthLayout = ({ children }) =>{
   
    return(
        <Container fluid>
            <ToastContainer/>
            <Row>
                <AuthLeftNav/>
                { children }
            </Row>
        </Container>
    )
}
AuthLayout.propTypes = {
    children: propTypes.node,
  };


export default AuthLayout