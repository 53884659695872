/*eslint-disable*/

import { OptionType } from '@/utils/constants/globals.interfaces';
import {
  IImpactForm,
  IImpact2,
  IGrantUpdateParams,
  IUserUpdateParams,
} from '../GrantForm.interfaces';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { IUserModel } from '@/utils/constants/userModel.interface';
import { AxiosResponse } from 'axios';
import { logError, useGetUserObject } from './GrantFormBaseLayout.service';
import { updateFullUserProfile, updateGrantInformation } from '@/api/User/User';

export const useGetImpactForm = () => {
  const { control, watch, formState, handleSubmit } = useForm<IImpactForm>();
  const { userData, refetch, userError, userLoading } = useGetUserObject();
  const navigate = useNavigate();
  let _userData = userData as AxiosResponse<Partial<IUserModel>>;
  const onSubmit: SubmitHandler<IImpactForm> = async (data) => {
    try {
      let params: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };
      let userUpdateParams: IUserUpdateParams = {
        id: undefined,
        data: undefined,
      };
      params.id = _userData.data.profile?.grant_information?.id;
      params.data = {
        ..._userData?.data?.profile?.grant_information,
        ...data,
      };
      userUpdateParams.id = _userData?.data?.profile?.client;
      userUpdateParams.data = {
        grant_onboarding_data: {
          ..._userData?.data?.grant_onboarding_data,
          impact: true,
        },
      };
      userUpdateParams.data.id = _userData?.data?.profile?.client;
      await updateFullUserProfile(userUpdateParams.data);
      await updateGrantInformation(params);
      navigate('/grants/impact2');
    } catch (e) {
      logError(e);
    }
  };

  return {
    control,
    watch,
    formState,
    handleSubmit,
    onSubmit,
    userData: _userData,
    isLoading: userLoading,
  };
};

export const useGetImpactForm2 = () => {
  const { control, watch, formState, handleSubmit } = useForm<IImpact2>();
  const { userData, refetch, userError, userLoading } = useGetUserObject();
  const navigate = useNavigate();
  let _userData = userData as AxiosResponse<Partial<IUserModel>>;
  const onSubmit: SubmitHandler<IImpact2> = async (data) => {
    try {
      let params: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };
      let userUpdateParams: Partial<IUserUpdateParams> = {
        id: undefined,
        data: undefined,
      };
      params.id = _userData.data.profile?.grant_information?.id;
      params.data = {
        ..._userData?.data?.profile?.grant_information,
        has_succession_plan: data.has_succession_plan == 'Y' ? true : false,
        succession_plan: data.succession_plan,
      };

      // userUpdateParams.id = _userData?.data?.user?.id;
      userUpdateParams.data = {
        profile: {
          ..._userData?.data?.profile,
          is_grant_application_complete: true,
        },
        grant_onboarding_data: {
          ..._userData?.data?.grant_onboarding_data,
          impact: true,
        },
      };

      userUpdateParams.data.id = _userData?.data?.user?.id;

      await updateGrantInformation(params);
      await updateFullUserProfile(userUpdateParams.data);
      navigate('/grants/preview');
    } catch (e) {
      logError(e);
    }
  };

  return {
    control,
    watch,
    formState,
    handleSubmit,
    onSubmit,
    userData: _userData,
    isLoading: userLoading,
  };
};

export const successionPlanOptions: OptionType<string, string>[] = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];
