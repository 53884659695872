/*eslint-disable*/
import React from 'react';
import {
  geaTrainingOptions,
  grantManagementOptions,
  mentorshipOptions,
  pwdOptions,
  useGetPersonalInformation2,
} from '@views/GrantForm/services/PersonalInformation.service';
import GrantFormBaseLayout from '@views/GrantForm/GrantFormBaseLayout';
import { Row, Col } from 'react-bootstrap';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import ControlledRadioButtonGroup from '@/components/forms/FormInput/ControlledRadioButton';
import ControlledMultiSelect from '@/components/forms/FormInput/ControlledMultiSelect';
import { getMultiSelectDefault } from '@/views/GrantForm/services/GrantFormBaseLayout.service';
import { IGrantFormStage } from '@/views/GrantForm/GrantForm.interfaces';

const GrantsPersonalInformation2 = ({
  isPreview,
  isPrint,
}: IGrantFormStage) => {
  const { control, handleSubmit, onSubmit, watch, isLoading, userData } =
    useGetPersonalInformation2();

  return (
    <GrantFormBaseLayout
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      page={2}
      title={'Personal Information'}
      isLoading={isLoading}
      stage={'personal_information_2'}
      preview={isPreview}
      print={isPrint}
    >
      <Row>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name={'email'}
            title={'Email Address'}
            placeholder="Email Address"
            rules={{
              required: {
                message: 'First Name is Required',
                value: false,
              },
            }}
            defaultValue={userData?.data?.user?.email}
            isDisabled={true}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledRadioButtonGroup
            control={control}
            name={'owner_is_disabled'}
            title={'Are you a person with disability(PWD)?'}
            defaultValue={
              userData?.data?.profile?.grant_information?.applicant_information
                ?.owner_is_disabled == true
                ? 'Y'
                : 'N'
            }
            rules={{
              required:
                !userData?.data?.profile?.grant_information
                  ?.applicant_information?.owner_is_disabled,
            }}
            options={pwdOptions}
            isDisabled={isPreview == true}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <ControlledMultiSelect
            control={control}
            name={'trainings_participated_in'}
            title={
              'Indicate which of the GEA Entrepreneurship Training you have participated (Tick all that apply)'
            }
            defaultValue={getMultiSelectDefault(
              userData?.data?.profile?.grant_information
                ?.trainings_participated_in
            )}
            placeholder="Trainings participated in"
            rules={{
              required: {
                message: 'Other Name is Required',
                value:
                  userData?.data?.profile?.grant_information
                    ?.trainings_participated_in?.length > 0
                    ? false
                    : true,
              },
            }}
            isDisabled={isPreview == true}
            options={geaTrainingOptions}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledRadioButtonGroup
            control={control}
            name={'has_undergone_mentorship'}
            title={
              ' Have you undergone any mentorship session offered under the GJSP or offered by GEA? '
            }
            options={mentorshipOptions}
            rules={{
              required: {
                message: 'Last Name is Required',
                value:
                  !userData?.data?.profile?.grant_information
                    ?.has_undergone_mentorship,
              },
            }}
            defaultValue={
              userData?.data?.profile?.grant_information
                ?.has_undergone_mentorship == true
                ? 'Y'
                : 'N'
            }
            isDisabled={isPreview == true}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <ControlledRadioButtonGroup
            control={control}
            name={'has_participated_in_grant_management_program'}
            title={
              'Have you ever participated in any grant management programme?'
            }
            options={grantManagementOptions}
            defaultValue={
              userData?.data?.profile?.grant_information
                ?.has_participated_in_grant_management_program == true
                ? 'Y'
                : 'N'
            }
            rules={{ required: { message: 'Field is required', value: true } }}
            isDisabled={isPreview == true}
          />
        </Col>
      </Row>
    </GrantFormBaseLayout>
  );
};

export default GrantsPersonalInformation2;
