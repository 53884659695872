import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';

import './style.css';

import 'react-phone-number-input/style.css';
import MainButton from '@components/buttons/Buttons';
import { useMutation, useQuery } from 'react-query';
import {
  getUserProfile,
  updateUserProfileWithouthCallBack,
} from 'src/api/User/User';
import { Link, useNavigate } from 'react-router-dom';
import { TextField } from '..';
import { handleErrorMessage, messageAlert } from '@utils/helpers/functions';

export default function ApplicationTermsForm() {
  let navigate = useNavigate();
  const mutation = useMutation(updateUserProfileWithouthCallBack);
  const [formState, setFormState] = useState({});

  const {
    error,
    data: responseData,
    isLoading,
  } = useQuery('user-data-verifcation', getUserProfile);

  if (isLoading) return <h1>Loading...</h1>;

  if (error) return <h1>Error...</h1>;

  const userId = responseData.data.user.id;
  const onboardingState = responseData?.data?.onboarding_data;

  function submitData() {
    var onboarding_data = { ...onboardingState, application_terms: true };

    const updatedUser = {
      profile: {
        is_application_complete: true,
      },
      onboarding_data: onboarding_data,
    };

    mutation.mutate(
      { id: userId, updatedUser },
      {
        onSuccess: () => {
          navigate('/onboarding/success');
        },
        onError: (error) => {
          var errorMessage = handleErrorMessage(error?.response);
          messageAlert('error', errorMessage);
        },
      }
    );
  }

  const loading = mutation.isLoading;

  function handleChange({ target }) {
    if (
      [
        'disclaimer_1',
        'disclaimer_2',
        'disclaimer_3',
        'disclaimer_4',
        'disclaimer_5',
        'disclaimer_6',
      ].includes(target.name)
    ) {
      setFormState({ ...formState, [target.name]: target.checked });
    } else {
      setFormState({
        ...formState,
        [target.name]: target.value === 'true' ? true : false,
      });
    }
  }

  return (
    <Form>
      <Row className="d-flex  align-items-center h-100">
        <Col md={8}>
          <Row className="mt-3">
            <TextField
              text={'Disclaimer and Signature'}
              className={'fs-5 fw-bold mb-4 text-center'}
            />
            <p>
              <Form.Check
                className="custom-checkbox-label"
                value={true}
                required
                inline
                label="I understand that this is not a financial support program or a grant/lending program"
                name="disclaimer_1"
                type="checkbox"
                onChange={handleChange}
                id={`inline-radio-1`}
              />
            </p>
            <p>
              <Form.Check
                className="custom-checkbox-label"
                value={true}
                required
                inline
                label="I certify that my answers are true and complete to the best of my knowledge"
                name="disclaimer_2"
                type="checkbox"
                onChange={handleChange}
                id={`inline-radio-1`}
              />
            </p>
            <p>
              <Form.Check
                className="custom-checkbox-label"
                value={true}
                required
                inline
                label="I understand that any false or misleading information, documents or submissions in my application may result in criminal prosecution for fraud"
                name="disclaimer_3"
                type="checkbox"
                onChange={handleChange}
                id={`inline-radio-1`}
              />
            </p>
            <p>
              <Form.Check
                className="custom-checkbox-label"
                value={true}
                required
                inline
                label="I understand that I may not be selected for this program and that only shortlisted applicants will be contacted"
                name="disclaimer_4"
                type="checkbox"
                onChange={handleChange}
                id={`inline-radio-1`}
              />
            </p>
            {/* <p>
            <Form.Check
                value={true}
                required
                inline
                label="I understand that I may be disqualified without a reason"
                name="disclaimer_5"
                type="checkbox"
                onChange={handleChange}
                id={`inline-radio-1`}
              />
            
            </p> */}
            <p>
              <Form.Check
                className="custom-checkbox-label"
                value={true}
                required
                inline
                label="I understand that by submitting this application, I give the Ghana Enterprises Agency and the Ghana Jobs and Skills Project permission to:"
                name="disclaimer_6"
                type="checkbox"
                onChange={handleChange}
                id={`inline-radio-1`}
              />
            </p>
            <p>
              <ul>
                <li className="custom-checkbox-label">
                  Validate and verify my identity documents with the specified
                  issuing agency
                </li>
              </ul>
            </p>
            <p>
              <ul>
                <li className="custom-checkbox-label">
                  Contact me at any time during and after the processing of this
                  application using the information I have submitted in this
                  application.
                </li>
              </ul>
            </p>
            <p>
              <Form.Check
                className="custom-checkbox-label"
                value={true}
                required
                inline
                label="Should this application be approved, I agree to be held accountable to project goals, receive and accept feedback and carry out the implementation or act on suggestions"
                name="disclaimer_7"
                type="checkbox"
                onChange={handleChange}
                id={`inline-radio-1`}
              />
            </p>
            <p>
              <Form.Check
                className="custom-checkbox-label"
                value={true}
                required
                inline
                label="I commit to be present at all training programmes as an obligation"
                name="disclaimer_8"
                type="checkbox"
                onChange={handleChange}
                id={`inline-radio-1`}
              />
            </p>
          </Row>
          <Row className="mb-5">
            <Form.Group as={Col} md="12">
              <Row>
                <Col>
                  <Form.Check
                    className="custom-checkbox-label"
                    value={true}
                    required
                    inline
                    label="I hereby declare that I understand the contents of this document before submitting."
                    name="final_disclaimer"
                    type="checkbox"
                    onChange={handleChange}
                    id={`inline-radio-1`}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Row>

          <Row className="mt-3 mb-5">
            <Col md={4} className="mb-3">
              <Link
                to="/onboarding/verification"
                className="text-decoration-none"
              >
                <MainButton
                  text={'Previous'}
                  type={'button'}
                  variant="outline-secondary"
                  size="lg"
                  className="fs-6"
                />
              </Link>
            </Col>
            <Col md={{ span: 4, offset: 4 }}>
              <MainButton
                text={'Submit'}
                type={'button'}
                variant="primary"
                size="lg"
                className="fs-6"
                onClick={submitData}
                isLoading={loading}
                isDisabled={
                  !(
                    formState.final_disclaimer &&
                    formState.disclaimer_1 &&
                    formState.disclaimer_2 &&
                    formState.disclaimer_3 &&
                    formState.disclaimer_4 &&
                    // formState.disclaimer_5 &&
                    formState.disclaimer_6 &&
                    formState.disclaimer_7 &&
                    formState.disclaimer_8
                  )
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
