import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import OnboardLayout from "@components/layout/OnboardLayout";
import { LogoDark } from "@components/utils";
import successImage from '@images/logo/Success.svg'
import { TextField } from "@components/forms";
import MainButton from "@components/buttons/Buttons";
import { Link } from "react-router-dom";


const SignUpSuccess = () =>{
    
    return(
        <OnboardLayout>
            <Row>
                <Col md={12}>
                    <LogoDark className="py-5 px-5"/>
                </Col>
                <Col md={12} className="mt-5">
                    <div className="d-flex justify-content-center align-items-center h-100 text-center">
                        <Row >
                            <Col md={12}><Image src={successImage}/></Col>
                            <Col md={12}>
                                <TextField text={'Your account has been successfully created'} className={'fs-6 fw-bold mb-4 mt-5'}/>
                                <TextField text={'You can now move on to apply for the Jobs and Skills Programme'} className={'mb-5'}/>
                            </Col>
                            <Col md={12} className="d-flex justify-content-center align-items-center">
                                <Col md={6}>
                                    <Link to="/loan_application">
                                        <MainButton text="Apply For The Jobs and Skills Programme" className="text-center"/>
                                    </Link>
                                </Col>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </OnboardLayout>
    )
}

export default SignUpSuccess