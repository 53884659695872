
import React from "react";
import LoanApplicationLayout from "@components/layout/layouts/LoanApplicationLayout/LoanApplicationLayout";
import BusinessFirstGuarantorForm from "@components/forms/Loan/BusinessFirstGuarantorForm";


export default function BusinessFirstGuarantor() {
    
    return(
        <LoanApplicationLayout page={5}>
            <BusinessFirstGuarantorForm/>
        </LoanApplicationLayout>              
    )

}


