
import React from "react";
import BusinessLocationForm from "@components/forms/Loan/BusinessLocationForm";
import LoanApplicationLayout from "@components/layout/layouts/LoanApplicationLayout/LoanApplicationLayout";


export default function BusinessLocation() {
    
    return(
        <LoanApplicationLayout page={2}>
            <BusinessLocationForm/>
        </LoanApplicationLayout>              
    )

}


