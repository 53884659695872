/*eslint-disable*/

import { IUserModel } from '@/utils/constants/userModel.interface';
import { AxiosResponse } from 'axios';
import {
  IFundingRequirements,
  IGrantUpdateParams,
  IUserUpdateParams,
} from '../GrantForm.interfaces';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useGetUserObject, logError } from './GrantFormBaseLayout.service';
import { updateGrantInformation, updateFullUserProfile } from '@/api/User/User';

export const useGetFundingRequirementsForm = () => {
  const { control, watch, formState, handleSubmit } =
    useForm<IFundingRequirements>();
  const navigate = useNavigate();
  const { userData, userError, userLoading } = useGetUserObject();
  const _userData = userData as AxiosResponse<IUserModel>;

  const onSubmit: SubmitHandler<IFundingRequirements> = async (data) => {
    try {
      let grantParams: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };

      let userUpdateParams: IUserUpdateParams = {
        id: undefined,
        data: undefined,
      };

      grantParams.id = _userData?.data?.profile?.grant_information?.id;
      grantParams.data = {
        ..._userData?.data?.profile?.grant_information,
        ...data,
      };

      userUpdateParams.id = _userData?.data?.profile?.client;
      userUpdateParams.data = {
        grant_onboarding_data: {
          ..._userData?.data?.grant_onboarding_data,
          funding_requirements: true,
        },
      };
      userUpdateParams.data.id = _userData?.data?.profile?.client;
      await updateFullUserProfile(userUpdateParams.data);
      await updateGrantInformation(grantParams);
      navigate('/grants/impact');
    } catch (e) {
      logError(e);
    }
  };

  return {
    control,
    watch,
    formState,
    handleSubmit,
    onSubmit,
    userData: _userData,
    isLoading: userLoading,
  };
};
