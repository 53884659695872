/*eslint-disable*/
import React from 'react';
import ControlledRadioButtonGroup from '@/components/forms/FormInput/ControlledRadioButton';
import { useGetProductInformationForm2 } from '../../../services/ProductInformation.service';
import GrantFormBaseLayout from '../../../GrantFormBaseLayout';
import { Row, Col } from 'react-bootstrap';
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import { useGetProductMarketingForm } from '../../../services/ProductMarketing.service';
import { IGrantFormStage } from '@/views/GrantForm/GrantForm.interfaces';
const ProductMarketing = ({ isPreview, isPrint }: IGrantFormStage) => {
  const { control, handleSubmit, onSubmit, userData, isLoading } =
    useGetProductMarketingForm();
  return (
    <GrantFormBaseLayout
      page={5}
      title={'Products/Services Marketing'}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      stage={'product_marketing'}
      preview={isPreview}
      print={isPrint}
    >
      <form>
        <Row>
          <Col md={6} xs={12}>
            <ControlledTextInput
              control={control}
              title={'Who are the customers of the products and services?'}
              name={'who_are_the_customers'}
              placeholder={'Who are the customers'}
              defaultValue={
                userData?.data?.profile?.grant_information
                  ?.who_are_the_customers
              }
              rules={{
                required:
                  !userData?.data?.profile?.grant_information
                    ?.who_are_the_customers,
              }}
              isDisabled={isPreview == true}
            />
          </Col>
          <Col md={6} xs={12}>
            <ControlledTextInput
              control={control}
              title={'Who are your immediate target markets?'}
              name={'immediate_target_market'}
              placeholder="Who are your immediate target markets"
              defaultValue={
                userData?.data?.profile?.grant_information
                  ?.immediate_target_market
              }
              rules={{
                required:
                  !userData?.data?.profile?.grant_information
                    ?.immediate_target_market,
              }}
              isDisabled={isPreview == true}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <ControlledTextInput
              control={control}
              title={' Who are the key competitors in the market?'}
              placeholder="Who are your key competitors"
              name={'key_competitors'}
              defaultValue={
                userData?.data?.profile?.grant_information?.key_competitors
              }
              rules={{
                required:
                  !userData?.data?.profile?.grant_information?.key_competitors,
              }}
              isDisabled={isPreview == true}
            />
          </Col>
        </Row>
      </form>
    </GrantFormBaseLayout>
  );
};

export default ProductMarketing;
