import MainButton from "@components/buttons/Buttons";
import Logo from "@components/utils";
import React, { Fragment, useState } from "react";
import { Col } from "react-bootstrap";
import ModalDiv from "@components/modals/Modal";
import { Link } from "react-router-dom";

export default function OnboardTopNav() {
    const [cancelSignupModal, setCancelSignupModal] = useState(false)
   
    const subTitle = 'Are you sure you want to save and close? You will continue from this page when you login later';

    return(
        <Fragment>
            <Col>
                <Logo type="large"/>
            </Col>
            <Col>
                <div className="float-end fw-bold mb-5 py-4">  
                    <MainButton text={'Save and close'} variant="outline-primary" size="lg" onClick={() => setCancelSignupModal(true)} className="fs-6 px-5" />
                </div>
            </Col>
            <ModalDiv show={cancelSignupModal} 
                onHide={() => setCancelSignupModal(false)} title={'Cancel Process'} 
                subTitle={subTitle}
                actionButton={<Link to="/login" className="text-decoration-none"><MainButton text={'Continue'}/></Link>}/>
        </Fragment>
    )
}