/*eslint-disable */
import { IUserModel } from '@/utils/constants/userModel.interface';
import { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { logError, useGetUserObject } from '../GrantFormBaseLayout.service';
import { IGrantUpdateParams } from '../../GrantForm.interfaces';
import { updateGrantInformation } from '@/api/User/User';

export const useGetSustainabilty = () => {
  const { control, watch, handleSubmit, formState } = useForm();
  const navigate = useNavigate();
  const { userData, userError, userLoading, refetch } = useGetUserObject();
  const _userData = userData as AxiosResponse<Partial<IUserModel>>;
  const onSubmit = async (data: any) => {
    try {
      let grantParams: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };
      grantParams.id = _userData?.data?.profile?.grant_information?.id;
      grantParams.data = {
        ..._userData?.data?.profile?.grant_information,
        ...data,
        received_support_from_bac:
          data.received_support_from_bac == 'Y' ? true : false,
      };
      await updateGrantInformation(grantParams);
      navigate('/grants/businessplan/preview');
    } catch (e) {
      logError(e);
    }
  };
  return {
    control,
    watch,
    handleSubmit,
    formState,
    onSubmit,
    userData: _userData,
    isLoading: userLoading,
  };
};
