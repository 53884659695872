import React from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Button,Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Deadline = () => {

  const navigate = useNavigate();
  const containerStyle = {
    // width:'100%',
    height:'100vh',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'column',
    padding:'1rem',
  }

  const messageFontStyle = {
    fontSize:'1.1rem',
    textAlign:'center',
    maxWidth:'500px',
    lineHeight:'1.5rem'
  }

  const returnToHome = () => {
    navigate('/home');
  }

  return (
    <Box style={containerStyle}>
        <Box>
            <ReportProblemIcon style={{fontSize:'8rem',color:'red'}}/>
        </Box>
        <Box marginY={'1.2rem'} style={messageFontStyle}>
            Thank you for your interest in the Jobs And Skills initiative. Unfortunately, the application deadline has passed and we are no longer accepting new applications. We encourage you to keep an eye on our website or follow us on social media for updates on future opportunities.
            Thank you for your understanding.
        </Box>
        <Box>
            <Button onClick={returnToHome} style={{textTransform:'initial',fontSize:'1rem',backgroundColor:'#2a3b8f',color:'white',fontWeight:'400'}}>Return to Homepage</Button>
        </Box>

    </Box>
  )
}

export default Deadline;