/*eslint-disable */
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { updateGrantInformation } from '@/api/User/User';
import {
  logError,
  useGetRegions,
  useGetUserObject,
} from '../GrantFormBaseLayout.service';
import { AxiosResponse } from 'axios';
import {
  IGrantFormModel,
  IUserModel,
} from '@/utils/constants/userModel.interface';
import React from 'react';
import {
  IActivityEditParams,
  IActivityForm,
  IGrantUpdateParams,
  IRevenueGeneration,
} from '../../GrantForm.interfaces';
import { getIndustries, messageAlert } from '@/utils/helpers/functions';
import { OptionType } from '@/utils/constants/globals.interfaces';

export const useGetProjectInformation = () => {
  const { control, watch: mainFormWatch, handleSubmit } = useForm();
  const {
    control: activityControl,
    watch: activityWatch,
    handleSubmit: activityHandleSubmit,
    setValue: activitySetValue,
    reset,
  } = useForm();
  const {
    control: subActivityControl,
    watch: subActivityWatch,
    handleSubmit: subActivityHandleSubmit,
  } = useForm();
  const { userData, refetch, isRefetching, userError, userLoading } =
    useGetUserObject();
  let _userData = userData as AxiosResponse<Partial<IUserModel>>;
  const [subActivityList, setSubActivityList] = React.useState<any>([]);
  const [activityLoader, setActivityLoader] = React.useState(false);
  const navigate = useNavigate();
  const onSubmitPage = async (data: any) => {
    try {
      let params: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };
      params.id = _userData?.data?.profile?.grant_information?.id;
      params.data = {
        ..._userData?.data?.profile?.grant_information,
        additional_marketing_information:
          data?.additional_marketing_information,
      };
      if (
        _userData?.data?.profile?.grant_information?.activities?.length != 0
      ) {
        await updateGrantInformation(params);
        navigate('/grants/businessplan/project_information_2');
      } else {
        messageAlert(
          'error',
          'Please Create At least one Activity before continuing'
        );
      }
    } catch (e) {
      logError(e);
    }
  };
  const onSubmitActivity: SubmitHandler<Partial<IActivityForm>> = async (
    data: Partial<IActivityForm>
  ) => {
    setActivityLoader(true);
    try {
      let params: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };
      params.id = _userData?.data?.profile?.grant_information?.id;
      params.data = {
        ..._userData?.data?.profile?.grant_information,
        activities: [
          ...(_userData?.data?.profile?.grant_information?.activities || []),
          {
            activity_name: data.activity_name,
            detailed_activity_description: data.detailed_activity_description,
            end_month: parseInt(data?.end_month || '1'),
            start_month: parseInt(data?.start_month || '1'),
            year: parseInt(data?.year || '2023'),
          },
        ],
      };
      await updateGrantInformation(params);
      messageAlert('success', 'Activity Created');
      refetch();
      reset({ year: '', start_month: '', end_month: '' });
    } catch (e) {
      logError(e);
    }
    setActivityLoader(false);
  };
  const onSubmitSubActivity = (data: any) => {};

  const onDelete = async (id: number | string | undefined) => {
    setActivityLoader(true);
    try {
      let grantParams: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };
      grantParams.id = _userData?.data?.profile?.grant_information?.id;
      let activityArray =
        _userData?.data?.profile?.grant_information?.activities?.filter(
          (activity) => {
            return activity.id !== id;
          }
        );
      grantParams.data = {
        ..._userData?.data?.profile?.grant_information,
        activities: activityArray,
      };

      await updateGrantInformation(grantParams);
      messageAlert('success', 'Acitivity Deleted');
      refetch();
    } catch (e) {
      logError(e);
    }
    setActivityLoader(false);
  };

  const onEditActivity = async (props: IActivityEditParams) => {
    setActivityLoader(true);
    try {
      let activityArray = [
        ..._userData?.data?.profile?.grant_information?.activities,
      ];

      const editIndex = activityArray.findIndex((activity) => {
        return activity.id === props.id;
      });

      if (editIndex !== -1) {
        activityArray[editIndex] = { ...props };
      }

      let grantParams: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };
      grantParams.id = _userData?.data?.profile?.grant_information?.id;
      grantParams.data = {
        ..._userData?.data?.profile?.grant_information,
        activities: activityArray,
      };
      await updateGrantInformation(grantParams);
      messageAlert('success', 'Activity Edited');
    } catch (e) {
      logError(e);
    }
    setActivityLoader(false);
    refetch();
  };

  const moveToNext = () => {
    if (_userData?.data?.profile?.grant_information?.activities?.length != 0) {
      navigate('/grants/businessplan/project_information_2');
    }
    messageAlert(
      'error',
      'Please Create At least one Activity before continuing'
    );
  };

  const loadDisplayTableRows = (
    rows: IUserModel['profile']['grant_information']['activities']
  ) => {
    let rowData: string[][] = [];
    rows?.forEach((row) => {
      rowData.push([
        row.activity_name,
        row.detailed_activity_description,
        monthsArray.find((value) => {
          return value.value == row.start_month.toString();
        }).label,
        monthsArray.find((value) => {
          return value.value == row.end_month.toString();
        }).label,
        row.year.toString(),
      ]);
    });
    return rowData;
  };

  React.useEffect(() => {
    // check if this is a valid operation to do first or changing needs to be done

    if (activityWatch('start_month')) {
      let indexPos = monthsArray.findIndex((month) => {
        return month.value == activityWatch('start_month');
      });

      let finalIndex = (indexPos + 3) % 12;
      activitySetValue('end_month', monthsArray[finalIndex].value);
    }
  }, [activityWatch('start_month')]);

  // React.useEffect(()=>{
  //   if (activityWatch('end_month')){
  //     let indexPos = monthsArray.findIndex((month)=>{
  //       return month.value == activityWatch('end_month')
  //     });

  //       let finalIndex = Math.abs((indexPos - 3))%12;
  //       activitySetValue('start_month',monthsArray[finalIndex].value)

  //     // else {
  //     //   let remainder = indexPos - 3;
  //     //   if (remainder<0){
  //     //     activitySetValue('start_month',monthsArray[11-Math.abs(remainder)].value)
  //     //   }
  //     // }

  //   }

  // },[activityWatch('end_month')])

  return {
    activityControl,
    control,
    isRefetching,
    activityLoader,
    subActivityControl,
    subActivityList,
    userLoading,
    userData: _userData,
    mainFormWatch,
    onSubmitPage,
    activityWatch,
    activityHandleSubmit,
    onEditActivity,
    handleSubmit,
    loadDisplayTableRows,
    subActivityWatch,
    subActivityHandleSubmit,
    onSubmitActivity,
    onSubmitSubActivity,
    moveToNext,
    setSubActivityList,

    onDelete,
  };
};

/**
 * 
 * @returns 
 * 
 * 
 * 
hook for project information 2
 */

export const useGetProjectInformation2 = () => {
  const { control, watch, handleSubmit } = useForm();
  const { userData, refetch, userError, userLoading } = useGetUserObject();
  const navigate = useNavigate();
  let _userData = userData as AxiosResponse<Partial<IUserModel>>;
  let grantUpdateParams: IGrantUpdateParams = {
    id: undefined,
    data: undefined,
  };
  const onSubmit = async (
    data: Partial<IGrantFormModel['applicant_information']>
  ) => {
    try {
      grantUpdateParams.id = _userData.data.profile?.grant_information?.id;
      grantUpdateParams.data = {
        ..._userData?.data?.profile?.grant_information,
        applicant_information: {
          ..._userData?.data?.profile?.grant_information?.applicant_information,
          ...data,
        },
      };
      await updateGrantInformation(grantUpdateParams);
      navigate('/grants/businessplan/project_information_3');
    } catch (e) {
      logError(e);
    }
  };
  return {
    control,
    watch,
    handleSubmit,
    onSubmit,
    userData: _userData,
    isLoading: userLoading,
  };
};

/**
 * 
 * @returns 
 * 
 * 
 * 
 * 
 * 
hook for project information 3
 */

export const useGetProjectInformation3 = () => {
  const { control, watch, handleSubmit } = useForm();
  const { userData, refetch, userError, userLoading } = useGetUserObject();
  const navigate = useNavigate();
  let _userData = userData as AxiosResponse<Partial<IUserModel>>;
  let grantUpdateParams: IGrantUpdateParams = {
    id: undefined,
    data: undefined,
  };
  const onSubmit = async (
    data: Partial<IGrantFormModel['applicant_information']>
  ) => {
    try {
      grantUpdateParams.id = _userData.data.profile?.grant_information?.id;
      grantUpdateParams.data = {
        ..._userData?.data?.profile?.grant_information,
        applicant_information: {
          ..._userData?.data?.profile?.grant_information?.applicant_information,
          ...data,
          has_business_benefited_from_any_grant:
            (data?.has_business_benefited_from_any_grant as unknown as string) ==
            'Y'
              ? true
              : false,
        },
      };
      await updateGrantInformation(grantUpdateParams);
      navigate(
        _userData?.data?.skipped_advanced_training == false
          ? '/grants/businessplan/project_information_4'
          : '/grants/businessplan/grant_request'
      );
    } catch (e) {
      logError(e);
    }
  };
  return {
    control,
    watch,
    handleSubmit,
    onSubmit,
    userData: _userData,
    isLoading: userLoading,
  };
};

/**
 * 
 * @returns 
 * 
 * 
 * 
 * 
 * 
hook for project information 4
 */

export const useGetProjectInformation4 = () => {
  const { control, watch, handleSubmit } = useForm();
  const { userData, refetch, userError, userLoading } = useGetUserObject();
  const navigate = useNavigate();
  let _userData = userData as AxiosResponse<Partial<IUserModel>>;
  let grantUpdateParams: IGrantUpdateParams = {
    id: undefined,
    data: undefined,
  };
  const onSubmit = async (
    data: Partial<IGrantFormModel> & Partial<IRevenueGeneration>
  ) => {
    try {
      grantUpdateParams.id = _userData.data.profile?.grant_information?.id;
      let _financial_projections: IGrantFormModel['financial_projections'] = [
        {
          year: 'year_one',
          projected_gross_profit: data['fiscalOneGrossProfit'],
          projected_net_cash_flow: data['fiscalOneNetCashFlow'],
          projected_operating_cost: data['fiscalOneOperatingCost'],
          projected_turnover: data['fiscalOneTurnover'],
        },
        {
          year: 'year_two',
          projected_gross_profit: data['fiscalTwoGrossProfit'],
          projected_net_cash_flow: data['fiscalTwoNetCashFlow'],
          projected_operating_cost: data['fiscalTwoOperatingCost'],
          projected_turnover: data['fiscalTwoTurnover'],
        },
        {
          year: 'year_three',
          projected_gross_profit: data['fiscalThreeGrossProfit'],
          projected_net_cash_flow: data['fiscalThreeNetCashFlow'],
          projected_operating_cost: data['fiscalThreeOperatingCost'],
          projected_turnover: data['fiscalThreeTurnover'],
        },

        // {
        //   year: 'year_four',
        //   projected_gross_profit: data['fiscalFourGrossProfit'],
        //   projected_net_cash_flow: data['fiscalFourNetCashFlow'],
        //   projected_operating_cost: data['fiscalFourOperatingCost'],
        //   projected_turnover: data['fiscalFourTurnover'],
        // },
        // {
        //   year: 'year_five',
        //   projected_gross_profit: data['fiscalFiveGrossProfit'],
        //   projected_net_cash_flow: data['fiscalFiveNetCashFlow'],
        //   projected_operating_cost: data['fiscalFiveOperatingCost'],
        //   projected_turnover: data['fiscalFiveTurnover'],
        // },
      ];
      grantUpdateParams.data = {
        ..._userData?.data?.profile?.grant_information,
        key_assumptions_for_business: data.key_assumptions_for_business,
        financial_projections: _financial_projections,
      };
      await updateGrantInformation(grantUpdateParams);
      navigate('/grants/businessplan/grant_request');
    } catch (e) {
      logError(e);
    }
  };

  const loadTableData = (
    rowData: IUserModel['profile']['grant_information']['financial_projections']
  ) => {
    try {
      let rows: any[][] = [];
      let projected_turnover_list = ['Projected Sales/Turnover'];
      let projected_operating_cost_list = ['Projected Operating Cost'];
      let projected_net_profit_list = ['Projected Net Profit'];

      rowData?.forEach((row) => {
        projected_turnover_list.push(row.projected_turnover);
        projected_operating_cost_list.push(row.projected_operating_cost);
        projected_net_profit_list.push(
          (
            parseInt(row.projected_turnover) -
            parseInt(row.projected_operating_cost)
          ).toString()
        );
      });

      rows.push(projected_turnover_list);
      rows.push(projected_operating_cost_list);
      rows.push(projected_net_profit_list);

      return rows;
    } catch (e) {
      return [];
    }
  };
  return {
    control,
    userData: _userData,
    isLoading: userLoading,
    watch,
    handleSubmit,
    onSubmit,
    loadTableData,
  };
};

export const monthsArray: OptionType<string, string>[] = [
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

export const yearsArray: OptionType<string, string>[] = [
  { label: '2023', value: '2023' },
  { label: '2024', value: '2024' },
  { label: '2025', value: '2025' },
  { label: '2026', value: '2026' },
  { label: '2027', value: '2027' },
  { label: '2028', value: '2028' },
  { label: '2029', value: '2029' },
  { label: '2030', value: '2030' },
  { label: '2031', value: '2031' },
  { label: '2032', value: '2032' },
  { label: '2033', value: '2033' },
  { label: '2034', value: '2034' },
  { label: '2035', value: '2035' },
];

export const ActivityTableColumns = [
  'Activity',
  'Detailed Output and Activity Description',
  'Start Month',
  'End Month',
];

export const FiscalProjectionTableColumns = [
  'Projection',
  'Year 1',
  'Year 2',
  'Year 3',
];
