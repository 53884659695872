import React from "react";
import propTypes from "prop-types";
import { NavLink } from "react-router-dom";
// import constants from "@utils/constants/constants";
export default function MainSidebarNav({ title, link, icon }) {

  const [_isActive,setIsActive] = React.useState(false);
  return (
    <NavLink
      end
      style={({ isActive }) => {
        setIsActive(isActive);
        return {
          display: "inline-block",
          width: "100%",
          paddingTop: "8px",
          paddingBottom: "8px",
          paddingStart: "12px",
          paddingEnd: "10px",
          textDecoration: "none",
          textAlign: "start",
          fontSize: "15px",
          margin: "8px 0px 0px 0px",
          borderRadius: isActive ? "0px 50px 50px 0px" : "0px",
          color: isActive ? `white` : "#FFF",
          backgroundColor: isActive ? "#F2F2F2" : "",
        };
      }}
      to={link}
    >
      <span className="ps-md-3 text-nowrap  text-start ">
        <span style={{color:_isActive?'#2a3b8f':'white'}}>
          <i className={`fa ${icon}  me-2 `}></i>
          {title}
        </span>
      </span>
    </NavLink>
  );
}

MainSidebarNav.propTypes = {
  title: propTypes.string.isRequired,
  img_url: propTypes.string,
  alt: propTypes.string,
  link: propTypes.string,
  icon: propTypes.string,
};
