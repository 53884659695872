import { GeaLogo } from "@components/utils";
import React from "react";
import { Nav, Stack } from "react-bootstrap";
import MainSidebarNav from "./MainSidebarNav";
import propTypes from "prop-types";
import { logOut } from "src/api/Auth/Auth";
import constants from "@utils/constants/constants";

export default function MainSidebar() {
  const style = {
    background: `${constants.DEFAULT_COLOR}`,
    paddingRight: "16px",
    zIndex: "10",
    top: "0",
    cursor: "pointer",
    width: "100%",
  };

  return (
    <Nav
      style={style}
      className="flex-column  justify-content-between position-sticky vh-100"
    >
      <div className="py-md-3">
        <div className="my-2 mx-xl-4 mx-md-2">
          <GeaLogo />
        </div>

        <div className="mt-5">
          <MainSidebarNav icon={"fa-home"} title={"Home"} link={"/dashboard"} />
          {/* <MainSidebarNav
            icon="fa-pencil-square-o"
            title={"Grant Payment"}
            link={"/dashboard/grant_payment"}
          /> */}
          <MainSidebarNav
            icon="fa-line-chart"
            title={"Training"}
            link={"/dashboard/training"}
          />
          <MainSidebarNav
            icon="fa-user"
            title={"Profile"}
            link={"/dashboard/profile"}
          />
        </div>
      </div>

      <div className="mb-4 position-sticky mx-1">
        <Stack gap={2}>
          <div>
            <InfoItem info="Contact us" />
            <InfoItem icon="fa fa-phone" info="+233 (0) 36 219 6909" />
            <InfoItem
              icon="fa-send-o"
              info="info@gea.gov.gh"
              link="info@gea.gov.gh"
            />

            <hr className="white-text" />
            <InfoItem icon="fa-cog" onclick={() =>  window.location.replace('/dashboard/profile') } info="Account Setting" />
            <InfoItem
              icon="fa-solid fa-sign-out"
              info="Logout"
              onclick={() => logOut()}
            />
          </div>
        </Stack>
      </div>
    </Nav>
  );
}

function InfoItem({ icon, info, onclick, link }) {
  const style = {
    color: "white",
    paddingStart: "12px",
    paddingEnd: "10px",
    textDecoration: "none",
    textAlign: "start",
    fontSize: "15px",
    maxWidth: "100%",
    margin: '8px 0px 0px 0px', 
    marginTop:'1rem',
    marginBottom:'1rem'
  };

  return (
    <div
      style={style}
      className="ps-md-3 text-start my-3 "
      onClick={onclick ? onclick : null}
    >
      <span style={{color:'white'}}>
        <i className={`fa ${icon}  me-2 white-text fs-6`}></i>
      </span>
      {link ? (
        <a style={style} href={`mailto: ${link}`}>
          <span style={{color:'white'}}>{info}</span>
        </a>
      ) : (
        <span className="user-select-none">{info}</span>
      )}
    </div>
  );
}

InfoItem.propTypes = {
  icon: propTypes.string,
  info: propTypes.string,
  onclick: propTypes.func,
  link: propTypes.string,
};
