import React from "react";
import { Card } from "react-bootstrap";
import training_thumbnail from "@images/bg/jas_hero_3.jpg";
import BlogBg from '@images/BlogBg.jpg';
import propTypes from 'prop-types';

export default function BlogPostCard({defaultImage}) {
  const cardStyle = {
    borderRadius: "10px 10px 0px 0px",
    height: "318px",
    width: "100%",
  };

  const imageStyle = {
    width: "100%",
    height: "300px",
    backgroundImage: `linear-gradient(rgba(12, 12, 12, 0.397), rgba(12, 12, 12, 0.479)), url(
   ${defaultImage?training_thumbnail:BlogBg}
    )`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: "10px 10px 0px 0px",
  };
  return (
    <>
      <Card style={cardStyle}>
        <div className="vh-25" style={imageStyle} variant="top"></div>
        {
          defaultImage && 
          <Card.Body>
          <h5 className="mb-3">What is the Program</h5>
          <span> 2 mins read </span>
        </Card.Body>

        }
        
      </Card>
      <br />
    </>
  );
}

BlogPostCard.propTypes = {
  defaultImage:propTypes.bool
}


