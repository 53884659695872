import HeroSection from "@components/layout/layouts/StaticPagesBaseLayout/HeroSection";
import React from "react";
import { Container } from "react-bootstrap";
import hero_img from "@images/bg/jas_hero_9.jpg";

export default function TermsAndConditions() {
  return (
    <>
      <HeroSection img={hero_img} title="Terms and Conditions" />
      <Container className="mt-4">
        <h5>
          <strong>TERMS AND CONDITIONS</strong>
        </h5>
        <br />
        <ul>
          <li>
            <p className="big-label">
              &middot;Not all applicants would be selected for the
              entrepreneurship training
            </p>
          </li>
          <li>
            <p className="big-label">
              &middot;Progression through the various levels of training would
              be based on a criteria determined by GEA
            </p>
          </li>
          <li>
            <p className="big-label">
              &middot;Business Startup Grant support would be determined by a
              criteria provided by the Grants Committee of GEA
            </p>
          </li>
        </ul>
        <h6>
          <strong>Grant funds cannot be used for:</strong>
        </h6>
        <br />
        <ul>
          <li>
            <p className="big-label">
              Production or trade in any product or activity deemed illegal
              under Ghana&rsquo;s laws or regulations or international
              conventions and agreements, or subject to international bans, such
              as pharmaceuticals, pesticides/herbicides, ozone depleting
              substances, PCB&apos;s (polychlorinated biphenyls), wildlife or
              products regulated under CITES (i.e. Convention on International
              Trade in Endangered Species of Wild Fauna and Flora).
            </p>
          </li>
          <li>
            <p className="big-label">Production or trade in weapons and ammunitions.</p>
          </li>
          <li>
            <p className="big-label">
              Production or trade in alcoholic beverages (excluding beer and
              wine).
            </p>
          </li>
          <li>
            <p className="big-label">Production or trade in tobacco.</p>
          </li>
          <li>
            <p className="big-label">Gambling, casinos and equivalent enterprises.</p>
          </li>
          <li>
            <p className="big-label">
              Production or trade in radioactive materials. This does not apply
              to the purchase of medical equipment, quality control
              (measurement) equipment and any equipment where World Bank
              considers the radioactive source to be trivial and/or adequately
              shielded.
            </p>
          </li>
          <li>
            <p className="big-label">
              Production or trade in unbonded asbestos fibers. This does not
              apply to purchase and use of bonded asbestos cement sheeting where
              the asbestos content is less than 20%.
            </p>
          </li>
          <li>
            <p className="big-label">
              Drift net fishing in the marine environment using nets in excess
              of 2.5km. in length.
            </p>
          </li>
          <li>
            <p className="big-label">
              Production or activities involving harmful or exploitative forms
              of forced labor/harmful child labor.
            </p>
          </li>
          <li>
            <p className="big-label">
              Commercial logging operations for use in primary tropical moist
              forest.
            </p>
          </li>
          <li>
            <p className="big-label">
              Production or trade in wood or other forestry products other than
              from sustainably managed forests.
            </p>
          </li>
          <li>
            <p className="big-label">
              Production, trade, storage, or transportation of significant
              volumes of hazardous chemicals, or commercial scale usage of
              hazardous chemicals. Hazardous chemicals include gasoline,
              kerosene, and other petroleum products.
            </p>
          </li>
          <li>
            <p className="big-label">
              Production or activities that impinge on the lands owned, or
              claimed under adjudication, by Indigenous Peoples, without full
              documented consent of such peoples.
            </p>
          </li>
          <li>
            <p className="big-label">Any activities involving involuntary resettlement;</p>
          </li>
          <li>
            <p className="big-label">
              Any activities that would lead to conversion or degradation of
              critical natural habitats or their supporting areas;
            </p>
          </li>
          <li>
            <p className="big-label">
              Any activities that would lead to conversion or degradation of
              critical forest areas, related critical natural habitats, clearing
              of forests or forest ecosystems;
            </p>
          </li>
          <li>
            <p className="big-label">
              Activities involving the financing the rehabilitation or
              construction of dams or, activities that depend on the performance
              of an existing dam; and
            </p>
          </li>
          <li>
            <p className="big-label">Artisanal and small-scale mining.</p>
          </li>
        </ul>
      </Container>
    </>
  );
}
