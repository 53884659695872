
import React from "react";
import LoanApplicationLayout from "@components/layout/layouts/LoanApplicationLayout/LoanApplicationLayout";
import BusinessFinancialForm from "@components/forms/Loan/BusinessFinancialForm";


export default function BusinessFinancialInfo() {
    
    return(
        <LoanApplicationLayout page={3}>
            <BusinessFinancialForm/>
        </LoanApplicationLayout>              
    )

}


