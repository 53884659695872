/*eslint-disable*/
import React from 'react';
import GrantFormBaseLayout from './GrantFormBaseLayout';
import { Row, Col } from 'react-bootstrap';
import { Tab, Tabs, styled } from '@mui/material';
import { useGetPreviewGrantForm } from './services/PreviewGrantForm.service';
import GrantsPersonalInformation from './components/forms/GrantForm/GrantsPersonalInformation';
import GrantsPersonalInformation2 from './components/forms/GrantForm/GrantsPersonalInformation2';
import BusinessForm from './components/forms/GrantForm/BusinessForm';
import BusinessForm2 from './components/forms/GrantForm/BusinessForm2';
import ProductInformationForm from './components/forms/GrantForm/ProductionInformationForm';
import ProductInformation2 from './components/forms/GrantForm/ProductInformation2';
import TechnicalCapacity from './components/forms/GrantForm/TechnicalCapacity';
import ProductMarketing from './components/forms/GrantForm/ProductMarketing';
import RevenueGeneration from './components/forms/GrantForm/RevenueGeneration';
import RevenueGeneration2 from './components/forms/GrantForm/RevenueGeneration2';
import FundingRequirement from './components/forms/GrantForm/FundingRequirement';
import ImpactForm from './components/forms/GrantForm/ImpactForm';
import ImpactForm2 from './components/forms/GrantForm/ImpactForm2';
import MainButton from '@/components/buttons/Buttons';
import { useNavigate, useLocation } from 'react-router-dom';

export const PreviewTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    borderBottom: 'none',
  },
  '& .MuiTab-root': {
    borderBottom: `0px solid ${theme.palette.background.paper}`,
  },
  '& .MuiTab-root.Mui-selected': {
    borderBottom: 'none',
  },
}));

export const PreviewTab = styled(Tab)(({ theme }) => ({
  '&.Mui-selected': {
    borderBottom: '0px',
    color: '#2A3B8F',
  },
  textTransform: 'initial',
}));

const PreviewGrantForm = () => {
  const {
    tabValue,
    formStages,
    printState,
    handleTabChange,
    setTabValue,
    setPrintState,
    printForm,
    ...props
  } = useGetPreviewGrantForm();
  const navigate = useNavigate();

  return (
    <GrantFormBaseLayout title="Preview" page={9}>
      <Row>
        <Col md={12} xs={12} style={{ marginBottom: '40px' }}>
          <PreviewTabs
            value={tabValue}
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { display: 'none' } }}
          >
            {formStages.map((stage, index) => {
              return (
                <PreviewTab
                  value={stage.value}
                  key={`${index}`}
                  label={stage.label}
                />
              );
            })}
          </PreviewTabs>
        </Col>
        <Col md={12} xs={12} sm={12} style={{ marginBottom: '20px' }}>
          <MainButton
            text={'Print Form'}
            type={'button'}
            onClick={() => {
              setPrintState('print');
            }}
          />
        </Col>
        <Col md={12} xs={12} sm={12}>
          {(tabValue == 0 || printState == 'print') && (
            <div
              className="html2pdf__page-break"
              ref={props.personalInformationRef}
            >
              <GrantsPersonalInformation
                isPreview={true}
                isPrint={printState == 'print'}
              />
            </div>
          )}
          {(tabValue == 1 || printState == 'print') && (
            <div
              className="html2pdf__page-break"
              ref={props.personalInformation2Ref}
            >
              <GrantsPersonalInformation2
                isPreview={true}
                isPrint={printState == 'print'}
              />
            </div>
          )}
          {(tabValue == 2 || printState == 'print') && (
            <div className="html2pdf__page-break" ref={props.businessFormRef}>
              <BusinessForm isPreview={true} isPrint={printState == 'print'} />
            </div>
          )}
          {(tabValue == 3 || printState == 'print') && (
            <div className="html2pdf__page-break" ref={props.businessFormRef}>
              <BusinessForm2 isPreview={true} isPrint={printState == 'print'} />
            </div>
          )}
          {(tabValue == 4 || printState == 'print') && (
            <div
              className="html2pdf__page-break"
              ref={props.productInformationRef}
            >
              <ProductInformationForm
                isPreview={true}
                isPrint={printState == 'print'}
              />
            </div>
          )}
          {(tabValue == 5 || printState == 'print') && (
            <div
              className="html2pdf__page-break"
              ref={props.productInformation2Ref}
            >
              <ProductInformation2
                isPreview={true}
                isPrint={printState == 'print'}
              />
            </div>
          )}
          {(tabValue == 6 || printState == 'print') && (
            <div
              className="html2pdf__page-break"
              ref={props.technicalCapacityRef}
            >
              <TechnicalCapacity
                isPreview={true}
                isPrint={printState == 'print'}
              />
            </div>
          )}
          {(tabValue == 7 || printState == 'print') && (
            <div
              className="html2pdf__page-break"
              ref={props.productMarketingRef}
            >
              <ProductMarketing
                isPreview={true}
                isPrint={printState == 'print'}
              />
            </div>
          )}
          {(tabValue == 8 || printState == 'print') && (
            <div
              className="html2pdf__page-break"
              ref={props.revenueGenerationRef}
            >
              <RevenueGeneration
                isPreview={true}
                isPrint={printState == 'print'}
              />
            </div>
          )}
          {(tabValue == 9 || printState == 'print') && (
            <div
              className="html2pdf__page-break"
              ref={props.revenueGeneration2Ref}
            >
              <RevenueGeneration2
                isPreview={true}
                isPrint={printState == 'print'}
              />
            </div>
          )}
          {(tabValue == 10 || printState == 'print') && (
            <div
              className="html2pdf__page-break"
              ref={props.fundingRequirementsRef}
            >
              <FundingRequirement
                isPreview={true}
                isPrint={printState == 'print'}
              />
            </div>
          )}
          {(tabValue == 11 || printState == 'print') && (
            <div className="html2pdf__page-break" ref={props.impactRef}>
              <ImpactForm isPreview={true} isPrint={printState == 'print'} />
            </div>
          )}
          {(tabValue == 12 || printState == 'print') && (
            <div className="html2pdf__page-break" ref={props.impact2Ref}>
              <ImpactForm2 isPreview={true} isPrint={printState == 'print'} />
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <div className="d-flex  align-items-center h-100">
          <Col md={12}>
            <Row className="mt-5">
              <Col sm={12} md={4}>
                <MainButton
                  text={'Previous'}
                  type={'button'}
                  variant="outline-secondary"
                  size="lg"
                  className="fs-6"
                  onClick={() => {
                    if (tabValue !== 0) {
                      setTabValue(tabValue - 1);
                      return;
                    }
                    navigate('/grants/impact2');
                  }}
                />
              </Col>
              <Col sm={12} md={{ span: 4, offset: 4 }}>
                <MainButton
                  text={tabValue == 12 ? 'Submit' : 'Next'}
                  type={'button'}
                  variant="primary"
                  size="lg"
                  className="fs-6"
                  onClick={() => {
                    if (tabValue !== 12) {
                      setTabValue(tabValue + 1);
                      return;
                    }
                    navigate('/dashboard');
                  }}
                />
              </Col>
            </Row>
          </Col>
        </div>
      </Row>
    </GrantFormBaseLayout>
  );
};

export default PreviewGrantForm;
