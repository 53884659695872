import React, { Fragment } from "react";
import { TextField } from "@components/forms";
import AuthLayout from "@components/layout/AuthLayout";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoginForm from "@components/forms/Auth/LoginForm";

const Login = () =>{
    return(
        <AuthLayout>
            <Col sm={12} xs={12} md={6} className="auth-right" style={{justifyContent:'space-between'}}>
                <Row className="text-right">
                    <Col style={{paddingRight:'5rem'}}>
                        <TextField text={<Fragment>New here?<Link to="/get_started" className="text-decoration-none mx-1 custom-link"> Start application</Link></Fragment>} className=" float-end fw-bold mb-5 mt-5 "/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <LoginForm/>
                    </Col>
                </Row>
            </Col>
        </AuthLayout>
    )
}


export default Login