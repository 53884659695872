// import RoundCircleUserImage from "@components/layout/layouts/RoundedCircleUserImage";
import RoundedCircleUserName from "@components/layout/layouts/RoundedCircleUserName";
import React from "react";
import { Navbar, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function DashboardNavbar() {
  const navigate = useNavigate();
  const user = JSON.parse(window.localStorage.getItem("user"));

  return (
    <Navbar bg="light" variant="light" className="small-shadow m-0 p-0">
      <Container fluid>
        <Navbar.Brand href="#home" onClick={()=>{navigate(-1)}}>
          <i className="fa fa-solid fa-angle-left blue-text"></i>
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text className="mx-3">
            <i className="fa fa-solid fa-bell me-2"></i>
            {user?.first_name || user?.user?.first_name}
          </Navbar.Text>
          <Navbar.Text>
            {/* <RoundCircleUserImage image="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" /> */}
            {user && (
              <RoundedCircleUserName initials={user?.user?.first_name[0]} />
            )}
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
