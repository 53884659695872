/*eslint-disable*/
import ControlledTextInput from '@/components/forms/FormInput/ControlledTextInput';
import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import CustomFileUpload from './CustomFileUpload';
import GrantAccordionBase from './GrantAccordionBase';
import RevenueInput from './RevenueInput';
import { useForm } from 'react-hook-form';
import { IExpenditureComponent } from '../GrantForm.interfaces';
import TableInputSaveButton from './TableInputSaveButton';

const ExpenditureComponent = ({
  item,
  quantity,
  total_amount_spent,
  unit_cost,
  id,
  isPreview,
  isPrint,
  onEdit,
  onDelete,
}: IExpenditureComponent) => {
  const { control, watch } = useForm();
  const [editState, setEditState] = React.useState(false);

  return (
    <GrantAccordionBase
      expandable
      title={item || ''}
      actions={['delete']}
      onDelete={onDelete}
      isStacked={true}
    >
      <Row>
        <Col md={12} xs={12} style={{ marginBottom: '20px' }}>
          {!isPreview && !isPrint && (
            <TableInputSaveButton
              isEditing={editState}
              setIsEditing={setEditState}
              onEdit={onEdit}
              params={{
                id: id,
                item: watch('item_name'),
                quantity: watch('item_quantity'),
                unit_cost: watch('unit_cost'),
                total_amount_spent: watch('amount'),
              }}
            />
          )}
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name="item_name"
            title={'Item'}
            placeholder="Item"
            defaultValue={item}
            isDisabled={!editState}
          />
        </Col>
        <Col md={6} xs={12}>
          <ControlledTextInput
            control={control}
            name="item_quantity"
            title={'Quantity'}
            type="number"
            placeholder="Enter Amount"
            defaultValue={quantity}
            isDisabled={!editState}
            min="1"
            rules={{
              validate: {
                valid: (v) => {
                  if (parseInt(v) > 1) {
                    return true;
                  }
                  return 'Cannot use a quantity less than 1';
                },
              },
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <RevenueInput
            control={control}
            name="unit_cost"
            title="Unit Cost"
            placeholder="Enter Amount"
            defaultValue={unit_cost}
            isDisabled={!editState}
            min="1"
            rules={{
              validate: {
                valid: (v) => {
                  if (parseInt(v) > 1) {
                    return true;
                  }
                  return 'Cannot use a quantity less than 1';
                },
              },
            }}
          />
        </Col>
        <Col md={6} xs={12}>
          <RevenueInput
            control={control}
            name="amount"
            title="Amount(GHS)"
            placeholder="Enter Amount"
            defaultValue={total_amount_spent}
            isDisabled={!editState}
            value={
              (parseInt(watch('unit_cost')) || 0) *
              (0 || parseInt(watch('item_quantity')))
            }
            min="1"
            rules={{
              validate: {
                valid: (v) => {
                  if (parseInt(v) > 1) {
                    return true;
                  }
                  return 'Cannot use a quantity less than 1';
                },
              },
            }}
          />
        </Col>
      </Row>
    </GrantAccordionBase>
  );
};

export default ExpenditureComponent;
